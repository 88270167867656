import MHCAlert, { SNACKBAR_TYPE } from "components/MHCAlert";
import MHCConfirm from "components/MHCConfirm";
import SearchModule from "components/SearchModule";
import { useSetStatus } from "context/statusContext";
import { useCheckBoxArchive } from "hooks/useCheckBoxArchive";
import { useCheckBoxAssign } from "hooks/useCheckBoxAssign";
import { usePermissionCheck } from "hooks/useCheckPermission";
import _ from "lodash";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchEmployeeName } from "redux/partners/partnersApi";
import { bulkAssign, bulkPermanentyDelete, fetchReviews } from "redux/reviews/api";
import PaginatedTable from "../../components/Table/PaginatedTable";
import config from "../../config";
import { MODULES } from "../../constants/modules";
import "./reviews.scss";
import {
  TableConstantsCancelled,
  TableConstantsR,
  TableConstantsRevieweArchive,
  TableConstantsReviewed,
} from "./TableConstants";
import { useHistory } from "react-router-dom";
import * as routes from "../../constants/routes";
import { Box, Modal } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { theme } from "theme";
import { GrDocumentCsv } from "react-icons/gr";
import { fetchCountriesData } from "redux/NewLocation/newLocation";
import Tooltip from "@mui/material/Tooltip";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  minHeight: "40%",
  bgcolor: "background.paper",
  p: 4,
  padding: "20px 32px 32px 32px",
};

const Reviews = ({ active, permissionList, isDeleted }: any) => {
  const [page, setPage] = useState(1);
  const [paginateBy, setPaginateBy] = useState(config.PAGE_SIZE);
  const [sortField, setSortField] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");
  const [searchQuery, setSearchQuery] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [confirmation, setConfirmation] = useState("");
  const [bulkAssignid, setBulkAssignId] = useState("");
  const [permanentlyDeletedIds, setPermanentlyDeletedIds] = useState({});
  const customIdd = "custom-id-yes";
  const setStatus = useSetStatus();
  let history = useHistory();

  const {
    data: productDataList,
    isFetching,
    refetch: fetchUserProductDataList,
  } = useQuery(
    ["projects", page, paginateBy, active, searchQuery, sortField, sortDirection, isDeleted],
    () => fetchReviews(page, paginateBy, active, searchQuery, sortField, sortDirection, isDeleted),
    { keepPreviousData: true },
  );

  const { data: countryDatas } = useQuery(["country"], () => {
    return fetchCountriesData();
  });
  const countryData = countryDatas && !_.isEmpty(countryDatas.data) ? countryDatas?.data : [];

  const { mutate: bulkAssignReviews } = useMutation(bulkAssign, {
    onSuccess() {
      toast("Assigned Successfully", {
        toastId: customIdd,
        autoClose: 3000,
        pauseOnFocusLoss: false,
      });
      setBulkAssignId("");
      fetchUserProductDataList();
    },
    onError(error) {
      let a = error as any;
      toast(a.response?.data?.message, {
        toastId: customIdd,
        autoClose: 3000,
        pauseOnFocusLoss: false,
      });
    },
  });

  const { mutate: bulkPermanentDeletion } = useMutation(bulkPermanentyDelete, {
    onSuccess() {
      setCheckedArchive([]);
      fetchUserProductDataList();
      setStatus(SNACKBAR_TYPE.SUCCESS, "Review Permanently Deleted");
    },
    onError(error) {
      setStatus(SNACKBAR_TYPE.ERROR, (error as any)?.response?.data?.description?.errors);
    },
  });

  const pageData = productDataList && !_.isEmpty(productDataList.data?.data) ? productDataList.data?.data : [];

  const modData = pageData.map((item: any) => {
    const countryId = item?.user_profile?.country;
    const countryNames = countryData.find((item: any) => item.id === countryId);
    const countryName = countryNames ? countryNames.name : "";
    return {
      User: item.user_profile.full_name,
      DOB: item?.date_of_birth ? item.date_of_birth : null,
      Contact_Number: item.user_profile.contact_number,
      Email: item.user_profile?.email,
      Country: countryName,
      Assigned_To: item.assigned_to,
    };
  });

  const handleExport = () => {
    const fs = require("fs");
    const path = require("path");
    const Papa = require("papaparse");

    const csv = Papa.unparse(modData);

    if (typeof window !== "undefined") {
      // Create a download link in browser environment
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "PatientsData.csv";
      link.click();
    } else {
      // Get the user's downloads directory
      const downloadsPath = path.join(require("os").homedir(), "Downloads");

      // Create the file path
      const filePath = path.join(downloadsPath, "PatientsDetail.csv");

      // Write the CSV data to the file
      fs.writeFileSync(filePath, csv);
      console.log(`CSV data written to file: ${filePath}`);
    }
  };

  const pageCount = (productDataList && productDataList.data?.page_count) || 0;

  const totalCount = (productDataList && productDataList.data?.total_count) || 0;

  const current_page_number = (productDataList && productDataList.data?.current_page_number) || 0;

  const { checkAssign, checkAll, checked, allChecked, setChecked, setAllChecked } = useCheckBoxAssign(
    page,
    pageData,
    refresh,
    setRefresh,
  );

  const {
    checkSelectArchive,
    checkAllArchive,
    checkedArchive,
    allCheckedArchive,
    setCheckedArchive,
    setAllCheckedArchive,
  } = useCheckBoxArchive(page, pageData, refresh, setRefresh);

  // const fetchforwardTo = async () => {
  //   axios
  //     .get(`https://mhs-dev.myhairscope.com/employee/list_approved_employees_names`)
  //     .then((res) => {
  //       setForwardTo(res?.data?.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // useEffect(() => {
  //   fetchforwardTo();
  // }, [active]);

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleClose = () => setModalOpen(false);

  const { data: forwardTo } = useQuery(["fetchEmployeeName"], fetchEmployeeName);

  const handlePagination = (page: number) => {
    setPage(page);
  };
  const handleSearch = (SearchKey: any) => {
    setSearchQuery(SearchKey);
  };

  const handleEntries = (pagesize: number) => {
    setPaginateBy(pagesize);
  };
  const handleSort = (key: string, direction: string) => {
    setSortField(key);
    setSortDirection(direction);
  };

  const handleview = (row: any) => {
    // setView(row);
  };

  const handleAssignChecked = () => {
    bulkAssignReviews({ ids: checked, assigned_to: parseInt(bulkAssignid) });
  };
  const handleConfirmation = () => {
    if (confirmation === "bulkAssign") {
      handleAssignChecked();
    } else if (confirmation === "bulkDelete") {
      if (checkedArchive?.length) {
        handlePermanentDeletionAPI(checkedArchive);
      } else {
        handlePermanentDeletionAPI(permanentlyDeletedIds);
      }
    }
    setConfirmation("");
  };

  //ToDo: use usePermissionCheck()
  const can_assign = true;

  const handlePermanentDeletion = (values: any) => {
    setPermanentlyDeletedIds(values);
    setConfirmation("bulkDelete");
  };
  const handlePermanentDeletionAPI = (values: any) => {
    console.log({ ids: [values] });
    bulkPermanentDeletion({ ids: values });
  };

  return (
    <>
      <Modal open={modalOpen} onClose={handleClose}>
        <Box sx={style}>
          <div className="closeCommentModal">
            <CloseIcon
              titleAccess="Close Model"
              sx={{ fontSize: "250%", color: theme.palette.primary.main, cursor: "pointer" }}
              onClick={handleClose}
            />
          </div>
          <div>
            <div style={{ fontWeight: "bold", color: theme.palette.primary.main }}>Review Photos Priority</div>
            <div className="red-badge">High priority: R1</div>
            <div className="yellow-badge">Normal priority: R2 </div>
            <div className="">No review</div>
          </div>
        </Box>
      </Modal>
      <MHCConfirm
        open={confirmation}
        message={
          confirmation === "bulkAssign"
            ? "Are you sure you want to bulk assign these?"
            : "Are you sure you want to permanently delete the selected Id(s)?"
        }
        title={confirmation === "bulkAssign" ? "Assign Confirmation" : "Delete Confirmation"}
        onCancel={() => setConfirmation("")}
        onConfirm={handleConfirmation}
        yesLabel={confirmation === "bulkAssign" ? "Assign" : "Delete"}
        noLabel="Cancel"
      />

      <div className="page">
        {/* <div style={{ direction: "rtl" }}>
          <Tooltip title="Export user Info">
            <GrDocumentCsv color="#878787" onClick={handleExport} />
          </Tooltip>
        </div> */}

        <div>
          <div className="module-header">
            <div className="module-name"> &nbsp; Reviews</div>
            <MHCAlert />
            <ToastContainer />
            <div className="module-action">
              {checked?.length > 0 && active === "1" && can_assign ? (
                <button
                  disabled={!bulkAssignid}
                  className="module-approve-button"
                  onClick={() => setConfirmation("bulkAssign")}
                >
                  Assign
                </button>
              ) : null}
              {checked.length > 0 && active === "1" && can_assign ? (
                <select
                  onChange={(e) => {
                    setBulkAssignId(e.target.value);
                  }}
                >
                  <option value="">---</option>
                  {forwardTo?.map((item: any, index) => (
                    <option key={`fowrward_index_${index}`} value={item.id}>
                      {item.first_name} {item.middle_name} {item.last_name}
                    </option>
                  ))}
                </select>
              ) : null}
              {checkedArchive.length > 0 && active === "" && can_assign ? (
                <button className="module-approve-button" onClick={() => setConfirmation("bulkDelete")}>
                  Delete
                </button>
              ) : null}
              <SearchModule handleSearch={handleSearch} />
            </div>
          </div>
          <PaginatedTable
            cols={
              active === "0"
                ? TableConstantsReviewed(handleview)
                : !isDeleted
                ? active === "1"
                  ? TableConstantsR(handleview, checkAssign, checked, active, handleOpenModal, countryData)
                  : TableConstantsCancelled()
                : TableConstantsRevieweArchive(handlePermanentDeletion, checkSelectArchive, checkedArchive)
            }
            data={pageData}
            isDark={false}
            bordered
            striped={false}
            hoverable
            entries={paginateBy}
            onRowClick={(item: any) => {
              // set to local storage
              sessionStorage.setItem("review_data", JSON.stringify(item));

              if (item?.status === "UPLOADING") {
                history.push({ pathname: routes.VIEW_REVIEWS_PENDING, state: { data: item } });
              } else if (item?.status === "REVIEWED") {
                history.push({ pathname: routes.VIEW_REVIEWS_REVIEWED, state: { data: item } });
              } else if (item?.status === "CANCELLED") {
                history.push({ pathname: routes.VIEW_REVIEWS_CANCELLED, state: { data: item } });
              }
            }}
            //selected={selectedRowID}
            componentName={MODULES.REVIEWS}
            handlePagination={handlePagination}
            handleEntries={handleEntries}
            currentPage={current_page_number}
            totalCount={totalCount}
            size="lg"
            isDataLoading={isFetching}
            handleSort={handleSort}
            isPaginationRequired={true}
            allChecked={isDeleted ? allCheckedArchive : allChecked}
            checkAll={isDeleted ? checkAllArchive : checkAll}
            initialSortConfig={{ key: sortField, direction: sortDirection }}
          />
        </div>
      </div>
    </>
  );
};

export default Reviews;
