import HqmIcon from "components/icon";
import eye from "../../assets/brand/eye.png";
import mhcTheme from "../../assets/style/style.module.scss";

export const TableConstantsR = (handleClick: Function) => {
  return [
    {
      title: "User",
      name: "first_name",
      sortable: true,
      render: (rowData: any) => {
        const name = [rowData?.first_name, rowData?.middle_name, rowData?.last_name].filter((x) => x).join(" ");
        return <span>{name}</span>;
      },
    },
    {
      title: "Points",
      name: "total_referral_points",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.total_referral_points}</span>;
      },
    },
    {
      title: "Last Update Date",
      name: "last_updated",
      sortable: true,
      render: (rowData: any) => {
        const lastupdate = String(new Date(rowData?.last_updated).toLocaleString());
        return <span>{lastupdate}</span>;
      },
    },
    {
      title: "Action",
      name: "action",
      sortable: false,
      render: (rowData: any) => {
        return (
          <HqmIcon name="eye" size={20} style={{ color: mhcTheme.secondary }} onClick={() => handleClick(rowData)} />
        );
      },
    },
  ];
};

//<HqmIcon name="eye" size={20} style={{color: mhcTheme.secondary}} onClick={()=>handleClick(rowData)}/>
