import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { theme } from "theme";
import "./styles.scss";

const DatePick = forwardRef((props: any, ref) => {
  const [viewDate, setViewDate] = useState<any>(moment(new Date()).format("YYYY-MM-DD"));
  const [pickDate, setPickDate] = useState<any>(moment(viewDate).format("YYYY-MM"));
  const [pickerTheme, setPickerTheme] = useState({ bg: "#fff", color: "#000" });
  const { onChange } = props;

  useImperativeHandle(ref, () => ({
    handleNext,
    handlePrevious,
    pickDate,
  }));

  function getRandomColor(month: number) {
    const colors = [
      { bg: theme.palette.primary.main, color: "#FFFFFF" },
      { bg: "#fff", color: "#000" },
    ];
    if (month % 2 === 0) {
      return colors[0];
    }
    return colors[1];
  }

  const handleChange = (target: any) => {
    const selectedDate = new Date(target);

    const month = moment(selectedDate).format("MM");

    const newColor = getRandomColor(Number(month)); // get a random color
    setPickerTheme(newColor);

    if (!selectedDate) {
      const date = new Date();
      const formatted = moment(date).format("YYYY-MM");
      setPickDate(formatted);
      return;
    }

    setViewDate(selectedDate);
    setPickDate(moment(selectedDate)?.format("YYYY-MM"));
  };

  const handleNext = (props: any) => {
    //pickdate needs to be increased by one
    // console.log(pickDate.getMonth() + 1);
    const updatedDate = new Date(pickDate);

    const finalDate = new Date(updatedDate.getFullYear(), updatedDate.getMonth() + 1);
    const pickDateUpdate = moment(finalDate).format("YYYY-MM");
    // get motn
    const month = moment(finalDate).format("MM");
    setPickDate(pickDateUpdate);
    setViewDate(pickDateUpdate);
    const newColor = getRandomColor(Number(month)); // get a random color
    setPickerTheme(newColor);
  };

  const handlePrevious = (props: any) => {
    //pickdate needs to be increased by one

    const updatedDate = new Date(pickDate);
    // console.log(updatedDate.getMonth() + 1);
    const finalDate = new Date(updatedDate.getFullYear(), updatedDate.getMonth() - 1);
    const pickDateUpdate = moment(finalDate).format("YYYY-MM");
    setPickDate(pickDateUpdate);
    setViewDate(pickDateUpdate);
    const month = moment(finalDate).format("MM");
    // onChange?.(pickDateUpdate);
    const newColor = getRandomColor(Number(month)); // get a random color
    setPickerTheme(newColor);
  };
  React.useEffect(() => {
    onChange?.(pickDate);
  }, [onChange, pickDate]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          views={["year", "month"]}
          openTo="month"
          value={viewDate}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              sx={{
                width: "15rem",
                backgroundColor: pickerTheme?.bg,
                "& input": { color: pickerTheme.color },
                "& .MuiOutlinedInput-input": {
                  padding: "10px",
                  caretColor: "transparent",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  top: 0,
                },
                "& .MuiOutlinedInput-notchedOutline legend": {
                  display: "none",
                },
              }}
              InputProps={{
                disabled: true,
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: false,
                sx: { display: "none" },
              }}
              {...params}
              helperText={null}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
});

export default DatePick;
