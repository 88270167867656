export const MODULES = {
  EMPLOYEES: "EMPLOYEES",
  PARTNERS: "PARTNERS",
  REVIEWS: "REVIEWS",
  REVIEWS_VIEW: "REVIEWS_VIEW",
  REFERRAL: "REFERRAL",
  REFERRAL_VIEW: "REFERRAL VIEW",
  DISCOUNT: "DISCOUNT",
  ASSIGNED: "ASSIGNED",
  USERS: "USERS",
  TREATMENT: "TREATMENT",
  PRODUCTS: "PRODUCTS",
};
