import { ISidebarAction, SIDEBAR_TOGGLE } from "./types";

type SIDEBAR_STATE = {
  isVisible: boolean;
};

const INITIAL_STATE = {
  isVisible: true,
};

export function sidebarReducer(state: SIDEBAR_STATE = INITIAL_STATE, action: ISidebarAction) {
  switch (action.type) {
    case SIDEBAR_TOGGLE:
      return { ...state, isVisible: action.payload === undefined ? !state.isVisible : action.payload };
    default:
      return state;
  }
}

export default sidebarReducer;
