// import { FC } from "react";

import MHCSpinner from "components/MHCSpinner";
import { auth } from "firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";
import { Redirect, Route, useLocation } from "react-router-dom";

function PrivateRoute({ children, ...rest }: any) {
  const location: any = useLocation();
  //const auth = useSelector((state: RootState) => state.loginReducer);
  const { from } = location.state || { from: { pathname: "/" } };

  const [user, loading, error] = useAuthState(auth);

  if (loading) return <MHCSpinner type="light" />;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              // state: from?.pathname === "profile" ? undefined : { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
