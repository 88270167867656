import { makeStyles } from "@material-ui/core";
import Logout from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { auth } from "firebase-config";
import { signOut } from "firebase/auth";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutSuccess } from "redux/actions";
import * as routes from "../../../constants/routes";
import { ReactComponent as Logo } from "assets/brand/logo.svg";
import "./PatientListDrawer.scss";
import { useIsAdmin } from "hooks/useCheckPermission";

type Anchor = "left";

const useStyles = makeStyles((theme) => ({
  rootBottom: {
    // width: "100%",
    // maxWidth: 360,
    // backgroundColor: theme.custom.white,
    // padding: '20px 0px 10px',
    padding: "90px 0px 10px",
  },
}));

type SideNavItems = {
  icon: React.ReactNode;
  text: string;
  to: string;
  show?: boolean;
};

export default function PatientListDrawer() {
  let history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const isAdmin = useIsAdmin();
  const [state, setState] = React.useState({
    left: false,
  });

  const sideNavItems: SideNavItems[] = [
    {
      icon: <PersonIcon />,
      text: "Patient List",
      to: routes.PATIENTS,
    },
    {
      icon: <PersonIcon />,
      text: "Reports",
      to: routes.PATIENTS_INACTIVE,
    },
    {
      icon: <PersonIcon />,
      text: "Admin ",
      to: routes.EMPLOYEES_ACTIVE,
      show: isAdmin,
    },
  ];

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "left" || anchor === "bottom" ? "auto" : 250, height: "100vh", position: "relative" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem sx={{ display: "flex", justifyContent: "flex-end" }}>
          <CloseIcon
            onClick={() => {
              setState((s) => {
                s.left = false;
                return s;
              });
            }}
          />
        </ListItem>
        <Divider></Divider>

        {sideNavItems.map((item, index) => {
          if (item.show === false) {
            return <></>;
          }

          return (
            <React.Fragment key={`${item.to}_${index}`}>
              <ListItem disablePadding onClick={() => history.push(item.to)}>
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
              <Divider />
            </React.Fragment>
          );
        })}
      </List>

      <List className={classes.rootBottom} sx={{ position: "absolute", bottom: 1, left: 0, width: "100%" }}>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText
              primary={"Log Out"}
              onClick={() =>
                signOut(auth).then((resp) => {
                  dispatch(logoutSuccess(null));
                  (window as any).location.href = "/";
                })
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      {(["left"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <MenuIcon style={{ color: "white" }} />
          </Button>
          <Logo className="brand-logo" />
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
