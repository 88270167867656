import { Redirect, Route, useLocation } from "react-router-dom";

import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";

import MHCSpinner from "components/MHCSpinner";
import * as routes from "constants/routes";
import { useIsAdmin, usePermissionCheck } from "hooks/useCheckPermission";
import React, { useContext } from "react";
import { permissionListAction } from "redux/Permission/PermissionAction";
import { PermissionResponse } from "redux/Permission/PermissionTypes";
import { fetchPermission } from "redux/home/api";
import { DashboardLayout, PhysicianLayout } from "./MainLayout";

const PermissionContext = React.createContext<any[]>([]);
export const usePermissions = () => useContext(PermissionContext);

const Home = () => {
  // const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { isLoading } = useQuery(["permissionList"], fetchPermission, {
    keepPreviousData: true,
    onSuccess: (res) => {
      const permissionGroups: PermissionResponse[] = res?.data;
      const permissionList = permissionGroups?.reduce((t: string[], c) => {
        return t.concat(c.permissions);
      }, []);
      dispatch(permissionListAction(permissionList));
    },
  });

  const permissionList = useSelector((state: RootState) => state.permissionReducer.permission_list);
  const isPhysicianRoute = location.pathname.includes("/home/physician");
  //used to check if the user has physician portal permission, needed to redirect the user to either physician portal or normal portal
  const isPhysician = permissionList.includes("is_professional_mode");

  const isAdmin = useIsAdmin();

  const renderLayout = () => {
    if (isPhysician && isAdmin) {
      return isPhysicianRoute ? <PhysicianLayout /> : <DashboardLayout />;
    } else if (isPhysician) {
      return <PhysicianLayout />;
    } else {
      return <DashboardLayout />;
    }
  };

  if (isLoading) return <MHCSpinner type="light" />;

  return (
    <PermissionContext.Provider value={permissionList}>
      {/* for physician  */}

      <Route exact path="/home" component={HomeRouter} />

      {/* for normal reviewer, qa, admin  */}
      {renderLayout()}
    </PermissionContext.Provider>
  );
};

export const HomeRouter = () => {
  const permissionList = usePermissions();
  const isPhysician = permissionList.includes("is_professional_mode");
  const isAdmin = useIsAdmin();

  const view_productdiscount = usePermissionCheck("view_productdiscount");
  const view_employee = usePermissionCheck("view_employee");
  const view_referral = usePermissionCheck("view_userprofile");
  const view_generalinformation = usePermissionCheck("view_generalinformation");
  const view_reviews = usePermissionCheck("view_hairimagereview");

  const redirectSwitch = () => {
    // if (view_employee) return <Redirect to={routes.EMPLOYEES_ACTIVE} />;
    // if (view_generalinformation) return <Redirect to={routes.PARTNERS_ACTIVE} />;
    // if (view_reviews) return <Redirect to={routes.REVIEWS_PENDING} />;
    // if (view_referral) return <Redirect to={routes.REFERRAL} />;
    // if (view_productdiscount) return <Redirect to={routes.DISCOUNT} />;
    // view_employee && history.push(routes.EMPLOYEES_PENDING);
    // return <FourOhFour />;
    return <Redirect to={"/home"} />;
  };

  if (isPhysician && isAdmin) {
    return redirectSwitch();
  } else if (isPhysician) {
    return <Redirect to={routes.PATIENTS} />;
  } else {
    return redirectSwitch();
  }
};

export default Home;
