import { Box, Modal } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import MHCConfirm from "components/MHCConfirm";
import SearchModule from "components/SearchModule";
import PaginatedTable from "components/Table/PaginatedTable";
import { useCheckBoxPatientList } from "hooks/useCheckBoxPatientList";
import { createContext, useContext, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchPatientsByStatus } from "redux/PatientInactive/api";
import { patient_pk_id, patient_user_profile_id, update_emr } from "redux/PhysicianPortalData/PhysicianPortalActions";
import { CLEAR } from "redux/PhysicianPortalData/PhysicianPortalTypes";
import { theme } from "theme";
import config from "../../../config/index";
import * as routes from "../../../constants/routes";
import "./styles.scss";
import { PatientListTable } from "./TableConstants";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  minHeight: "40%",
  bgcolor: "background.paper",
  p: 4,
  padding: "20px 32px 32px 32px",
};

const PatientActionCtx = createContext({
  setStatus: (status: string) => {},
  setRetention: (retention: string) => {},
});

export const usePatientAction = () => {
  return useContext(PatientActionCtx);
};

function PatientList() {
  let history = useHistory();
  const [paginateBy, setPaginateBy] = useState(config.PAGE_SIZE);
  const [page, setPage] = useState(1);
  const [sortField, setSortField] = useState("appointment_time");
  const [sortDirection, setSortDirection] = useState("desc");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const dispatch = useDispatch();

  const [confirmation, setConfirmation] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const [status, setStatus] = useState("");
  const [retention, setRetention] = useState("");

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const { data: patientListData, isFetching: isPatientDataFetching } = useQuery(
    ["patientList", page, paginateBy, searchQuery, sortField, sortDirection, status, retention],
    () => fetchPatientsByStatus(page, paginateBy, searchQuery, sortField, sortDirection, status, retention),
  );
  const pageData = patientListData?.data?.data;
  const handlePagination = (page: number) => {
    setPage(page);
  };
  const handleEntries = (pagesize: number) => {
    setPaginateBy(pagesize);
  };
  const handleSort = (key: string, direction: string) => {
    setSortField(key);
    setSortDirection(direction);
  };
  const [refresh, setRefresh] = useState(false);

  const current_page_number = patientListData?.data?.current_page_number || 0;
  const total_count = patientListData?.data?.total_count || 0;

  const handleSearch = (SearchKey: any) => {
    setSearchQuery(SearchKey);
  };

  // checkbox for patient list
  const { checkPatient, checkAll, checked, allChecked, setChecked, setAllChecked } = useCheckBoxPatientList(
    page,
    pageData,
    refresh,
    setRefresh,
  );
  const arrayOfObject = checked.map((i: any) => ({
    ...i,
    user_profile: i.user_profile.full_name,
    location: i.location.location_name,
    ContactNumber: i.user_profile.contact_number,
  }));

  const modifiedArray = arrayOfObject.map((item: any) => {
    const { id, retention_status, retention_status_updated_at, ...rest } = item;
    return rest;
  });
  const mod = modifiedArray.map((item: any) => {
    const modItems = { ...item, ClientName: item.user_profile, Clinic_Spa: item.location };
    delete modItems.user_profile;
    delete modItems.location;
    if (item.status === "ACTIVE") {
      modItems.status = "UNREVIEWED";
    } else {
      modItems.status = "REVIEWED";
    }
    return modItems;
  });

  // console.log("indicators", patientListData?.data?.data?.patient_treatment_status?.status);
  const handleCheckedExport = () => {
    const fs = require("fs");
    const path = require("path");
    const Papa = require("papaparse");

    const csv = Papa.unparse(mod);

    if (typeof window !== "undefined") {
      // Create a download link in browser environment
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "PatientsData.csv";
      link.click();
    } else {
      // Get the user's downloads directory
      const downloadsPath = path.join(require("os").homedir(), "Downloads");

      // Create the file path
      const filePath = path.join(downloadsPath, "PatientsData.csv");

      // Write the CSV data to the file
      fs.writeFileSync(filePath, csv);
      console.log(`CSV data written to file: ${filePath}`);
    }
  };

  const handleConfirmation = () => {
    if (confirmation === "Export") {
      handleCheckedExport();
    }
    setConfirmation("");
  };

  return (
    <PatientActionCtx.Provider value={{ setRetention, setStatus }}>
      <MHCConfirm
        open={confirmation}
        message={"The exported file will be in .csv file. Continue export?"}
        title={"Approval Confirmation"}
        onCancel={() => setConfirmation("")}
        onConfirm={handleConfirmation}
        yesLabel={"Yes"}
        noLabel="No"
      />
      <Modal open={modalOpen} onClose={handleClose}>
        <Box sx={style}>
          <div className="closeCommentModal">
            <CloseIcon
              titleAccess="Close Model"
              sx={{ fontSize: "250%", color: theme.palette.primary.main, cursor: "pointer" }}
              onClick={handleClose}
            />
          </div>
          <div>
            <div style={{ fontWeight: "bold", color: theme.palette.primary.main }}>Patient's Treatment Status</div>
            <div className="red-badge">Red -  Less than 8 diary entries in the past 30 days</div>
            <div className="yellow-badge">
              Yellow - More than 9 diary entries but less than 5 treatments used in the past 30 days
            </div>
            <div className="green-badge">Green - All up to date</div>
          </div>
        </Box>
      </Modal>

      <div className="patient-list-layout">
        <div className="module-header">
          {/* <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "3rem" }}> */}
          <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
            <div className="module-name">Patient List</div>
            <div>
              <button
                className="Report-Button"
                onClick={() => {
                  history.push(routes.PATIENTS_INACTIVE);
                }}
              >
                Go Back To Reports
              </button>
            </div>
          </div>

          {/* <button className="module-add-button" onClick={handleBackClick}>
            Back
          </button> */}
          {/* </div> */}
          <div className="module-action">
            <SearchModule handleSearch={handleSearch} />
          </div>
        </div>
        <PaginatedTable
          tableClass={"align-left"}
          cols={PatientListTable(handleOpenModal, checkPatient, checked)}
          data={pageData}
          isDark={false}
          bordered
          striped={false}
          hoverable
          entries={paginateBy}
          onRowClick={(item: any) => {
            if (item?.status === "ACTIVE") {
              const emr = item?.emr_number || item?.patient_id;
              dispatch(update_emr(emr));
              dispatch(patient_pk_id(item?.id));
              dispatch(patient_user_profile_id(item?.user_profile?.id));
              dispatch({ type: CLEAR });
              history.push({ pathname: routes.PATIENTDATA, state: { data: item } });
            } else {
              // dispatch(patient_pk_id(item?.id));
              // dispatch(patient_user_profile_id(item?.user_profile?.id));
              // history.push({ pathname: routes.PATIENT_COMPLETED_REPORT, state: { data: item, hideSave: true } });
              const emr = item?.emr_number || item?.patient_id;
              dispatch(update_emr(emr));
              dispatch(patient_pk_id(item?.id));
              dispatch(patient_user_profile_id(item?.user_profile?.id));
              dispatch({ type: CLEAR });
              history.push({ pathname: routes.PATIENT_REVIEWED_DATA, state: { data: item } });
            }
          }}
          componentName={"PATIENTS LIST"}
          handlePagination={handlePagination}
          handleEntries={handleEntries}
          currentPage={current_page_number}
          totalCount={total_count}
          size="lg"
          isDataLoading={isPatientDataFetching}
          isPaginationRequired={true}
          handleSort={handleSort}
          allChecked={allChecked}
          checkAll={checkAll}
          initialSortConfig={{ key: sortField, direction: sortDirection }}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <button
              className="Report-Button"
              onClick={() => {
                history.push(routes.PATIENTS_INACTIVE);
              }}
            >
              Go Back To Reports
            </button>
          </div>
          {checked?.length > 0 && (
            <div>
              <button className="Report-Button" onClick={() => setConfirmation("Export")}>
                Export
              </button>
            </div>
          )}
        </div>
      </div>
    </PatientActionCtx.Provider>
  );
}

export default PatientList;
