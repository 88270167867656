import { Paper } from "@material-ui/core";
import { auth } from "firebase-config";
import { sendPasswordResetEmail } from "firebase/auth";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { ReactComponent as Logo } from "../../assets/brand/logo.svg";
import { useLocation } from "react-router-dom";

const ForgotPassword = () => {
  const location = useLocation();
  const [redirectedEmail, setRedirectedEmail] = useState<string>("");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get("email") as string;
    setRedirectedEmail(email);
  }, [location.search]);
  const [submitted, setSubmitted] = useState(false);

  const actionCodeSettings = {
    url: `${window.location.origin}`,
    handleCodeInApp: true,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { email: "" },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please enter email"),
    }),
    onSubmit: async (values: any) => {
      //setSubmitted(true);
      // login(values);
      actionCodeSettings.url = actionCodeSettings.url + "?email=" + values?.email;
      try {
        await sendPasswordResetEmail(auth, values?.email, actionCodeSettings);
        setSubmitted(true);
      } catch (e: any) {
        //formik.setFieldError("email",e?.message || "Something went wrong!");
        if (e.code === "auth/user-not-found") {
          formik.setFieldError("email", "User not found");
        } else {
          formik.setFieldError("email", e?.message || "Something went wrong!");
        }
      }
    },
  });

  return (
    <div className="container login-page">
      <div className="brand-section">
        <Logo className="login-page-logo" />
        <div className="brand-description">
          {window.location.href.includes("regimen360") ? (
            <span className="brand-name">
              Regimen 360 Treatment <br />
              and Compliance Portal
            </span>
          ) : (
            <>
              <span className="brand-name">MyHairCounts</span>
              <span className="app-platform">Web Portal</span>
            </>
          )}
        </div>
      </div>
      <div className="form-section reset-section">
        <Paper className="login-form-container">
          <div className="login-title">
            <span className="title">{submitted ? "Email sent" : "Reset Password"}</span>
          </div>
          <div className="login-form">
            <div className="form-group">
              <label className="reset-section-label">
                {submitted
                  ? "An email with instructions to reset your password has been sent."
                  : "Enter your email and we will send you a link to reset your password."}
              </label>
              {!submitted && (
                <input
                  className={
                    formik.errors && formik.errors?.email && formik.touched && formik.touched?.email
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  name="email"
                  value={formik.values?.email || redirectedEmail || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              )}
              {formik.errors && formik.errors?.email && formik.touched && formik.touched?.email && (
                <div className="invalid-feedback">{formik.errors?.email || ""}</div>
              )}
            </div>
          </div>
          {!submitted && (
            <div className="login-footer">
              <button className="login-submit" onClick={(e: any) => formik.handleSubmit(e)}>
                Send reset instructions
              </button>
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
};

export default ForgotPassword;
