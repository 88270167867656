import React from "react";
import ImageSlider from "react-image-comparison-slider";
import { theme } from "theme";
interface BAModelProps {
  bimg: string;
  next?: any;
  prev?: any;
  limg: string;
}
const BAModel = ({ bimg, next, prev, limg }: BAModelProps) => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ImageSlider
        image2={bimg}
        image1={limg}
        sliderColor={theme.palette.primary.main}
        handleColor={theme.palette.primary.main}
        onSlide={() => {
          console.log("sliding");
        }}
      />
    </div>
  );
};

export default BAModel;
