import _ from "lodash";
import {
  CLEAR,
  COMMENT_DATA,
  DRAFT_RECORD,
  DRAFT_RECORD_REMOVE,
  EMR_NO_UPDATE,
  PATIENT_PK_ID,
  PATIENT_USER_PROFILE_ID,
  PhysicianPortalTypes,
  TREATMENT_ADJUSTMENT_DATA,
  TREATMENT_DIRECTIVE_DATA,
  TREATMENT_REGIMEN_DATA,
} from "./PhysicianPortalTypes";

const initial_state: PhysicianPortalTypes = {
  patient_pk_id: 0,
  patient_user_profile_id: 0,
  treatment_adjustment_data: "",
  treatment_directive_data: "",
  comment_data: "",
  treatment_regimen_data: "",
  emr_no: "",
  draft_record: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export const physicianPortalReducer = (state: PhysicianPortalTypes = initial_state, action: any) => {
  const tempState = { ...state };
  switch (action.type) {
    case PATIENT_PK_ID: {
      return { ...state, patient_pk_id: action.payload };
    }
    case PATIENT_USER_PROFILE_ID: {
      return {
        ...state,
        patient_user_profile_id: action.payload,
      };
    }
    case COMMENT_DATA: {
      return {
        ...state,
        comment_data: action.payload,
      };
    }
    case TREATMENT_ADJUSTMENT_DATA: {
      return { ...state, treatment_adjustment_data: action.payload };
    }
    case TREATMENT_DIRECTIVE_DATA: {
      return {
        ...state,
        treatment_directive_data: action.payload,
      };
    }
    case TREATMENT_REGIMEN_DATA: {
      return {
        ...state,
        treatment_regimen_data: action.payload,
      };
    }
    case EMR_NO_UPDATE: {
      return {
        ...state,
        emr_no: action.payload,
      };
    }
    case CLEAR: {
      const { emr_no, comment_data, treatment_adjustment_data, treatment_directive_data, treatment_regimen_data } =
        initial_state;

      return {
        ...state,
        emr_no,
        comment_data,
        treatment_adjustment_data,
        treatment_directive_data,
        treatment_regimen_data,
      };
    }
    case DRAFT_RECORD: {
      const payload = action.payload;

      const userId = payload.patient_user_profile_id;

      tempState.draft_record = {
        ...tempState.draft_record,
        [userId]: {
          ...tempState.draft_record?.[userId],
          comment_data: payload.comment,
          treatment_adjustment_data: payload.treatment_adjustment,
          treatment_directive_data: payload.treatment_directives,
          treatment_regimen_data: payload.treatment_regimen,
        },
      };

      return tempState;
    }

    case DRAFT_RECORD_REMOVE: {
      const payload = action.payload;

      const userId = payload.id;

      if (_.get(tempState, `draft_record.${userId}`)) delete tempState.draft_record[userId];

      return tempState;
    }
    default:
      return state;
  }
};

export default physicianPortalReducer;
