import axios from "axios";
import { FilterOptions } from "hooks/useFilter";

export const fetchDiscount = async (filterOptions?: FilterOptions) => {
  return await axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method: "GET",
    url: `productdiscount/v2/`,
    params: {
      ...filterOptions,
    },
  });
};

export const postDiscount = async (discount: any) => {
  return await axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method: discount.id ? "PUT" : "POST",
    url: discount.id ? `productdiscount/v2/${discount?.id}/` : `productdiscount/v2/`,
    data: discount,
  });
};

export const deleteDiscount = async (id: number) => {
  return await axios({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "DELETE",
    url: `productdiscount/v2/${id}/`,
  });
};

export const bulkDeleteDiscount = async (data: { ids: Array<number> }) => {
  return await axios({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "DELETE",
    url: `productdiscount/bulk-delete/`,
    data: data,
  });
};

export const fetchPartnerData = async () => {
  const res = await axios({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "GET",
    url: "api/web/partner/approved-partners-names",
  });
  return res?.data;
};

export const fetchProduct = async () => {
  const res = await axios({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "GET",
    url: "api/rest/store/v2",
  });
  return res?.data;
};

export const fetchProducts = async () => {
  const res = await axios({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "GET",
    url: "api/rest/ps/v2/",
  });
  return res?.data;
};
