import store from "../index";
import {SET_ACCESS_TOKEN,CLEAR_TOKENS,LOGOUT_REQUEST} from "../redux/constants";

class TokenService {
    _setToken(tokenObj:any) {
      const auth = store;
        try{
          let user =  (auth[0].getState() as any).loginReducer.user;
          user.stsTokenManager.accessToken = tokenObj;
          auth[0].dispatch({type:SET_ACCESS_TOKEN,payload:user} as any);
        }catch(err){
        }
    }
    _getAccessToken() {
       const auth = store;
        try{
        return (auth[0].getState() as any)?.loginReducer?.user?.stsTokenManager?.accessToken;
        }catch(e){
          return null;
        }
    }
    _getRefreshToken() :any{
      const auth = store;
        try{
        return (auth[0].getState() as any).loginReducer.user.data.data.refresh;
        }catch(e){
          return null;
        }
    }
    _clearToken() {
        const auth = store;
          try{
          let user =  (auth[0].getState() as any).loginReducer.user;
          user.data.data.access = "";
          user.data.data.refresh = "";
          auth[0].dispatch({type:CLEAR_TOKENS,payload:user} as any);
          auth[0].dispatch({type:LOGOUT_REQUEST} as any);          
          }catch(e){
  
          }
    }
   }
   export default TokenService;