import { useState } from "react";
export const useCheckBoxPatientList = (page: number, pageData: Array<any>, refresh: boolean, setRefresh: Function) => {
  const [checked, setChecked] = useState<Array<number>>([]);
  const [allChecked, setAllChecked] = useState<Map<number, boolean>>(new Map().set(1, false));
  const checkPatient = (id: number) => {
    let newChecked = [...checked];
    const dataItem = pageData.find((item) => item.id === id);
    if (checked.includes(dataItem)) {
      newChecked = newChecked.filter((i) => i !== dataItem);
    } else {
      newChecked.push(dataItem);
    }
    setChecked(newChecked);
  };
  const checkAll = (page: number) => {
    let newChecked = [...checked];
    if (!allChecked.has(page) || (allChecked.has(page) && allChecked.get(page) === false)) {
      pageData.forEach((dataItem) => {
        if (!newChecked.includes(dataItem)) {
          newChecked.push(dataItem);
        }
      });
      setAllChecked(allChecked.set(page, true));
    } else {
      pageData.forEach((dataItem) => {
        if (newChecked.includes(dataItem)) {
          newChecked = newChecked.filter((i) => i !== dataItem);
        }
      });
      setAllChecked(allChecked.set(page, false));
    }
    setChecked(newChecked);
  };
  return { checkPatient, checkAll, checked, allChecked, setChecked, setAllChecked };
};
