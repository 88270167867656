import InfoIcon from "@mui/icons-material/Info";
import { Stack } from "@mui/material";
import crossedCalenderIcon from "assets/brand/crossCalender.svg";
import { ReactComponent as laser } from "components/SummaryTable/assets/laser.svg";
import { ReactComponent as oral } from "components/SummaryTable/assets/oral.svg";
import { ReactComponent as shampoo } from "components/SummaryTable/assets/shampoo.svg";
import ProductCategoryEnum from "components/SummaryTable/Utility/ProductCategoryEnum";
import moment from "moment";

const imageMap = {
  [ProductCategoryEnum.LASER]: laser,
  [ProductCategoryEnum.ORAL]: oral,
  [ProductCategoryEnum.SHAMPOO]: shampoo,
};

const LegendIcon = (props: { icon: ProductCategoryEnum }) => {
  const Icon = imageMap[props.icon];
  return <Icon className="calender-icon-svg legend-icon" />;
};

export const PatientHistoryTable = () => {
  return [
    {
      title: "Name",
      name: "product_name",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.treatment_product?.product_name}</span>;
      },
    },
    {
      title: "Start Date",
      name: "start_date",
      sortable: true,
      render: (rowData: any) => {
        return (
          <span>
            {rowData?.start_date ? (
              <>{moment(rowData?.start_date).format("MM/DD/YYYY")}</>
            ) : (
              <img src={crossedCalenderIcon} alt="calender cross" />
            )}
          </span>
        );
      },
    },
    {
      title: "End Date",
      name: "end_date",
      sortable: false,
      render: (rowData: any) => {
        return (
          <span>
            {rowData?.end_date ? (
              <> {moment(rowData?.end_date).format("MM/DD/YYYY")}</>
            ) : (
              <img src={crossedCalenderIcon} alt="calender cross" />
            )}
          </span>
        );
      },
    },
    {
      title: " Discontinuation Remark ",
      name: "remark",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.end_date ? rowData?.remarks : null}</span>;
      },
    },
    {
      title: "Type",
      name: "treatment_adjustment",
      sortable: false,
      render: (rowData: any) => {
        return (
          <span style={{ display: "-webkit-inline-box" }}>
            {rowData?.treatment_category?.category_code === "SHAMPOO" ? (
              <div className="calender-icon-history">
                <LegendIcon icon={ProductCategoryEnum.SHAMPOO} />
              </div>
            ) : rowData?.treatment_category?.category_code === "ORAL" ? (
              <div className="calender-icon-history">
                <LegendIcon icon={ProductCategoryEnum.ORAL} />
              </div>
            ) : rowData?.treatment_category?.category_code === "LASER" ? (
              <div className="calender-icon-history">
                <LegendIcon icon={ProductCategoryEnum.LASER} />
              </div>
            ) : null}
          </span>
        );
      },
    },
    {
      title: "Days",
      name: "end_date",
      sortable: false,
      render: (rowData: any) => {
        // return <span>{rowData?.comment}</span>;
        return (
          <span>
            {" "}
            {rowData?.end_date !== null ? (
              <>
                {" "}
                {Math.floor(
                  (new Date(rowData?.end_date).getTime() - new Date(rowData?.start_date).getTime()) /
                    (1000 * 60 * 60 * 24),
                )}
              </>
            ) : (
              <>
                {" "}
                {Math.floor((new Date().getTime() - new Date(rowData?.start_date).getTime()) / (1000 * 60 * 60 * 24))}
              </>
            )}
          </span>
        );
      },
    },
  ];
};
