import moment from "moment";

export const TableConstants = () => {
  return [
    {
      title: "Date",
      name: "created date",
      sortable: false,
      render: (rowData: any) => {
        let date_created = moment(rowData?.created_at).format("YYYY-MM-DD");
        return <span>{date_created}</span>;
      },
    },
    {
      title: "Credit",
      name: "credit",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.credit ? rowData?.credit : 0}</span>;
      },
    },
    {
      title: "Debit",
      name: "debit",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.debit ? rowData?.debit : 0}</span>;
      },
    },
    {
      title: "Net Amount",
      name: "net amount",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.net_amount ? rowData?.net_amount : 0}</span>;
      },
    },
    {
      title: "Type",
      name: "type",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.type ? rowData?.type : "N/A"}</span>;
      },
    },
    {
      title: "Message",
      name: "message",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.message}</span>;
      },
    },
  ];
};
