import { TableConstants } from "layouts/ReferralView/TableConstants";
import _ from "lodash";
import { useState } from "react";
import { useQuery } from "react-query";
import { fetchReferralView } from "redux/referralview/api";
import backicon from "../../assets/brand/goback.png";
import PaginatedTable from "../../components/Table/PaginatedTable";
import config from "../../config";
import { MODULES } from "../../constants/modules";
import "./referralview.scss";

const ReferralView = ({ view, setView }: any) => {
  const [page, setPage] = useState(1);
  const [paginateBy, setPaginateBy] = useState(config.PAGE_SIZE);

  const { data, isFetching } = useQuery(
    ["projects", view?.uuid, page, paginateBy],
    () => fetchReferralView(view?.uuid, page, paginateBy),
    { keepPreviousData: true },
  );

  const pageData = data && !_.isEmpty(data?.data?.data) ? data?.data?.data : [];

  const pageCount = (data && data.data?.page_count) || 0;

  const totalCount = (data && data.data?.total_count) || 0;

  const current_page_number = (data && data.data?.current_page_number) || 0;

  const handlePagination = (page: number) => {
    setPage(page);
  };

  const handleEntries = (pagesize: number) => {
    setPaginateBy(pagesize);
  };
  const handleSort = (key: string, direction: string) => {};
  const handleback = () => {
    setView(null);
  };

  return (
    <div className="page">
      <div className="referralview_horizontal_texts">
        <div className="back-icon">
          <span onClick={() => handleback()}>
            {" "}
            <img src={backicon} alt="/" />
          </span>{" "}
          <h6>Back to Points</h6>
        </div>
        <h5>Points detail for : {view?.first_name + " " + view?.last_name}</h5>
        <h5>Last Date Update : {String(new Date(view?.last_updated).toLocaleString().substring(0, 9))}</h5>
      </div>
      <PaginatedTable
        cols={TableConstants()}
        data={pageData}
        isDark={false}
        bordered
        striped={false}
        hoverable
        entries={paginateBy}
        onRowClick={() => {}}
        //selected={selectedRowID}
        componentName={MODULES.REFERRAL_VIEW}
        handlePagination={handlePagination}
        handleEntries={handleEntries}
        currentPage={current_page_number}
        totalCount={totalCount}
        size="lg"
        isDataLoading={isFetching}
        handleSort={handleSort}
        isPaginationRequired={true}
      />
    </div>
  );
};

export default ReferralView;
