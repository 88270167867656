import api from "api";

export const fetchPartners = async (
  page: number,
  paginateBy: number,
  active: boolean,
  search: any,
  sortField: string,
  sortDirection: string,
  isDeleted: boolean,
) => {
  return api({
    method: "GET",
    url: `/api/web/partner/v2/partner/?is_approved=${
      active ? "True" : "False"
    }&page_number=${page}&paginate_by=${paginateBy}&search=${search}&order_by=${sortField}&sort_order=${sortDirection}&is_deleted=${
      isDeleted ? "True" : "False"
    }`,
  });
};

export const postPartners = async (partner: any) => {
  return api({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: partner?.id ? "PUT" : "POST",
    url: partner?.id ? `partner/v2/partner/${partner?.id}/` : `partner/v2/`,
    data: partner,
  });
};

export const deletePartners = async (id: number) => {
  return api({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "DELETE",
    url: `partner/v2/partner/${id}/`,
  });
};

export const bulkApprovePartners = async (data: { ids: Array<number> }) => {
  return api({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "PUT",
    url: `api/web/partner/bulk-approve/`,
    data,
  });
};

export const bulkDeletePartners = async (data: { ids: Array<number> }) => {
  return api({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "DELETE",
    url: `api/web/partner/bulk-delete/`,
    data: data,
  });
};

export const restorePartners = async (data: any) => {
  return api({
    baseURL: process?.env.REACT_APP_BASE_URL,
    method: "PATCH",
    url: `api/web/partner/bulk-restore/`,
    data: data,
  });
};

export const permanentlyDeletePartners = async (data: any) => {
  return api({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "DELETE",
    url: `api/web/partner/bulk-delete/`,
    data: data,
  });
};

interface EmployeeNameResponse {
  id: number;
  user: number;
  employee_id: string;
  first_name: string;
  last_name: string;
  middle_name: string;
}

// fetch employee names
export const fetchEmployeeName = async () => {
  const res = await api.get<EmployeeNameResponse[]>("employee/list_approved_employees_names", {
    baseURL: process?.env?.REACT_APP_BASE_URL,
  });
  return res?.data;
};

interface StateResponse {
  country: string;
  name: string;
  id: any;
}

export const fetchStateNames = async () => {
  const res = await api.get<StateResponse[]>("/user/state/");
  return res?.data;
};

export const fetchCountryNames = async () => {
  const res = await api.get<StateResponse[]>("/user/country/");
  return res?.data;
};
