import { Box, Divider, Modal } from "@mui/material";
import { SNACKBAR_TYPE } from "components/MHCAlert";
import { GroupIcon } from "components/PhysicianComponent/PatientDataBNA/GroupIcon";
import HeadIcon from "components/PhysicianComponent/PatientDataBNA/HeadIcon";
import { useSetStatus } from "context/statusContext";
import { useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getAPatientData } from "redux/PatientList/PatientList";
import { DRAFT_RECORD_REMOVE } from "redux/PhysicianPortalData/PhysicianPortalTypes";
import { getUserComments, reviewComment, ReviewCommentRequest } from "redux/Review-Comment/api";
import { RootState } from "redux/store";
import * as routes from "../../../constants/routes";
import "../physicianLayout.scss";
import { Table } from "./JSPDFComponents";
import PatientPDF from "./PatientjsPDF";
import ShowMoreContent from "./ShowMoreContent";
import "./styles.scss";
import { ReportDataProps, RowCellItem, RowHeaderItem } from "./types";

const PatientReport = (props: { hideSave?: boolean }) => {
  const history = useHistory();
  const location: any = useLocation();
  const { hideSave = false } = props;
  const dispatch = useDispatch();

  const setStatus = useSetStatus();

  const reviewed = location?.state?.reviewed;

  const [saved, setSaved] = useState(hideSave || false);
  const [modalOpen, setModalOpen] = useState(false);
  // image session data
  const [imageData, setImageData] = useState<Record<string, any>>({});
  const [nextic, setNextic] = useState<string>();

  const bnaRef = useRef<any>(null);
  const { current: bnaData } = bnaRef;

  // const state = location.state as { data: Record<string, any> };

  //patient emr number
  const patient_emr = useSelector((state: RootState) => state.physicianPortalReducer.emr_no);
  // patient id
  const patient_pk_id = useSelector((state: RootState) => state.physicianPortalReducer.patient_pk_id);
  // patient profile id
  const patient_user_profile_id = useSelector(
    (state: RootState) => state.physicianPortalReducer.patient_user_profile_id,
  );
  // comments
  const comment = useSelector((state: RootState) => state.physicianPortalReducer.comment_data);
  // directives
  const treatment_directives = useSelector((state: RootState) => state.physicianPortalReducer.treatment_directive_data);
  // adjustment
  const treatment_adjustment = useSelector(
    (state: RootState) => state.physicianPortalReducer.treatment_adjustment_data,
  );
  // regimen
  const treatment_regimen = useSelector((state: RootState) => state.physicianPortalReducer.treatment_regimen_data);

  /**
   * mutation to post comments
   */
  const {
    mutate: PostValues,
    // isLoading: postLoading,
    // isError: postIsError,
    error: postError,
    // isSuccess: postIsSuccess,
  } = useMutation(reviewComment, {
    onSuccess: () => {
      // dispatch({ type: CLEAR });
      setStatus(SNACKBAR_TYPE.SUCCESS, "Reviewed Successfully");
      setSaved(true);
      // history.push(routes.PATIENTS);
      dispatch({
        type: DRAFT_RECORD_REMOVE,
        payload: {
          id: patient_user_profile_id,
        },
      });
    },
    onError: () => {
      // history.push(routes.PATIENTS);
      // dispatch({ type: CLEAR });
      setStatus(SNACKBAR_TYPE.ERROR, (postError as any)?.response?.data?.message || "Something went wrong");
    },
  });

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    scrollTo(0, 0);
  }, []);

  const { data: patientDataDetails, isLoading: patientDataLoading } = useQuery(
    ["get patient by id", patient_pk_id],
    () => getAPatientData(patient_pk_id),
  );

  let display = true;

  const handleSave = () => {
    const values: ReviewCommentRequest = {
      comment,
      comment_type: "USER_COMMENT",
      user_profile: patient_user_profile_id,
      treatment_directives,
      treatment_adjustment,
      treatment_regimen,
      location: patientDataDetails?.data?.location?.id,
      // for emr number
      patient_id: patient_pk_id,
      emr_no: patient_emr,
    };
    PostValues(values);
  };
  const handleBackClick = () => {
    if (reviewed) {
      history.goBack();
      return;
    }
    hideSave ? history.push(routes.PATIENTS_INACTIVE) : history.goBack();
  };

  const patientData = patientDataDetails?.data;
  const emr = hideSave ? patientData?.emr_number : patient_emr || patientData?.patient_id;
  const patientName = patientData?.user_profile?.full_name;
  const dob = patientData?.user_profile?.date_of_birth || "";

  const { data: userCommentList, isLoading: commentsLoading } = useQuery(
    ["getUserComments", patient_user_profile_id],
    () => getUserComments(patient_user_profile_id),
  );

  const sessionComments = useMemo(() => {
    if (!userCommentList) return {};
    return userCommentList?.[userCommentList?.length - 1];
  }, [userCommentList]);

  const comments = hideSave ? sessionComments?.comment : comment;
  const treatmentDirectives = hideSave ? sessionComments?.treatment_directives : treatment_directives;
  const treatmentRegimen = hideSave ? sessionComments?.treatment_regimen : treatment_regimen;
  const treatmentAdjustment = hideSave ? sessionComments?.treatment_adjustment : treatment_adjustment;

  const reportDataInfo = useMemo(
    () => ({
      appointment_time: patientData?.appointment_time.toString().substring(0, 10),
      emr,
      patientName,
      dob,
      comments,
      treatmentDirectives,
      treatmentAdjustment,
      treatmentRegimen,
      baselineOverall: imageData?.baselineOverall,
      latestOverall: imageData?.latestOverall,
    }),
    [
      comments,
      dob,
      emr,
      imageData?.baselineOverall,
      imageData?.latestOverall,
      patientData?.appointment_time,
      patientName,
      treatmentAdjustment,
      treatmentDirectives,
      treatmentRegimen,
    ],
  );

  const pdfData = useMemo(
    () => ({
      session: {
        comments,
        treatmentDirectives,
        treatmentAdjustment,
        treatmentRegimen,
      },
      patientDataDetails: reportDataInfo,
      baselineImageData: imageData?.baselineDictionary || {},
      latestImageData: imageData?.latestDictionary || {},
      baselineOverall: imageData?.baselineOverall,
      latestOverall: imageData?.latestOverall,
    }),
    [
      comments,
      imageData?.baselineDictionary,
      imageData?.baselineOverall,
      imageData?.latestDictionary,
      imageData?.latestOverall,
      reportDataInfo,
      treatmentAdjustment,
      treatmentDirectives,
      treatmentRegimen,
    ],
  );
  const handleHistoryClick = () => {
    history.push(routes.PATIENT_HISTORY);
  };

  const onPDFGenerate = () => {
    // generatePDf();
    const bnaImageData = bnaData?.getPatientData();
    bnaImageData && setImageData(bnaImageData);
    setModalOpen(true);
  };

  const disableGeneratePDF = !saved || patientDataLoading || commentsLoading;

  return (
    <div className="report__layout">
      <ReportData {...reportDataInfo} />
      <Divider sx={{ m: "1rem 0" }} />

      <h3 className="report__layout__header">Before & After Images</h3>
      <div className="report__layout__img__date">
        <HeadIcon ref={bnaRef} section={GroupIcon} nextic={nextic} display={display} />
      </div>

      {/* <div className="report__layout__img__data" style={{ marginTop: "0" }}>
        Comment on the image
      </div> */}

      <div className="report__layout__buttons module-action">
        <button className="module-add-button" onClick={handleBackClick}>
          Back
        </button>

        {/* review-comment api */}
        <div className="btn-generate">
          {!hideSave && (
            <button disabled={saved} className={`module-add-button ${saved && "disabled"} `} onClick={handleSave}>
              Save
            </button>
          )}

          {/* {!hideSave && ( */}
          <div className="buttons-phy" onClick={handleHistoryClick}>
            Treatment History
          </div>
          {/* )} */}

          <button
            disabled={disableGeneratePDF}
            onClick={onPDFGenerate}
            className={` module-add-button ${disableGeneratePDF && "disabled"}`}
          >
            Generate Pdf
          </button>
        </div>
        <Modal open={modalOpen}>
          <Box className="modal-content">
            <PatientPDF data={pdfData} onClose={() => setModalOpen(false)}></PatientPDF>
            {/* react pdf <PatientReportPDF
              data={{
                session: {
                  comment,
                  treatment_directives,
                  treatment_adjustment,
                  treatment_regimen,
                },
                patientDataDetails: patientDataDetails?.data,
                ...dataRef.current,
              }}
              onClose={() => setModalOpen(false)}
            /> */}
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export const ReportData = (props: ReportDataProps) => {
  const history = useHistory();
  const {
    appointment_time,
    emr,
    patientName,
    dob,
    comments,
    treatmentAdjustment,
    treatmentDirectives,
    treatmentRegimen,
    baselineOverall,
    latestOverall,
    showOverall,
    showCommentHistory = true,
  } = props;

  const overallTableHeaders: RowHeaderItem[] = [
    { key: "empty", value: "", bold: true },
    {
      key: "density",
      value: "Density",
    },
    {
      key: "grayHair",
      value: "MyGrowthScore",
    },
    {
      key: "dandruff",
      value: "MyHairStatus",
    },
  ];

  const overallTableData: RowCellItem[] = [
    {
      empty: "Baseline",
      ...baselineOverall,
    },

    {
      empty: "Latest",
      ...latestOverall,
    },
  ];
  // patient id
  const patient_pk_id = useSelector((state: RootState) => state.physicianPortalReducer.patient_pk_id);
  // patient profile id
  const patient_user_profile_id = useSelector(
    (state: RootState) => state.physicianPortalReducer.patient_user_profile_id,
  );
  const { data: patientDataDetails, isLoading: patientDataLoading } = useQuery(
    ["get patient by id", patient_pk_id],
    () => getAPatientData(patient_pk_id),
  );
  const handelCommentHistory = () => {
    history.push(routes.PATIENTS_COMMENT_HISTORY);
  };

  return (
    <>
      <div className="report_data" style={{ width: "100%" }}>
        <div className="report__layout__header_container">
          <h3 className="report__layout__header">Patient Information</h3>
          <h2 className="date">Appointment Date: {appointment_time}</h2>
        </div>
        <div className="report__layout__patient__details">
          <div>Patient ID: {emr || patientDataDetails?.data?.patient_id}</div>
          <div>Patient Name: {patientName}</div>
          <div>Patient DOB: {dob}</div>
        </div>
        <Divider sx={{ m: "1rem 0" }} />

        {showOverall && (
          <>
            <h3 className="report__layout__header">Overall Scores</h3>
            <Table h={overallTableHeaders} r={overallTableData} />
            <Divider sx={{ m: "1rem 0" }} />{" "}
          </>
        )}

        <h3 className="report__layout__header">Session Comments</h3>

        <div className="comments" style={{ marginBottom: "2rem" }}>
          <div>
            <h4>Comments: </h4>
            {comments && (
              <div className="report__layout__comments ">
                <ShowMoreContent str={comments} />
              </div>
            )}
          </div>
          <div>
            <h4>Treatment Directives: </h4>
            {treatmentDirectives && (
              <div>
                <ShowMoreContent str={treatmentDirectives} />
              </div>
            )}
          </div>

          <div>
            <h4>Treatment Regimen: </h4>
            {treatmentRegimen && (
              <div>
                <ShowMoreContent str={treatmentRegimen} />
              </div>
            )}
          </div>
          <div>
            <h4>Treatment Adjustment: </h4>
            {treatmentAdjustment && (
              <div>
                <ShowMoreContent str={treatmentAdjustment} />
              </div>
            )}
          </div>
        </div>
        {showCommentHistory && (
          <div>
            <button className="buttons-phy" style={{ marginTop: "2rem" }} onClick={handelCommentHistory}>
              Comment History
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default PatientReport;
