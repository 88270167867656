import { ConnectedFocusError } from "focus-formik-error";
import { FormikProvider, useFormik } from "formik";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { Col, Row } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { editGeneralDetails, postGeneralDetails } from "redux/GeneralInformation/api";
import * as Yup from "yup";
import { getPartnerInfo } from "./requests";

import { Box, Divider } from "@mui/material";
import { fetchCountryNames, fetchEmployeeName, fetchStateNames } from "redux/partners/partnersApi";
import { CompanyDetailsFooter, usePartner } from "./CompanyDetails";
import { FormikSelect, FormikTextInput, SelectItem } from "./components/TextInput";
import { useState } from "react";
import { LocalHospital } from "@mui/icons-material";
import MHCSpinner from "components/MHCSpinner";

const USA_ID = 1;

export type GeneralInfoFormType = {
  partner_id: string;
  company_name: string;
  primary_representative: string | number;
  secondary_representative: string | number;
  address: string;
  city: string;
  country: string;
  state: string;
  zip_code: string;
  branch_choices: string;
  franchisor_name: string;
  primary_contact_info: {
    primary_contact_id: string;
    first_name: string;
    last_name: string;
    contact_title: string;
    mobile_number: string;
    email: string;
  };
  software_information: {
    software_name: string;
    software_provider: string;
    info_usa_id: string;
  };
  bank_details: {
    employer_identification_number: string;
    bank_name: string;
    beneficiary_name: string;
    account_number: string;
    routing_number: string;
  };
};

const initialValues: GeneralInfoFormType = {
  partner_id: "",
  company_name: "",
  primary_representative: "",
  secondary_representative: "",
  address: "",
  city: "",
  country: "",
  state: "",
  zip_code: "",
  branch_choices: "",
  franchisor_name: "",
  primary_contact_info: {
    primary_contact_id: "",
    first_name: "",
    last_name: "",
    contact_title: "",
    mobile_number: "",
    email: "",
  },
  software_information: {
    software_name: "",
    software_provider: "",
    info_usa_id: "",
  },
  bank_details: {
    employer_identification_number: "",
    bank_name: "",
    beneficiary_name: "",
    account_number: "",
    routing_number: "",
  },
};

export interface GeneralInformationProps {
  onSubmit?: (val: any) => void;
  activeStep: number;
  id?: number;
  setActiveStep: (val: number) => void;
}

const GeneralInformation = forwardRef((props: GeneralInformationProps, ref: any) => {
  const history = useHistory();
  const { id, activeStep, setActiveStep } = props;

  const isEditMode = usePartner()?.edit;

  useImperativeHandle(ref, () => ({
    isLoading: postLoading,
    handleSubmit: formik.handleSubmit,
  }));

  const generalInfoQry = useQuery(["getInfo", id], () => id && getPartnerInfo(id), {
    retry: false,
  });

  const apiInitial = { ...initialValues, ...generalInfoQry?.data } as GeneralInfoFormType;

  const { data: employeeList } = useQuery(["employee names"], async () => {
    const response = await fetchEmployeeName();
    if (!response) throw new Error("Error in finding employee name");
    return response?.map((item) => ({
      label: `${item.first_name} ${item.last_name}`,
      value: item?.id,
    }));
  });

  const { data: country, isLoading: stateLoading } = useQuery(["country names"], async () => {
    const response = await fetchCountryNames();
    if (!response) throw new Error("Error in finding country names");
    return response?.map((item) => ({
      label: item?.name,
      value: item?.id,
    }));
  });

  const { data: states } = useQuery(["state names"], async () => {
    const response = await fetchStateNames();
    if (!response) throw new Error("Error in finding state names");

    return response.map((item) => ({
      label: item.name,
      value: item.id,
      country: item.country,
    }));
  });

  const branchChoicesOptions: SelectItem[] = [
    {
      label: "Headquarter",
      value: "Headquarter",
    },
    {
      label: "Branch",
      value: "Branch",
    },
  ];

  const { mutateAsync: PostGeneralInfo, isLoading: postLoading } = useMutation(postGeneralDetails, {
    onSuccess: (data) => {
      toast.success("General Information Saved Successfully", {
        autoClose: 3000,
        pauseOnHover: false,
        theme: "colored",
      });

      history.push({
        state: {
          id: data?.id,
        },
      });
    },
    onError: (error: any) => {
      if (error && error.response && error.response.data && error.response.data.message) {
        // Extract the error message from the response data
        const errorMessage = error.response.data.message;

        // Display the error message using toast.error()
        toast.error(errorMessage, {
          pauseOnHover: false,
          autoClose: 3000,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong", {
          pauseOnHover: false,
          autoClose: 3000,
          theme: "colored",
        });
      }
    },
  });

  const { mutateAsync: UpdateGeneralInfo } = useMutation(editGeneralDetails, {
    onSuccess: (data) => {
      toast.success("General Information Updated", {
        autoClose: 3000,
        pauseOnHover: false,
        theme: "colored",
      });

      history.push({
        state: {
          id: data?.id,
        },
      });
    },
    onError: () => {
      toast.error("Something went wrong", {
        pauseOnHover: false,
        autoClose: 3000,
        theme: "colored",
      });
    },
  });

  const formik = useFormik<GeneralInfoFormType>({
    enableReinitialize: true,
    initialValues: id ? apiInitial : initialValues,
    validationSchema: Yup.object({
      partner_id: Yup.string(),
      company_name: Yup.string().required("Company Name is required").nullable(),
      primary_representative: Yup.number().required("Primary Representative is required"),
      secondary_representative: Yup.number().required("Secondary Representative is required"),
      address: Yup.string().required("Address is required").nullable(),

      city: Yup.string().required("City is required").nullable(),
      country: Yup.number().required("Country is required").nullable(),
      state: Yup.number().required("State is required"),
      zip_code: Yup.string()
        .matches(/^\d{5}([-]\d{4})?$/, "Invalid Zip code")
        .required("Zip Code is required")
        .nullable(),
      branch_choices: Yup.string().nullable(),
      franchisorName: Yup.string().nullable(),
      primary_contact_info: Yup.object().shape({
        primary_contact_id: Yup.string(),
        first_name: Yup.string().required("First Name is required").nullable(),
        last_name: Yup.string().required("Last Name is required").nullable(),
        contact_title: Yup.string().required("Contact Title is required").nullable(),
        mobile_number: Yup.string()
          .matches(/^\d{10}$/, "10 Digit number required")
          .required("Mobile Number is required")
          .nullable(),
        email: Yup.string().email("Invalid email address").required("Email is required").nullable(),
      }),
      software_information: Yup.object().shape({
        software_name: Yup.string().nullable(),
        software_provider: Yup.string().nullable(),
        info_usa_id: Yup.string().nullable(),
      }),
      bank_details: Yup.object().shape({
        employer_identification_number: Yup.string().required("Employer Identification Number is required").nullable(),
        bank_name: Yup.string().required("Bank Name is required").nullable(),
        beneficiary_name: Yup.string().required("Benificiary Name is required").nullable(),
        account_number: Yup.string().nullable(),
        routing_number: Yup.string().nullable(),
      }),
    }),
    onSubmit: (values) => handleGeneralInfoNew(values),
  });

  const countryId = formik.values.country;

  // useEffect(() => {
  //   if (formik?.values?.country === null) {
  //     setCountryId("");
  //   }
  // }, [formik?.values?.state]);

  // useEffect(()=>{})

  const stateList = countryId
    ? states?.filter((i) => String(i.country) === String(countryId))
    : states?.filter((i) => String(i.country) === String(USA_ID));

  const handleGeneralInfoNew = async (values: any) => {
    try {
      if (!formik?.dirty) {
        setActiveStep(1);
        return;
      }
      const cbk = id ? UpdateGeneralInfo : !isEditMode ? PostGeneralInfo : UpdateGeneralInfo;

      const res = await cbk(values);
      if (!res) throw new Error("Error in general Information");

      setActiveStep(1);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          height: "99%",
          width: "99%",
          display: stateLoading ? "grid" : "none",
          alignItems: "center",
        }}
      >
        <MHCSpinner type="light" />
      </Box>
      <FormikProvider value={formik}>
        <ConnectedFocusError />
        <div className="box-container">
          <div className="box">
            <h6 className="mar-general">General Information</h6>

            <Row>
              <Col>
                {/* <div></div> */}

                <FormikTextInput
                  inputProps={{ disabled: true }}
                  name="partner_id"
                  label="Partners ID"
                  placeholder="SS-XXXXXXX-P"
                />
              </Col>
              <Col>
                <FormikTextInput name="company_name" label="Company Name" />
              </Col>
            </Row>
            <Row>
              <Col>
                {/* <div></div> */}
                <FormikSelect
                  label="Primary Representative"
                  name="primary_representative"
                  options={employeeList || []}
                />
              </Col>
              <Col>
                <FormikSelect
                  label="Secondary Representative"
                  name="secondary_representative"
                  options={employeeList || []}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {/* <div></div> */}
                <FormikTextInput name="address" label="Address" />
              </Col>
              <Col>
                <FormikTextInput name="city" label="City" />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikSelect
                  name="country"
                  label="Country"
                  key={`country_${country?.length}`}
                  options={country || []}
                  onChange={() => {
                    formik.setFieldValue("state", "");
                  }}
                />
              </Col>

              <Col>
                {/* <div></div> */}
                <FormikSelect
                  name="state"
                  label="State"
                  key={`state_${countryId}_${stateList?.length}`}
                  options={stateList || []}
                  // value={formik.values.state}
                />
              </Col>

              <Col>
                <FormikTextInput name="zip_code" label="Zip Code" />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikSelect label="Headquarter/Branch" name="branch_choices" options={branchChoicesOptions} />
                {/* <div></div> */}
              </Col>

              <Col>
                <FormikTextInput name="franchisor_name" label="Franchisor Name" />
              </Col>
            </Row>
          </div>
          <Divider sx={{ marginBottom: "1rem" }} />
          {/* Sub Group2 */}
          <div className="box2">
            <h6 className="mar-general">Primary Contact Information</h6>

            <Row>
              <Col>
                <FormikTextInput
                  name="primary_contact_info.primary_contact_id"
                  placeholder="PC-XXXXXXX"
                  label="Primary Contact ID"
                  className="half"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikTextInput name="primary_contact_info.first_name" label="First Name" />
              </Col>
              <Col>
                <FormikTextInput name="primary_contact_info.last_name" label="Last Name" />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikTextInput name="primary_contact_info.contact_title" label="Contact Title" className="half" />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikTextInput name="primary_contact_info.email" label="Email" inputProps={{ type: "email" }} />
              </Col>
              <Col>
                <FormikTextInput
                  name="primary_contact_info.mobile_number"
                  label="Mobile Number"
                  inputProps={{ type: "tel" }}
                />
              </Col>
            </Row>
          </div>

          <Divider sx={{ marginBottom: "1rem" }} />
          {/* Sub Group 3*/}
          <div className="box3">
            <h6 className="mar-general">Appointment Booking Software Information</h6>
            {/* <FormikProvider value={formik}> */}
            {/* <Form> */}
            <Row>
              <Col>
                <FormikTextInput
                  name="software_information.software_name"
                  label="Software Name (Optional)"
                  placeholder="Software Name"
                />
              </Col>
              <Col>
                <FormikTextInput
                  name="software_information.software_name"
                  label="Sofware Provider (Optional)"
                  placeholder="Software Provider"
                />
              </Col>
              <Col>
                <FormikTextInput
                  name="software_information.info_usa_id"
                  label="INFOUSA ID (Optional)"
                  placeholder="ABC-DEF"
                  inputProps={{
                    disabled: true,
                  }}
                />
              </Col>
            </Row>
          </div>

          <Divider sx={{ marginBottom: "1rem" }} />
          {/* Sub Group 4*/}
          <div className="box4">
            <h6 className="mar-general">Bank Details</h6>
            <Row>
              <Col>
                <FormikTextInput
                  name="bank_details.employer_identification_number"
                  label="Employer Identification Number (EIN)"
                  className="half"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikTextInput name="bank_details.bank_name" label="Bank Name" />
              </Col>
              <Col>
                <FormikTextInput name="bank_details.beneficiary_name" label="Beneficiary Name" />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikTextInput name="bank_details.account_number" label="Account Number" />
              </Col>
              <Col>
                <FormikTextInput name="bank_details.routing_number" label="Routing Number" />
              </Col>
            </Row>
          </div>
        </div>

        <CompanyDetailsFooter
          activeStep={activeStep}
          lastItem={1}
          onSubmit={formik.handleSubmit}
          setActiveStep={setActiveStep}
        />
      </FormikProvider>
    </>
  );
});
export default GeneralInformation;
