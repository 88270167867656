import axios from "axios";
import { FilterOptions } from "hooks/useFilter";

export const fetchEmployees = async (
  active: boolean,

  isDeleted: boolean,
  filterOptions?: FilterOptions,
) => {
  const responses = await axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method: "GET",
    url: `employee/v2/`,
    params: {
      ...filterOptions,
      is_approved: active ? "True" : "False",
      is_deleted: isDeleted ? "True" : "False",
    },
  });

  responses.data.data = responses.data.data.map((employee: any) => {
    employee["working_hour_start"] = new Date(`0 ${employee["working_hour_start"]} UTC`).toString().split(" ")[4];
    employee["working_hour_end"] = new Date(`0 ${employee["working_hour_end"]} UTC`).toString().split(" ")[4];

    return employee;
  });

  return responses;
};

export const postEmployee = async (employee: any) => {
  employee["working_hour_start"] = new Date(`0 ${employee["working_hour_start"]}`).toUTCString().split(" ")[4];
  employee["working_hour_end"] = new Date(`0 ${employee["working_hour_end"]}`).toUTCString().split(" ")[4];

  console.log("post employee", employee);

  return await axios({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: employee?.id ? "PUT" : "POST",
    url: employee?.id ? `employee/v2/${employee?.id}/` : `employee/v2/`,
    data: employee,
  });
};

export const deleteEmployee = async (id: number) => {
  return await axios({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "DELETE",
    url: `employee/v2/${id}/`,
  });
};

export const bulkApproveEmployees = async (data: { ids: Array<number> }) => {
  return await axios({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "PATCH",
    url: `employee/bulk_approve/`,
    data: data,
  });
};

export const bulkDeleteEmployees = async (data: { ids: Array<number> }) => {
  return await axios({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "DELETE",
    url: `employee/bulk_delete/`,
    data: data,
  });
};

export const restoreEmployees = async (data: any) => {
  return axios({
    baseURL: process?.env.REACT_APP_BASE_URL,
    method: "PATCH",
    url: "/employee/bulk-restore/",
    data: data,
  });
};

export const permanentlyDeleteEmployees = async (data: any) => {
  return axios({
    baseURL: process?.env.REACT_APP_BASE_URL,
    method: "DELETE",
    url: "/employee/bulk_delete/",
    data: data,
  });
};
