import React from "react";

interface MHCSpinnerProps {
  type: string;
}
const MHCSpinner = ({ type }: MHCSpinnerProps) => {
  return (
    <>
      {type === "light" ? (
        <div className="loading">
          <div className="spinner">
            <div className="circle circle-1">
              <div className="circle-inner"></div>
            </div>
            <div className="circle circle-2">
              <div className="circle-inner"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="loading loading-alt">
          <div className="spinner">
            <div className="circle circle-1">
              <div className="circle-inner"></div>
            </div>
            <div className="circle circle-2">
              <div className="circle-inner"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MHCSpinner;
