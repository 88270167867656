import axios from "axios";

const http = axios.create({ baseURL: process?.env?.REACT_APP_BASE_URL });

export const fetchProductDetails = async (id: number) => {
  return http({
    method: "GET",
    url: `/api/rest/ps/v2/${id}/`,
  });
};

export const checkDiscountCode = async (values: any) => {
  return http({
    method: "GET",
    url: `/productdiscount/verify-discount-code/${values?.discountCode}/`,
  });
};

export const createCheckoutSession = async (payload: any) => {
  const requestBody = {
    productId: payload?.productId,
    userId: payload?.userId,
    ...(payload.discountCode && { discountCode: payload.discountCode }),
  };
  const response = await http({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "POST",
    url: "/stripe/v2/checkout-session/",
    data: requestBody,
  });
  return response.data;
};
