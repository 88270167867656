import head1 from "assets/brand/head1.svg";
import head2 from "assets/brand/head2.svg";
import head3 from "assets/brand/head3.svg";
import head4 from "assets/brand/head4.svg";
import head5 from "assets/brand/head5.svg";
import head6 from "assets/brand/head6.svg";
import head7 from "assets/brand/head7.svg";
import head8 from "assets/brand/head8.svg";
import head9 from "assets/brand/head9.svg";
import head10 from "assets/brand/head10.svg";
import head11 from "assets/brand/head11.svg";
import head12 from "assets/brand/head12.svg";

export const HeadCodeList = ["1C", "1G", "1F", "crown", "front", "back", "right", "left", "1B", "1E", "1D", "1A"];

export const GroupIcon = {
  "1C": {
    active: true,
    id: "1C",
    img: head1,
  },
  "1G": {
    id: "1G",
    img: head2,
  },
  "1F": {
    id: "1F",
    img: head3,
  },
  crown: {
    id: "crown",
    img: head4,
  },
  front: {
    id: "front",
    img: head5,
  },
  back: {
    id: "back",
    img: head6,
  },
  right: {
    id: "right",
    img: head7,
  },
  left: {
    id: "left",
    img: head8,
  },
  "1B": {
    id: "1B",
    img: head9,
  },
  "1E": {
    id: "1E",
    img: head10,
  },
  "1D": {
    id: "1D",
    img: head11,
  },
  "1A": {
    id: "1A",
    img: head12,
  },
};
