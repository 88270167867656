import { HeadImage } from "layouts/ReviewScreen/ReviewScreenHeadCarousel";
import { BaseAction } from "redux/reducer";
type REVIEWSCREEN_STATE = {
  hGrid: boolean;
  vGrid: boolean;
  zoom: number;
  showForm: boolean;
  rotate: number;
  head: {
    list: HeadImage[];
    selected: HeadImage;
  };
  showTools: boolean;
};

const initialState: REVIEWSCREEN_STATE = {
  hGrid: false,
  vGrid: false,
  zoom: 1,
  rotate: 0,
  showForm: false,
  showTools: false,
  head: {
    list: [],
    selected: "1A",
  },
};

export enum REVIEWSCREEN_ACTION_TYPE {
  INIT_REVIEW_SCREEN = "INIT_REVIEW_SCREEN",
  TOGGLE_H_GRID = "TOGGLE_H_GRID",
  TOGGLE_V_GRID = "TOGGLE_V_GRID",
  UPDATE_ZOOM = "UPDATE_ZOOM",
  UPDATE_ROTATE = "UPDATE_ROTATE",
  UPDATE_HEAD_LIST = "UPDATE_HEAD_LIST",
  UPDATE_HEAD_SELECTED = "UPDATE_HEAD_SELECTED",
  TOGGLE_FORM = "TOGGLE_FORM",
  TOGGLE_TOOLS = "TOGGLE_TOOLS",
}

const reviewScreenReducer = (
  state: REVIEWSCREEN_STATE = initialState,
  action: BaseAction<REVIEWSCREEN_ACTION_TYPE>,
) => {
  const tempstate = { ...state };

  switch (action.type) {
    default:
      return state;
    case REVIEWSCREEN_ACTION_TYPE.TOGGLE_H_GRID:
      return { ...state, hGrid: action.payload === undefined ? !state.hGrid : action.payload };
    case REVIEWSCREEN_ACTION_TYPE.TOGGLE_V_GRID:
      return { ...state, vGrid: action.payload === undefined ? !state.vGrid : action.payload };
    case REVIEWSCREEN_ACTION_TYPE.UPDATE_ZOOM:
      return { ...state, zoom: action.payload };
    case REVIEWSCREEN_ACTION_TYPE.UPDATE_ROTATE:
      return { ...state, rotate: action.payload };
    case REVIEWSCREEN_ACTION_TYPE.UPDATE_HEAD_LIST:
      tempstate.head.list = action.payload;
      return tempstate;
    case REVIEWSCREEN_ACTION_TYPE.UPDATE_HEAD_SELECTED:
      tempstate.head.selected = action.payload;
      return tempstate;
    case REVIEWSCREEN_ACTION_TYPE.INIT_REVIEW_SCREEN:
      tempstate.head.list = action.payload.list;
      tempstate.head.selected = action.payload.selected || action.payload.list?.[0];
      return tempstate;
    case REVIEWSCREEN_ACTION_TYPE.TOGGLE_FORM:
      return { ...state, showForm: action.payload || !state.showForm };
    case REVIEWSCREEN_ACTION_TYPE.TOGGLE_TOOLS:
      return { ...state, showTools: action.payload || !state.showTools };
  }
};

const toggleFn = (type: REVIEWSCREEN_ACTION_TYPE) => (payload?: boolean) => ({
  type,
  payload,
});

export const reviewScreenActions = {
  toggleHGrid: toggleFn(REVIEWSCREEN_ACTION_TYPE.TOGGLE_H_GRID),
  toggleVGrid: toggleFn(REVIEWSCREEN_ACTION_TYPE.TOGGLE_V_GRID),
  toggleForm: toggleFn(REVIEWSCREEN_ACTION_TYPE.TOGGLE_FORM),
  toggleTools: toggleFn(REVIEWSCREEN_ACTION_TYPE.TOGGLE_TOOLS),

  updateZoom: (payload: number) => ({ type: REVIEWSCREEN_ACTION_TYPE.UPDATE_ZOOM, payload }),
  updateRotate: (payload: number) => ({ type: REVIEWSCREEN_ACTION_TYPE.UPDATE_ROTATE, payload }),

  updateHeadList: (payload: HeadImage[]) => ({ type: REVIEWSCREEN_ACTION_TYPE.UPDATE_HEAD_LIST, payload }),
  updateHeadSelected: (payload: HeadImage) => ({ type: REVIEWSCREEN_ACTION_TYPE.UPDATE_HEAD_SELECTED, payload }),
  initReviewScreen: (list: HeadImage[], selected?: HeadImage) => ({
    type: REVIEWSCREEN_ACTION_TYPE.INIT_REVIEW_SCREEN,
    payload: { list, selected },
  }),
};

export default reviewScreenReducer;
