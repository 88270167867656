import { useMemo } from "react";

export interface Table1RowsType {
  treatmentProductId: number;
  productName: string;
  startDate: Date | string;
  endDate: Date | string;
  total: number;
  remarks: string;
}
export const useTableToRows = (data: any): Table1RowsType[] => {
  return useMemo(
    () =>
      data?.map((productDetail: any): Table1RowsType => {
        const endDateMilliSecond = productDetail.end_date
          ? new Date(productDetail.end_date).getTime()
          : new Date().getTime();

        const total = Math.floor(
          (endDateMilliSecond - new Date(productDetail?.start_date).getTime()) / (1000 * 60 * 60 * 24),
        );
        // console.log("prod de",productDetail.end_date)
        // if (!productDetail.end_date)
          return {
            // id: productDetail.id,
            remarks: productDetail.remarks,
            productName: productDetail.treatment_product.product_name,
            startDate: new Date(productDetail.start_date).toLocaleDateString(),
            endDate: productDetail.end_date,
            total,
            treatmentProductId: productDetail?.treatment_product_id,
          };
        // else return {};
      }),
    [data],
  );
};
