import { IAction } from "../../types";
import { SIDEBAR_TOGGLE } from "./types";
export const toggleSidebar = (payload?: boolean) => ({
  type: SIDEBAR_TOGGLE,
  payload,
});

export const SET_TOOLBAR_TITLE = "SET_TOOLBAR_TITLE";

interface ISetToolbarTitleAction extends IAction {
  payload: string;
}

export const setToolbarTitle = (title: string): ISetToolbarTitleAction => ({
  type: SET_TOOLBAR_TITLE,
  payload: title,
});

export type IToolbarAction = ISetToolbarTitleAction;
