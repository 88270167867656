// function to compare heights of rows
import { format } from "date-fns";
export const compareHeights = (treatmentRow: Element, dateRow: Element) => {
  const treatmentRowHeight = treatmentRow.getBoundingClientRect()?.height || 0;
  const dateRowHeight = dateRow.getBoundingClientRect()?.height || 0;

  if (treatmentRowHeight > dateRowHeight) {
    dateRow.querySelectorAll("td").forEach((item) => {
      item.style.height = `${treatmentRowHeight}px`;
    });
  } else if (dateRowHeight > treatmentRowHeight) {
    treatmentRow.querySelectorAll("td").forEach((item) => {
      item.style.height = `${dateRowHeight}px`;
    });
  }
};

// function to set css property to the root element
export const setCssProperty = (property: string, pixels: number) => {
  document.documentElement.style.setProperty(`--${property}`, `${pixels}px`);
};
// function to get children list from query
export const getChildrenList = (query: string) => {
  const rowCollection = document.querySelector(query)?.children;
  return rowCollection ? Array.from(rowCollection) : [];
};

export const formatDate = (date: Date) => format(date, "MM/dd/yyyy");
