import axios, { AxiosRequestConfig } from "axios";
import config from "config";
import { addAuthHeader, refreshAuthTokenOnError } from "interceptors/HttpInterceptor";

import { CustomAxiosError, ICustomAxiosError } from "./CustomAxiosError";

const BASE_URL = config.apiUrl;

const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use(addAuthHeader, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use(undefined, refreshAuthTokenOnError);

export type AsyncRequestFn<T> = [ICustomAxiosError | null, T | null];

export const asyncRequest = async <T = {}>(axiosConfig: AxiosRequestConfig): Promise<AsyncRequestFn<T>> => {
  try {
    const response = await api.request<T>(axiosConfig);
    return [null, response.data];
  } catch (err) {
    return [(err as CustomAxiosError).toJSON(), null];
  }
};

export default api;
