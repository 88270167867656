import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Switch } from "@material-ui/core";
import HqmIcon from "components/icon";
import { useEffect, useState } from "react";

const ViewEmployee = ({
  change_employee,
  delete_employee,
  employee,
  handleApproveEmployee,
  handleDeleteEmployee,
  openModal,
}: any) => {
  const [radioValue, setRadioValue] = useState<Boolean>(false);
  console.log("employee", employee);

  useEffect(() => {
    // if (employee?.is_sales_representative) {
    //   setRadioValue(employee?.is_sales_representative);
    // }
    setRadioValue(employee?.is_sales_representative);
  }, [employee?.is_sales_representative]);

  //    let start_time=new Date(`0 ${employee?.working_hour_start} UTC`).toString().split(' ')[4]
  //    let end_time=new Date(`0 ${employee?.working_hour_end} UTC`).toString().split(' ')[4]

  //    console.log("Start time is",start_time)
  //    console.log("end time is",end_time)

  return (
    <div className="employee-view">
      <div className="drawer-body">
        <div className="form-group">
          <label className="preview-label">Employee ID</label>
          <div className="preview-value">{employee?.employee_id}</div>
        </div>

        <div className="form-group">
          <label className="preview-label">Employee Type</label>
          <div className="preview-value">{employee?.employee_type}</div>
        </div>

        <div className="form-group">
          <label>Status</label>
          <br />
          <FormControlLabel
            control={
              <Switch
                name="status"
                color="primary"
                checked={employee?.status ? true : false}
                disabled={true}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Active"
            style={{
              color: "#000",
            }}
          />
        </div>
        <div className="section-text">Personal Details</div>

        <div className="form-group">
          <label className="preview-label">First Name</label>
          <div className="preview-value">{employee?.first_name}</div>
        </div>
        <div className="form-group">
          <label className="preview-label">Last Name</label>
          <div className="preview-value">{employee?.last_name}</div>
        </div>

        <div className="section-text">Contact Information</div>
        <div className="form-group">
          <label className="preview-label">Mobile Number</label>
          <div className="preview-value">{employee?.mobile}</div>
        </div>

        <div className="form-group">
          <label className="preview-label">Alternate Contact Details</label>
          <div className="preview-value">{employee?.additional_contact_details}</div>
        </div>

        <div className="form-group">
          <label className="preview-label">Email</label>
          <div className="preview-value">{employee?.email}</div>
        </div>

        <div className="section-text">Additional Information</div>

        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Is a sales representative ?</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={radioValue}
            name="is_sales_representative"
          >
            <FormControlLabel value={true} control={<Radio disabled color="primary" />} label="Yes" />
            <FormControlLabel value={false} control={<Radio disabled color="primary" />} label="No" />
          </RadioGroup>
        </FormControl>

        <div className="form-group">
          <label className="preview-label">Notes</label>
          <div className="preview-value">{employee?.notes}</div>
        </div>
        <div className="form-group">
          <label className="preview-label">Working Hour Start</label>
          <div className="preview-value">{employee?.working_hour_start}</div>
        </div>
        <div className="form-group">
          <label className="preview-label">Working Hour End</label>
          <div className="preview-value">{employee?.working_hour_end}</div>
        </div>
      </div>
      <div className="drawer-footer">
        {delete_employee ? (
          <button className="module-delete-button" onClick={(e: any) => handleDeleteEmployee(employee)}>
            <HqmIcon name="trash" size={20} />
          </button>
        ) : null}
        {change_employee ? (
          <button className="employee-approve-button" onClick={(e: any) => handleApproveEmployee(employee)}>
            Approve
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default ViewEmployee;
