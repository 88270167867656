import { Box, Modal } from "@mui/material";
import { GenericFn } from "config";
import React, { useEffect, useMemo } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { reviewScreenActions } from "redux/reviewScreen";
import { posthairValues } from "redux/reviewviewview/api";
import { RootState } from "redux/store";
import styles from "./review-screen.module.scss";
import ReviewScreenForm from "./ReviewScreenForm";
import { HeadImage } from "./ReviewScreenHeadCarousel";
import ReviewScreenImage, { ReviewScreenToolsProps } from "./ReviewScreenImage";
export interface ReviewScreenProps {
  selected?: HeadImage;
  imageData?: any;
  onClose?: GenericFn;
  view?: any;
  refetchPageData?: GenericFn;
}

const ReviewScreen: React.FC<ReviewScreenProps> = (props, ref) => {
  const { imageData, onClose, selected, view, refetchPageData } = props;
  const dispatch = useDispatch();
  const showForm = useSelector((s: RootState) => s.reviewScreen.showForm);

  const imageActions: ReviewScreenToolsProps = {
    onHGridChange: (state: boolean) => {
      dispatch(reviewScreenActions.toggleHGrid(state));
    },
    onVGridChange: (state: boolean) => {
      dispatch(reviewScreenActions.toggleVGrid(state));
    },
    onZoomChange: (scale: number) => {
      dispatch(reviewScreenActions.updateZoom(scale));
    },
    onHeadChange: (activeId: HeadImage) => {
      dispatch(reviewScreenActions.updateHeadSelected(activeId));
    },
    onClose,
    onRotationChange: (rotate: number) => {
      dispatch(reviewScreenActions.updateRotate(rotate));
    },
    onImageFail() {
      refetchPageData?.();
    },
  };

  const [imageIdMap, imgIdArr, imageDataMap] = useMemo(() => {
    const map = new Map<string, any>();
    const imageDataMap = new Map<string, any>();
    const arr: any[] = [];

    Array.isArray(imageData) &&
      imageData?.forEach((image) => {
        //for image and hair section code
        map.set(image.haircapture?.head_section_code, image?.image);
        //hair section code and image data
        imageDataMap.set(image.haircapture?.head_section_code, image);
        //all hair section code
        arr.push(image.haircapture?.head_section_code);
      });

    return [map, arr, imageDataMap];
  }, [imageData]);

  useEffect(() => {
    dispatch(reviewScreenActions.initReviewScreen(imgIdArr, selected));
  }, [dispatch, imgIdArr, selected]);

  const { mutate: updateReviewviewView, isLoading: postLoading } = useMutation(posthairValues, {
    onSuccess(data, variables, context) {
      toast.success("Review Updated Successfully", { autoClose: 3000 });
      refetchPageData?.();
      imageActions.onClose?.();
    },
  });

  const handleDrawerClose = () => dispatch(reviewScreenActions.toggleForm(false));

  return (
    <>
      <Modal
        open={showForm}
        onClose={handleDrawerClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        sx={{ display: "flex", justifyContent: "right" }}
      >
        <Box className={styles.review_screen_form_modal}>
          <ReviewScreenForm
            modal
            formData={imageDataMap}
            submitting={postLoading}
            view={view}
            onCancel={imageActions.onClose}
            onSubmit={updateReviewviewView}
          />
        </Box>
      </Modal>

      <Box className={styles.review_screen_container}>
        <ReviewScreenImage {...imageActions} img={imageIdMap} />

        <ReviewScreenForm
          formData={imageDataMap}
          submitting={postLoading}
          view={view}
          onCancel={imageActions.onClose}
          onSubmit={updateReviewviewView}
        />
      </Box>
    </>
  );
};

export default React.memo(ReviewScreen);
