import MHCDrawer from "components/Drawer";
import HqmIcon from "components/icon";
import MHCAlert, { SNACKBAR_TYPE } from "components/MHCAlert";
import MHCConfirm from "components/MHCConfirm";
import SearchModule from "components/SearchModule";
import { useSetStatus } from "context/statusContext";
import { useCheckBox } from "hooks/useCheckBox";
import { useCheckBoxArchive } from "hooks/useCheckBoxArchive";
import { usePermissionCheck } from "hooks/useCheckPermission";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import PaginatedTable from "../../components/Table/PaginatedTable";
import config from "../../config";
import { MODULES } from "../../constants/modules";
import {
  bulkApproveEmployees,
  bulkDeleteEmployees,
  deleteEmployee,
  fetchEmployees,
  permanentlyDeleteEmployees,
  postEmployee,
  restoreEmployees,
} from "../../redux/employee/api";
import AddEmployeeForm from "./AddEmployeeForm";
import { TableConstants, TableConstantsArchived, TableConstantsPending } from "./TableConstants";
import ViewEmployee from "./ViewEmployee";
import useFilter from "hooks/useFilter";

const Employee = ({ active, permissionList, isDeleted }: any) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
  const [refresh, setRefresh] = useState(false);
  const [confirmation, setConfirmation] = useState("");
  const [restoreConfirmationDeletionModal, setRestoreConfirmationDeletionModal] = useState("");
  const [restoreData, setRestoreData] = useState();
  const queryClient = useQueryClient();

  const setStatus = useSetStatus();
  const {
    filter: filterOptions,
    actions: { handleSize, handlePagination, handleSearch, handleSort },
  } = useFilter({
    ordering: "id",
  });
  const { page, paginate_by, ordering: sortField, search: searchQuery } = filterOptions;
  const { isLoading, isError, error, data, isFetching, isPreviousData, refetch } = useQuery(
    ["projects", active, isDeleted, filterOptions],
    () => fetchEmployees(active, isDeleted, filterOptions),
    { keepPreviousData: true },
  );

  const {
    mutate: addUpdateEmployee,
    isLoading: postLoading,
    isError: postIsError,
    error: postError,
    isSuccess: postIsSuccess,
  } = useMutation(postEmployee);

  const {
    mutate: delEmployee,
    isLoading: deleteLoading,
    isError: deleteIsError,
    error: deleteError,
    isSuccess: deleteIsSuccess,
  } = useMutation(deleteEmployee);

  const {
    mutate: bulkApproveEmps,
    isLoading: bApproveLoading,
    isError: bApproveIsError,
    error: bApproveError,
    isSuccess: bApproveIsSuccess,
  } = useMutation(bulkApproveEmployees);

  const {
    mutate: bulkDelEmps,
    isLoading: bDeleteLoading,
    isError: bDeleteIsError,
    error: bDeleteError,
    isSuccess: bDeleteIsSuccess,
  } = useMutation(bulkDeleteEmployees);

  const {
    mutate: restoreEmps,
    isLoading: restoreEmpsIsLoading,
    isError: restoreEmpsIsError,
    error: restoreEmpsError,
    isSuccess: restoreEmpsIsSuccess,
  } = useMutation(restoreEmployees);

  const {
    mutate: permanentlyDeleteEmps,
    isLoading: pDeleteIsLoading,
    isError: pDeleteIsError,
    error: pdeleteError,
    isSuccess: pDeleteIsSuccess,
  } = useMutation(permanentlyDeleteEmployees);

  const handleSubmitEmployee = (employee: any) => {
    //console.log(employee);
    addUpdateEmployee(employee);
  };

  useEffect(() => {
    if (postIsSuccess && !postLoading && !postIsError && !postError) {
      handleCloseModal();
      setStatus(SNACKBAR_TYPE.SUCCESS, "Employee added successfully.");
      refetch();
    } else if (!postLoading && postIsError && postError) {
      (postError as any)?.response?.data?.description?.errors?.forEach((err: any) => {
        let v: any = Object.values(err)[0];
        setStatus(SNACKBAR_TYPE.ERROR, v || (postError as any)?.response?.data?.message || "Something went wrong");
      });
    }
  }, [postLoading, postIsError, postError, postIsSuccess]);

  const pageData = data && !_.isEmpty(data.data?.data) ? data.data?.data : [];

  const pageCount = (data && data.data?.page_count) || 0;

  const totalCount = (data && data.data?.total_count) || 0;

  const current_page_number = (data && data.data?.current_page_number) || 0;

  const { checkEmployee, checkAll, checked, allChecked, setChecked, setAllChecked } = useCheckBox(
    page || 1,
    pageData,
    refresh,
    setRefresh,
  );

  const {
    checkSelectArchive,
    checkAllArchive,
    checkedArchive,
    allCheckedArchive,
    setCheckedArchive,
    setAllCheckedArchive,
  } = useCheckBoxArchive(page || 1, pageData, refresh, setRefresh);

  useEffect(() => {
    if (pDeleteIsSuccess && !pDeleteIsError && !pdeleteError && !pDeleteIsLoading) {
      setStatus(SNACKBAR_TYPE.SUCCESS, "Employee Permanently Deleted");
      setCheckedArchive([]);
      refetch();
    } else if (pDeleteIsError && pdeleteError && !pDeleteIsLoading) {
      setStatus(SNACKBAR_TYPE.ERROR, (pdeleteError as any)?.response?.data?.description?.errors);
    }
  }, [pDeleteIsError, pDeleteIsLoading, pDeleteIsSuccess, pdeleteError]);

  useEffect(() => {
    if (restoreEmpsIsSuccess && !restoreEmpsError && !restoreEmpsIsLoading && !restoreEmpsIsError) {
      setStatus(SNACKBAR_TYPE.SUCCESS, "Employee restored successfully");
      setCheckedArchive([]);
      refetch();
    } else if (!restoreEmpsIsLoading && restoreEmpsError && restoreEmpsIsError) {
      setStatus(SNACKBAR_TYPE.ERROR, (restoreEmpsError as any)?.response?.data?.message);
    }
  }, [restoreEmpsError, restoreEmpsIsError, restoreEmpsIsLoading, restoreEmpsIsSuccess]);

  useEffect(() => {
    if (bApproveIsSuccess && !bApproveLoading && !bApproveIsError && !bApproveError) {
      setChecked([]);
      allChecked?.forEach((v: any, k: any) => {
        allChecked.set(k, false);
      });
      setStatus(SNACKBAR_TYPE.SUCCESS, "Approved employees successfully");
      refetch();
    } else if (!bApproveLoading && bApproveIsError && bApproveError) {
      setStatus(SNACKBAR_TYPE.ERROR, (postError as any)?.message);
    }
  }, [bApproveLoading, bApproveIsError, bApproveError, bApproveIsSuccess]);

  useEffect(() => {
    if (bDeleteIsSuccess && !bDeleteLoading && !bDeleteIsError && !bDeleteError) {
      setChecked([]);
      allChecked?.forEach((v: any, k: any) => {
        allChecked.set(k, false);
      });
      setStatus(SNACKBAR_TYPE.SUCCESS, "Deleted employees successfully");
      refetch();
    } else if (!bDeleteLoading && bDeleteIsError && bDeleteError) {
      setStatus(SNACKBAR_TYPE.ERROR, (postError as any)?.message);
    }
  }, [bDeleteLoading, bDeleteIsError, bDeleteError, bDeleteIsSuccess]);

  useEffect(() => {
    if (deleteIsSuccess && !deleteLoading && !deleteIsError && !deleteError) {
      handleCloseModal();
      setStatus(SNACKBAR_TYPE.SUCCESS, "Deleted employee successfully");
      refetch();
    } else if (!deleteLoading && deleteIsError && deleteError) {
      setStatus(SNACKBAR_TYPE.ERROR, (postError as any)?.message);
    }
  }, [deleteLoading, deleteIsError, deleteError, deleteIsSuccess]);

  const handleEditEmployee = (employee: any) => {
    setSelectedEmployee(employee);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedEmployee(null);
    setOpenModal(false);
  };

  const handleViewEmployee = (employee: any) => {
    setSelectedEmployee(employee);
    setOpenModal(true);
  };

  const handleApproveChecked = () => {
    bulkApproveEmps({ ids: checked });
  };

  const handleDeleteChecked = () => {
    bulkDelEmps({ ids: checked });
  };

  const handleApproveEmployee = async (employee: any) => {
    await bulkApproveEmps({ ids: [employee.id] });
    setOpenModal(false);
  };

  const handleDeleteEmployee = (employee: any) => {
    delEmployee(parseInt(employee?.id || 0));
  };

  const handleConfirmation = () => {
    if (confirmation === "bulkApprove") {
      handleApproveChecked();
    } else if (confirmation === "bulkDelete") {
      handleDeleteChecked();
    }
    setConfirmation("");
  };
  // console.log(permissionList);
  const add_employee = usePermissionCheck("add_employee");

  const approve_employee = usePermissionCheck("approve_employee");
  const change_employee = usePermissionCheck("change_employee");
  const delete_employee = usePermissionCheck("delete_employee");

  const handleRestore = (values: any) => {
    // restoreEmps({ids:[values?.id]});
    setRestoreConfirmationDeletionModal("restoreData");
    setRestoreData(values);
  };

  const handleConfirmationRestoreDelete = (values: any) => {
    if (restoreConfirmationDeletionModal === "restoreData") {
      if (checkedArchive?.length) {
        const uniqueValues = uniqueValueGenerator(checkedArchive);

        restoreEmps({ ids: uniqueValues });
        setRestoreConfirmationDeletionModal("");
      } else {
        restoreEmps({ ids: [restoreData?.["id"]] });
        setRestoreConfirmationDeletionModal("");
      }
    } else if (restoreConfirmationDeletionModal === "deleteData") {
      if (checkedArchive?.length) {
        const uniqueValues = uniqueValueGenerator(checkedArchive);
        permanentlyDeleteEmps({ ids: uniqueValues, permanently_delete: true });

        setRestoreConfirmationDeletionModal("");
      } else {
        permanentlyDeleteEmps({ ids: [restoreData?.["id"]], permanently_delete: true });
        setRestoreConfirmationDeletionModal("");
      }
    }
  };

  const handlePermanentlyDelete = (values: any) => {
    setRestoreConfirmationDeletionModal("deleteData");
    setRestoreData(values);
    // permanentlyDeleteEmps({ ids: [values?.id], permanently_delete: true });
  };
  function uniqueValueGenerator(value: any) {
    const uniqueValues = value.filter((item: any, index: any) => value.indexOf(item) === index);
    return uniqueValues;
  }
  let location = useLocation();

  useEffect(() => {
    handleCloseModal();
  }, [location.pathname]);

  return (
    <>
      <MHCConfirm
        open={confirmation}
        message={
          confirmation === "bulkApprove"
            ? "Are you sure you want to approve the selected employees?"
            : "Are you sure you want to delete the selected employees?"
        }
        title={confirmation === "bulkApprove" ? "Approval Confirmation" : "Deletion Confirmation"}
        onCancel={() => setConfirmation("")}
        onConfirm={handleConfirmation}
        yesLabel={confirmation === "bulkApprove" ? "Approve" : "Delete"}
        noLabel="Cancel"
      />
      <MHCConfirm
        open={restoreConfirmationDeletionModal}
        message={
          restoreConfirmationDeletionModal === "restoreData"
            ? "Are you sure you want to restore the selected item(s)?"
            : "Are you sure you want to permanently delete the User?"
        }
        title={restoreConfirmationDeletionModal === "restoreData" ? "Approve Restoration" : "Approve Deletion"}
        onCancel={() => {
          setRestoreConfirmationDeletionModal("");
        }}
        onConfirm={handleConfirmationRestoreDelete}
        yesLabel={restoreConfirmationDeletionModal === "restoreData" ? "Restore" : "Delete"}
        noLabel="Cancel"
      />
      <MHCAlert />

      <MHCDrawer
        open={openModal}
        onClose={() => handleCloseModal()}
        title={active && !selectedEmployee ? "New Employee" : active && selectedEmployee ? "Edit Employee" : "Approve"}
      >
        {active ? (
          <AddEmployeeForm
            change_employee={change_employee}
            employee={selectedEmployee}
            handleSubmitEmployee={handleSubmitEmployee}
            openModal={openModal}
            error={postError}
          />
        ) : (
          <ViewEmployee
            change_employee={change_employee}
            delete_employee={delete_employee}
            employee={selectedEmployee}
            handleApproveEmployee={handleApproveEmployee}
            handleDeleteEmployee={handleDeleteEmployee}
            openModal={openModal}
          />
        )}
      </MHCDrawer>
      <div className="page">
        <div className="module-header">
          <div className="module-name">Employee Module</div>
          <div className="module-action">
            {add_employee && active && (
              <button className="module-add-button" onClick={() => setOpenModal(true)}>
                + Add Employee
              </button>
            )}
            {!active && !isDeleted && checked?.length > 0 && (
              <>
                {approve_employee ? (
                  <button className="module-approve-button" onClick={() => setConfirmation("bulkApprove")}>
                    Approve
                  </button>
                ) : null}
                {delete_employee ? (
                  <button className="module-delete-button" onClick={() => setConfirmation("bulkDelete")}>
                    <HqmIcon name="trash" size={20} />
                  </button>
                ) : null}
              </>
            )}

            {!active && isDeleted && checkedArchive?.length > 0 && (
              <>
                {approve_employee ? (
                  <button
                    className="module-approve-button"
                    onClick={() => setRestoreConfirmationDeletionModal("restoreData")}
                  >
                    Restore
                  </button>
                ) : null}
                {delete_employee ? (
                  <button
                    className="module-delete-button"
                    onClick={() => setRestoreConfirmationDeletionModal("deleteData")}
                  >
                    <HqmIcon name="trash" size={20} />
                  </button>
                ) : null}
              </>
            )}
            <SearchModule handleSearch={handleSearch} />
          </div>
        </div>
        <PaginatedTable
          cols={
            active
              ? TableConstants(handleEditEmployee)
              : !isDeleted
              ? TableConstantsPending(handleViewEmployee, checkEmployee, checked)
              : TableConstantsArchived(handleRestore, handlePermanentlyDelete, checkSelectArchive, checkedArchive)
          }
          data={pageData}
          isDark={false}
          bordered
          striped={false}
          hoverable
          entries={paginate_by || 10}
          onRowClick={() => {}}
          //selected={selectedRowID}
          componentName={MODULES.EMPLOYEES}
          handlePagination={handlePagination}
          handleEntries={handleSize}
          currentPage={current_page_number}
          totalCount={totalCount}
          size="lg"
          isDataLoading={isFetching || postLoading || deleteLoading}
          handleSort={handleSort}
          initialSortConfig={{ key: sortField || "", direction: "desc" }}
          allChecked={isDeleted ? allCheckedArchive : allChecked}
          checkAll={isDeleted ? checkAllArchive : checkAll}
          isPaginationRequired={true}
        />
      </div>
    </>
  );
};

export default Employee;
