import HqmIcon from "components/icon";
import _ from "lodash";
import { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MHCSpinner from "../MHCSpinner";

interface TableComponets {
  cols: Array<TableConstantProps>;
  data: Array<any>;
  bordered: boolean;
  hoverable: boolean;
  striped: boolean;
  isDark: boolean;
  entries: number;
  onRowClick?: (id: string) => any;
  onDotsClicked?: Function;
  checkAll?: any;
  allChecked?: Map<number, boolean>;
  selected?: any;
  view?: string;
  componentName?: string;
  module?: string;
  handlePagination: any;
  totalCount: number;
  currentPage: number;
  handleEntries?: any;
  threeDotsEnabled?: boolean;
  size?: string;
  isDataLoading?: boolean;
  handleSort?: any;
  showHeader?: boolean;
  redirectEnabled?: boolean;
  isPaginationRequired?: boolean;
  initialSortConfig?: { key: string; direction: string };
  tableClass?: string;
}

export interface TableConstantProps {
  title: ReactNode;
  name: string;
  render: Function;
  sortable?: boolean;
  notClickable?: boolean;
  align?: string;
}
const useSortableData = (items: Array<any>, handleSort?: any, config = { key: "", direction: "" }) => {
  const [sortConfig, setSortConfig] = useState(config);

  const requestSort = (key: string) => {
    let direction = "asc";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "desc") {
      direction = "";
    }
    setSortConfig({ key, direction });
    if (handleSort) {
      handleSort(key, direction);
    }
  };

  const defaultSort = (key: string, direction: string) => {
    //let direction = "desc";

    setSortConfig({ key, direction });
    if (handleSort) {
      handleSort(key, direction);
    }
  };

  if (!handleSort) {
    items = items;
  }
  return { items: items, requestSort, sortConfig, defaultSort };
};

const PaginatedTable: React.FC<TableComponets> = ({ showHeader = true, tableClass, ...props }: TableComponets) => {
  const [ent, setEnt] = useState(props.entries);
  const { items, requestSort, sortConfig, defaultSort } = useSortableData(
    props.data,
    props.handleSort,
    props.initialSortConfig,
  );

  {
    /* 
    Calculation for Pagination
  
  
  */
  }

  var cloneData = _.clone(items);
  var paginatedData = cloneData;
  const [page, setPage] = useState(1);
  let pageCount = Math.ceil(props.totalCount / ent);
  const pageLimit = pageCount <= 5 ? pageCount : 5;
  const getPaginatedGroup = () => {
    let start = Math.floor((props.currentPage - 1) / pageLimit) * pageLimit || 0;
    return new Array(Math.min(pageLimit, pageCount - start)).fill(0).map((_, index) => start + index + 1);
  };

  const goToPreviousPage = () => {
    props.handlePagination(props.currentPage - 1);
  };

  const goToNextPage = () => {
    props.handlePagination(props.currentPage + 1);
  };

  const goToFirstPage = () => {
    props.handlePagination(1);
  };

  const goToLastPage = () => {
    props.handlePagination(pageCount);
  };

  useEffect(() => {
    props.handlePagination(1);
  }, [ent]);

  {
    /* 
   State of this Table
  
  
  */
  }
  const dispatch = useDispatch();

  {
    /* 




    
    GENERIC FUNCTIONS





    
    */
  }

  // const exportPDF = (title: string, headers: any, data: any) => {
  //   const unit = "pt";
  //   const size = "A4"; // Use A1, A2, A3 or A4
  //   const orientation = "landscape"; // portrait or landscape

  //   const marginLeft = 40;
  //   const doc = new jsPDF(orientation, unit, size);

  //   doc.setFontSize(15);
  //   let content = {
  //     startY: 50,
  //     head: headers,
  //     body: data,
  //   };

  //   doc.text(title, marginLeft, 40);
  //   autoTable(doc, content);
  //   doc.save(`${title}_report.pdf`);
  // };

  useEffect(() => {
    defaultSort(props.initialSortConfig?.key || "", props?.initialSortConfig?.direction || "");
  }, []);

  // useEffect(() => {
  //   if (props.selected && props.onRowClick) {
  //     props.onRowClick(props.selected);
  //   }
  // }, [props.selected, props.onRowClick]);

  return (
    <div className="table-with-loader">
      <div className={props.size === "compact" ? "table-responsive custom compact" : "table-responsive custom"}>
        <div
          className={props.size === "compact" ? "scrollable-table-container-compact" : "scrollable-table-container-lg"}
        >
          <table
            className={` ${tableClass} table ${props.bordered ? "table-bordered" : "table-borderless"} ${
              props.hoverable && "table-hover"
            } ${props.striped && "table-striped"} ${props.isDark && "table-dark"}`}
          >
            <thead>
              <tr>
                {props.cols?.map(
                  (headerItem: TableConstantProps, index: number) =>
                    headerItem.name !== "not_required" && (
                      <th
                        className="text-nowrap"
                        key={index}
                        onClick={() => {
                          if (headerItem.title != "checkbox" && headerItem.sortable) {
                            requestSort(headerItem.name);
                          }
                        }}
                      >
                        {headerItem.title === "checkbox" ? (
                          <div className="form-check">
                            <input
                              className="form-check-input "
                              type="checkbox"
                              id="flexCheckDefault"
                              onChange={() => props.checkAll(page)}
                              checked={props.allChecked?.get(page) || false}
                            ></input>
                          </div>
                        ) : (
                          <span className={headerItem.align ? `float-${headerItem.align}` : ""}>
                            {headerItem.title}
                          </span>
                        )}
                        {headerItem.sortable &&
                          (sortConfig.key === headerItem.name && sortConfig.direction === "asc" ? (
                            <HqmIcon
                              name="chevron-up"
                              className="float-right pr-0 sort-up"
                              onClick={() => requestSort(headerItem.name)}
                              focusable
                              size={14}
                            />
                          ) : sortConfig.key === headerItem.name && sortConfig.direction === "desc" ? (
                            <HqmIcon
                              name="chevron-down"
                              className="float-right sort-down"
                              onClick={() => requestSort(headerItem.name)}
                              size={14}
                            />
                          ) : (
                            <></>
                          ))}
                      </th>
                    ),
                )}
                {props.threeDotsEnabled && (
                  <th key="dots" style={{ paddingBottom: 18 }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-three-dots-vertical"
                      viewBox="0 0 16 16"
                      onClick={(e) => {
                        alert("clicked 3 dots");
                        return;
                      }}
                      style={{ float: "right" }}
                    >
                      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                    </svg>
                  </th>
                )}
              </tr>
            </thead>

            <tbody style={{ filter: props.isDataLoading ? "blur(2px)" : "blur(0px)" }}>
              {paginatedData?.length === 0 && !props.isDataLoading ? (
                <tr aria-disabled>
                  <td
                    colSpan={props.cols?.length + (props.threeDotsEnabled ? 1 : 0)}
                    style={{
                      textAlign: "center",
                      fontStyle: "italic",
                      cursor: "auto",
                    }}
                  >
                    No Records Found!
                  </td>
                </tr>
              ) : (
                <>
                  {Array.isArray(paginatedData)
                    ? paginatedData?.map((item: any, index: number) => (
                        <tr
                          key={index}
                          // data-view={view}
                          className={props.selected && item.id === props.selected ? "is-active" : ""}
                          onClick={() => props.onRowClick?.(item)}
                        >
                          {props.cols?.map((col: TableConstantProps, key: number) => (
                            <td
                              className="text-nowrap"
                              key={key}
                              onClick={() => {
                                if (col.notClickable === true) {
                                  return;
                                } else {
                                  props.onRowClick && props.onRowClick(item);
                                }
                              }}
                            >
                              {col.render(item)}
                            </td>
                          ))}
                          {props.threeDotsEnabled && (
                            <td key={`opt-${index}`}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-three-dots-vertical"
                                viewBox="0 0 16 16"
                                style={{ float: "right" }}
                                onClick={(e) => {
                                  alert("3 dots clicked!");
                                  return;
                                }}
                              >
                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                              </svg>
                            </td>
                          )}
                        </tr>
                      ))
                    : null}
                </>
              )}
            </tbody>
            <tfoot></tfoot>
          </table>
          {props.isDataLoading && (
            <div className="mhc-loader-flexbox">
              <MHCSpinner type="light" />
            </div>
          )}
        </div>
        {props.isPaginationRequired && (
          <>
            <div className="paginate" style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="displayed-page-text">
                {Math.floor((props.currentPage - 1) * props.entries) + 1}
                {" - "}
                {(props.currentPage - 1) * props.entries + props.data?.length}
                {" of "}
                {[props.totalCount]} {props.componentName?.toLowerCase()}
              </div>
              {paginatedData?.length > 0 && (
                <ul className="pagination pull-right">
                  <>
                    {/* <button
                  onClick={goToFirstPage}
                  disabled={props.currentPage === 1}
                >
                   <i className="fa fa-angle-double-left" aria-hidden="true"></i> 
                  <HqmIcon name="chevron-left" size={20}/>
                </button> */}
                    &nbsp; &nbsp;
                    <button
                      onClick={goToPreviousPage}
                      disabled={props.currentPage === 1}
                      style={{
                        padding: "0px",
                        marginRight: "10px",
                        width: "36px",
                        height: "36px",
                        borderRadius: "4px",
                      }}
                    >
                      {/* <i className="fa fa-angle-left" aria-hidden="true"></i> */}
                      <HqmIcon name="chevron-left" size={20} />
                    </button>
                    {getPaginatedGroup()?.map((ele: any, i: number) => (
                      <li
                        key={`paginatedGroup${i}`}
                        className={ele === props.currentPage ? "page-item active" : "page-item"}
                        value={ele}
                        // onClick={(e) => setPage(e.currentTarget.value)}
                        onClick={(e) => {
                          props.handlePagination(e.currentTarget.value);
                          setPage(e.currentTarget.value);
                        }}
                      >
                        <div className="page-link">{ele}</div>
                      </li>
                    ))}
                    <button
                      disabled={props.currentPage === pageCount}
                      onClick={goToNextPage}
                      style={{ padding: "0px", marginLeft: "10px", width: "36px", height: "36px", borderRadius: "4px" }}
                    >
                      {/* <i className="fa fa-angle-right" aria-hidden="true"></i> */}
                      <HqmIcon name="chevron-right" size={20} />
                    </button>
                    {/* <button
                  disabled={props.currentPage === pageCount}
                  onClick={goToLastPage}
                >
                   <i
                    className="fa fa-angle-double-right"
                    aria-hidden="true"
                  ></i> 
                  <HqmIcon name="chevron-right" size={20}/>
                </button> */}
                  </>
                </ul>
              )}

              <div className="d-flex align-items-center paginate-entries">
                <label>Showing &nbsp;&nbsp;&nbsp;</label>
                <select
                  //className="borderless"
                  value={ent}
                  onChange={(e) => {
                    props.handleEntries(Number(e.target.value));
                    setEnt(Number(e.target.value));
                    setPage(0);
                  }}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>

                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  {props.size !== "compact" &&
                    //    !props.isDataLoading &&
                    paginatedData?.length > 0 && <span>&nbsp;&nbsp;&nbsp;results per page</span>}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* {props.isDataLoading && <CircularProgress className="mhc-loader-flexbox"/>} */}
    </div>
  );
};

export default PaginatedTable;
