import { Stack } from "@mui/material";
import HqmIcon from "components/icon";
import moment from "moment";
import mhcTheme from "../../assets/style/style.module.scss";

export const DiscountTableConstants = (
  handleEditDiscount: Function,
  checkDiscount: Function,
  checked: Array<number> = [],
  partners: any,
  setPartners: any,
  skumap: any,
  setSk: any,
  delete_productdiscount: any,
) => {
  const ary: { company_name: any; id: any }[] = [];
  for (let i = 0; i < partners.length; i++) {
    ary.push({ company_name: partners[i].company_name, id: partners[i].id });
  }

  return [
    {
      title: "checkbox",
      name: "checkbox",
      notClickable: true,
      render: (rowData: any) => {
        return (
          <div className="form-check">
            <input
              disabled={!delete_productdiscount}
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              onChange={() => checkDiscount(parseInt(rowData.id))}
              onClick={(e) => e.stopPropagation()}
              checked={delete_productdiscount && checked.includes(parseInt(rowData.id))}
            />
          </div>
        );
      },
    },

    {
      title: "Code",
      name: "discount_code",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.discount_code}</span>;
      },
    },
    // {
    //   title: "SKUs",
    //   name: "sku",
    //   sortable: true,
    //   render: (rowData: any) => {
    //     // <Stack direction={"row"} sx={{ flexWrap: "wrap" }} gap={"4px"}>
    //     //   {rowData.sku.map((sku: any, index: number) => {
    //     //     if (!skumap?.[sku]) return null;
    //     //     return (
    //     //       <span key={sku}>
    //     //         {skumap[sku].sku}
    //     //         {index !== rowData.sku.length - 1 && ", "}
    //     //       </span>
    //     //     );
    //     //   })}
    //     // </Stack>
    //     console.log("rowData?.sku", rowData?.sku);
    //     <Stack direction={"row"} sx={{ flexWrap: "wrap" }} gap={"4px"}>

    //     </Stack>

    //     return <span>{rowData?.sku}</span>;
    //   },
    // },
    {
      title: "Partner",
      name: "partner",
      sortable: true,

      render: (rowData: any) => {
        return <span>{ary.find((x) => x.id === rowData?.partner)?.company_name}</span>;
      },
    },
    {
      title: "Start Date",
      name: "start_date",
      sortable: true,
      render: (rowData: any) => {
        const startDate = moment(rowData?.start_date).format("YYYY/MM/DD");
        return <span>{startDate}</span>;
      },
    },
    {
      title: "End Date",
      name: "end_date",
      sortable: true,
      render: (rowData: any) => {
        const endDate = moment(rowData?.end_date).format("YYYY/MM/DD");
        return <span>{endDate}</span>;
      },
    },
    {
      title: "Discount %",
      name: "discount_percentage",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.discount_percentage}</span>;
      },
    },
    {
      title: "Count",
      name: "count",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.count}</span>;
      },
    },
    {
      title: "Edit",
      name: "action",
      //sortable: true,
      render: (rowData: any) => {
        return (
          <span>
            <HqmIcon
              name="edit"
              size={20}
              style={{ color: mhcTheme.secondary }}
              onClick={() => handleEditDiscount(rowData)}
            />
          </span>
        );
      },
    },
  ];
};
