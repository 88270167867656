import React, { useState } from "react";
import "./patientpdf.scss";
import "./ShowMoreContent.scss";

interface Props {
  str: string;
}

const ShowMoreContent: React.FC<Props> = ({ str }) => {
  const stringLength = str.length || 0;
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className={`show-more ${isExpanded ? "more" : "less"} `}>{str}</div>
      <span style={{ cursor: "pointer", marginTop: "0.6rem", color: "#6554b2" }} onClick={toggleExpanded}>
        {stringLength > 170 && (!isExpanded ? "...Show More" : "Show Less")}
      </span>
    </>
  );
};

export default ShowMoreContent;
