import mhcTheme from "./assets/style/style.module.scss";

const cssVar = (name: string, alternative: string) =>
  getComputedStyle(document.documentElement).getPropertyValue(name).trim() || alternative;

export const theme = {
  palette: {
    primary: {
      main: cssVar("--primary-color", window.location.href.includes("regimen360") ? "#535ADF" : "#594A9E"),
    },
  },
  typography: {
    fontFamily: "Nunito-Regular",
  },
  custom: {
    white: mhcTheme.white,
    paleWhite: mhcTheme.paleWhite,
    purple: mhcTheme.purple,
    lightPurple: mhcTheme.lightPurple,
    darkPurple: mhcTheme.darkPurple,
    yellow: mhcTheme.yellow,
    black: mhcTheme.black,
    pureBlack: mhcTheme.pureBlack,
    grey: mhcTheme.grey,
    lightGrey: mhcTheme.lightGrey,
    lighterGrey: mhcTheme.lighterGrey,
    green: mhcTheme.green,
    red: mhcTheme.red,
    paleRed: mhcTheme.paleRed,
    palePurple: mhcTheme.palePurple,
    orange: mhcTheme.orange,
    facebook: mhcTheme.facebook,
    google: mhcTheme.google,
    bronze: mhcTheme.bronze,
    silver: mhcTheme.silver,
    gold: mhcTheme.gold,
    secondary: mhcTheme.secondary,
    fontPrimary: mhcTheme.primaryFont,
  },
};
