import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAPatientData } from "redux/PatientList/PatientList";
import { fetchProductDateTables } from "redux/productDate/productDate";
import { RootState } from "redux/store";
import "./PatientHistory.scss";

import PaginatedTable from "components/Table/PaginatedTable";
import config from "config";
import { HeadingComponent } from "layouts/PatientData/PatientData";
import { PatientHistoryTable } from "./PatientHistoryTable";

const PatientHistory = () => {
  // const location: any = useLocation();
  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    scrollTo(0, 0);
  }, []);

  let history = useHistory();
  const patient_pk_id = useSelector((state: RootState) => state.physicianPortalReducer.patient_pk_id);

  const patient_user_profile_id = useSelector((state: RootState) => {
    return state.physicianPortalReducer.patient_user_profile_id;
  });

  const patient_emr = useSelector((state: RootState) => state.physicianPortalReducer.emr_no);

  // const locData = location.state;
  // console.log("loc"locData)

  // for pagination table
  const [page, setPage] = useState(1);
  const [paginateBy, setPaginateBy] = useState(config.PAGE_SIZE);
  const [sortField, setSortField] = useState("start_date");
  const [sortDirection, setSortDirection] = useState("desc");

  const handlePagination = () => {
    setPage(1);
  };
  const handleSort = (key: string, direction: string) => {
    setSortField(key);
    setSortDirection(direction);
  };

  const { data: patientDataList, isLoading } = useQuery(["patient data list", sortField, sortDirection], () => {
    return fetchProductDateTables(patient_user_profile_id, sortField, sortDirection);
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const patientProductData = patientDataList && !_.isEmpty(patientDataList.data) ? patientDataList?.data : [];

  //fetch
  const { data: patientDataDetails } = useQuery(["get patient by id"], () => {
    return getAPatientData(patient_pk_id);
  });

  const { shampoo, laser, oral }: any = useMemo(() => {
    // const shampoo = [];
    const shampoo: { product_name: string; start_date: any; end_date: any }[] = [];
    const oral: { product_name: string; start_date: any; end_date: any }[] = [];
    const laser: { product_name: string; start_date: any; end_date: any }[] = [];

    for (let i = 0; i < patientProductData.length; i++) {
      if (patientProductData[i].treatment_category.category_code === "SHAMPOO") {
        shampoo.push({
          product_name: patientProductData[i]?.treatment_product?.product_name,
          start_date: patientProductData[i]?.start_date,
          end_date: patientProductData[i]?.start_date,
        });
      } else if (patientProductData[i].treatment_category.category_code === "ORAL") {
        oral.push({
          product_name: patientProductData[i]?.treatment_product?.product_name,
          start_date: patientProductData[i]?.start_date,
          end_date: patientProductData[i]?.start_date,
        });
      } else if (patientProductData[i].treatment_category.category_code === "LASER") {
        laser.push({
          product_name: patientProductData[i]?.treatment_product?.product_name,
          start_date: patientProductData[i]?.start_date,
          end_date: patientProductData[i]?.start_date,
        });
      }
    }

    return {
      shampoo,
      laser,
      oral,
    };
  }, [patientProductData]);

  const headingItems = [
    {
      title: "Patient ID:",
      component: patient_emr || patientDataDetails?.data?.emr_number || patientDataDetails?.data?.patient_id,
    },

    {
      title: "Full Name:",
      component: patientDataDetails?.data?.user_profile?.full_name,
    },
    {
      title: "Gender:",
      component: <span style={{ textTransform: "capitalize" }}>{patientDataDetails?.data?.user_profile?.gender} </span>,
    },
    {
      title: "DOB:",
      component: patientDataDetails?.data?.user_profile?.date_of_birth
        ? patientDataDetails?.data?.user_profile?.date_of_birth
        : "-",
    },
    {
      title: "Appointment Date:",
      component: patientDataDetails?.data?.appointment_time.toString().substring(0, 10),
    },
  ];

  return (
    <>
      <div
        className="patient-info"
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "1rem 0",
          maxWidth: "91vw",
          margin: "auto",
        }}
      >
        {headingItems.map((item, index) => (
          <HeadingComponent key={"headingItems" + index} {...item} />
        ))}
      </div>
      <div style={{ marginTop: "1rem" }} className="patient-container">
        <div className="page">
          <div className="module-header">
            <div style={{ display: "flex", flexDirection: "row", gap: "1rem", alignItems: "center" }}>
              <div className="module-name">Treatment History</div>
              <div>
                <button
                  className="product-btn"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
        <PaginatedTable
          tableClass={"align-left"}
          cols={PatientHistoryTable()}
          data={patientProductData}
          isDark={false}
          bordered
          striped={false}
          hoverable
          entries={paginateBy}
          onRowClick={() => {}}
          //selected={selectedRowID}
          componentName={"Patient History"}
          handlePagination={handlePagination}
          // handleEntries={1}
          currentPage={1}
          totalCount={1}
          size="lg"
          initialSortConfig={{ key: sortField, direction: sortDirection }}
          // isDataLoading={isFetching || postLoading || deleteLoading}
          handleSort={handleSort}
          // isPaginationRequired={true}
          // cols={[]}
        />{" "}
        <button
          style={{ marginTop: "2rem" }}
          className="product-btn"
          onClick={() => {
            history.goBack();
          }}
        >
          Back
        </button>
      </div>
    </>
  );
};

export default PatientHistory;
