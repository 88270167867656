import { Box, Modal } from "@material-ui/core";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import MHCSpinner from "components/MHCSpinner";
import { Formik } from "formik";
import { SelectItem } from "layouts/CompanyDetails/components/TextInput";
import React, { useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { fetchCancellationReasons } from "redux/reviewview/api";
import { theme } from "theme";
import * as Yup from "yup";
import { GenericFn } from "../../config";
import "./reviewview.scss";

export interface RetakeFormProps {
  loading?: boolean;
  onSubmit: (values: Record<string, any>) => void;
  onClose: () => void;
}

enum CancelReason {
  ANALYSIS_RESULT = "ANALYSIS_RESULT",
  CAUSES_RESULT = "CAUSES_RESULT",
  SUGGESTION_RESULT = "SUGGESTION_RESULT",
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "55%",
  height: "auto",
  bgcolor: "background.paper",
  p: 4,
  borderRadius: "80px",
  boxShadow: "4px 4px 4px 2px rgba(0, 0, 0, 0.25)",
};
const useCancelReason = (data: any, type: CancelReason) => {
  return useMemo(() => {
    if (!data) return [];
    return data
      .filter((item: any) => item.type === type)
      .map((i: any) => ({ label: i.reason, value: i.id }))
      .sort((a: { label: any }, b: { label: string }) => a.label.localeCompare(b.label));
  }, [data, type]);
};

const useCancelReasonCauses = (data: any, type: CancelReason) => {
  return useMemo(() => {
    if (!data) return [];
    return data
      .filter((item: any) => item.type === type)
      .map((i: any) => ({ label: i.reason, value: i.id }))
      .sort((a: { label: string; value: any }, b: { label: string; value: any }) => {
        const prefixA = parseInt(a.label.split(".")[0]);
        const prefixB = parseInt(b.label.split(".")[0]);
        return prefixA - prefixB;
      });
  }, [data, type]);
};

export const RetakeForm = (props: RetakeFormProps) => {
  const { onSubmit, onClose, loading = false } = props;

  const [showModal, setShowModal] = useState(false);
  const openModals = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  const { data, isLoading } = useQuery("cancellationReason", fetchCancellationReasons);

  const analysisReasons = useCancelReason(data, CancelReason.ANALYSIS_RESULT);
  const causesReasons = useCancelReasonCauses(data, CancelReason.CAUSES_RESULT);
  const suggestionReasons = useCancelReason(data, CancelReason.SUGGESTION_RESULT);

  if (isLoading) {
    return <MHCSpinner type="light" />;
  }

  return (
    <>
      <Formik
        initialValues={{ analysis: [], causes: [], suggestions: [] }}
        validationSchema={Yup.object().shape({
          analysis: Yup.array(Yup.number()).min(1, "Please add at least one cancellation reason"),
          causes: Yup.array(Yup.number()).min(1, "Please add at least one cancellation reason"),
          suggestions: Yup.array(Yup.number()).min(1, "Please add at least one cancellation reason"),
        })}
        onSubmit={onSubmit}
      >
        {(formik) => {
          const { touched, errors } = formik;

          const handleCancellationReasons = (label: string) => (value: string[]) => {
            formik.setFieldValue(label, Array.from(new Set([...value])));
          };

          return (
            <>
              <Modal open={showModal} onClose={handleClose}>
                <Box sx={style}>
                  <div>
                    <div style={{ fontWeight: "bold", color: theme.palette.primary.main, textAlign: "center" }}>
                      Attention
                    </div>
                  </div>

                  <div style={{ textAlign: "justify", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    Please select at least one reason from all three topics &nbsp;
                    {/* {formik.values.analysis.length + formik.values.causes.length + formik.values.suggestions.length > 2?} */}
                    {/* {formik.values.analysis.length > 0 ? null : `reasons `}
                    {formik.values.causes.length > 0 ? null : "possible causes "}
                    {formik.values.suggestions.length > 0 ? null : "suggestions "} */}
                    <div>Select them to move forward.</div>
                    <div style={{ marginTop: "1rem" }}>
                      <button
                        className="product-btn"
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        OK
                      </button>
                    </div>
                    {/* {formik.values.analysis.length > 0 &&
                    formik.values.causes.length > 0 &&
                    formik.values.suggestions.length > 0
                      ? null
                      : "no values"} */}
                  </div>
                  {/* <div>
                    <p>{errors}</p>
                  </div> */}
                </Box>
              </Modal>
              <DialogTitle
                id="alert-dialog-title"
                style={{ color: theme.palette.primary.main }}
              >{`Reason for Retaking`}</DialogTitle>
              <div className="selections">
                <div className="form-group">
                  <label className="reason-label">
                    We tried to analyze your hair images but could not get a reliable hair density due to the image
                    quality reason(s) below:
                  </label>
                  <CustomRadioGroup items={analysisReasons} onChange={handleCancellationReasons("analysis")} />
                </div>

                {/* <div className="error-message">
                  {errors.analysis && touched.analysis && <span>*{errors.analysis || ""}</span>}
                </div> */}

                <div className="form-group">
                  <label className="reason-label">Possible Causes:</label>
                  <CustomRadioGroup items={causesReasons} onChange={handleCancellationReasons("causes")} />
                </div>
                {/* <div className="error-message">
                  {errors.causes && touched.causes && <span>*{errors.causes || ""}</span>}
                </div> */}

                <div className="form-group">
                  <label className="reason-label">Suggestions to Improve:</label>
                  <CustomRadioGroup
                    single
                    items={suggestionReasons}
                    onChange={handleCancellationReasons("suggestions")}
                  />
                </div>

                {/* <div className="error-message">
                  {errors.suggestions && touched.suggestions && <span>*{errors.suggestions || ""}</span>}
                </div> */}
              </div>
              <DialogActions
                style={{ display: "flex", justifyContent: "space-between", padding: "1.5rem" }}
                className="module-action"
              >
                <button className="module-add-button cancel" onClick={onClose}>
                  Go Back
                </button>
                {
                  <button
                    onClick={() => {
                      console.log(
                        "formik.values",
                        formik.values.analysis.length + formik.values.causes.length + formik.values.suggestions.length,
                      );

                      if (
                        formik.values.analysis.length < 1 ||
                        formik.values.causes.length < 1 ||
                        formik.values.suggestions.length < 1
                      ) {
                        openModals();
                        console.log("first");
                      }
                      formik.handleSubmit();
                    }}
                    className="module-add-button"
                  >
                    <span style={{ marginRight: loading ? "0.5rem" : 0 }}>Retake Review</span>{" "}
                    {loading && <Spinner animation="border" size="sm" />}
                  </button>
                }
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </>
  );
};

interface CustomSelectProps {
  options: { label: string; value: string }[];
  onChange?: GenericFn;
}

const CustomSelect = React.forwardRef<any, CustomSelectProps>((props, ref) => {
  const { options, onChange } = props;

  const [selected, setSelected] = useState<{ label: string; value: string }[]>([]);

  return (
    <div className={"custom-select"}>
      <Select
        value={selected}
        onChange={(val: any) => {
          onChange?.(val.map((i: any) => Number(i.value)));
          setSelected(val);
        }}
        styles={{
          menu: (base) => ({ ...base, padding: "0.2rem 0.5rem" }),
          menuList: (base) => ({ ...base, display: "flex", flexWrap: "wrap" }),
          option: (base) => ({ ...base, width: "unset", marginRight: "0.8rem", background: "#e1e1e1" }),
        }}
        isMulti
        options={options}
      />
    </div>
  );
});

interface CustomRadioGroupProps {
  items: SelectItem[];
  onChange?: GenericFn;
  single?: boolean;
}

const handleToggle = (v: any, value: string) => {
  if (!v[value]) return { ...v, [value]: !v[value] };
  else {
    const temp = { ...v };
    delete temp[value];
    return temp;
  }
};

export const CustomRadioGroup: React.FC<CustomRadioGroupProps> = (props) => {
  const { items, onChange, single } = props;

  const [selected, setSelected] = useState<Record<string, boolean | undefined>>({});

  const handleClick = (e: any, value: string) => {
    const toggled = handleToggle(selected, value);

    setSelected(toggled);
    onChange?.(Object.keys(toggled));
  };

  return (
    <div className={`custom-radio ${single && "single"}`}>
      {items.map((item, index) => {
        return (
          <div
            key={`selected${index}`}
            className="custom-radio-items"
            onClick={(e) => handleClick(e, item.value.toString())}
          >
            <input type="radio" checked={Boolean(selected?.[item.value])} onChange={console.log} />
            <span>
              {item.label.split("\n").map((item, index) => {
                return (
                  <>
                    {item} {"\n"}
                  </>
                );
              })}
            </span>
          </div>
        );
      })}
    </div>
  );
};
