import HqmIcon from "components/icon";
import MHCSpinner from "components/MHCSpinner";
import { auth } from "firebase-config";
import {
  browserLocalPersistence,
  browserSessionPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useLocation } from "react-router-dom";
import { RootState } from "redux/store";
import * as Yup from "yup";
import { ReactComponent as Logo } from "../../assets/brand/logo.svg";
import * as routes from "../../constants/routes";
import "./login.scss";

const Login = () => {
  const dispatch = useDispatch();
  //const [email,setEmail] = useState('');
  //const [password,setPassword] = useState('');

  const location: any = useLocation();
  const { from } = location.state || { from: { pathname: "/home" } };

  const [pending, setPending] = useState(true);
  const [user, setUser] = useState<any>(null);
  const [eye, setEye] = useState("eye-off");

  const toggleEye = () => {
    setEye(eye === "eye-off" ? "eye" : "eye-off");
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { email: "", password: "", keepLoggedIn: "false" },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please enter email"),
      password: Yup.string().required("Please enter password"),
    }),
    onSubmit: (values: any) => {
      login(values);
    },
  });

  const login = async (values: any) => {
    try {
      setPersistence(auth, values.keepLoggedIn + "" === "true" ? browserLocalPersistence : browserSessionPersistence)
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return signInWithEmailAndPassword(auth, values?.email, values?.password); //'test@test.com','Nepal2020')
        })
        .catch((err: any) => {
          if (err.code === "auth/user-not-found") {
            //alert('User not found');
            formik.setFieldError("password", "User not found");
          } else if (err.code === "auth/wrong-password") {
            //alert('Wrong password');
            formik.setFieldError("password", "Wrong password");
          } else {
            alert(err.message);
          }
          //formik.setFieldError("password",err?.message || "Something went wrong!");
        });
    } catch (e: any) {
      console.log(e);
      return null;
    }
  };

  onAuthStateChanged(auth, (currentUser: any) => {
    if (currentUser && user?.accessToken != currentUser?.accessToken) {
      setUser(currentUser);
      //dispatch(loginSuccess(currentUser));
    }
    setPending(false);
  });

  //const user = auth?.currentUser;
  const reduxUser = useSelector((state: RootState) => state.loginReducer.user);

  if (pending) {
    return <MHCSpinner type="light" />;
  }

  return user ? (
    <Redirect to={from} />
  ) : (
    <form className="container login-page" onSubmit={formik.handleSubmit}>
      <div className="brand-section">
        <Logo className="login-page-logo" />
        <div className="brand-description">
          {window.location.href.includes("regimen360") ? (
            <span className="brand-name">
              Regimen 360 Treatment <br />
              and Compliance Portal
            </span>
          ) : (
            <>
              <span className="brand-name">MyHairCounts</span>
              <span className="app-platform">Web Portal</span>
            </>
          )}
        </div>
      </div>
      <div className="form-section">
        <div className="login-form-container">
          <div className="login-title">
            <span className="title">Login</span>
          </div>
          <div className="login-form">
            <div className="form-group">
              <label>Email</label>
              <input
                className={
                  formik.errors && formik.errors?.email && formik.touched && formik.touched?.email
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="email"
                value={formik.values?.email || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors && formik.errors?.email && formik.touched && formik.touched?.email && (
                <div className="invalid-feedback">{formik.errors?.email || ""}</div>
              )}
            </div>
            <div className="form-group">
              <label>Password</label>
              <div
                className={
                  formik.errors && formik.errors?.password && formik.touched && formik.touched?.password
                    ? "is-invalid password-field"
                    : "password-field"
                }
              >
                <input
                  className={
                    formik.errors && formik.errors?.password && formik.touched && formik.touched?.password
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  name="password"
                  type={eye === "eye" ? "text" : "password"}
                  value={formik.values?.password || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <HqmIcon name={eye} onClick={() => toggleEye()} size={18} />
              </div>
              {formik.errors && formik.errors?.password && formik.touched && formik.touched?.password && (
                <div className="invalid-feedback">{formik.errors?.password || ""}</div>
              )}
            </div>
            <div className="keep-logged-in">
              <input
                name="keepLoggedIn"
                type="checkbox"
                value={formik.values?.keepLoggedIn || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label className="keep-logged-in-text">Keep me logged in</label>
            </div>
          </div>
          <div className="login-footer">
            <button className="login-submit">Login</button>
            <Link to={routes.FORGOT_PASSWORD}>
              <div className="forgot-password">Forgot Password?</div>
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Login;
