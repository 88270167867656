import styles from "./review-screen.module.scss";

import { ChevronLeft, ChevronRight } from "@material-ui/icons";

import { GenericFn } from "config";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import A from "../../assets/brand/1A.png";
import B from "../../assets/brand/1B.png";
import C from "../../assets/brand/1C.png";
import D from "../../assets/brand/1D.png";
import E from "../../assets/brand/1E.png";
import F from "../../assets/brand/1F.png";
import G from "../../assets/brand/1G.png";
import logo_back from "../../assets/brand/back.png";
import logo_front from "../../assets/brand/front.png";
import logo_left from "../../assets/brand/left.png";
import logo_right from "../../assets/brand/right.png";
import logo_crown from "../../assets/brand/crown.png";
import { useCallback } from "react";

const logoMap = {
  "1A": A,
  "1B": B,
  "1C": C,
  "1D": D,
  "1E": E,
  "1F": F,
  "1G": G,
  front: logo_front,
  back: logo_back,
  left: logo_left,
  right: logo_right,
  crown: logo_crown,
};

export type HeadImage = keyof typeof logoMap;

export interface ReviewScreenHeadCarouselProps {
  onHeadChange?: GenericFn;
}

const ReviewScreenHeadCarousel: React.FC<ReviewScreenHeadCarouselProps> = (props) => {
  const { onHeadChange } = props;

  const activeId = useSelector((state: RootState) => state.reviewScreen.head.selected);
  const headData = useSelector((state: RootState) => state.reviewScreen.head.list);

  const handlePrev = useCallback(() => {
    const activeIndex = headData.indexOf(activeId);
    const prevSelectIndex = activeIndex === 0 ? headData.length - 1 : activeIndex - 1;
    onHeadChange?.(headData[prevSelectIndex], headData);
  }, [activeId, headData, onHeadChange]);

  const handleNext = () => {
    const activeIndex = headData.indexOf(activeId);
    const nextSelectIndex = activeIndex === headData.length - 1 ? 0 : activeIndex + 1;

    onHeadChange?.(headData[nextSelectIndex], headData);
  };

  //edges of carousel

  return (
    <div className={styles.review_screen_head_carousel}>
      <div className={`${styles.review_screen_head_carousel_arrow} `} onClick={handlePrev}>
        <ChevronLeft />
      </div>

      <div className={styles.review_screen_head_image}>
        <img src={logoMap[activeId]} alt="1a" />
      </div>
      <div className={`${styles.review_screen_head_carousel_arrow} `} onClick={handleNext}>
        <ChevronRight />
      </div>
    </div>
  );
};

export default ReviewScreenHeadCarousel;
