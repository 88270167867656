import { Modal } from "@material-ui/core";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloseIcon from "@mui/icons-material/Close";
import CompareIcon from "@mui/icons-material/Compare";
import Looks3Icon from "@mui/icons-material/Looks3";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import Looks2Icon from "@mui/icons-material/LooksTwo";
import PersonIcon from "@mui/icons-material/Person";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import Box from "@mui/material/Box";
import shuffle from "assets/brand/shuffle.svg";
import MHCSpinner from "components/MHCSpinner";
import moment from "moment";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { fetchPhysicianReview } from "redux/physicianReview/api";
import { RootState } from "redux/store";
import { theme } from "theme";
import noimageIcon from "../../../assets/brand/noImage.svg";
import BAModel from "../BAModel/BAModel";
import ZoomImage from "../Zoom/ZoomImage";
import DateRangePicker from "./Datepicker";
import "./PatientData.scss";
import { CaptureImageData } from "./types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#797575",

  boxShadow: 24,
};

const styleZoom = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#797575",

  boxShadow: 24,
  // pt: 2,
  // px: 4,
  pb: 3,
};

const useDict = (data: any[]) =>
  useMemo(
    () =>
      data?.reduce((t: any, c: any) => {
        const { head_section_code, ...rest } = c;
        t[head_section_code] = rest;
        return t;
      }, {} as Record<string, any>),
    [data],
  );

// const PatientComment = forwardRef((props, ref) => {
const HeadIcon = forwardRef((props: any, ref) => {
  const { section, onChange: onActiveChange, display: displayInBNA, buttonList: ButtonList } = props;
  // anti pattern
  const [sectionLocal, setSectionLocal] =
    useState<Record<string, { active?: boolean; img: string; id: string }>>(section);

  const patient_user_profile_id: number = useSelector(
    (state: RootState) => state.physicianPortalReducer.patient_user_profile_id,
  );

  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);

  // baseline state
  const [baselineImageData, setBaselineImageData] = useState<CaptureImageData[]>([]);
  // latest state
  const [latestImageData, setLatestImageData] = useState<CaptureImageData[]>([]);

  const [baselineCaptureDate, setBaselineCaptureDate] = useState<string>("");
  const [latestCaptureDate, setLatestCaptureDate] = useState<string>("");

  // active baseline session
  const [baselineSessionIndex, setBaselineSessionIndex] = useState<number>(0);
  // active latest session
  const [latestSessionIndex, setLatestSessionIndex] = useState<number>(0);

  // bna zoom toogle
  const [toggel, setToggel] = useState(false);
  // toggle base zoom
  const [toggelBase, setToggelBase] = useState(false);
  // toggle last zoom
  const [toggelLast, setToggelLast] = useState(false);

  const [changeScore, setChangeScore] = useState(false);
  const [toggelFull, setToggelFull] = useState(false);

  const customStyle = {
    containerStyle: {
      width: "95%",
      height: "100%",
    },
    mediaStyle: {
      // left: "-00%",
    },
    cropAreaStyle: {
      backgroundImage: "",
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
      left: "50%",
    },
  };

  const { isLoading } = useQuery(
    ["bnaInit data", patient_user_profile_id],
    () => fetchPhysicianReview(patient_user_profile_id),
    {
      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data?.length === 2) {
          const baseline = data?.[0];
          const latest = data?.[data?.length - 1];

          // we could ve used captured date array to set default
          // TODO: in the future

          setBaselineImageData([baseline]); // set default
          fetchBaselineData(baseline?.capture_date); // fetch multiple sessions

          setLatestImageData([latest]); // set default
          fetchLatestData(latest?.capture_date); // fetch multiple sessions
        }
      },
    },
  );

  const {
    mutateAsync: fetchBaselineData,
    // isLoading: baselineFetchLoading,
    // isSuccess,
  } = useMutation(
    ["baselineImages", patient_user_profile_id],
    (captureDate: string) => fetchPhysicianReview(patient_user_profile_id, captureDate),
    {
      onSuccess: (baselineSession) => {
        if (baselineSession) {
          setBaselineImageData(baselineSession.slice(0, 3));
        }
      },
    },
  );

  const {
    mutateAsync: fetchLatestData,
    // isLoading: latestFetchLoading,
    // isSuccess,
  } = useMutation(
    ["latestImages", patient_user_profile_id],
    (captureDate: string) => fetchPhysicianReview(patient_user_profile_id, captureDate),
    {
      onSuccess: (latestSession) => {
        if (latestSession) {
          setLatestImageData(latestSession.slice(0, 3));
        }
      },
    },
  );

  function handleSuffel() {
    setToggel(!toggel);
  }

  function handleBase() {
    setToggelBase(!toggelBase);
  }

  function handleLast() {
    setToggelLast(!toggelLast);
  }

  function individual() {
    setChangeScore(true);
  }

  function overall() {
    setChangeScore(false);
  }

  function handleFullImage() {
    setToggelFull(!toggelFull);
  }

  function handleClose() {
    setToggel(false);
    setToggelBase(false);
    setToggelLast(false);
    setToggelFull(false);
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [prev, active, next] = useMemo(() => {
    const keyArr = Object.keys(sectionLocal);

    const active = keyArr
      .map((item) => {
        if (sectionLocal[item].active) {
          return item;
        }
        return undefined;
      })
      .filter((x) => x);
    const activeIndex = keyArr.indexOf(active?.[0]!);

    const prev = keyArr?.[activeIndex - 1] || keyArr?.[keyArr.length - 1];
    const next = keyArr?.[activeIndex + 1] || keyArr?.[0];
    const current = active?.[0] || "1C";

    return [prev, current, next];
  }, [sectionLocal]);

  useEffect(() => {
    onActiveChange?.(sectionLocal);
  }, [onActiveChange, sectionLocal]);

  useEffect(() => {
    props?.onLoading?.(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const selectedHeadRef = useRef<string>("1C");

  /**
   * Function to change active head image
   * @param str
   */
  const onChange = (str: string) => {
    setSectionLocal((s) => {
      const temp = { ...s };
      //remove current active
      temp[active].active = undefined;
      //change input to current
      temp[str].active = true;
      // set to reference
      selectedHeadRef.current = str;
      return temp;
    });
  };
  // used for first render to make group Icon 1C as Active Icon
  useEffect(() => {
    selectedHeadRef.current = "1C";
    setSectionLocal((s) => {
      const temp = { ...s };
      //remove current active
      temp[active].active = undefined;
      //change input to current
      temp["1C"].active = true;
      // set to reference
      selectedHeadRef.current = "1C";
      return temp;
    });
  }, []);

  // active session index to show active image data
  const selectedBaselineImageData = baselineImageData[baselineSessionIndex]; //baseline and latest interchanged
  const selectedLatestImageData = latestImageData[latestSessionIndex];

  // store section to variables
  const baseline_data = selectedBaselineImageData?.sections;
  const latest_data = selectedLatestImageData?.sections;

  // convert section array to map
  const latestDictionary = useDict(latest_data); //baseline and latest interchanged
  const baselineDictionary = useDict(baseline_data);

  const sessionHandleIconList = [LooksOneIcon, Looks2Icon, Looks3Icon];

  const SessionHandle = (props: {
    imageList: CaptureImageData[];
    type: "baseline" | "latest";
    activeIndex: number;
  }) => {
    const { imageList, type, activeIndex } = props;

    if (imageList.length === 1) {
      return <></>;
    }

    return (
      <div className="image_session">
        {imageList.map((image, index) => {
          const Icon = sessionHandleIconList[index];
          return (
            <div key={`session_handle ${index}`} className={`${activeIndex === index && "active_session"}`}>
              <Icon
                onClick={() => (type === "baseline" ? setBaselineSessionIndex(index) : setLatestSessionIndex(index))}
                sx={{ fontSize: "110%", color: theme.palette.primary.main }}
                style={{ margin: "auto", cursor: "pointer" }}
              ></Icon>
            </div>
          );
        })}
      </div>
    );
  };

  useImperativeHandle(ref, () => ({
    getPatientData() {
      return {
        baselineDictionary,
        latestDictionary,
        selectedHeadRef,
        baselineOverall: selectedBaselineImageData,
        latestOverall: selectedLatestImageData,
      };
    },
  }));

  if (isLoading) {
    return (
      <div className="mhc-loader-flexbox">
        <MHCSpinner type="light" />
      </div>
    );
  }

  return (
    <>
      <Modal open={toggel} onClose={handleClose}>
        <Box sx={{ ...style, width: "100%", height: "100%" }}>
          <div className="bamodelset">
            <div className="bna_container">
              <div style={{ cursor: "pointer", margin: "auto", marginLeft: "1%", marginRight: "1%" }}>
                <ArrowBackIosIcon
                  titleAccess="Previous Image"
                  sx={{ fontSize: "180%", color: "#594a9e" }}
                  style={{ margin: "auto" }}
                  onClick={() => onChange(prev)}
                />
              </div>
              <BAModel
                bimg={baselineDictionary?.[selectedHeadRef.current]?.image}
                next={next}
                prev={prev}
                limg={latestDictionary?.[selectedHeadRef.current]?.image}
              />

              <div style={{ cursor: "pointer", margin: "auto", marginLeft: "1%", marginRight: "1%" }}>
                <ArrowForwardIosIcon
                  titleAccess="Next Image"
                  sx={{ fontSize: "180%", color: "#594a9e" }}
                  style={{ margin: "" }}
                  onClick={() => onChange(next)}
                />
              </div>
            </div>
            <div className="baunity-icon">
              {/* <button onClick={() => onChange(prev)}>Prev</button> */}
              <Box sx={{ my: 2 }}>Comparison Slider</Box>
              {Object.keys(sectionLocal).map((ite) => {
                return (
                  <React.Fragment key={"head_section" + ite}>
                    <div
                      className={`icon1 icon-border ${sectionLocal[ite].active && "active-head"}`}
                      onClick={() => onChange(ite)}
                    >
                      <div key={ite}>
                        {/* {sectionLocal[ite].active } */}
                        {/* setActiveData(sectionLocal[ite]?.id) */}
                        <img src={sectionLocal[ite].img} alt="crown" style={{ width: "35px", height: "35px" }} />
                      </div>
                    </div>

                    {/* <div className="icon1 icon-border"> */}

                    {/* </div> */}
                  </React.Fragment>
                );
              })}
            </div>
            <div className="closeModal">
              <CloseIcon
                titleAccess="Close Model"
                sx={{ fontSize: "250%", color: theme.palette.primary.main, cursor: "pointer" }}
                onClick={handleClose}
              />
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={toggelBase} onClose={handleClose}>
        <Box sx={{ ...style, width: "100%", height: "100%" }}>
          <div className="cropper-box">
            <Cropper
              image={baselineDictionary?.[selectedHeadRef.current]?.image}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              showGrid={false}
              style={customStyle}
            />

            <div style={{ display: "flex", flexDirection: "column-reverse" }}>
              {/* <div style={{ width: "15%" }}>
                {" "}
                <Slider min={1} max={3} step={5} value={zoom} onChange={(e, zoom) => setZoom(Number(zoom))} />
              </div> */}
              <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <CloseIcon
                  sx={{ fontSize: "250%", color: theme.palette.primary.main, cursor: "pointer" }}
                  onClick={handleClose}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={toggelLast} onClose={handleClose}>
        <Box sx={{ ...style, width: "100%", height: "100%" }}>
          <div>
            <div>
              <Cropper
                image={latestDictionary?.[selectedHeadRef.current]?.image}
                crop={crop}
                zoom={zoom}
                aspect={50 / 100}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                showGrid={false}
                style={customStyle}
              />
            </div>
            {/* <div >
              {" "}
              <Slider min={1} max={2} step={5} value={zoom} onChange={(e, zoom) => setZoom(Number(zoom))} />
            </div> */}
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <CloseIcon
                sx={{ fontSize: "250%", color: theme.palette.primary.main, cursor: "pointer" }}
                onClick={handleClose}
              />
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={toggelFull} onClose={handleClose}>
        <Box sx={{ ...styleZoom, width: "100%", height: "100%" }}>
          <div>
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <CloseIcon
                style={{ zIndex: "9999" }}
                sx={{ fontSize: "250%", color: "#fff", cursor: "pointer" }}
                onClick={handleClose}
              />
            </div>
            <div className="full-img-grid">
              <div style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "cover", overflow: "hidden" }}>
                <ZoomImage image={baselineDictionary?.[selectedHeadRef.current]?.image} />
              </div>
              <div style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "cover", overflow: "hidden" }}>
                <ZoomImage image={latestDictionary?.[selectedHeadRef.current]?.image} />
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Icon Section */}
      <div className="unity-icon">
        {/* <button onClick={() => onChange(prev)}>Prev</button> */}
        {Object.keys(sectionLocal).map((ite) => {
          return (
            <React.Fragment key={"head_section" + ite}>
              <div
                className={`icon1 icon-border ${sectionLocal[ite].active && "active-head"}`}
                onClick={() => onChange(ite)}
                style={{ cursor: "pointer" }}
              >
                <div key={ite}>
                  {/* {sectionLocal[ite].active } */}
                  {/* setActiveData(sectionLocal[ite]?.id) */}
                  <img src={sectionLocal[ite].img} alt="crown" style={{ width: "40px", height: "40px" }} />
                </div>
              </div>

              {/* <div className="icon1 icon-border"> */}

              {/* </div> */}
            </React.Fragment>
          );
        })}
      </div>

      {/* B/A section */}

      <div className="mainContainer">
        <div className="arrow">
          <div className="arrowIcon">
            <ArrowCircleLeft
              titleAccess="Previous Image"
              sx={{ fontSize: "180%", color: theme.palette.primary.main }}
              style={{ margin: "auto", cursor: "pointer" }}
              onClick={() => onChange(prev)}
            />
          </div>
        </div>
        {/* baseline */}
        <div className="baseline">
          <div className="imgContaineroutline">
            <div style={{ marginRight: "4rem" }}>Baseline</div>
            <div style={{ marginBottom: "11px" }}>
              <DateRangePicker
                displayDate={baselineCaptureDate}
                type="baseline"
                parentCallback={async (val: any) => {
                  await fetchBaselineData(val);
                  setBaselineCaptureDate(val);
                }}
              />
            </div>
          </div>
          {baselineDictionary?.[selectedHeadRef.current] ? (
            <BNAImage image={baselineDictionary?.[selectedHeadRef.current]?.image || ""} />
          ) : (
            <div className="imageContainer noImg">
              <img src={noimageIcon} alt="" />
            </div>
          )}
          <div className="bna-footer">
            <div>
              <ZoomInIcon
                titleAccess="Zoom Image"
                sx={{ fontSize: "170%", color: theme.palette.primary.main }}
                style={{ cursor: "pointer" }}
                onClick={handleBase}
              />

              {selectedBaselineImageData?.capture_type === "SELF" ? (
                <PersonIcon
                  titleAccess="Self Captured"
                  sx={{ fontSize: "180%", color: theme.palette.primary.main }}
                  style={{ cursor: "pointer", paddingLeft: "0.5rem" }}
                />
              ) : (
                <CameraAltIcon
                  titleAccess="Professional Captured"
                  sx={{ fontSize: "180%", color: theme.palette.primary.main }}
                  style={{ cursor: "pointer", paddingLeft: "0.5rem" }}
                />
              )}
            </div>

            <SessionHandle
              activeIndex={baselineSessionIndex}
              imageList={baselineImageData}
              type="baseline"
            ></SessionHandle>
          </div>
          {/* dateChange value changes to true when date is passed to parent from datepicker*/}
        </div>
        <div className="action-container">
          <CompareIcon
            titleAccess="Compare Image"
            sx={{ fontSize: "180%", color: theme.palette.primary.main }}
            style={{ cursor: "pointer" }}
            onClick={handleFullImage}
          />
          {displayInBNA && (
            <img src={shuffle} title="Comparison Slider" alt="shuffle" className="shuffle" onClick={handleSuffel} />
          )}
        </div>
        {/* latest */}
        <div className="baseline second-img">
          <div className="imgContaineroutline">
            <div>Latest</div>
            <div style={{ marginBottom: "11px" }}>
              <DateRangePicker
                displayDate={latestCaptureDate}
                type="latest"
                parentCallback={async (val: any) => {
                  await fetchLatestData(val);
                  setLatestCaptureDate(val);
                }}
              />
            </div>
          </div>
          {latestDictionary?.[selectedHeadRef.current] ? (
            <BNAImage image={latestDictionary?.[selectedHeadRef.current]?.image || ""} />
          ) : (
            <div className="imageContainer noImg">
              <img src={noimageIcon} alt="" />
            </div>
          )}
          <div className="bna-footer">
            <div>
              <ZoomInIcon
                titleAccess="Zoom Image"
                sx={{ fontSize: "170%", color: theme.palette.primary.main }}
                style={{ margin: "auto", cursor: "pointer" }}
                onClick={handleLast}
              />
              {selectedLatestImageData?.capture_type === "SELF" ? (
                <PersonIcon
                  titleAccess="Self Captured"
                  sx={{ fontSize: "180%", color: theme.palette.primary.main }}
                  style={{ cursor: "pointer", paddingLeft: "0.5rem" }}
                />
              ) : (
                <CameraAltIcon
                  titleAccess="Professional Captured"
                  sx={{ fontSize: "180%", color: theme.palette.primary.main }}
                  style={{ cursor: "pointer", paddingLeft: "0.5rem" }}
                />
              )}
            </div>

            <SessionHandle activeIndex={latestSessionIndex} imageList={latestImageData} type="latest" />
          </div>
        </div>
        <div className="arrow right">
          <div className="arrowIcon">
            <ArrowCircleRight
              titleAccess="Next Image"
              sx={{ fontSize: "180%", color: theme.palette.primary.main }}
              style={{ margin: "auto", cursor: "pointer" }}
              onClick={() => onChange(next)}
            />
          </div>
        </div>
      </div>

      {ButtonList && <ButtonList showPreview={false} />}

      <div className="grid-score">
        <div>
          <div className="phy-score">
            <div className={`individual-txt-op1 ${!changeScore && "phy-activebox"}`} onClick={overall}>
              Image level data
            </div>
            <div className={`overall-txt-op1 ${changeScore && "phy-activebox"}`} onClick={individual}>
              Image-specific comment
            </div>
          </div>
          <div className="phy-box">
            {!changeScore ? (
              <>
                <table className="overall-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Baseline Image</th>
                      <th>Last Image</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>MyHairDensity</th>
                      <td>
                        {baselineDictionary?.[selectedHeadRef?.current]
                          ? baselineDictionary?.[selectedHeadRef.current]?.terminal_hair
                          : "-"}
                      </td>
                      <td>
                        {" "}
                        {latestDictionary?.[selectedHeadRef?.current]
                          ? latestDictionary?.[selectedHeadRef.current]?.terminal_hair
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>MyGrowthScore</th>
                      <td>
                        {baselineDictionary?.[selectedHeadRef?.current]
                          ? baselineDictionary?.[selectedHeadRef.current]?.gray_hair
                          : "-"}
                      </td>
                      <td>
                        {" "}
                        {latestDictionary?.[selectedHeadRef?.current]
                          ? latestDictionary?.[selectedHeadRef.current]?.gray_hair
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>MyHairStatus</th>
                      <td>
                        {baselineDictionary?.[selectedHeadRef?.current]
                          ? baselineDictionary?.[selectedHeadRef.current]?.dandruff
                          : "-"}
                      </td>
                      <td>
                        {" "}
                        {latestDictionary?.[selectedHeadRef?.current]
                          ? latestDictionary?.[selectedHeadRef.current]?.dandruff
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Anagen Follicles <sup>*</sup>
                      </th>
                      <td>
                        {baselineDictionary?.[selectedHeadRef?.current]
                          ? baselineDictionary?.[selectedHeadRef.current]?.anagen_follicles
                          : "-"}
                      </td>
                      <td>
                        {latestDictionary?.[selectedHeadRef?.current]
                          ? latestDictionary?.[selectedHeadRef.current]?.anagen_follicles
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Dormant Follicles <sup>*</sup>
                      </th>
                      <td>
                        {baselineDictionary?.[selectedHeadRef?.current]
                          ? baselineDictionary?.[selectedHeadRef.current]?.dormant_follicles
                          : "-"}
                      </td>
                      <td>
                        {" "}
                        {latestDictionary?.[selectedHeadRef?.current]
                          ? latestDictionary?.[selectedHeadRef.current]?.dormant_follicles
                          : "-"}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        Vellus Hair <sup>*</sup>
                      </th>
                      <td>
                        {baselineDictionary?.[selectedHeadRef?.current]
                          ? baselineDictionary?.[selectedHeadRef.current]?.vellus_hair
                          : "-"}
                      </td>
                      <td>
                        {" "}
                        {latestDictionary?.[selectedHeadRef?.current]
                          ? latestDictionary?.[selectedHeadRef.current]?.vellus_hair
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Observation</th>
                      <td>
                        {baselineDictionary?.[selectedHeadRef?.current]
                          ? baselineDictionary?.[selectedHeadRef.current]?.remarks
                          : "-"}
                      </td>
                      <td>
                        {latestDictionary?.[selectedHeadRef?.current]
                          ? latestDictionary?.[selectedHeadRef.current]?.remarks
                          : "-"}
                      </td>
                    </tr>
                  </tbody>

                  {/* <div className="form-group" style={{ marginTop: "0.3rem" }}>
                  <div className="individual-cmt">
                    <label style={{ padding: "1.5rem" }}>Comment</label>
                    <textarea className="individual-txtarea" name="Comment" />
                  </div>
                </div> */}
                </table>
              </>
            ) : (
              <>
                <div className="comment-ind-overall">
                  <div style={{ padding: "1rem", width: "100%" }}>
                    <div style={{ paddingBottom: "0.5rem" }}>Baseline</div>
                    <div style={{ fontSize: "16px" }}>Comment 1:</div>{" "}
                    <textarea
                      className="individual-txtarea"
                      style={{ marginBottom: "1rem" }}
                      disabled
                      value={baselineDictionary?.[selectedHeadRef.current]?.comment1}
                    ></textarea>
                    <div style={{ fontSize: "16px" }}>Comment 2:</div>
                    <textarea
                      className="individual-txtarea"
                      disabled
                      style={{ marginBottom: "1rem" }}
                      value={baselineDictionary?.[selectedHeadRef.current]?.comment2}
                    ></textarea>
                    <div style={{ fontSize: "16px" }}>Comment 3:</div>
                    <textarea
                      className="individual-txtarea"
                      disabled
                      value={baselineDictionary?.[selectedHeadRef.current]?.comment3}
                    ></textarea>
                  </div>
                  <div style={{ padding: "1rem", width: "100%" }}>
                    <div style={{ paddingBottom: "0.5rem" }}>Latest</div>
                    <div style={{ fontSize: "16px" }}>Comment 1:</div>
                    <textarea
                      className="individual-txtarea"
                      style={{ marginBottom: "1rem" }}
                      disabled
                      value={latestDictionary?.[selectedHeadRef.current]?.comment1}
                    ></textarea>
                    <div style={{ fontSize: "16px" }}>Comment 2:</div>
                    <textarea
                      className="individual-txtarea"
                      disabled
                      style={{ marginBottom: "1rem" }}
                      value={latestDictionary?.[selectedHeadRef.current]?.comment2}
                    ></textarea>
                    <div style={{ fontSize: "16px" }}>Comment 3:</div>
                    <textarea
                      className="individual-txtarea"
                      disabled
                      value={latestDictionary?.[selectedHeadRef.current]?.comment3}
                    ></textarea>
                  </div>
                </div>
              </>
            )}
          </div>
          <div style={{ fontSize: "small" }}>
            <sup>*</sup> Images from a higher, 20X+ magnification is required perform the analysis
          </div>
        </div>
        <div className="overall-scores-container">
          <div className="phy-score">
            <div className="individual-txt1 " style={{ backgroundColor: "#FDBA12" }} onClick={overall}>
              Overall Score
            </div>
          </div>
          <div className="phy-box">
            <table className="overall-scores">
              <thead>
                <tr>
                  <th></th>
                  <th>Baseline Image</th>
                  <th>Last Image</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th>My Hair Density</th>
                  <td>{selectedBaselineImageData ? selectedBaselineImageData?.overall_density : "N/A"}</td>
                  <td> {selectedLatestImageData ? selectedLatestImageData?.overall_density : "N/A"}</td>
                </tr>
                <tr>
                  <th>MyGrowthScore</th>
                  <td>{selectedBaselineImageData ? selectedBaselineImageData?.overall_gray_hair : "N/A"}</td>
                  <td> {selectedLatestImageData ? selectedLatestImageData?.overall_gray_hair : "N/A"}</td>
                </tr>
                <tr>
                  <th>MyHairStatus</th>
                  <td>{selectedBaselineImageData ? selectedBaselineImageData?.overall_dandruff : "N/A"}</td>
                  <td> {selectedLatestImageData ? selectedLatestImageData?.overall_dandruff : "N/A"}</td>
                </tr>
                <tr>
                  <th>Observation</th>
                  <td>{selectedBaselineImageData ? selectedBaselineImageData?.remarks : "-"}</td>
                  <td>{selectedLatestImageData ? selectedLatestImageData?.remarks : "-"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
});

export const BNAImage: React.FC<{ image: string }> = (props) => {
  const { image } = props;

  const [isImageLoading, setImageLoading] = useState(false);

  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    setImageLoading(true);
  }, [image]);

  useEffect(() => {
    const callback = () => {
      setImageLoading(false);
    };

    const image = imageRef.current;

    image && image.addEventListener("load", callback);
    return () => {
      setImageLoading(false);
      image && image.removeEventListener("load", callback);
    };
  }, []);

  if (!image) {
    return (
      <div className="imageContainer noImg">
        <img src={noimageIcon} alt="" />
      </div>
    );
  }

  return (
    <div className="container-img">
      <div className={isImageLoading ? "imageBlur" : ""}>
        <img ref={imageRef} src={image} alt="sectionImage" className={`imageContainer`} />
      </div>
    </div>
  );
};

export default HeadIcon;
