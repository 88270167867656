import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

export interface ReviewCommentRequest {
  comment: string;
  treatment_directives: string;
  treatment_regimen: string;
  treatment_adjustment: string;
  location: number;
  comment_type: string;
  // type_id: number;
  user_profile: number;
  // for emr
  patient_id: number;
  emr_no: string;
}

export const reviewComment = async (data: ReviewCommentRequest) => {
  const { emr_no, patient_id, ...rest } = data;

  const promise1 = axios.put(`${baseUrl}patients/emr-number/${patient_id}`, {
    emr_number: emr_no,
  });

  const promise2 = axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method: "POST",
    url: `review-comment/`,
    data: rest,
  });

  return Promise.all([promise1, promise2]);
};

export const getUserComments = async (userProfileId: number) => {
  const res = await axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method: "POST",
    url: `review-comment/user/${userProfileId}`,
  });

  return res?.data;
};

// comment,
//       comment_type: "REVIEW_SET",
//       type_id: reviewSetId,
//       user_profile: patient_user_profile_id,
//       treatment_directives,
//       treatment_adjustment,
//       treatment_regimen,

