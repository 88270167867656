import SearchModule from "components/SearchModule";
import * as routes from "constants/routes";
import { useCheckBox } from "hooks/useCheckBox";
import useFilter from "hooks/useFilter";
import _ from "lodash";
import { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { fetchProducts } from "redux/Products/api";
import PaginatedTable from "../../components/Table/PaginatedTable";
import { MODULES } from "../../constants/modules";
import { ProductTable } from "./ProductTable";

const Products = ({ active, permissionList, isDeleted }: any) => {
  const {
    filter: filterOptions,
    actions: { handleSize, handlePagination, handleSearch, handleSort },
  } = useFilter({
    ordering: "created_date",
  });
  const history = useHistory();

  const { page, paginate_by, ordering: sortField, search: searchQuery } = filterOptions;

  const [refresh, setRefresh] = useState(false);

  const { data, isLoading, refetch, isFetching } = useQuery(
    ["projects", active, filterOptions],
    () => fetchProducts(filterOptions),
    {
      keepPreviousData: true,
    },
  );

  const pageData = data && !_.isEmpty(data?.data) ? data?.data : [];
  const pageCount = (data && data?.count) || 0;
  const totalCount = (data && data?.total_count) || 0;
  const current_page_number = (data && data?.current_page_number) || 0;

  const { checkAll, allChecked } = useCheckBox(page || 1, pageData, refresh, setRefresh);

  return (
    <>
      <div className="page">
        <div className="module-header">
          <div className="module-name">Products Module</div>
          <div className="module-action">
            <button
              className="module-add-button"
              onClick={() => {
                history.push(routes.ADD_PRODUCTS);
              }}
            >
              + Add Product
            </button>
            <SearchModule handleSearch={handleSearch} />
          </div>
        </div>
        <PaginatedTable
          cols={ProductTable((rowData: any) => history.push(routes.PRODUCTS + `/${rowData?.id}`))}
          data={pageData}
          isDark={false}
          bordered
          striped={false}
          hoverable
          entries={paginate_by || 10}
          onRowClick={() => {}}
          //selected={selectedRowID}
          componentName={MODULES.PRODUCTS}
          handlePagination={handlePagination}
          handleEntries={handleSize}
          currentPage={current_page_number}
          totalCount={totalCount}
          size="lg"
          isDataLoading={isFetching || isLoading}
          handleSort={handleSort}
          initialSortConfig={{ key: sortField || "", direction: "desc" }}
          allChecked={allChecked}
          checkAll={checkAll}
          isPaginationRequired={true}
        />
      </div>
    </>
  );
};

export default Products;
