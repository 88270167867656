import { Drawer, Hidden, makeStyles } from "@material-ui/core";
import HqmIcon from "components/icon";
import { DRAWER_WIDTH_COLLAPSED } from "constants/ui";
import React, { ReactNode } from "react";

interface MHCDrawerProps {
  open: boolean;
  onClose: Function;
  anchor?: "left" | "right" | "bottom" | "top";
  title?: string;
  children: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.custom.white,
    padding: "10px 0px",
  },
  sidebar: {
    marginTop: "0",
    height: "100vh",
    border: "1px solid rgba(0,0,0,0.1)",
    overflow: "auto",
    backgroundColor: theme.custom.white,
  },
  drawer: {
    boxShadow: "0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)",
    [theme.breakpoints.up("sm")]: {
      width: "25vw",
      flexShrink: 0,
    },
  },
  drawerPaper: {
    boxShadow: "1px 0px 1px -3px rgba(0, 0, 0, 0.8)",
    border: "none",
    borderRadius: "none",
    padding: "0 8px 0 16px",
  },
  drawerCollapsed: {
    boxShadow: "0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)",
    [theme.breakpoints.up("sm")]: {
      width: DRAWER_WIDTH_COLLAPSED,
      flexShrink: 0,
    },
  },
  drawerPaperCollapsed: {
    width: DRAWER_WIDTH_COLLAPSED,
    boxShadow: "1px 0px 1px -3px rgba(0, 0, 0, 0.8)",
    border: "none",
    borderRadius: "none",
  },
  drawerTitle: {
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
    fontFamily: theme.custom.fontPrimary,
  },
}));

const MHCDrawer = ({ open, onClose, title, anchor = "right", children }: MHCDrawerProps) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor={anchor}
      variant="temporary"
      open={open}
      onClose={() => onClose()}
      classes={{ paper: classes.drawerPaper }}
      ModalProps={{ keepMounted: true }}
      style={{ zIndex: 99 }}
    >
      {title && (
        <div className={`${classes.drawerTitle} drawer-title`}>
          <span> {title} </span>
          <HqmIcon name="cross" size={20} onClick={onClose} />
        </div>
      )}
      {children}
    </Drawer>
  );
};

export default MHCDrawer;
