import { Box } from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import HqmIcon from "components/icon";
import moment from "moment";
import { useState } from "react";
import { usePatientAction } from "./PatientList";

const statusStyle = {
  // position: "absolute",
  background: "#ffff",
  // display: "flex",
  flexDirection: "column",
  left: "-6rem",
};

const StatusFilter: React.FC = () => {
  const [isVisible, setVisible] = useState(false);

  const { setStatus: filterStatus } = usePatientAction();

  const handleReviewed = () => {
    filterStatus("INACTIVE");
    setVisible(false);
  };

  const handleUnReviewed = () => {
    filterStatus("ACTIVE");
    setVisible(false);
  };
  const handleNone = () => {
    filterStatus("");
    setVisible(false);
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        {!isVisible ? (
          <HqmIcon
            name="chevron-down"
            className="float-right sort-down"
            size={15}
            style={{ color: "white" }}
            onClick={() => setVisible(true)}
          />
        ) : (
          <HqmIcon
            name="chevron-up"
            className="float-right sort-down"
            size={15}
            style={{ color: "white" }}
            onClick={() => setVisible(false)}
          />
        )}
        <Box
          className="filter"
          sx={{
            display: isVisible ? "flex" : "none",
          }}
        >
          <button className="status_blue" onClick={handleReviewed}>
            REVIEWED
          </button>
          <button className="button badge-error-unreview" onClick={handleUnReviewed}>
            UNREVIEWED
          </button>
          <button className="status_none" onClick={handleNone}>
            NONE
          </button>
        </Box>
      </div>
    </>
  );
};

const RetentionFilter: React.FC = () => {
  const [isVisible, setVisible] = useState(false);

  const { setRetention: filterRetention } = usePatientAction();

  const handleRetentionGreen = () => {
    filterRetention("Green");
    setVisible(false);
  };

  const handleRetentionRed = () => {
    filterRetention("Red");
    setVisible(false);
  };
  const handleRetentionYellow = () => {
    filterRetention("Yellow");
    setVisible(false);
  };
  const handleNone = () => {
    filterRetention("");
    setVisible(false);
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        {!isVisible ? (
          <HqmIcon
            name="chevron-down"
            className="float-right sort-down"
            size={15}
            style={{ color: "white" }}
            onClick={() => setVisible(true)}
          />
        ) : (
          <HqmIcon
            name="chevron-up"
            className="float-right sort-down"
            size={15}
            style={{ color: "white" }}
            onClick={() => setVisible(false)}
          />
        )}
        <div className="filter" style={{ display: isVisible ? "flex" : "none" }}>
          <button className="status_green" onClick={handleRetentionGreen}>
            Green
          </button>
          <button className="status_red" onClick={handleRetentionRed}>
            Red
          </button>
          <button className="status_yellow" onClick={handleRetentionYellow}>
            Yellow
          </button>
          <button className="status_none" onClick={handleNone}>
            None
          </button>
        </div>
      </div>
    </>
  );
};

export const PatientListTable = (handleOpenModal: Function, checkPatient: Function, checked: Array<number> = []) => {
  return [
    {
      title: "checkbox",
      name: "Checkbox",
      notClickable: true,
      render: (rowData: any) => {
        return (
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              onChange={() => checkPatient(parseInt(rowData.id))}
              onClick={(e) => e.stopPropagation()}
              checked={checked.includes(rowData)}
            />
          </div>
        );
      },
    },
    {
      title: "Patient ID",
      name: "emr_number",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.emr_number || rowData?.patient_id}</span>;
      },
    },
    {
      title: "Client Name",
      name: "full_name",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.user_profile?.full_name ? rowData?.user_profile?.full_name : "-"}</span>;
      },
    },

    {
      title: (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            Status
            <StatusFilter />
          </div>
        </>
      ),
      name: "status",
      sortable: false,
      render: (rowData: any) => {
        return (
          <span
            className={
              rowData?.status === "ACTIVE"
                ? "badge badge-error-unreview"
                : rowData?.status === "INACTIVE"
                ? "patient-list badge badge-primary"
                : "badge badge-ca"
            }
          >
            {rowData?.status === "INACTIVE" ? <>Reviewed</> : <>Unreviewed</>}
          </span>
        );
      },
    },
    {
      title: "Appointment Date",
      name: "appointment_time",
      sortable: true,
      render: (rowData: any) => {
        return <span>{moment(rowData?.appointment_time).format("YYYY-MM-DD")}</span>;
      },
    },
    {
      title: "Clinic / Spa",
      name: "location_name",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.location?.location_name}</span>;
      },
    },
    {
      title: "Phone Number",
      name: "contact_number",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.user_profile?.contact_number}</span>;
      },
    },
    {
      title: (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            Retention
            <InfoIcon
              titleAccess="Treatment Status"
              style={{ margin: "auto", cursor: "pointer", paddingLeft: "0.3rem" }}
              onClick={() => handleOpenModal()}
            />
            <RetentionFilter />
          </div>
        </>
      ),
      name: "retention_status",
      sortable: false,
      render: (rowData: any) => {
        return (
          <span
            className={
              rowData?.retention_status === "Red"
                ? "badge badge-error-status patient-list"
                : rowData?.retention_status === "Green"
                ? "badge badge-success-status patient-list"
                : rowData?.retention_status === "Yellow"
                ? "badge badge-warning-status patient-list"
                : "badge badge-ca"
            }
          >
            {rowData?.retention_status === "Red" ? (
              <>Red</>
            ) : rowData?.retention_status === "Green" ? (
              <>Green</>
            ) : rowData?.retention_status === "Yellow" ? (
              <>Yellow</>
            ) : null}
          </span>
        );
      },
    },
  ];
};
// export const PATIENTDATA = "/physician/patientdata";
