import HqmIcon from "components/icon";
import moment from "moment";
import mhcTheme from "../../assets/style/style.module.scss";

export const TreatmentTable = (handleTreatmentTable: Function) => {
  return [
    {
      title: "Name",
      name: "end_user__username",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.end_user?.username}</span>;
      },
    },
    {
      title: "Email",
      name: "end_user__email",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.end_user?.email}</span>;
      },
    },
    {
      title: "Created At",
      name: "creation_ts",
      sortable: true,
      render: (rowData: any) => {
        return <span>{moment(rowData?.creation_ts).format("YYYY-MM-DD")}</span>;
      },
    },
    {
      title: "Product",
      name: "product_name",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.product_name}</span>;
      },
    },

    {
      title: "View",
      name: "action",
      //sortable: true,
      render: (rowData: any) => {
        return (
          <HqmIcon
            name="eye"
            size={20}
            style={{ color: mhcTheme.secondary }}
            onClick={() => handleTreatmentTable(rowData)}
          />
        );
      },
    },
  ];
};
