import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import HqmIcon from "components/icon";
import { auth } from "firebase-config";
import { signOut } from "firebase/auth";
import { logoutSuccess } from "redux/actions";
import { RootState } from "redux/store";
import * as routes from "../../constants/routes";
import { DRAWER_WIDTH, DRAWER_WIDTH_COLLAPSED } from "../../constants/ui";
import { setToolbarTitle, toggleSidebar } from "../../redux/sidebar/actions";
import { ISidebarItem } from "../../redux/sidebar/types";
import { sidebarItems } from "./sidebar.config";
import SidebarItem from "./SidebarItem";
import { useIsPhysician } from "hooks/useCheckPermission";
import { useMediaQuery } from "@mui/material";

const INITIAL_DEPTH = 0;
const INITIAL_DEPTH_STEP = 56;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.custom.white,
    // padding: '20px 0px 10px',
    padding: "20px 0px 10px",
  },
  rootBottom: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.custom.white,
    // padding: '20px 0px 10px',
    padding: "90px 0px 10px",
  },
  sidebar: {
    marginTop: "0",
    height: "100vh",
    border: "1px solid rgba(0,0,0,0.1)",
    overflow: "auto",
    backgroundColor: theme.custom.white,
  },
  drawer: {
    boxShadow: "0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)",
    [theme.breakpoints.up("sm")]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    boxShadow: "1px 0px 1px -3px rgba(0, 0, 0, 0.8)",
    border: "none",
    borderRadius: "none",
  },
  drawerCollapsed: {
    boxShadow: "0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)",
    [theme.breakpoints.up("sm")]: {
      width: DRAWER_WIDTH_COLLAPSED,
      flexShrink: 0,
    },
  },
  drawerPaperCollapsed: {
    width: DRAWER_WIDTH_COLLAPSED,
    boxShadow: "1px 0px 1px -3px rgba(0, 0, 0, 0.8)",
    border: "none",
    borderRadius: "none",
  },
}));

const Sidebar: React.FC = () => {
  return (
    <nav className="sidebar_home" aria-label="sidebar menus">
      <SidebarContent></SidebarContent>
    </nav>
  );
};

export const SidebarContent: React.FC = () => {
  const history = useHistory();
  const isVisible = useSelector((state: RootState) => state.sidebar.isVisible);
  const permissionList = useSelector((state: RootState) => state.permissionReducer.permission_list);
  const dispatch = useDispatch();
  const classes = useStyles();

  const view_employee = permissionList.includes("view_employee");
  const view_productdiscount = permissionList.includes("view_productdiscount");
  const view_referral = permissionList.includes("view_userprofile");
  const view_reviews = permissionList.includes("view_hairimagereview");
  const view_generalinformation = permissionList.includes("view_generalinformation");
  const view_users = permissionList.includes("view_user");
  const user_submitted_prod = permissionList.includes("add_usersubmittedproduct");
  const treatment_products = permissionList.includes("view_usertreatmentproduct");

  const matches = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (matches) {
      dispatch(toggleSidebar(false));
    } else {
      dispatch(toggleSidebar(true));
    }
  }, [dispatch, matches]);

  const onSidebarItemClick = useCallback(
    (sidebarItem: ISidebarItem) => {
      const { path, toolbarTitle } = sidebarItem;
      if (!path) {
        return;
      }

      if (toolbarTitle) {
        dispatch(setToolbarTitle(toolbarTitle));
      }
      history.push(path);
    },
    [history, dispatch],
  );

  const isPhysician = useIsPhysician();

  const renderSidebarItems = sidebarItems.map((sidebarItem, index) => {
    // console.log("sidebar item is", Object.keys(sidebarItem));

    const a = sidebarItem as ISidebarItem;

    if (!view_employee && a.key === "employees") {
      return "";
    }
    if (!view_productdiscount && a.key === "discount") {
      return "";
    }
    if (!view_referral && a.key === "referral") {
      return "";
    }
    if (!view_reviews && a.key === "reviews") {
      return "";
    }
    if (!view_generalinformation && a.key === "partners") {
      return "";
    }

    if (!view_users && a.key === "users") {
      return "";
    }
    // if (a.key === "home") {
    //   return "";
    // }
    // if (a.key === "assigned" && view_reviews) {
    //   return "";
    // }

    if (!user_submitted_prod && a.key === "treatment") {
      return "";
    }
    if (!treatment_products && a.key === "products") {
      return "";
    }

    if (a.key === "physician" && !isPhysician) {
      return "";
    }

    // console.log(permissionList)
    return (
      <React.Fragment key={index}>
        {typeof sidebarItem === "string" ? (
          <Divider className="sidebar-item__divider" />
        ) : (
          <>
            <SidebarItem
              depthStep={INITIAL_DEPTH_STEP}
              depth={INITIAL_DEPTH}
              sidebarItem={sidebarItem}
              onClick={onSidebarItemClick}
            />
            <Divider />
          </>
        )}
      </React.Fragment>
    );
  });

  const renderSidebar = useCallback(
    () => (
      <div className={`${classes.sidebar} sidebar`}>
        <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
          {renderSidebarItems}
        </List>
      </div>
    ),
    [renderSidebarItems, classes.root, classes.sidebar],
  );

  return (
    <>
      <div className="toggle-icon" onClick={() => dispatch(toggleSidebar())} data-bg={isVisible ? "open" : "collapsed"}>
        <HqmIcon
          name={isVisible ? "minimize-arrows" : "maximize-arrows"}
          size={isVisible ? 20 : 20}
          style={{ cursor: "pointer" }}
        />
      </div>
      {renderSidebar()}
      <div style={{ borderRight: "1px solid rgba(0,0,0,0.1)" }}>
        <List component="nav" aria-labelledby="nested-list-subheader">
          {useCallback(() => {
            return (
              <React.Fragment>
                <SidebarItem
                  depthStep={INITIAL_DEPTH_STEP}
                  depth={INITIAL_DEPTH}
                  sidebarItem={{
                    key: "logout",
                    label: "Log Out",
                    toolbarTitle: "LogOut",
                    Icon: "log-out",
                    path: routes.LOG_OUT,
                  }}
                  onClick={() =>
                    signOut(auth).then((resp) => {
                      dispatch(logoutSuccess(null));
                      (window as any).location.href = "/";
                    })
                  }
                />
              </React.Fragment>
            );
          }, [onSidebarItemClick])()}
        </List>
      </div>
    </>
  );
};

export default Sidebar;
