import { useState } from "react";

export const useCheckBoxArchive = (page: number, pageData: Array<any>, refresh: boolean, setRefresh: Function) => {
  const [checkedArchive, setCheckedArchive] = useState<Array<number>>([]);
  const [allCheckedArchive, setAllCheckedArchive] = useState<Map<number, boolean>>(new Map().set(1, false));

  const checkSelectArchive = (id: number) => {
    let newChecked = checkedArchive;
    if (checkedArchive?.includes(id)) {
      newChecked = checkedArchive?.filter((i) => i != id);
      if (allCheckedArchive?.has(page)) {
        allCheckedArchive?.set(page, false);
      }
    } else {
      newChecked?.push(id);
      let dataIds = pageData?.map((d: any) => parseInt(d.id));
      if (dataIds?.some((i: number) => !checkedArchive?.includes(i))) {
        allCheckedArchive.set(page, false);
      } else {
        allCheckedArchive.set(page, true);
      }
    }
    setAllCheckedArchive(allCheckedArchive);
    setCheckedArchive(newChecked);
    setRefresh(!refresh);
  };

  const checkAllArchive = (page: number) => {
    let dataIds = pageData?.map((d: any) => parseInt(d.id));
    if (!allCheckedArchive?.has(page) || (allCheckedArchive?.has(page) && allCheckedArchive.get(page) === false)) {
      allCheckedArchive?.set(page, true);
      let newChecked = checkedArchive?.concat(dataIds);
      setAllCheckedArchive(allCheckedArchive);
      setCheckedArchive(newChecked);
    } else {
      allCheckedArchive?.set(page, false);
      let newChecked = checkedArchive?.filter((i) => !dataIds?.includes(i));
      setCheckedArchive(newChecked);
    }
  };

  return { checkSelectArchive, checkAllArchive, checkedArchive, allCheckedArchive, setCheckedArchive, setAllCheckedArchive };
};

