import grp1 from "assets/brand/Group1.png";
import * as routes from "constants/routes";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useMutation, useQueries } from "react-query";
import { useHistory } from "react-router-dom";
import {
  fetchBusiness,
  fetchCountries,
  fetchEmployeeList,
  fetchLocationInfo,
  fetchStates,
  fetchTimezones,
} from "./requests";
import "./styles/LocationEmployees.style.scss";

import { toast } from "react-toastify";
import { deleteLocation } from "redux/LocationEmp/api";
import { CompanyDetailsFooter, usePartner } from "./CompanyDetails";
import NewLocationForm, { newLocationInitialValues } from "./components/NewLocationForm";

const fetchQueries = [
  {
    queryKey: "business",
    queryFn: fetchBusiness,
    retry: false,
  },

  {
    queryKey: "employeeList",
    queryFn: fetchEmployeeList,
    retry: false,
  },
  {
    queryKey: "countries",
    queryFn: fetchCountries,
    retry: false,
  },
  {
    queryKey: "timezones",
    queryFn: fetchTimezones,
    retry: false,
  },

  {
    queryKey: "states",
    queryFn: fetchStates,
    retry: false,
  },
];

const useSimpleSelect = (queryData: any, field = "name") => {
  return useMemo(() => {
    const responseData = queryData;
    if (!responseData) return [];

    return responseData?.map((i: any) => {
      const selectItem: { label: string; value: any; country?: number } = { label: i?.[field], value: i?.id };

      if (i?.country) {
        selectItem.country = i?.country;
      }

      return selectItem;
    });
  }, [field, queryData]);
};

export interface LocationEmployeeProps {
  id?: number;
  activeStep: number;
  setActiveStep: (s: any) => void;
}

const LocationEmployees: React.FC<LocationEmployeeProps> = ({ id, activeStep, setActiveStep }) => {
  const history = useHistory();
  const generalInfoId = id;

  const isEdit = usePartner()?.edit;

  //get dropdown data
  const queries = useQueries([
    ...fetchQueries,
    {
      queryKey: ["locationInfo", generalInfoId],
      queryFn: () => generalInfoId && fetchLocationInfo(generalInfoId),
      retry: false,
    },
  ]);
  const queryData = queries?.map((item) => item?.data);

  // business info
  const businessInfo = queryData?.[0];

  const employeeList = useSimpleSelect(queryData?.[1], "first_name");
  // employee list

  const countries = useSimpleSelect(queryData?.[2]); // country list
  const timezones = useSimpleSelect(queryData?.[3], "value"); // timezone list
  const states = useSimpleSelect(queryData?.[4]); //states list

  // list of location data
  const locationList = queryData?.[5];
  // loading state for locations
  const locationInfoLoading = queries[5]?.isLoading;

  // location in partner
  const [formList, setFormList] = useState<any[]>([]);

  // select option values
  const extraProps = useMemo(
    () => ({
      states,
      countries,
      businessInfo,
      employeeList,
      timezones,
      generalInfoId,
    }),
    [states, countries, businessInfo, employeeList, timezones, generalInfoId],
  );

  const { mutate: delLocation } = useMutation(deleteLocation, {
    onSuccess: () => {
      toast.success("Location Deleted");
    },
  });
  //remove form
  const removeForm = useCallback(
    async (id: string, locationId?: number) => {
      if (locationId) {
        delLocation(locationId);
      }
      setFormList((s) => {
        const filtered = s.filter((item) => item.id !== id);
        return filtered;
      });
    },
    [delLocation],
  );

  useEffect(() => {
    // mapping api data to form initial values
    const locationInfoList =
      locationList &&
      Array.isArray(locationList) &&
      locationList?.map((info: any) => ({
        initialValues: info,
        id: `location_form_${Date.now()}_${Math.random() * 100}`,
        disabled: !isEdit,
        isEdit: isEdit,
        onDelete: removeForm,
      }));

    locationInfoList && locationInfoList.length && setFormList(locationInfoList);
  }, [isEdit, locationList, removeForm]);

  //add form
  const addForm = () => {
    setFormList((s) => [
      ...s,
      {
        id: `location_form_${Date.now()}_${Math.random() * 100}`,
        initialValues: newLocationInitialValues,
        onDelete: removeForm,
        onSubmit: async (values: any) => {},
      },
    ]);
  };

  if (locationInfoLoading)
    return (
      <Spinner style={{ marginLeft: "1rem" }} as="span" animation="border" size="sm" role="status" aria-hidden="true" />
    );

  return (
    <div className="location-employees">
      {/* <FormikProvider value={formik}>selectedData*/}

      <div className="add-form-btn-container">
        <button className="add-btn" onClick={addForm}>
          <img src={grp1} alt="img" className="stylepadright" /> Create new location
        </button>
      </div>

      {formList.map((form, index) => (
        <NewLocationForm {...form} {...extraProps} index={form.id} key={`location_form_${index}`} />
      ))}

      <CompanyDetailsFooter
        onSubmit={() => history.push(routes.PARTNERS_PENDING)}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        lastItem={1}
      />
    </div>
  );
};
export default LocationEmployees;
