import { createStore, compose, applyMiddleware } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "./reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["loginReducer", "physicianPortalReducer"],
};

export default function store() {
  const middleware: Array<any> = [];
  const enhancers: Array<any> = [];

  const composeEnhancers =
    typeof window === "object" && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          serialize: { options: { map: true } },
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose;

  enhancers.push(composeEnhancers(applyMiddleware(...middleware)));

  const enhancer = composeEnhancers(
    applyMiddleware(...middleware),
    // other store enhancers if any
  );

  if (typeof window === "object" && (window as any).__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push((window as any).__REDUX_DEVTOOLS_EXTENSION__());
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(persistedReducer, {}, enhancer);
  const persistor = persistStore(store);

  return { store, persistor };
}

export type RootState = ReturnType<typeof rootReducer>;
