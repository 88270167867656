import {
  PATIENT_PK_ID,
  PATIENT_USER_PROFILE_ID,
  COMMENT_DATA,
  TREATMENT_ADJUSTMENT_DATA,
  TREATMENT_DIRECTIVE_DATA,
  TREATMENT_REGIMEN_DATA,
  EMR_NO_UPDATE,
  // DRAFT_RECORD,
} from "./PhysicianPortalTypes";

export const patient_pk_id = (payload: number) => {
  return {
    type: PATIENT_PK_ID,
    payload: payload,
  };
};

export const patient_user_profile_id = (payload: number) => {
  return {
    type: PATIENT_USER_PROFILE_ID,
    payload: payload,
  };
};

export const comments = (payload: string) => {
  return {
    type: COMMENT_DATA,
    payload: payload,
  };
};

export const treatment_regimen = (payload: string) => {
  return {
    type: TREATMENT_REGIMEN_DATA,
    payload: payload,
  };
};

export const treatment_directives = (payload: string) => {
  return {
    type: TREATMENT_DIRECTIVE_DATA,
    payload: payload,
  };
};

export const treatment_adjustment = (payload: string) => {
  return {
    type: TREATMENT_ADJUSTMENT_DATA,
    payload: payload,
  };
};

export const update_emr = (payload: string) => {
  return {
    type: EMR_NO_UPDATE,
    payload,
  };
};



// export const setAllTransactions = (payload: Transactions[]) => {
//     return (dispatch: any) => {
//       dispatch({
//         type: ALL_TRANSACTIONS,
//         payload,
//       });
//     };
//   };
