import HqmIcon from "components/icon";
import _ from "lodash";

const SearchModule = ({handleSearch}:any) => {

    return(
        <div className="search-module">
            <input 
                name="search"
                defaultValue =""
                placeholder="Search"
                onChange={_.debounce(function(e) {
                    handleSearch(e?.target?.value);
                  }, 1000)}
            />
            <HqmIcon name="search" size={20} />
        </div>
    )
}

export default SearchModule;