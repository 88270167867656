import { useContext } from "react";

import Sidebar from "components/Sidebar";
// import Header from 'containers/Header'
import NavBar from "components/NavBar/NavBar";
import { GlobalContext } from "context/globalContext";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { RootState } from "redux/store";

import {
  ASSIGNED_ACTIVE,
  ASSIGNED_CANCELLED,
  ASSIGNED_CANCELLED_VIEW,
  ASSIGNED_PENDING,
  ASSIGNED_PENDING_VIEW,
  ASSIGNED_REVIEWED_VIEW,
  DISCOUNT,
  EDIT_PARTNER,
  EMPLOYEES_ACTIVE,
  EMPLOYEES_ARCHIVED,
  EMPLOYEES_PENDING,
  PATIENTDATA,
  PATIENTREPORT,
  PATIENTS,
  PATIENTS_INACTIVE,
  PATIENT_HISTORY,
  PATIENT_LIST_DRAWER,
  REFERRAL,
  REVIEWS_ARCHIVE,
  REVIEWS_CANCELLED,
  REVIEWS_PENDING,
  REVIEWS_REVIEWED,
  VIEW_REVIEWS_CANCELLED,
  VIEW_REVIEWS_PENDING,
  VIEW_REVIEWS_REVIEWED,
  ADD_PARTNER,
  USERS,
  VIEW_USER,
  TREATMENT,
  VIEW_TREATMENT,
  PRODUCTS,
  EDIT_PRODUCTS,
  ADD_PRODUCTS,
} from "constants/routes";
import Employee from "./Employee/Employee";
import Users from "./Users";
import Partners from "./Partners/Partners";

import { FourOhFour } from "components/Route/404";
import { PARTNERS_ACTIVE, PARTNERS_ARCHIVED, PARTNERS_PENDING } from "constants/routes";
import Assigned from "./Assigned/Assigned";
import Discount from "./Discount/Discount";
import Referral from "./Referral/Referral";
import Reviews from "./Reviews/Reviews";

import React from "react";
import ReviewView from "./ReviewView/ReviewView";

import MHCAlert from "components/MHCAlert";
import PatientListDrawer from "components/PhysicianComponent/PatientsListComponent/PatientListDrawer";
import PhysicianNavbar from "components/PhysicianComponent/PhysicianNavBar/Navbar";
import PatientHistory from "components/PhysicianLayout/PatientHistory/PatientHistory";
import PatientInactive from "components/PhysicianLayout/PatientInactive/PatientInactive";
import PatientList from "components/PhysicianLayout/PatientList/PatientList";
import PatientReport from "components/PhysicianLayout/Report/PatientReport";
import * as routes from "constants/routes";
import { usePermissions } from "hooks/useCheckPermission";
import PatientData from "./PatientData/PatientData";
import CompanyDetails from "./CompanyDetails/CompanyDetails";
import PatientCommentHistory from "components/PhysicianComponent/PatientDataBNA/PatientCommentHistory";
import ViewUser from "./Users/ViewUser";
import Treatment from "./Treatment/Treatment";
import ViewTreatmentModal from "./Treatment/ViewTreatmentModal";
import Products from "./Products/Products";
import ProductForm from "./Products/ProductForm";

interface MainLayoutProps {
  children?: React.ReactNode;
}

const MainLayout = ({ children }: MainLayoutProps) => {
  const { setSideBarToggled } = useContext(GlobalContext);

  return (
    <div className="app-main-layout">
      <Sidebar />
      <main>
        <div className="ap-container">
          <header className="d-flex align-items-center pb-4">
            <div
              className="sidebar-toggle-hamburger cursor-pointer me-3"
              onClick={() => setSideBarToggled((prev) => !prev)}
              role="presentation"
            >
              <span className="icon-bar-ellipsis-v text-lg" />
            </div>
            <div style={{ flexGrow: 1 }}>{/* <Header /> */}</div>
          </header>
          {children}
        </div>
      </main>
    </div>
  );
};

// layout for normal
export const DashboardLayout: React.FC = (props) => {
  const isVisible = useSelector((state: RootState) => state.sidebar.isVisible);
  const permissionList = useSelector((state: RootState) => state.permissionReducer.permission_list);

  const view_productdiscount = permissionList.includes("view_productdiscount");
  const view_employee = permissionList.includes("view_employee");
  const view_referral = permissionList.includes("view_userprofile");
  const view_generalinformation = permissionList.includes("view_generalinformation");
  const view_reviews = permissionList.includes("view_hairimagereview");
  const rerouts = permissionList.includes("view_employee_name_list");
  const user_submitted_prod = permissionList.includes("add_usersubmittedproduct");

  const treatment_products = permissionList.includes("view_usertreatmentproduct");

  return (
    <div className="container-fluid home">
      <div className="navbar-wrapper home_navbar">
        <NavBar />
      </div>

      <div className="main-page">
        <div className="sidebar-wrapper" data-bg={isVisible ? "open" : "collapsed"}>
          <Sidebar />
        </div>
        <div className="main-content-wrapper ">
          <Switch>
            <Route exact path="/home">
              <h1 className="welcomeText">Welcome to MHC dashboard</h1>
            </Route>

            {view_generalinformation && (
              <Route path={"/home/partners"}>
                <Route exact path={PARTNERS_ACTIVE}>
                  <Partners active={true} permissionList={permissionList} />
                </Route>
                <Route exact path={PARTNERS_PENDING}>
                  <Partners active={false} permissionList={permissionList} />
                </Route>
                <Route exact path={PARTNERS_ARCHIVED}>
                  <Partners active={false} isDeleted={true} permissionList={permissionList} />
                </Route>

                <Route exact path={ADD_PARTNER}>
                  <CompanyDetails />
                </Route>

                <Route exact path={EDIT_PARTNER}>
                  <CompanyDetails edit />
                </Route>
              </Route>
            )}

            {view_employee && (
              <Route path={"/home/employees"}>
                <Route path={EMPLOYEES_ACTIVE}>
                  <Employee active={true} permissionList={permissionList} />
                </Route>

                <Route path={EMPLOYEES_PENDING}>
                  <Employee active={false} permissionList={permissionList} />
                </Route>

                <Route path={EMPLOYEES_ARCHIVED}>
                  <Employee active={false} isDeleted={true} permissionList={permissionList} />
                </Route>
              </Route>
            )}

            {view_reviews && (
              <Route path="/home/reviews">
                <Route exact path={REVIEWS_REVIEWED}>
                  <Reviews exact active={"0"} permissionList={permissionList} />
                </Route>
                <Route exact path={VIEW_REVIEWS_REVIEWED}>
                  <ReviewView />
                </Route>
                <Route exact path={REVIEWS_PENDING}>
                  <Reviews exact active={"1"} permissionList={permissionList} />
                </Route>
                <Route exact path={VIEW_REVIEWS_PENDING}>
                  <ReviewView />
                </Route>
                <Route exact path={REVIEWS_CANCELLED}>
                  <Reviews exact active={"2"} permissionList={permissionList} />
                </Route>

                <Route exact path={VIEW_REVIEWS_CANCELLED}>
                  <ReviewView />
                </Route>
                <Route exact path={REVIEWS_ARCHIVE}>
                  <Reviews exact active={""} permissionList={permissionList} isDeleted={true} />
                </Route>
              </Route>
            )}

            {/* !view_reviews */}

            <Route path={"/home/assigned"}>
              <Route exact path={ASSIGNED_PENDING}>
                <Assigned active={"1"} permissionList={permissionList} />
              </Route>
              <Route exact path={ASSIGNED_ACTIVE}>
                <Assigned active={"0"} permissionList={permissionList} />
              </Route>
              <Route exact path={ASSIGNED_CANCELLED}>
                <Assigned active={"2"} permissionList={permissionList} />
              </Route>
              <Route exact path={ASSIGNED_PENDING_VIEW}>
                <ReviewView />
              </Route>
              <Route exact path={ASSIGNED_REVIEWED_VIEW}>
                <ReviewView />
              </Route>
              <Route exact path={ASSIGNED_CANCELLED_VIEW}>
                <ReviewView />
              </Route>
            </Route>

            {view_referral && (
              <Route exact path={REFERRAL}>
                <Referral />
              </Route>
            )}
            {view_productdiscount && (
              <Route exact path={DISCOUNT}>
                <Discount permissionList={permissionList} />
              </Route>
            )}

            <Route exact path={USERS}>
              <Users />
            </Route>

            {user_submitted_prod && (
              <Route exact path={TREATMENT}>
                <Treatment />
              </Route>
            )}

            <Route exact path={VIEW_USER}>
              <ViewUser />
            </Route>
            <Route exact path={VIEW_TREATMENT}>
              <ViewTreatmentModal />
            </Route>

            {treatment_products && (
              <Route exact path={PRODUCTS}>
                <Products />
              </Route>
            )}
            <Route exact path={EDIT_PRODUCTS}>
              <ProductForm />
            </Route>
            <Route exact path={ADD_PRODUCTS}>
              <ProductForm add />
            </Route>

            <Route>
              <FourOhFour />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export const PhysicianLayout: React.FC = (props) => {
  const permissionList = usePermissions();

  const isPhysician = permissionList.includes("is_professional_mode");

  if (!isPhysician) {
    return <Redirect to={routes.EMPLOYEES_ACTIVE} />;
  }

  return (
    <Route path="/home/physician">
      <MHCAlert />
      <PhysicianNavbar />
      <div className="physician-panel">
        <Switch>
          <Route path={PATIENT_LIST_DRAWER}>
            <PatientListDrawer />
          </Route>
          {/* patient list all */}
          <Route path={PATIENTS}>
            <PatientList />
          </Route>
          {/* patient Reports */}
          <Route path={PATIENTS_INACTIVE}>
            <PatientInactive />
          </Route>
          {/* patient comment edit page */}
          <Route exact path={PATIENTDATA}>
            <PatientData />
          </Route>
          {/* patient report page */}
          <Route path={PATIENTREPORT}>
            <PatientReport />
          </Route>
          {/* patient treatment history */}
          <Route path={PATIENT_HISTORY}>
            <PatientHistory />
          </Route>
          {/* patient report from reports */}
          <Route path={routes.PATIENT_COMPLETED_REPORT}>
            <PatientReport hideSave />
          </Route>
          <Route path={routes.PATIENT_REVIEWED_DATA}>
            <PatientData lock />
          </Route>
          <Route path={routes.PATIENTS_COMMENT_HISTORY}>
            <PatientCommentHistory />
          </Route>
          <Route>
            <FourOhFour />
          </Route>
        </Switch>
      </div>
    </Route>
  );
};

export default MainLayout;
