import { Box, Button, CircularProgress, CSSObject, Typography } from "@mui/material";
import MHCAlert from "components/MHCAlert";
import { GenericFn } from "config";
import { Field, FieldProps, Form, Formik } from "formik";
import React, { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import * as yup from "yup";
import styles from "./review-screen.module.scss";

import CloseIcon from "@material-ui/icons/Close";
import { usePermissionCheck } from "hooks/useCheckPermission";
import { useLocation } from "react-router-dom";
import { reviewScreenActions } from "redux/reviewScreen";

const formGroup: CSSObject = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  margin: 0,
  position: "relative",
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
export interface ReviewScreenFormProps {
  view?: any;
  formData?: Map<string, any>;
  submitting?: boolean;
  onSubmit?: GenericFn;
  onCancel?: GenericFn;
  modal?: boolean;
}

const minMax = yup.number().min(0, "Cannot be less than 0").max(999, "Cannot be more than 999").required("Required");

const reviewScreenValidationSchema = yup.object({
  anagen_follicles: minMax,
  dandruff: minMax,
  gray_hair: minMax,
  dormant_follicles: minMax,
  terminal_hair: minMax,
  vellus_hair: minMax,
  remarks: yup.string().nullable().min(3),
});

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ReviewScreenForm: React.FC<ReviewScreenFormProps> = (props) => {
  const { formData, view, onCancel, onSubmit, submitting, modal } = props;
  const dispatch = useDispatch();
  const showForm = useSelector((s: RootState) => s.reviewScreen.showForm);

  //selected id
  const selected = useSelector((s: RootState) => s.reviewScreen.head.selected);
  //get permission
  const change_hairimagescores = usePermissionCheck("change_hairimagescores");
  //current form data
  const viewview = formData && formData.get(selected);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const reviewScreenInitialValues = {
    anagen_follicles: viewview?.anagen_follicles || 0,
    dandruff: viewview?.dandruff || 0,
    gray_hair: viewview?.gray_hair || 0,
    dormant_follicles: viewview?.dormant_follicles || 0,
    terminal_hair: viewview?.terminal_hair || 0,
    vellus_hair: viewview?.vellus_hair || 0,
    remarks: viewview?.remarks || "",
    id: viewview?.id,
  };

  const closeFormModal = () => dispatch(reviewScreenActions.toggleForm(false));

  const location: any = useLocation();
  const user_profile = location.state.data.user_profile;

  const commentBoxStyles: CSSObject = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  return (
    <div
      className={`${styles.review_screen_form} ${modal && styles.modal} ${
        showForm && styles.review_screen_form_active
      }`}
    >
      {/* <Stack direction="row" justifyContent="space-between"> */}

      {modal && <CloseIcon onClick={closeFormModal} />}
      <div className={styles.displaynamedate}>
        <Typography variant="h4" mb="1rem">
          {user_profile.full_name || viewview?.haircapture?.capture_by}
        </Typography>
        <Typography>{viewview?.haircapture?.image_capture_ts}</Typography>
        {/* <div>{pageData?.haircapture_id?.capture_id}</div> */}
      </div>

      {/* </Stack> */}
      <Box sx={{ width: "100%" }}>
        <Typography variant="h5">User Comments</Typography>
        <Box rowGap={1} className={styles.reviewviewview_display_texts} mt={2}>
          <Box sx={commentBoxStyles}>
            <Typography>Comment1</Typography>
            <Typography className={styles.comment}>{viewview?.comment1 || "N/A"}</Typography>
          </Box>
          <Box sx={commentBoxStyles}>
            <Typography>Comment2</Typography>
            <Typography className={styles.comment}>{viewview?.comment2 || "N/A"}</Typography>
          </Box>
          <Box sx={commentBoxStyles}>
            <Typography>Comment3</Typography>
            <Typography className={styles.comment}>{viewview?.comment3 || "N/A"}</Typography>
          </Box>
          <Box sx={commentBoxStyles}>
            <Typography>Photo taker license</Typography>
            <Typography className={styles.comment}>{viewview?.haircapture?.photo_taker_license_no || "N/A"}</Typography>
          </Box>
        </Box>
      </Box>

      <Formik
        enableReinitialize={true}
        validationSchema={reviewScreenValidationSchema}
        initialValues={reviewScreenInitialValues}
        onSubmit={(values) => onSubmit?.(values)}
      >
        {(formikbag) => {
          return (
            <Form>
              {selected.includes("1") ? (
                <Box rowGap={3} mt={3}>
                  <Typography variant="h5" mb={2}>
                    Count per cm<sup>2</sup>
                  </Typography>
                  <Box
                    display={"grid"}
                    gridTemplateColumns={"1fr 1fr 1fr"}
                    gap={1}
                    rowGap={formikbag.isValid ? 1 : "3rem"}
                    mb={formikbag.isValid ? 0 : "4rem"}
                  >
                    <Box sx={formGroup} className="form-group">
                      <Typography mb={1}>Anagen Follicles</Typography>
                      <ReviewScreenFormInput hasPermission={change_hairimagescores} name="anagen_follicles" />
                    </Box>
                    <Box sx={formGroup} className="form-group">
                      <Typography mb={1}>Dormant Follicles</Typography>
                      <ReviewScreenFormInput hasPermission={change_hairimagescores} name="dormant_follicles" />
                    </Box>
                    <Box sx={formGroup} className="form-group">
                      <Typography mb={1}>MyHairStatus</Typography>
                      <ReviewScreenFormInput max={25} hasPermission={change_hairimagescores} name="dandruff" />
                    </Box>

                    <Box sx={formGroup} className="form-group">
                      <Typography mb={1}>Terminal hair</Typography>
                      <ReviewScreenFormInput max={300} hasPermission={change_hairimagescores} name="terminal_hair" />
                    </Box>
                    <Box sx={formGroup} className="form-group">
                      <Typography mb={1}>Vellus Hair</Typography>

                      <ReviewScreenFormInput hasPermission={change_hairimagescores} name="vellus_hair" />
                    </Box>
                    <Box sx={formGroup} className="form-group">
                      <Typography mb={1}>MyGrowthScore</Typography>
                      <ReviewScreenFormInput max={10} hasPermission={change_hairimagescores} name="gray_hair" />
                    </Box>
                  </Box>
                </Box>
              ) : null}

              <Box width={"100%"} className="form-group" mt={3}>
                <h6>Notes</h6>
                <ReviewScreenFormTextArea hasPermission={change_hairimagescores} name="remarks" />
              </Box>
              <Box
                sx={{ display: "flex", columnGap: "1rem", justifyContent: "center", alignItems: "center", mt: "1rem" }}
                className="submit_discard"
              >
                {/* <button
                      className="employee-form-button"
                      type="submit"
                      name='button'
                     >Save</button> */}
                <button type="submit" disabled={!change_hairimagescores || submitting} className="employee-form-button">
                  Save
                  {submitting && <CircularProgress sx={{ ml: 1 }} size={20} />}
                </button>
                <Button
                  className="discard-btn"
                  sx={{ padding: ".8rem", borderRadius: "20%", color: "#000" }}
                  onClick={onCancel}
                >
                  Discard
                </Button>
              </Box>
              <MHCAlert />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export interface ReviewScreenFormFieldProps {
  name: string;
  hasPermission?: boolean;
  max?: number;
  children?: (...args: any) => ReactNode;
}

const ReviewScreenFormInput: React.FC<Omit<ReviewScreenFormFieldProps, "children">> = (props) => {
  const { name, hasPermission, max } = props;
  return (
    <ReviewScreenFormField name={name}>
      {(field, meta) => {
        return (
          <input
            type="number"
            min={0}
            max={max ? max : 999}
            disabled={!hasPermission}
            className={meta.error && meta.touched ? "form-control is-invalid" : "form-control"}
            {...field}
            placeholder="0"
          />
        );
      }}
    </ReviewScreenFormField>
  );
};

const ReviewScreenFormTextArea: React.FC<Omit<ReviewScreenFormFieldProps, "children">> = (props) => {
  const { name, hasPermission } = props;

  return (
    <ReviewScreenFormField name={name}>
      {(field, meta) => {
        return (
          <textarea
            cols={3}
            disabled={!hasPermission}
            className={
              meta.error && meta.touched
                ? "form-control is-invalid"
                : "form-control " + styles.review_screen_form_text_area
            }
            {...field}
          />
        );
      }}
    </ReviewScreenFormField>
  );
};

const ReviewScreenFormField: React.FC<ReviewScreenFormFieldProps> = (props) => {
  const { name, children } = props;
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <>
          {children?.(field, meta)}
          <Box mt={1} fontSize="0.8rem" position={"absolute"} top={"100%"} color={"#dc3545"}>
            {meta.touched && meta.error}
          </Box>
        </>
      )}
    </Field>
  );
};

export default ReviewScreenForm;
