import axios from "axios";
import HqmIcon from "components/icon";
import MHCAlert, { SNACKBAR_TYPE } from "components/MHCAlert";
import MHCConfirm from "components/MHCConfirm";
import SearchModule from "components/SearchModule";
import { useSetStatus } from "context/statusContext";
import { useCheckBoxDis } from "hooks/useCheckBoxDis";
import { usePermissionCheck } from "hooks/useCheckPermission";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import PaginatedTable from "../../components/Table/PaginatedTable";
import config from "../../config";
import { MODULES } from "../../constants/modules";
import { bulkDeleteDiscount, deleteDiscount, fetchDiscount, postDiscount } from "../../redux/discount/api";
import CreateDiscountForm from "./CreateDiscountForm";
import { DiscountTableConstants } from "./DiscountTableConstants";
import useFilter from "hooks/useFilter";

const Discount = ({ active, permissionList }: any) => {
  const [openModal, setOpenModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const {
    filter: filterOptions,
    actions: { handleSize, handlePagination, handleSearch, handleSort },
  } = useFilter({
    ordering: "id",
  });
  const { page, paginate_by, ordering: sortField, search: searchQuery } = filterOptions;
  const { data, isFetching, refetch } = useQuery(
    ["projects", active, filterOptions],
    () => fetchDiscount(filterOptions),
    { keepPreviousData: true },
  );
  const [partners, setPartners] = useState<any>([]);
  const [sk, setSk] = useState<any>([]);

  const { mutate: addUpdateDiscount, isLoading: postLoading } = useMutation(postDiscount, {
    onSuccess() {
      setStatus(SNACKBAR_TYPE.SUCCESS, "Posted data successfully.");
      refetch();
      setOpenModal(false);
    },
    onError(error: any) {
      (error as any)?.response?.data?.description?.errors?.forEach((err: any) => {
        let v: any = Object.values(err)[0];
        setStatus(SNACKBAR_TYPE.ERROR, v || (error as any)?.response?.data?.message || "Something went wrong");
      });
    },
  });
  const {
    mutate: delDiscount,
    isLoading: deleteLoading,
    isError: deleteIsError,
    error: deleteError,
    isSuccess: deleteIsSuccess,
  } = useMutation(deleteDiscount, {
    onSuccess() {
      handleCloseModal();
      setStatus(SNACKBAR_TYPE.SUCCESS, "Deleted discounts successfully");
      refetch();
    },
    onError(error: any) {
      setStatus(SNACKBAR_TYPE.ERROR, error?.response?.data?.message);
    },
  });

  const { mutate: bulkDelDisc } = useMutation(bulkDeleteDiscount, {
    onSuccess() {
      setChecked([]);
      allChecked?.forEach((v: any, k: any) => {
        allChecked.set(k, false);
      });
      setStatus(SNACKBAR_TYPE.SUCCESS, "Deleted discount successfully");
      refetch();
    },
    onError(error: any) {
      setStatus(SNACKBAR_TYPE.ERROR, error?.response?.data?.message);
    },
  });

  const [selectedDiscount, setSelectedDiscount] = useState<any>(null);
  const [confirmation, setConfirmation] = useState("");
  const setStatus = useSetStatus();

  const handleSubmitDiscount = (discount: any) => {
    addUpdateDiscount(discount);
  };

  const pageData = data && !_.isEmpty(data.data?.data) ? data.data?.data : [];

  const pageCount = (data && data.data?.page_count) || 0;

  const totalCount = (data && data.data?.total_count) || 0;

  const current_page_number = (data && data.data?.current_page_number) || 0;

  const { checkDiscount, checkAll, checked, allChecked, setChecked, setAllChecked } = useCheckBoxDis(
    page || 1,
    pageData,
    refresh,
    setRefresh,
  );

  const handleCloseModal = () => {
    setSelectedDiscount(null);
    setOpenModal(false);
  };

  const handleEditDiscount = (discount: any) => {
    setSelectedDiscount(discount);
    setOpenModal(true);
  };

  const handleDeleteChecked = () => {
    bulkDelDisc({ ids: checked });
  };

  const history = useHistory();

  const handleConfirmation = () => {
    if (confirmation === "bulkDelete") {
      handleDeleteChecked();
    }
    setConfirmation("");
  };

  const add_productdiscount = usePermissionCheck("add_productdiscount");
  const delete_productdiscount = usePermissionCheck("delete_productdiscount");
  const change_productdiscount = usePermissionCheck("change_productdiscount");

  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/web/partner/v2/partner/?is_approved=True&paginate_by=100`)
      .then((res) => {
        setPartners(res.data.data);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchSk = async () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}api/rest/store/v2`).then((res) => {
      setSk(res.data);
    });
  };
  useEffect(() => {
    fetchSk();
  }, []);

  const skumap = useMemo(
    () =>
      sk.reduce((t: any, c: any) => {
        t[c.id] = c;
        return t;
      }, {} as any),
    [sk],
  );

  return (
    <>
      <MHCConfirm
        open={confirmation}
        message={
          confirmation === "bulkApprove"
            ? "Are you sure you want to approve the selected Discounts?"
            : "Are you sure you want to delete the selected Discounts?"
        }
        title={confirmation === "bulkApprove" ? "Approval Confirmation" : "Deletion Confirmation"}
        onCancel={() => setConfirmation("")}
        onConfirm={handleConfirmation}
        yesLabel={confirmation === "bulkApprove" ? "Approve" : "Delete"}
        noLabel="Cancel"
      />
      <MHCAlert />
      {openModal ? (
        <CreateDiscountForm
          discount={selectedDiscount}
          handleSubmitDiscount={handleSubmitDiscount}
          openModal={openModal}
          onCancel={() => setOpenModal(false)}
          change_productdiscount={change_productdiscount}
        />
      ) : (
        <div className="page">
          <div className="module-header">
            <div className="module-name">Discount Codes</div>
            <div className="module-action">
              {add_productdiscount ? (
                <button
                  className="module-add-button"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  + Add Discount Code
                </button>
              ) : null}
              {checked?.length > 0 && (
                <>
                  {delete_productdiscount ? (
                    <button
                      className="module-delete-button"
                      style={{ marginLeft: "1rem" }}
                      disabled={!delete_productdiscount}
                      onClick={() => setConfirmation("bulkDelete")}
                    >
                      <HqmIcon name="trash" size={20} />
                    </button>
                  ) : null}
                </>
              )}
              <SearchModule handleSearch={handleSearch} />
            </div>
          </div>
          <PaginatedTable
            cols={DiscountTableConstants(
              handleEditDiscount,
              checkDiscount,
              checked,
              partners,
              setPartners,
              skumap,
              setSk,
              delete_productdiscount,
            )}
            data={pageData}
            isDark={false}
            bordered
            striped={false}
            hoverable
            entries={paginate_by || 10}
            onRowClick={() => {}}
            //selected={selectedRowID}
            componentName={MODULES.DISCOUNT}
            handlePagination={handlePagination}
            handleEntries={handleSize}
            currentPage={current_page_number}
            totalCount={totalCount}
            size="lg"
            isDataLoading={isFetching || postLoading || deleteLoading}
            handleSort={handleSort}
            initialSortConfig={{ key: sortField || "", direction: "desc" }}
            allChecked={allChecked}
            checkAll={checkAll}
            isPaginationRequired={true}
          />
        </div>
      )}
    </>
  );
};

export default Discount;
