import { Face, MoreVert } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";

import { Box, Slider } from "@mui/material";
import MHCSpinner from "components/MHCSpinner";
import { GenericFn } from "config";
import React, { useState } from "react";
import Cropper, { Size } from "react-easy-crop";
import { CgZoomIn, CgZoomOut } from "react-icons/cg";
import { GrRotateLeft, GrRotateRight } from "react-icons/gr";
import { Image as KonvaImage, Layer, Stage } from "react-konva";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { reviewScreenActions } from "redux/reviewScreen";
import { RootState } from "redux/store";
import useImage from "use-image";
import hbar from "../../assets/brand/horizontal_bar.png";
import ihbar from "../../assets/brand/illuminated_horizontalbar.png";
import ivbar from "../../assets/brand/illuminated_verticalbar.png";
import vbar from "../../assets/brand/vertical_bar.png";
import styles from "./review-screen.module.scss";
import ReviewScreenABCDGrid from "./ReviewScreenABCDGrid";
import ReviewScreenHeadCarousel from "./ReviewScreenHeadCarousel";
import verticalGrid from "./verticalgrid.png";

//resolves image src on load
//TODO: Fix double load image
const fetchImage = (src: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = (e) => {
      resolve(image?.src);
    };
    image.onerror = (e) => {
      reject(image.src);
    };
    image.src = src;
  });

export interface ReviewScreenImageProps extends ReviewScreenToolsProps {
  img: Map<string, any>;
}
/**
 * Component to render the review screen image section
 * @returns
 */
const ReviewScreenImage: React.FC<ReviewScreenImageProps> = (props) => {
  const { img, onZoomChange, onClose, onRotationChange, onImageFail } = props;
  const dispatch = useDispatch();

  const selectedId = useSelector((s: RootState) => s.reviewScreen.head.selected);
  const showTools = useSelector((s: RootState) => s.reviewScreen.showTools);

  const openReviewTools = () => dispatch(reviewScreenActions.toggleTools());
  const openForm = () => dispatch(reviewScreenActions.toggleForm(true));

  return (
    <div className={styles.review_screen_image_container}>
      <div className={styles.review_screen_close}>
        <CloseIcon onClick={onClose} />
        <div>
          <Face className={`${styles.review_screen_face_icon} `} onClick={openReviewTools} />
          <MoreVert className={styles.review_screen_more_icon} onClick={openForm} />
        </div>
      </div>

      <div className={styles.review_screen_views_container}>
        <ReviewScreenImageView
          img={img.get(selectedId)}
          onZoomChange={onZoomChange}
          onRotationChange={onRotationChange}
          onImageFail={onImageFail}
        />
        <div
          className={`${styles.review_screen_tools_container} ${
            showTools && styles.review_screen_tools_container_active
          }`}
        >
          <ReviewScreenTools {...props} />
        </div>
      </div>
    </div>
  );
};

export interface CropperType {
  zoom: number;
  crop: {
    x: number;
    y: number;
  };
}
interface ReviewScreenImageViewProps {
  img: string;
  onZoomChange?: (zoom: number) => void;
  onRotationChange?: (rotate: number) => void;
  onImageFail?: GenericFn;
}
/**
 * Render cropped section
 * @param props
 * @returns
 */
const ReviewScreenImageView: React.FC<ReviewScreenImageViewProps> = (props) => {
  //props
  const { img, onZoomChange, onRotationChange, onImageFail } = props;
  //state
  const [cropper, setCropper] = useState({ x: 0, y: 0 });
  //cropped area
  const [cpd, setCropAreaDimension] = useState({
    h: 0, //height
    w: 0, //width
  });
  // default aspect ratio for cropped area
  const [defaultAspectRatio, setDefaultAspectRatio] = useState(9 / 12);

  const zoom = useSelector((s: RootState) => s.reviewScreen.zoom);
  const rotate = useSelector((s: RootState) => s.reviewScreen.rotate);

  // const selected = useSelector((s: RootState) => s.reviewScreen.head.selected);

  const handleZoom = (zoom: number) => onZoomChange?.(zoom);
  const handleCropChange = (crop: typeof cropper) => setCropper(crop);

  const handleCropSizeChange = ({ height, width }: Size) => {
    // if (cpd.h > 0 || cpd.w > 0) return; this code will not allow you to update if there is change in dimensions
    setCropAreaDimension({ h: height, w: width });
  };

  const handleRotateChange = (rotate: number) => onRotationChange?.(rotate);

  const { isLoading, data: imageData } = useQuery(["fetchImage", img], () => fetchImage(img), {
    refetchOnWindowFocus: false,
    onSuccess(data) {
      const aspectRatioImage = new Image();
      aspectRatioImage.addEventListener("load", (e) => {
        if (!e.currentTarget) return;

        const { height: h, width: w } = e.currentTarget as HTMLImageElement;
        setDefaultAspectRatio(w / h);
      });

      if (data) aspectRatioImage.src = data as string;
    },
    onError() {
      console.log("Image load failed");
      onImageFail?.();
    },
  });

  return (
    <div className={styles.review_screen_image}>
      {/* {data && <img src={data as string} ref={imageDimensionRef} style={{ display: "none" }} alt="hidden " />} */}

      <Box
        sx={{
          position: "absolute",
          height: "99%",
          width: "99%",
          display: isLoading ? "grid" : "none",
          alignItems: "center",
        }}
      >
        <MHCSpinner type="light" />
      </Box>

      <ReviewScreenCanvas cpd={cpd} imageInitialWidth={cpd.w + 150} />

      <Cropper
        objectFit="vertical-cover"
        image={imageData as string}
        showGrid={false}
        crop={cropper}
        rotation={rotate * 9}
        zoom={zoom}
        aspect={defaultAspectRatio}
        maxZoom={10}
        onZoomChange={handleZoom}
        onCropChange={handleCropChange}
        onCropSizeChange={handleCropSizeChange}
        onRotationChange={handleRotateChange}
        style={{
          cropAreaStyle: {
            boxShadow: "none",
          },
        }}
        // onMediaLoaded={handleMediaLoad}
      />
    </div>
  );
};

interface ReviewScreenCanvasProps {
  cpd: { h: number; w: number }; //cropped area
  imageInitialWidth: number;
}
/**
 * Render canvas for mhc grid
 * @returns
 */
export const ReviewScreenCanvas: React.FC<ReviewScreenCanvasProps> = (props) => {
  const { cpd, imageInitialWidth } = props;

  const showHGrid = useSelector((state: RootState) => state.reviewScreen.hGrid);
  const showVGrid = useSelector((s: RootState) => s.reviewScreen.vGrid);

  const [image] = useImage(verticalGrid);

  const imageW = cpd.w * 0.8; //vertical grid image width
  const imageH = cpd.h * 0.91; // vertical grid image height

  //center coordinates
  const imageX = imageInitialWidth / 2 - imageW / 2;
  const imageY = cpd.h / 2 - imageH / 2;

  return (
    <Stage className={styles.review_screen_canvas} width={imageInitialWidth || 10} height={cpd.h || 10}>
      <Layer>
        <ReviewScreenABCDGrid cpd={cpd} imageInitialWidth={imageInitialWidth} show={showHGrid} />
        {showVGrid && <KonvaImage image={image} width={imageW} height={imageH} x={imageX} y={imageY} />}
      </Layer>
    </Stage>
  );
};

export interface ReviewScreenToolsProps {
  onHGridChange?: GenericFn;
  onVGridChange?: GenericFn;
  onZoomChange?: GenericFn;
  onHeadChange?: GenericFn;
  onClose?: GenericFn;
  onRotationChange?: GenericFn;
  onImageFail?: GenericFn;
}
/**
 * component for grid toggle, head carousel and zoom slider
 * @param props
 * @returns
 */
const ZOOM_DELTA = 0.1;
const ROTATE_DELTA = 0.2;
const MAX_ROTATE = 10;
const MIN_ROTATE = -10;

const ReviewScreenTools: React.FC<ReviewScreenToolsProps> = React.memo((props) => {
  const { onHGridChange, onVGridChange, onHeadChange, onZoomChange, onRotationChange } = props;
  const zoom = useSelector((state: RootState) => state.reviewScreen.zoom);
  const rotate = useSelector((state: RootState) => state.reviewScreen.rotate);

  const showHGrid = useSelector((s: RootState) => s.reviewScreen.hGrid);
  const showVGrid = useSelector((s: RootState) => s.reviewScreen.vGrid);

  const toggleHorizontal = () => onHGridChange?.(!showHGrid);
  const toggleVertical = () => onVGridChange?.(!showVGrid);
  const handleZoomChange = (e: any) => onZoomChange?.(e.target.value);
  const handleRotateChange = (e: any) => onRotationChange?.(e.target.value);

  const dispatch = useDispatch();

  const updateZoom = (zoom: number) => {
    dispatch(reviewScreenActions.updateZoom(zoom));
  };

  const updateRotate = (rotate: number) => {
    if (rotate > MAX_ROTATE || rotate < MIN_ROTATE) return;

    dispatch(reviewScreenActions.updateRotate(rotate));
  };

  const handleZoomIncrease = () => {
    const diff = zoom + ZOOM_DELTA;
    if (diff <= 10) updateZoom(diff);
  };
  const handleZoomDecrease = () => {
    const diff = zoom - ZOOM_DELTA;
    if (diff > 0.99) updateZoom(diff);
  };

  const handleRotateLeft = () => {
    const diff = rotate - ROTATE_DELTA;
    updateRotate(diff);
  };
  const handleRotateRight = () => {
    const diff = rotate + ROTATE_DELTA;
    updateRotate(diff);
  };

  const handleResetZoom = (e: any) => {
    if (e.detail === 2) {
      return updateZoom(1);
    }
  };

  const handleResetRotate = (e: any) => {
    if (e.detail === 2) {
      return updateRotate(0);
    }
  };

  return (
    <div className={styles.review_screen_tools}>
      <ReviewScreenHeadCarousel onHeadChange={onHeadChange} />
      <div
        className={`${styles.review_screen_grid_v_toggle} ${styles.review_screen_grid_toggle} ${
          showVGrid ? styles.active || "" : ""
        }`}
        onClick={toggleVertical}
      >
        <img src={!showVGrid ? vbar : ivbar} alt="" />
      </div>
      <div
        className={`${styles.review_screen_grid_h_toggle} ${styles.review_screen_grid_toggle} ${
          showHGrid ? styles.active || "" : ""
        }`}
        onClick={toggleHorizontal}
      >
        <img src={!showHGrid ? hbar : ihbar} alt="" />
      </div>

      <div className={styles.review_screen_slider_container}>
        <div className={styles.review_screen_slider}>
          <button className={styles.review_screen_handles} onClick={handleZoomIncrease}>
            <CgZoomIn />
          </button>
          <button className={styles.review_screen_slider_container} onClick={handleResetZoom}>
            <Slider
              sx={{
                "& .MuiSlider-rail": {
                  opacity: 0.5,
                  backgroundColor: "#bfbfbf",
                },
                "& .MuiSlider-track": {
                  opacity: 0.5,
                  backgroundColor: "#6554b2",
                },
                "& .MuiSlider-thumb": { backgroundColor: "#6554b2" },
              }}
              orientation="vertical"
              value={zoom}
              min={1}
              max={10}
              step={0.1}
              size="small"
              aria-label="Default"
              onChange={handleZoomChange}
            />
          </button>

          <button className={styles.review_screen_handles} onClick={handleZoomDecrease}>
            <CgZoomOut />
          </button>
        </div>
      </div>

      <div className={styles.review_screen_slider_container} onClick={handleResetZoom}>
        <div className={styles.review_screen_slider}>
          <button className={styles.review_screen_handles} onClick={handleRotateRight}>
            <GrRotateRight />
          </button>
          <button className={styles.review_screen_slider_container} onClick={handleResetRotate}>
            <Slider
              sx={{
                "& .MuiSlider-rail": {
                  opacity: 0.5,
                  backgroundColor: "#bfbfbf",
                },
                "& .MuiSlider-track": {
                  opacity: 0.5,
                  backgroundColor: "#6554b2",
                },
                "& .MuiSlider-thumb": { backgroundColor: "#6554b2" },
              }}
              orientation="vertical"
              value={rotate}
              min={-10}
              max={10}
              step={0.2}
              size="small"
              aria-label="Default"
              onChange={handleRotateChange}
            />
          </button>

          <button className={styles.review_screen_handles} onClick={handleRotateLeft}>
            <GrRotateLeft />
          </button>
        </div>
      </div>
    </div>
  );
});

export default ReviewScreenImage;
