import CloseIcon from "@mui/icons-material/Close";
import { Divider } from "@mui/material";
import { formatDate } from "components/SummaryTable/helpers";
import jsPDF, { jsPDFOptions } from "jspdf";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { BNAPDFImage, headSectionMap, Page } from "./JSPDFComponents";
import "./patientpdf.scss";
import { ReportData } from "./PatientReport";
import { PatientPDFProps, ReportDataProps } from "./types";

const html2pdf = require("html2pdf.js");

const jsPDFConfig: jsPDFOptions = {
  orientation: "p",
  unit: "px",
  format: "a4",
  hotfixes: ["px_scaling"],
};

const PatientPDF = (props: PatientPDFProps) => {
  const { data, onClose } = props;

  const session = data?.session || {
    comments: "",
    treatmentDirectives: "",
    treatmentAdjustment: "",
    treatmentRegimen: "",
  };

  const patientData = data?.patientDataDetails;
  const baselineOverall = data?.baselineOverall;
  const latestOverall = data?.latestOverall;

  const [isLoadingComplete, setLoadingComplete] = useState(false);

  const pdfContentRef = useRef<any>(null);
  const pdfImageRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { current: doc } = useRef<jsPDF>(new jsPDF(jsPDFConfig));
  const docWidth = doc.internal.pageSize.getWidth();
  const docHeight = doc.internal.pageSize.getHeight();

  const dimension = {
    width: docWidth,
    height: docHeight,
  };

  const reportData: ReportDataProps = {
    appointment_time: patientData?.appointment_time && formatDate(new Date(patientData?.appointment_time)),
    dob: patientData?.dob,
    emr: patientData?.emr,
    patientName: patientData?.patientName,
    baselineOverall: {
      dandruff: baselineOverall?.overall_dandruff || 0,
      grayHair: baselineOverall?.overall_gray_hair || 0,
      density: baselineOverall?.overall_density || 0,
      remarks: baselineOverall?.remarks,
    },
    latestOverall: {
      dandruff: latestOverall?.overall_dandruff || 0,
      grayHair: latestOverall?.overall_gray_hair || 0,
      density: latestOverall?.overall_density || 0,
      remarks: latestOverall?.remarks,
    },
    ...session,
  };

  const saveToPdf = async () => {
    const pdfContent = pdfContentRef.current;
    const pdfImage = pdfImageRef.current;

    if (!pdfContent) return;

    const html2CanvasConfig = {
      width: docWidth,
      useCORS: true,
      allowTaint: true,

    };
    // refer to https://github.com/eKoopmans/html2pdf.js/issues/187 for why this is the way this is

    html2pdf()
      .set({
        margin: 20,
        filename: "gen1.pdf",
        image: { type: "jpeg", quality: 1 },
        html2canvas: html2CanvasConfig,
        jsPDF: jsPDFConfig,
      })
      .from(pdfContent)
      .toPdf()
      .get("pdf")
      .then((pdf: jsPDF) => pdf.addPage())
      .from(pdfImage)
      .toContainer()
      .toCanvas()
      .toPdf()
      .save()
      .catch((err: any) => {
        console.log(err);
      });
  };

  // baseline individual image data
  const baselineData = data?.baselineImageData;
  // latest individual image data
  const latestData = data?.latestImageData;

  const baselineCodeList = baselineData ? Object.keys(baselineData) : [];
  const latestCodeList = latestData ? Object.keys(latestData) : [];

  const baselineDataLength = baselineCodeList.length;
  const latestDataLength = latestCodeList.length;

  const codeList = baselineDataLength >= latestDataLength ? baselineCodeList : latestCodeList;

  return (
    <div className="pdf-container" ref={containerRef}>
      <div className="close-icon" onClick={onClose}>
        <CloseIcon sx={{ color: "#fff" }}></CloseIcon>
      </div>
      <button onClick={saveToPdf} className="save-to-pdf">
        Save to Pdf
      </button>
      <div className="pdf-content-container">
        <div className="pdf-content" style={{ width: dimension.width }} ref={pdfContentRef}>
          <Page {...dimension}>
            <ReportData showOverall showCommentHistory={false} {...reportData} />
          </Page>
        </div>
        <div className="pdf-content" style={{ width: dimension.width }} ref={pdfImageRef}>
          {codeList.map((section, index) => {
            return (
              <Fragment key={`head_section_${index}`}>
                <Page className="bna-data" height={dimension.height}>
                  <img className="section_head" src={headSectionMap[section]} alt="" />
                  <BNAPDFImage
                    type="baseline"
                    section={section}
                    image={baselineData?.[section]?.image || ""}
                    individualScores={baselineData?.[section]}
                  />
                  <Divider sx={{ my: "1rem", mb: "2rem" }} />
                  <BNAPDFImage
                    type="latest"
                    section={section}
                    image={latestData?.[section]?.image || ""}
                    individualScores={latestData?.[section]}
                  />
                </Page>
              </Fragment>
            );
          })}
        </div>
        <Divider />
        <div id="pdf-output"></div>
      </div>
    </div>
  );
};

export default PatientPDF;
