import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Switch } from "@material-ui/core";
import { ConnectedFocusError } from "focus-formik-error";
import { FormikProvider, useFormik } from "formik";
import _ from "lodash";
import React, { useEffect } from "react";
import * as Yup from "yup";

const AddEmployeeForm = ({ employee, handleSubmitEmployee, openModal, error, change_employee }: any) => {
  const initialValues: any = employee
    ? employee
    : {
        employee_type: "FULLTIME",
        status: true,
        first_name: "",
        last_name: "",
        mobile: "",
        additional_contact_details: "",
        email: "",
        is_sales_representative: true,
        notes: "",
        working_hour_start: "",
        working_hour_end: "",
      };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      employee_type: Yup.string().required("Employee type is required"),
      status: Yup.boolean().required("Status is required"),
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      mobile: Yup.string()
        .matches(/^[0-9]+$/g, "Mobile number cannot contain characters")
        .max(15, "Mobile Number is invalid")
        .required("Mobile Number is required"),
      additional_contact_details: Yup.string(),
      email: Yup.string().email().required("Email is required"),
      is_sales_representative: Yup.boolean().required("Please Enter is sales representative"),
      notes: Yup.string(),
      working_hour_start: Yup.string().required("Please Enter the Start time"),
      working_hour_end: Yup.string().required("Please Enter the End time"),
    }),
    onSubmit: (values: any) => {
      handleSubmitEmployee(values);
    },
  });

  useEffect(() => {
    if (openModal === false && formik?.resetForm) {
      formik?.resetForm();
    }
  }, [openModal]);

  useEffect(() => {
    if (
      error &&
      error?.response &&
      error?.response?.data?.description &&
      !_.isEmpty(error?.response?.data?.description?.errors)
    ) {
      error?.response?.data?.description?.errors?.forEach((err: any) => {
        let k = Object.keys(err)[0] || "";
        let v: any = Object.values(err)[0];
        formik.setFieldError(k, v);
      });
    }
  }, [error]);

  console.log(formik.errors);

  return (
    <FormikProvider value={formik}>
      <ConnectedFocusError />
      <div className="employee-form">
        <div className="drawer-body">
          {employee && (
            <div className="form-group">
              <label>Employee ID</label>
              <input
                disabled={!change_employee}
                className={
                  formik.errors && formik.errors?.employee_id && formik.touched && formik.touched?.employee_id
                    ? "form-control is-invalid"
                    : "form-control "
                }
                name="employee_id"
                value={formik.values?.employee_id || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="ATI-XXXXX"
              />
              {formik.errors && formik.errors?.employee_id && formik.touched && formik.touched?.employee_id && (
                <div className="invalid-feedback">{formik.errors?.employee_id || ""}</div>
              )}
            </div>
          )}

          <div className="form-group">
            <label>Employee Type</label>
            <select
              disabled={!change_employee}
              className={
                formik.errors && formik.errors?.employee_type && formik.touched && formik.touched?.employee_type
                  ? "form-select is-invalid"
                  : "form-select"
              }
              name="employee_type"
              value={formik.values?.employee_type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="FULLTIME">Full Time</option>
              <option value="CONTRACT">Contract</option>
              <option value="PART_TIME">Part Time</option>
            </select>
            {formik.errors && formik.errors?.employee_type && formik.touched && formik.touched?.employee_type && (
              <div className="invalid-feedback">{formik.errors?.employee_type || ""}</div>
            )}
          </div>

          <div className="form-group">
            <label>Status</label>
            <br />
            <FormControlLabel
              control={
                <Switch
                  disabled={!change_employee}
                  name="status"
                  color="primary"
                  checked={formik.values?.status}
                  onChange={(e: any) => {
                    formik.setFieldValue("status", e?.target?.checked);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Active"
              style={{
                color: "#000",
              }}
            />
          </div>
          <div className="section-text">Personal Details</div>

          <div className="form-group">
            <label>First Name</label>
            <input
              disabled={!change_employee}
              className={
                formik.errors && formik.errors?.first_name && formik.touched && formik.touched?.first_name
                  ? "form-control is-invalid"
                  : "form-control"
              }
              name="first_name"
              value={formik.values?.first_name || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors && formik.errors?.first_name && formik.touched && formik.touched?.first_name && (
              <div className="invalid-feedback">{formik.errors?.first_name || ""}</div>
            )}
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input
              disabled={!change_employee}
              className={
                formik.errors && formik.errors?.last_name && formik.touched && formik.touched?.last_name
                  ? "form-control is-invalid"
                  : "form-control"
              }
              name="last_name"
              value={formik.values?.last_name || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors && formik.errors?.last_name && formik.touched && formik.touched?.last_name && (
              <div className="invalid-feedback">{formik.errors?.last_name || ""}</div>
            )}
          </div>

          <div className="section-text">Contact Information</div>
          <div className="form-group">
            <label>Mobile Number</label>
            <input
              disabled={!change_employee}
              className={
                formik.errors && formik.errors?.mobile && formik.touched && formik.touched?.mobile
                  ? "form-control is-invalid"
                  : "form-control"
              }
              name="mobile"
              value={formik.values?.mobile || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors && formik.errors?.mobile && formik.touched && formik.touched?.mobile && (
              <div className="invalid-feedback">{formik.errors?.mobile || ""}</div>
            )}
          </div>

          <div className="form-group">
            <label>Alternate Contact Details</label>
            <input
              disabled={!change_employee}
              className={
                formik.errors &&
                formik.errors?.additional_contact_details &&
                formik.touched &&
                formik.touched?.additional_contact_details
                  ? "form-control is-invalid"
                  : "form-control"
              }
              name="additional_contact_details"
              value={formik.values?.additional_contact_details || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors &&
              formik.errors?.additional_contact_details &&
              formik.touched &&
              formik.touched?.additional_contact_details && (
                <div className="invalid-feedback">{formik.errors?.additional_contact_details || ""}</div>
              )}
          </div>

          <div className="form-group">
            <label>Email</label>
            <input
              disabled={!change_employee}
              className={
                formik.errors && formik.errors?.email && formik.touched && formik.touched?.email
                  ? "form-control is-invalid"
                  : "form-control"
              }
              name="email"
              value={formik.values?.email || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors && formik.errors?.email && formik.touched && formik.touched?.email && (
              <div className="invalid-feedback">{formik.errors?.email || ""}</div>
            )}
          </div>

          <div className="section-text">Additional Information</div>

          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Is a sales representative ?</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={formik.values?.is_sales_representative}
              name="is_sales_representative"
              onChange={(e) => {
                if (e.target.value === "true") {
                  formik.setFieldValue("is_sales_representative", true);
                } else {
                  formik.setFieldValue("is_sales_representative", false);
                }
              }}
              onBlur={formik.handleBlur}
            >
              <FormControlLabel
                value={true}
                control={<Radio color="primary" />}
                label="Yes"
                disabled={!change_employee}
              />
              <FormControlLabel
                value={false}
                control={<Radio color="primary" />}
                label="No"
                disabled={!change_employee}
              />
            </RadioGroup>
            {formik.errors &&
              formik.errors?.is_sales_representative &&
              formik.touched &&
              formik.touched?.is_sales_representative && (
                <div className="invalid-feedback">{formik.errors?.is_sales_representative || ""}</div>
              )}
          </FormControl>

          <div className="form-group">
            <label>Start Time</label>
            <input
              type={"time"}
              disabled={!change_employee}
              className={
                formik.errors &&
                formik.errors?.working_hour_start &&
                formik.touched &&
                formik.touched?.working_hour_start
                  ? "form-control is-invalid"
                  : "form-control"
              }
              name="working_hour_start"
              value={formik.values?.working_hour_start || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors &&
              formik.errors?.working_hour_start &&
              formik.touched &&
              formik.touched?.working_hour_start && (
                <div className="invalid-feedback">{formik.errors?.working_hour_start || ""}</div>
              )}
          </div>
          <div className="form-group">
            <label>End Time</label>
            <input
              type={"time"}
              disabled={!change_employee}
              //new Date(`0 ${employee?.working_hour_start} UTC`).toString().split(' ')[4]
              className={
                formik.errors && formik.errors?.working_hour_end && formik.touched && formik.touched?.working_hour_end
                  ? "form-control is-invalid"
                  : "form-control"
              }
              name="working_hour_end"
              value={formik.values?.working_hour_end || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors && formik.errors?.working_hour_end && formik.touched && formik.touched?.working_hour_end && (
              <div className="invalid-feedback">{formik.errors?.working_hour_end || ""}</div>
            )}
          </div>

          <div className="form-group">
            <label>Notes</label>
            <textarea
              disabled={!change_employee}
              cols={3}
              className={
                formik.errors && formik.errors?.notes && formik.touched && formik.touched?.notes
                  ? "form-control is-invalid"
                  : "form-control"
              }
              name="notes"
              value={formik.values?.notes || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors && formik.errors?.notes && formik.touched && formik.touched?.notes && (
              <div className="invalid-feedback">{formik.errors?.notes || ""}</div>
            )}
          </div>
        </div>
        <div className="drawer-footer">
          {change_employee ? (
            <button className="employee-form-button" onClick={(e: any) => formik.handleSubmit(e)}>
              {employee ? "Update" : "Save"}
            </button>
          ) : null}
        </div>
      </div>
    </FormikProvider>
  );
};

export default AddEmployeeForm;
