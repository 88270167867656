import React, { Component } from 'react';

const _ = (styles:any) => {
  if(!styles) return {};
  if(styles.length){
    let temp = {};
    styles.map((i:any) => Object.assign(temp, i));
    return temp;
  }
  return styles;
};
export default function createIconSet(glyphMap:any, fontFamily:any) {
  class Icon extends Component {
    render() {
      const props:any = this.props;

      let glyph = props.name ? glyphMap[props.name] || '?' : '';
      if (typeof glyph === 'number') {
        glyph = String.fromCharCode(glyph);
      }

      var style = _([
        style = {
          fontSize: props.size ? props.size : 30,
          color: props.color ? props.color : 'grey',
        },
        props.style,
        {
          fontFamily: fontFamily,
          fontWeight: 'normal',
          fontStyle: 'normal',
        }
      ]);

      return (
        <i style={style} onClick= {props.onClick ? props.onClick : () => {}} className={props.className ? props.className : ""}>
          {glyph}
        </i>
      );
    }
  }

  return Icon;
}
