import HqmIcon from "components/icon";
import moment from "moment";
import mhcTheme from "../../assets/style/style.module.scss";

export const ProductTable = (handleProductTable: Function) => {
  return [
    {
      title: "Product Name",
      name: "product_name",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.product_name}</span>;
      },
    },

    {
      title: "Created At",
      name: "created_date",
      sortable: true,
      render: (rowData: any) => {
        return <span>{moment(rowData?.created_date).format("YYYY-MM-DD")}</span>;
      },
    },
    {
      title: "Treatment Category",
      name: "treatment_category__category_name",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.treatment_category_name}</span>;
      },
    },
    {
      title: "status",
      name: "is_active",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.is_active ? "Active" : "Not Active"}</span>;
      },
    },

    {
      title: "Edit",
      name: "action",
      //sortable: true,
      render: (rowData: any) => {
        return (
          <HqmIcon
            name="edit"
            size={20}
            style={{ color: mhcTheme.secondary }}
            onClick={() => handleProductTable(rowData)}
          />
        );
      },
    },
  ];
};
