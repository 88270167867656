import config from "config";
import { useReducer } from "react";

const INITIAL = {
  page: 1,
  paginate_by: config.PAGE_SIZE,
  ordering: "",
  search: "",
};

export type FilterOptions = Partial<typeof INITIAL> & Record<string, any>;

export const FilterReducer = (state: FilterOptions, action: { type: string; payload: any }) => {
  switch (action.type) {
    default:
      return state;
    case "UPDATE":
      const { field, value } = action.payload;
      return {
        ...state,
        [field]: value,
      };
  }
};

const useFilter = (defaultFilter?: FilterOptions) => {
  const [state, dispatch] = useReducer(FilterReducer, defaultFilter || INITIAL);

  const setField = (field: keyof FilterOptions, value: any) => {
    dispatch({ type: "UPDATE", payload: { field, value } });
  };
  const handleSearch = (searchKey: any) => {
    setField("search", searchKey);
  };

  const handlePagination = (page: number) => {
    setField("page", page);
  };

  const handleSize = (pagesize: number) => {
    setField("paginate_by", pagesize);
  };
  const handleSort = (key: string, direction: string) => {
    let keyOrder = direction === "desc" ? `-${key}` : direction === "asc" ? key : "";

    setField("ordering", keyOrder);
  };

  return {
    filter: state,
    dispatch,
    setField,
    actions: { handleSearch, handlePagination, handleSize: handleSize, handleSort },
  };
};

export default useFilter;
