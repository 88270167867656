import React from "react";
import trash from "assets/brand/trash.png";

function LocationEmployeeTable(props: { data: any; setSelectedId?: any; onChange?: (...args: any) => void }) {
  const { data, onChange } = props;

  const handleTrashIconClick = (item: any) => {
    const finalId = data.filter((dataItem: any) => {
      return dataItem.user_profile_id !== item.user_profile_id;
    });

    onChange?.(finalId);
  };
  return (
    <table className="employee-list custom-table" style={{ marginTop: "2rem" }}>
      <thead>
        <tr>
          <th>Full Name</th>
          <th>License Number</th>
          {/* <th>State</th>
          <th>Country</th> */}
          <th>Access Code</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        {data?.map((item: any) => (
          <tr key={`table_data${item?.user_profile_id}`}>
            <td>{item.full_name}</td>
            <td>{item.license_number}</td>
            {/* <td>{item.state}</td>
            <td>{item.country}</td> */}
            <td>{item?.access_code}</td>
            <td>
              <img
                src={trash}
                alt="img"
                onClick={() => handleTrashIconClick(item)}
                style={{ padding: "0.5rem 0.125rem" }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default React.memo(LocationEmployeeTable);
