import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  comments,
  treatment_adjustment,
  treatment_directives,
  treatment_regimen,
  // update_draft,
} from "redux/PhysicianPortalData/PhysicianPortalActions";
import { RootState } from "redux/store";
import * as routes from "../../../constants/routes";
import { GroupIcon } from "./GroupIcon";
import HeadIcon from "./HeadIcon";
import PatientComment from "./PatientComment";
import "./PatientData.scss";

function PatientDataBNA(props: any) {
  const [nextic, setNextic] = useState<string>();

  let history = useHistory();
  let dispatch = useDispatch();
  // const patient_user_profile_id = useSelector((state: RootState) => {
  //   return state.physicianPortalReducer.patient_user_profile_id;
  // });

  const commentRef = useRef<any>(null);
  const directiveRef = useRef<any>(null);
  const combinedRef = useRef<any>({ commentRef, directiveRef });
  const headIconRef = useRef<any>(null);

  // const childRef = useRef();
  //handles preview button click

  // const patient_emr = useSelector((state: RootState) => state.physicianPortalReducer.emr_no);

  const persistComment = () => {
    const commentData = combinedRef?.current?.getComments() && combinedRef?.current!.getComments();
    const directiveData = combinedRef?.current?.getDirectiveData() && combinedRef?.current!.getDirectiveData();
    const regimenData = combinedRef?.current?.getRegimenData() && combinedRef?.current!.getRegimenData();
    const adjustmentData = combinedRef?.current?.getAdjustmentData() && combinedRef?.current!.getAdjustmentData();

    dispatch(comments(commentData));
    dispatch(treatment_directives(directiveData));
    dispatch(treatment_regimen(regimenData));
    dispatch(treatment_adjustment(adjustmentData));
    // dispatch(update_draft(patient_user_profile_id, commentData, adjustmentData, directiveData, regimenData));
  };

  const handleHistoryClick = () => {
    persistComment();
    history.push(routes.PATIENT_HISTORY);
    // history.push({ pathname: routes.PATIENT_HISTORY, state: { data: props?.patientDataList } });
  };

  const handlePreviewClick = () => {
    persistComment();
    if (props.lock) {
      history.push({ pathname: routes.PATIENT_COMPLETED_REPORT, state: { reviewed: true } });
    } else {
      history.push({ pathname: routes.PATIENTREPORT });
    }
  };

  const handlePreviewReviewedClick = () => {
    persistComment();
    history.push({ pathname: routes.PATIENTREPORT });
  };

  const handleBack = () => {
    history.push(routes.PATIENTS);
    // dispatch(update_emr(patient_emr));
  };
  // this is needed to conditionally display elements in report generation page and BNA screen page
  let display = true;

  const ButtonList = (props: any) => {
    const { showPreview = true } = props;
    return (
      <div className="align-btns">
        <div
          className="buttons-phy"
          style={{ justifySelf: "flex-start" }}
          // onClick={() => history.push(routes.PATIENTS)}
          onClick={handleBack}
        >
          Go to Patient List
        </div>
        <div className="buttons-phy" onClick={handleHistoryClick}>
          Treatment History
        </div>
        {/* <div className="buttons-phy">Generate PDF</div> */}
        {showPreview && (
          <div className="buttons-phy" onClick={handlePreviewClick}>
            Report Preview
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="container-main">
      <div className="bna-header-container">
        <h4 className="bna_header">Before & After Images</h4>
        <ButtonList showPreview={false} />
      </div>
      <div className="container-position">
        <div className="bna-container">
          <div className="container-separator">
            <HeadIcon
              section={GroupIcon}
              onLoading={props?.onLoading}
              nextic={nextic}
              display={display}
              ref={headIconRef}
              buttonList={ButtonList}
            />
          </div>

          <div className="comment-container">
            <PatientComment ref={combinedRef} lock={props?.lock} />
          </div>
          <ButtonList />
        </div>
      </div>
    </div>
  );
}

export default PatientDataBNA;
