import api from "api";
import { FilterOptions } from "hooks/useFilter";

export const fetchUsers = async (filterOptions?: FilterOptions) => {
  const res = await api.get("/authentication/users", {
    params: {
      ...filterOptions,
    },
  });
  return res?.data;
};

export const fetchPermissionGroups = async () => {
  const res = await api.get("/authentication/permission-groups");
  return res?.data;
};
