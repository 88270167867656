import  createIconSet from "./icon-font"
import fontelloConfig from "./config.json"
// export const HqmIcon = createIconSet(fontelloConfig, "myhairscope-webfont-v2_1")
// export default HqmIcon

import createIcon from './icon-font';

import React, { Component } from 'react';

class HqmIcon extends Component<any,any>{
  render(){
    const glyphMap:any = {};
    fontelloConfig.glyphs.forEach(glyph => {
    glyphMap[glyph.css] = glyph.code;
  });
    let Icon:any = createIconSet(glyphMap, "MHCFont");
    return(
      <Icon
        className = {this.props.className}
        name={this.props.name}
        color={this.props.color ? this.props.color : 'grey'}
        size={this.props.size ? this.props.size : 30}
        style={this.props.style}
        onClick = {this.props.onClick}
      />
    );
  }
}

export default HqmIcon;