import "./App.css";

import AppRouter from "./router";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { theme } from "theme";
import { IThemeOptions } from "./global";
import { useEffect } from "react";

const muiTheme = createTheme(theme as IThemeOptions);
const measurementId = process.env.REACT_APP_MEASUREMENT_ID || "";

function App() {
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--primary-color",
      window.location.href.includes("regimen360") ? "#535ADF" : "#594A9E",
    );
  }, []);

  return (
    <ThemeProvider theme={muiTheme}>
      <Router>
        <AppRouter />
      </Router>

      <script async src={`https://www.googletagmanager.com/gtag/js?id=${measurementId}`}></script>
      <script>
        {`  window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${measurementId}');`}
      </script>
    </ThemeProvider>
  );
}

export default App;
