import InfoIcon from "@mui/icons-material/Info";
import HqmIcon from "components/icon";
import ReviewView from "layouts/ReviewView/ReviewView";
import moment from "moment";
import { useHistory } from "react-router-dom";
import mhcTheme from "../../assets/style/style.module.scss";
import * as routes from "../../constants/routes";
import Tooltip from "@mui/material/Tooltip";

const formatDate = (date: string | Date) => moment(date).format("YYYY-MM-DD");
// const { data: countryName } = useQuery(["location"], () => {
//   return fetchCountriesDataById(id);
// });
interface Country {
  id: number;
  name: string;
  active: boolean;
}
export const TableConstantsR = (
  handleview: Function,
  checkAssign: Function,
  checked: Array<number> = [],
  active: any,
  handleOpenModal: Function,
  countryData: Country[],
) => {
  let history = useHistory();
  return [
    {
      title: "checkbox",
      name: "checkbox",
      notClickable: true,
      render: (rowData: any) => {
        return (
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              onChange={() => checkAssign(parseInt(rowData.id))}
              onClick={(e) => e.stopPropagation()}
              checked={checked.includes(parseInt(rowData.id))}
            />
          </div>
        );
      },
    },
    {
      title: "User",
      name: "user_profile",
      sortable: false,
      render: (rowData: any) => {
        let name = rowData?.user_profile.full_name;
        <ReviewView full_name={name} />;
        return <span>{rowData?.user_profile.full_name}</span>;
      },
    },
    {
      title: "Captured Date",
      name: "captured_date",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.capture_date}</span>;
      },
    },
    {
      title: "DOB",
      name: "user_profile.date_of_birth ",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.user_profile?.date_of_birth}</span>;
      },
    },
    {
      title: "Email",
      name: "user_profile.email",
      sortable: false,
      render: (rowData: any) => {
        return (
          <Tooltip title={rowData?.user_profile?.email}>
            <span>{rowData?.user_profile?.email}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Country",
      name: "user_profile.country",
      sortable: false,
      render: (rowData: any) => {
        const countryId = rowData?.user_profile?.country;
        const countryName = countryData.find((item: any) => item.id === countryId);

        return <span>{countryName ? countryName.name : null}</span>;
      },
    },
    // {
    //   title: "Forwaded To",
    //   name: "forwaded_to_email",
    //   sortable: false,
    //   render: (rowData: any) => {
    //     return <span>{rowData?.forwarded_to_email ? rowData?.forwarded_to_email : <span>-</span>}</span>;
    //   },
    // },
    // {
    //   title: "Updated By",
    //   name: "reviewed_by",
    //   sortable: false,
    //   render: (rowData: any) => {
    //     return <span>{rowData?.reviewed_by ? rowData?.reviewed_by : <span>-</span>}</span>;
    //   },
    // },
    {
      title: "Assigned To",
      name: "assigned_to",
      sortable: false,
      render: (rowData: any) => {
        return (
          <>
            <Tooltip title={rowData?.assigned_to}>
              <span>{rowData?.assigned_to ? rowData?.assigned_to : <span>-</span>}</span>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Spa/Saloon",
      name: "spasaloon",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.capture_type?.toLowerCase() === "self" ? "SELF" : rowData?.location}</span>;
      },
    },
    {
      title: (
        <>
          Review Photos{" "}
          <InfoIcon
            titleAccess="Photo Review Priorities"
            style={{ margin: "auto", cursor: "pointer", paddingLeft: "0.3rem" }}
            onClick={() => handleOpenModal()}
          />
        </>
      ),
      name: "request_for_analysis",
      sortable: false,
      render: (rowData: any) => {
        return (
          <span
            className={
              rowData?.request_for_analysis === "PRIORITY"
                ? "review-badge review-error"
                : rowData?.request_for_analysis === "YES"
                ? "review-badge review-warning"
                : rowData?.request_for_analysis === "NO"
                ? "review-badge review-no"
                : ""
            }
          >
            {rowData?.request_for_analysis === "PRIORITY"
              ? "Yes"
              : rowData?.request_for_analysis === "YES"
              ? "Yes"
              : rowData?.request_for_analysis === "NO"
              ? "Maybe Later"
              : ""}
          </span>
        );
      },
    },
    // {
    //   title: "View",
    //   name: "action",
    //   sortable: false,
    //   render: (rowData: any) => {
    //     return (
    //       <HqmIcon
    //         name="eye"
    //         size={20}
    //         style={{ color: mhcTheme.secondary }}
    //         onClick={() => {
    //           handleview(rowData);
    //           history.push({ pathname: routes.VIEW_REVIEWS_PENDING, state: { data: rowData } });
    //         }}
    //       />
    //     );
    //   },
    // },
  ];
};

export const TableConstantsReviewed = (handleview: Function) => {
  // const [packageCost,setPackageCost]=useState(null)
  // const fetchCostDetails=async()=>{
  //   axios.get(`https://mhs-dev.myhairscope.com/api/web/review/v2/cost/`).then((res)=>{
  //     setPackageCost(res?.data?.id)
  //     console.log(res?.data)
  //   })
  // }
  // useEffect(()=>{fetchCostDetails()},[])
  let history = useHistory();

  return [
    {
      title: "User",
      name: "user_profile",
      sortable: false,
      render: (rowData: any) => {
        let name = rowData?.user_profile.full_name;
        <ReviewView full_name={name} />;
        return <span>{rowData?.user_profile.full_name}</span>;
      },
    },
    {
      title: "Captured Date",
      name: "capture_date",
      sortable: true,
      render: (rowData: any) => {
        const lastupdate = String(formatDate(rowData?.capture_date));
        return <span>{lastupdate}</span>;
      },
    },
    {
      title: "Reviewed Date",
      name: "reviewed_ts",
      sortable: true,
      render: (rowData: any) => {
        const lastupdate = String(formatDate(rowData?.reviewed_ts));
        return <span>{lastupdate}</span>;
      },
    },
    {
      title: "Cost",
      name: "cost",
      sortable: false,
      render: (rowData: any) => {
        let a = "";
        if (rowData?.cost === 1) {
          a = "Free Review";
        } else if (rowData?.cost === 2) {
          a = "Silver Review";
        } else if (rowData?.cost === 3) {
          a = "Gold Review";
        } else {
          a = "No Cost";
        }

        // return<span>{rowData?.cost}</span>
        return (
          <span
            className={
              rowData?.cost === 1
                ? "badge badge-primary"
                : rowData?.cost === 2
                ? "badge  badge-silver"
                : !rowData?.cost
                ? "badge badge-disabled"
                : "badge  badge-warning"
            }
          >
            {a}
          </span>
        );
      },
    },
    // {
    //   title: "Forwaded To",
    //   name: "forwaded_to_email",
    //   sortable: false,
    //   render: (rowData: any) => {
    //     return <span>{rowData?.forwarded_to_email ? rowData?.forwarded_to_email : <span>-</span>}</span>;
    //   },
    // },
    // {
    //   title: "Updated By",
    //   name: "reviewed_by",
    //   sortable: false,
    //   render: (rowData: any) => {
    //     return <span>{rowData?.reviewed_by ? rowData?.reviewed_by : <span>-</span>}</span>;
    //   },
    // },
    {
      title: "Assigned To",
      name: "assigned_to",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.assigned_to ? rowData?.assigned_to : <span>-</span>}</span>;
      },
    },
    {
      title: "Spa/Saloon",
      name: "spasaloon",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.capture_type?.toLowerCase() === "self" ? "SELF" : rowData?.location}</span>;
      },
    },
    {
      title: "View",
      name: "action",
      sortable: false,
      render: (rowData: any) => {
        return (
          <HqmIcon
            name="eye"
            size={20}
            style={{ color: mhcTheme.secondary }}
            onClick={() => {
              handleview(rowData);
              history.push({ pathname: routes.VIEW_REVIEWS_REVIEWED, state: { data: rowData } });
            }}
          />
        );
      },
    },
  ];
};
export const TableConstantsRevieweArchive = (
  handlePermanentDeletion: Function,
  checkSelectArchive: Function,
  checkedArchive: Array<number> = [],
) => {
  return [
    {
      title: "checkbox",
      name: "checkbox",
      notClickable: true,
      render: (rowData: any) => {
        return (
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              onChange={() => checkSelectArchive(parseInt(rowData?.id))}
              onClick={(e) => e.stopPropagation()}
              checked={checkedArchive.includes(parseInt(rowData?.id))}
            />
          </div>
        );
      },
    },
    {
      title: "User",
      name: "user_profile",
      sortable: false,
      render: (rowData: any) => {
        let name = rowData?.user_profile.full_name;
        <ReviewView full_name={name} />;
        return <span>{rowData?.user_profile?.full_name ? rowData?.user_profile?.full_name : <span>-</span>}</span>;
      },
    },
    {
      title: "Captured date",
      name: "capture_date",
      sortable: true,
      render: (rowData: any) => {
        const lastupdate = String(formatDate(rowData?.capture_date));
        return <span>{lastupdate}</span>;
      },
    },

    {
      title: "Assigned To",
      name: "assigned_to",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.assigned_to ? rowData?.assigned_to : <span>-</span>}</span>;
      },
    },

    {
      title: "Status",
      name: "status",
      sortable: false,
      render: (rowData: any) => {
        let status = "";
        if (rowData?.status === "UPLOADING") {
          status = "Pending";
        } else if (rowData?.status === "REVIEWED") {
          status = "Reviewed";
        } else if (rowData?.status === "CANCELLED") {
          status = "Cancelled";
        }
        return <span>{status}</span>;
      },
    },
    {
      title: "Delete",
      name: "action",
      sortable: false,
      render: (rowData: any) => {
        return (
          <HqmIcon
            name="trash"
            size={20}
            style={{ color: mhcTheme.secondary }}
            onClick={() => handlePermanentDeletion([rowData?.id])}
          />
        );
      },
    },
  ];
};

export const TableConstantsCancelled = () => {
  let history = useHistory();

  return [
    {
      title: "User",
      name: "user_profile",
      sortable: false,
      render: (rowData: any) => {
        let name = rowData?.user_profile.full_name;
        <ReviewView full_name={name} />;
        return <span>{rowData?.user_profile.full_name}</span>;
      },
    },
    {
      title: "Captured date",
      name: "capture_date",
      sortable: true,
      render: (rowData: any) => {
        const lastupdate = String(formatDate(rowData?.capture_date));
        return <span>{lastupdate}</span>;
      },
    },
    {
      title: "Cost",
      name: "cost",
      sortable: false,
      render: (rowData: any) => {
        let a = "";
        if (rowData?.cost === 1) {
          a = "Free Review";
        } else if (rowData?.cost === 2) {
          a = "Silver Review";
        } else if (rowData?.cost === 3) {
          a = "Gold Review";
        } else {
          a = "No Cost";
        }

        // return<span>{rowData?.cost}</span>
        return (
          <span
            className={
              rowData?.cost === 1
                ? "badge badge-primary"
                : rowData?.cost === 2
                ? "badge  badge-silver"
                : !rowData?.cost
                ? "badge badge-disabled"
                : "badge  badge-warning"
            }
          >
            {a}
          </span>
        );
      },
    },
    // {
    //   title: "Forwaded To",
    //   name: "forwaded_to_email",
    //   sortable: false,
    //   render: (rowData: any) => {
    //     return <span>{rowData?.forwarded_to_email ? rowData?.forwarded_to_email : <span>-</span>}</span>;
    //   },
    // },
    // {
    //   title: "Updated By",
    //   name: "reviewed_by",
    //   sortable: false,
    //   render: (rowData: any) => {
    //     return <span>{rowData?.reviewed_by ? rowData?.reviewed_by : <span>-</span>}</span>;
    //   },
    // },
    {
      title: "Assigned To",
      name: "assigned_to",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.assigned_to ? rowData?.assigned_to : <span>-</span>}</span>;
      },
    },
    {
      title: "Spa/Saloon",
      name: "spasaloon",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.capture_type?.toLowerCase() === "self" ? "SELF" : rowData?.location}</span>;
      },
    },
    {
      title: "View",
      name: "action",
      sortable: false,
      render: (rowData: any) => {
        return (
          <HqmIcon
            name="eye"
            size={20}
            style={{ color: mhcTheme.secondary }}
            onClick={() => {
              history.push({ pathname: routes.VIEW_REVIEWS_CANCELLED, state: { data: rowData } });
            }}
          />
        );
      },
    },
  ];
};
