import HqmIcon from "components/icon";
import mhcTheme from "../../assets/style/style.module.scss";
import restoreIcon from "../../assets/brand/restoreIcon.svg";
import moment from "moment";

interface Country {
  id: number;
  name: string;
  active: boolean;
}

export const TableConstants = (handleEditEmployee: Function, countryData: Country[]) => {
  return [
    {
      title: "Name",
      name: "name",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.first_name + " " + (rowData?.middle_name || "") + " " + rowData?.last_name}</span>;
      },
    },
    {
      title: "Phone",
      name: "user_profile.contactNumber",
      sortable: false,
      render: (rowData: any) => {
        console.log("rowData12user", rowData);
        return <span>{rowData?.user_profile?.contactNumber}</span>;
      },
    },
    {
      title: "Country",
      name: "user_profile.country",
      sortable: false,
      render: (rowData: any) => {
        const countryId = rowData?.user_profile?.country;
        const countryName = countryData.find((item: any) => item.id === countryId);

        return <span>{countryName ? countryName.name : null}</span>;
      },
    },
    // {
    //   title: "Username",
    //   name: "username",
    //   sortable: true,
    //   render: (rowData: any) => {
    //     return <span>{rowData?.username}</span>;
    //   },
    // },
    {
      title: "Email",
      name: "email",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.email}</span>;
      },
    },
    {
      title: "Date Joined",
      name: "date_joined",
      sortable: true,
      render: (rowData: any) => {
        return <span>{moment(rowData?.date_joined).format("YYYY-MM-DD HH:mm")}</span>;
      },
    },

    {
      title: "Edit",
      name: "action",
      //sortable: true,
      render: (rowData: any) => {
        return (
          <HqmIcon
            name="edit"
            size={20}
            style={{ color: mhcTheme.secondary }}
            onClick={() => handleEditEmployee(rowData)}
          />
        );
      },
    },
  ];
};

// export const TableConstantsPending = (
//   handleViewEmployee: Function,
//   checkEmployee: Function,
//   checked: Array<number> = [],
// ) => {
//   return [
//     {
//       title: "checkbox",
//       name: "checkbox",
//       notClickable: true,
//       render: (rowData: any) => {
//         return (
//           <div className="form-check">
//             <input
//               className="form-check-input"
//               type="checkbox"
//               id="flexCheckDefault"
//               onChange={() => checkEmployee(parseInt(rowData.id))}
//               onClick={(e) => e.stopPropagation()}
//               checked={checked.includes(parseInt(rowData.id))}
//             />
//           </div>
//         );
//       },
//     },
//     {
//       title: "ID",
//       name: "employee_id",
//       sortable: true,
//       render: (rowData: any) => {
//         return <span>{rowData?.employee_id}</span>;
//       },
//     },
//     {
//       title: "Name",
//       name: "name",
//       sortable: true,
//       render: (rowData: any) => {
//         return <span>{rowData?.first_name + " " + rowData?.middle_name + " " + rowData?.last_name}</span>;
//       },
//     },
//     {
//       title: "Mobile",
//       name: "mobile",
//       sortable: true,
//       render: (rowData: any) => {
//         return <span>{rowData?.mobile}</span>;
//       },
//     },
//     {
//       title: "IsSalesRep?",
//       name: "is_sales_representative",
//       sortable: true,
//       render: (rowData: any) => {
//         return <span>{rowData?.is_sales_representative === "YES" ? "Yes" : "No"}</span>;
//       },
//     },
//     {
//       title: "Email",
//       name: "email",
//       sortable: true,
//       render: (rowData: any) => {
//         return <span>{rowData?.email}</span>;
//       },
//     },
//     {
//       title: "Status",
//       name: "status",
//       sortable: true,
//       render: (rowData: any) => {
//         return (
//           <span className={rowData?.status === "ACTIVE" ? "badge badge-success" : "badge badge-error"}>
//             {rowData?.status}
//           </span>
//         );
//       },
//     },
//     {
//       title: "View",
//       name: "action",
//       //sortable: true,
//       render: (rowData: any) => {
//         return (
//           <HqmIcon
//             name="eye"
//             size={20}
//             style={{ color: mhcTheme.secondary }}
//             onClick={() => handleViewEmployee(rowData)}
//           />
//         );
//       },
//     },
//   ];
// };

// export const TableConstantsArchived = (
//   handleRestore: Function,
//   handlePermanentlyDelete: Function,
//   checkSelectArchive: Function,
//   checkedArchive: Array<number> = [],
// ) => {
//   return [
//     {
//       title: "checkbox",
//       name: "checkbox",
//       notClickable: true,
//       render: (rowData: any) => {
//         return (
//           <div className="form-check">
//             <input
//               className="form-check-input"
//               type="checkbox"
//               id="flexCheckDefault"
//               onChange={() => checkSelectArchive(parseInt(rowData.id))}
//               onClick={(e) => e.stopPropagation()}
//               checked={checkedArchive.includes(parseInt(rowData?.id))}
//             />
//           </div>
//         );
//       },
//     },
//     {
//       title: "Name",
//       name: "name",
//       sortable: true,
//       render: (rowData: any) => {
//         return <span>{rowData?.first_name + " " + rowData?.middle_name + " " + rowData?.last_name}</span>;
//       },
//     },
//     {
//       title: "Mobile",
//       name: "mobile",
//       sortable: false,
//       render: (rowData: any) => {
//         return <span>{rowData?.mobile}</span>;
//       },
//     },
//     {
//       title: "IsSalesRep?",
//       name: "is_sales_representative",
//       sortable: true,
//       render: (rowData: any) => {
//         return <span>{rowData?.is_sales_representative === "YES" ? "Yes" : "No"}</span>;
//       },
//     },
//     {
//       title: "Email",
//       name: "email",
//       sortable: true,
//       render: (rowData: any) => {
//         return <span>{rowData?.email}</span>;
//       },
//     },

//     {
//       title: "Restore",
//       name: "view",
//       //sortable: true,
//       render: (rowData: any) => {
//         return (
//           <img
//             src={restoreIcon}
//             alt="restoreIcon"
//             style={{ maxWidth: "32px" }}
//             onClick={() => handleRestore(rowData)}
//           />
//         );
//       },
//     },
//     {
//       title: "Delete",
//       name: "delete",
//       render: (rowData: any) => {
//         return (
//           <HqmIcon
//             name="trash"
//             size={20}
//             style={{ color: mhcTheme.secondary }}
//             onClick={() => handlePermanentlyDelete(rowData)}
//           />
//         );
//       },
//     },
//   ];
// };
