import { makeStyles } from "@material-ui/styles";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import "./CompanyDetail.scss";
import { Divider } from "@mui/material";
import backIcon from "assets/brand/goback.png";
import * as routes from "constants/routes";
import LocationEmployees from "./LocationEmployees";
import "./styles/CompanyDetail.style.scss";
import GeneralInformation from "./GeneralInformation";
import { theme } from "theme";

export interface CompanyDetailProps {
  edit?: boolean;
}

const steps = ["Company Details", "Location and Employees"];

const PartnerCtx = React.createContext({
  edit: false,
});
export const usePartner = () => React.useContext(PartnerCtx);

const CompanyDetails: React.FC<CompanyDetailProps> = (props) => {
  const { edit } = props;

  const history = useHistory();

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const location = useLocation<{ id: number }>();
  const { id } = useParams<{ id: string }>();
  // current partner id
  const partnerId = id !== undefined ? id : location?.state?.id;

  function getStepContent(activeStep: number) {
    switch (activeStep) {
      default:
      case 0: //Company Details
        return <GeneralInformation id={Number(partnerId)} activeStep={activeStep} setActiveStep={setActiveStep} />;
      case 1: //Location and Employees
        return <LocationEmployees id={Number(partnerId)} activeStep={activeStep} setActiveStep={setActiveStep} />;
    }
  }

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const useStyles = makeStyles(() => ({
    root: {
      "& .MuiStepIcon-active": { color: "" },
      // "& .MuiStepIcon-completed": { color: "black" },
      "& .Mui-disabled .MuiStepIcon-root": { color: "lightgray", opacity: 0.2 },
      "& .MuiSvgIcon-root circle": { color: theme.palette.primary.main },
      "& svg.Mui-completed": { color: "#34C272" },
      "& .css-qivjh0-MuiStepLabel-label.Mui-active": {
        color: theme.palette.primary.main,
        fontFamily: "Nunito-Regular",
        fontWeight: "700",
        fontSize: "19.2px",
        lineHeight: "26px",
        letterSpacing: "0.5%",
      },
      "& .css-qivjh0-MuiStepLabel-label.Mui-completed": {
        color: "#34C272",
        fontFamily: "Nunito-Regular",
        fontWeight: "700",
        fontSize: "19.2px",
        lineHeight: "26px",
        letterSpacing: "0.5%",
      },
      "& .css-qivjh0-MuiStepLabel-label": {
        color: "#878787",
        fontFamily: "Nunito-Regular",
        fontWeight: "700",
        fontSize: "19.2px",
        lineHeight: "26px",
        letterSpacing: "0.5%",
      },
      // "& .css-qivjh0-MuiStepLabel-label": { color: "green" },
    },
  }));

  const c = useStyles();
  const handleBackClick = () => {
    // eslint-disable-next-line no-restricted-globals
    history.goBack();
  };

  return (
    <PartnerCtx.Provider value={{ edit: !!edit }}>
      <div className="partner">
        <ToastContainer
          className={"toast-container"}
          bodyClassName="toast-body"
          pauseOnFocusLoss={false}
          autoClose={3000}
        />
        <div className="title-container">
          <img src={backIcon} alt="/" onClick={handleBackClick} className="back-icon" />
          <div className="title-text add-txt">{edit ? "Edit" : "Add"} partner</div>
        </div>
        <Divider sx={{ marginY: "1rem" }} />
        <Box className="stepper-container">
          <Stepper className={"step-label-container " + c.root} activeStep={activeStep} connector={null}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div className="center">
            <div className="aliign-itm">{getStepContent(activeStep)} </div>
          </div>
        </Box>
      </div>
    </PartnerCtx.Provider>
  );
};

export interface CompanyDetailsFooterProps {
  activeStep: number;
  setActiveStep: (val: number) => void;
  onSubmit: () => void;
  lastItem: number;
}

export const CompanyDetailsFooter: React.FC<CompanyDetailsFooterProps> = (props) => {
  const { activeStep, setActiveStep, onSubmit, lastItem } = props;
  const history = useHistory();

  return (
    <div className={`bottomborder button-group module-action ${activeStep > 0 && "hasPrev"}`}>
      {activeStep !== 0 && (
        <button className="module-add-button prev-btn" disabled={activeStep === 0} onClick={() => setActiveStep(0)}>
          Previous
        </button>
      )}
      <button className="module-add-button sub-btn cancel-btn" onClick={() => history.push(routes.PARTNERS_ACTIVE)}>
        Cancel
      </button>

      <button className="module-add-button sub-btn save-btn" type="submit" onClick={onSubmit}>
        {/* {<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />} */}
        <span className="visually-hidden">Loading...</span>
        {activeStep === lastItem ? "Save" : "Next"}
      </button>
    </div>
  );
};

export default CompanyDetails;
