import api from "api";

//get partner info
export const getPartnerInfo = async (general_info_id: number) => {
  const res = await api.get(`api/web/partner/v2/partner/${general_info_id}`);
  return res?.data;
};

//get business
export const fetchBusiness = async () => {
  const response = await api.get("partner/primary-business/");

  return response?.data;
};

//get location info
export const fetchLocationInfo = async (id: number) => {
  const response = await api.get(`partner/locations/?general_info_id=${id}`);
  return response?.data;
};

//get representative list
export const fetchEmployeeList = async () => {
  const response = await api.get("employee/list_approved_employees_names");
  return response?.data;
};
// fetch country list
export const fetchCountries = async () => {
  const response = await api.get("user/country/");
  return response?.data;
};
// fetch timezones
export const fetchTimezones = async () => {
  const response = await api.get("api/web/partner/v2/timezone");
  return response?.data;
};
// fetch states
export const fetchStates = async () => {
  const response = await api.get(`user/state/`);
  return response?.data;
};
// fetch single employee
export const fetchEmployeeQry = async (qry: string) => {
  const response = await api.get("api/web/partner/employee-list", {
    params: {
      search_query: qry,
    },
  });
  if (!response?.data?.length) throw new Error("No data found");
  return response?.data;
};

// fetch employee profile id
export const fetchEmployees = async (partner_id: number[]) => {
  const response = await api.get(`api/web/partner/user-profile-list`, {
    params: {
      ids: partner_id.join(","),
    },
  });
  return response?.data;
};
