import api from "api";
import axios from "axios";
import { FilterOptions } from "hooks/useFilter";

export const fetchProducts = async (filterOptions?: FilterOptions) => {
  const res = await api.get("product/treatment/v2", {
    params: {
      ...filterOptions,
    },
  });
  return res?.data;
};
export const fetchCategories = async () => {
  const res = await api.get("/category/treatment/");
  return res?.data;
};

export const fetchProductID = async (id: number) => {
  const res = await axios({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "GET",
    url: `product/treatment/${id}/`,
  });
  return res?.data;
};

export const deleteProduct = async (id: number) => {
  return await axios({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "DELETE",
    url: `product/treatment/${id}/`,
  });
};

export const createProduct = async (value: any) => {
  return await axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method: value.id ? "PUT" : "POST",
    url: value.id ? `product/treatment/${value?.id}/` : `product/treatment/`,
    data: value,
  });
};
