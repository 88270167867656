import HqmIcon from "components/icon";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { patient_pk_id, patient_user_profile_id } from "redux/PhysicianPortalData/PhysicianPortalActions";
import * as routes from "constants/routes";

export const PatientCompletedTable = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  return [
    {
      title: "Patient Id",
      name: "patientId",
      sortable: false,
      render: (rowData: any) => {
        // console.log(rowData?.patient_id);
        return <span>{rowData?.emr_number || rowData?.patient_id}</span>;
      },
    },
    {
      title: "Client Name",
      name: "patient_id",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.user_profile?.full_name ? rowData?.user_profile?.full_name : "-"}</span>;
      },
    },
    {
      title: "Appointment Date",
      name: "appointment_name",
      sortable: false,
      render: (rowData: any) => {
        return <span>{moment(rowData?.appointment_time).format("YYYY-MM-DD")}</span>;
      },
    },
    {
      title: "Clinic / Spa",
      name: "location_name",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.location?.location_name}</span>;
      },
    },
    {
      title: "Phone Number",
      name: "phonenumber",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.user_profile?.contact_number}</span>;
      },
    },
    {
      title: "View",
      name: "action",
      sortable: false,
      render: (rowData: any) => {
        return (
          <HqmIcon
            name="eye"
            size={20}
            style={{ color: "black" }}
            onClick={() => {
              dispatch(patient_pk_id(rowData?.id));
              dispatch(patient_user_profile_id(rowData?.user_profile?.id));
              history.push({ pathname: routes.PATIENT_COMPLETED_REPORT, state: { data: rowData, hideSave: true } });
            }}
          />
        );
      },
    },
  ];
};
