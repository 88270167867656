import api from "api";

export const fetchparternsDetailId = async (id: number) => {
  const res = await api({
    method: "GET",
    url: `/api/web/partner/v2/partner/${id}`,
  });
  return res?.data;
};

export const editGeneralDetails = async (values: any) => {
  const res = await api({
    method: "PUT",
    url: `/api/web/partner/v2/partner/${values?.id}`,
    data: values,
  });
  return res?.data;
};

export const postGeneralDetails = async (values: any) => {
  const response = await api({
    method: "POST",
    url: `/api/web/partner/v2/partner/`,
    data: values,
  });

  return response?.data;
};
