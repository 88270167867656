//import StoreIcon from '@material-ui/icons/Store';
import * as routes from "../../constants/routes";
import { ISidebarItem } from "../../redux/sidebar/types";
import { ReactComponent as UserProduct } from "assets/brand/userProduct.svg";
import { ReactComponent as Products } from "assets/brand/products.svg";

export type SIDEBAR_ITEM_DIVIDER = "SIDEBAR_ITEM_DIVIDER";
const iconSize = { width: 24, height: 24 };

export const sidebarItems: (ISidebarItem | SIDEBAR_ITEM_DIVIDER)[] = [
  {
    key: "home",
    label: "Home",
    toolbarTitle: "Home",
    Icon: "home",
    path: routes.HOME,
  },
  {
    key: "employees",
    label: "Employees",
    Icon: "id-card",
    path: routes.EMPLOYEES_ACTIVE,
    subItems: [
      {
        key: "active",
        label: "Active",
        path: routes.EMPLOYEES_ACTIVE,
        toolbarTitle: "Active Employees",
      },
      {
        key: "pending",
        label: "Pending",
        path: routes.EMPLOYEES_PENDING,
        toolbarTitle: "Pending Employees",
      },
      {
        key: "archived",
        label: "Archived",
        path: routes.EMPLOYEES_ARCHIVED,
        toolbarTitle: "Archived Employees",
      },
    ],
  },
  {
    key: "partners",
    label: "Partners",
    Icon: "building",
    path: routes.PARTNERS_ACTIVE,
    subItems: [
      {
        key: "active",
        label: "Active",
        path: routes.PARTNERS_ACTIVE,
        toolbarTitle: "Active Partners",
      },
      {
        key: "pending",
        label: "Pending",
        path: routes.PARTNERS_PENDING,
        toolbarTitle: "Pending Partners",
      },
      {
        key: "archived",
        label: "Archived",
        path: routes.PARTNERS_ARCHIVED,
        toolbarTitle: "Archived Employees",
      },
    ],
  },

  {
    key: "reviews",
    label: "Reviews",
    Icon: "review",
    path: routes.REVIEWS_PENDING,
    subItems: [
      {
        key: "pending",
        label: "Pending",
        path: routes.REVIEWS_PENDING,
        toolbarTitle: "Pending Reviews",
      },
      {
        key: "reviewed",
        label: "Reviewed",
        path: routes.REVIEWS_REVIEWED,
        toolbarTitle: "Reviewed Reviews",
      },
      {
        key: "cancelled",
        label: "Cancelled",
        path: routes.REVIEWS_CANCELLED,
        toolbarTitle: "Cancelled Reviews",
      },
      {
        key: "archive",
        label: "Archived",
        path: routes.REVIEWS_ARCHIVE,
        toolbarTitle: "Archive Reviews",
      },
    ],
  },
  // {
  //   key: "assigned",
  //   label: "Assigned",
  //   Icon: "review",
  //   path: routes.ASSIGNED_PENDING,
  //   subItems: [
  //     {
  //       key: "Reviewed",
  //       label: "Reviewed",
  //       toolbarTitle: "Reviewed",
  //       path: routes.ASSIGNED_ACTIVE,
  //     },

  //     {
  //       key: "Pending",
  //       label: "Pending",
  //       toolbarTitle: "Pending",
  //       path: routes.ASSIGNED_PENDING,
  //     },

  //     {
  //       key: "Cancelled",
  //       label: "Cancelled",
  //       toolbarTitle: "Cancelled",
  //       path: routes.ASSIGNED_CANCELLED,
  //     },
  //   ],
  // },
  {
    key: "referral",
    label: "Points",
    toolbarTitle: "Points",
    Icon: "reward-points",
    path: routes.REFERRAL,
  },
  {
    key: "discount",
    label: "Discount",
    toolbarTitle: "Discount",
    Icon: "discount",
    path: routes.DISCOUNT,
  },
  {
    key: "users",
    label: "Users",
    toolbarTitle: "Users",
    Icon: "user",
    path: routes.USERS,
  },
  {
    key: "treatment",
    label: "User Products",
    path: routes.TREATMENT,
    toolbarTitle: "Treatment",
    Icon: () => <UserProduct {...iconSize} />,
  },
  {
    key: "products",
    label: "Treatment Products",
    path: routes.PRODUCTS,
    toolbarTitle: "Products",
    Icon: () => <Products {...iconSize} />,
  },
  {
    key: "physician",
    label: "Physician",
    path: routes.PATIENTS,
    toolbarTitle: " Physician",
    Icon: "pill",
  },
].filter((a) => a.key !== "emploes");
