import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export const usePermissionCheck = (permission: string) => {
  const permissionList = usePermissions();
  return permissionList.includes(permission);
};

export const useIsAdmin = () => {
  const view_productdiscount = usePermissionCheck("view_productdiscount");
  const view_employee = usePermissionCheck("view_employee");
  const view_referral = usePermissionCheck("view_userprofile");
  const view_generalinformation = usePermissionCheck("view_generalinformation");
  const view_reviews = usePermissionCheck("view_hairimagereview");

  return view_productdiscount || view_employee || view_referral || view_generalinformation || view_reviews;
};

export const useIsPhysician = () => {
  const permissionList = usePermissions();
  return permissionList.includes("is_professional_mode");
};

export const usePermissions = () => {
  return useSelector((state: RootState) => state.permissionReducer.permission_list);
};
