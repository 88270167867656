import axios from "api";

export const fetchReviews = async (
  page: number,
  paginateBy: number,
  active: string,
  search: any,
  sortField: string,
  sortDirection: string,
  isDeleted: string | undefined,
) => {
  try {
    if (active === "0") {
      active = "REVIEWED";
    } else if (active === "1") {
      active = "UPLOADING";
    } else if (active === "2") {
      active = "CANCELLED";
    }
    return await axios({
      baseURL: process.env.REACT_APP_BASE_URL,
      method: "GET",
      url: `api/web/review/v2/review/`,
      params: {
        status: active,
        page_number: page,
        paginate_by: paginateBy,
        search: search,
        order_by: sortField,
        sort_order: sortDirection,
        is_deleted: isDeleted ? "True" : "False",
      },
    });
  } catch (e: any) {
    return e;
  }
};

export const bulkAssign = async (data: { ids: Array<number>; assigned_to: number }) => {
  const res = await axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method: "POST",
    url: `/api/web/review/bulk-assign`,
    data: data,
  });

  return res;
};

export const bulkPermanentyDelete = async (data: any) => {
  return axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method: "DELETE",
    url: "/api/web/review/bulk-delete",
    data: data,
  });
};

export const fetchProductList = async () => {
  const res = await axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method: "GET",
    url: "api/web/review/v2/cost",
  });
  return res?.data;
};
