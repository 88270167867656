import { Add as AddIcon, DeleteForever } from "@mui/icons-material";
import HqmIcon from "components/icon";
import { ConnectedFocusError } from "focus-formik-error";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { editLocationData, postLocationData } from "redux/LocationEmp/api";
import * as Yup from "yup";
import { fetchEmployeeQry } from "../requests";
import LocationEmployeeTable from "./LocationEmployeeTable";

import { Chip, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";
import { usePartner } from "../CompanyDetails";
import "./../styles/LocationEmployees.style.scss";
import { FormikSelect, FormikTextInput } from "./TextInput";

export const newLocationInitialValues = {
  location_id: "",
  location_address: "",
  city: "",
  country: "",
  state: "",
  zip_code: "",
  county: "",
  primary_representative: "",
  secondary_representative: "",
  open_hours: "",
  close_hours: "",
  time_zone: "",
  location_contact_info: {
    location_contact_id: "",
    first_name: "",
    last_name: "",
    contact_title: "",
    email: "",
    mobile_number: "",
  },
  business_info: {
    primary_business_sic: [] as any,
    contract_date: "",
    onboarding_date: "",
    first_onboarding_appointment_time: "",
    no_of_people_at_first_training: 0,
    actual_employee_size: 0,
  },
  location_name: "",
  location_emails: [],
};

export const newLocationValidationSchema = Yup.object({
  location_id: Yup.string().nullable(true),
  location_address: Yup.string().nullable(true),
  city: Yup.string().required("City is required"),
  county: Yup.string().required("County is required"),
  state: Yup.number().required("State is required"),
  zip_code: Yup.string()
    .matches(/^\d{5}([-]\d{4})?$/, "Invalid Zip code")
    .required("Zip Code is required"),
  country: Yup.string().required("Country is required"),
  primary_representative: Yup.number().required("Primary Representative is required"),
  secondary_representative: Yup.number().required("Secondary Representative is required"),
  open_hours: Yup.string().required("Open Hours is required"),
  close_hours: Yup.string().required("Close Hours is required"),
  time_zone: Yup.number().required("Time Zone is required"),
  location_contact_info: Yup.object().shape({
    location_contact_id: Yup.string().nullable(true),
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    contact_title: Yup.string().required("Contact Title is required"),
    email: Yup.string().email("Enter a valid email").required("Email is required"),
    mobile_number: Yup.string().required("Mobile Number is required"),
  }),
  business_info: Yup.object().shape({
    primary_business_sic: Yup.array().min(1, "Required at least one business").required("Required"),
    contract_date: Yup.date().required("Contract Date is required"),
    onboarding_date: Yup.date().required("Onboarding Date is required"),
    first_onboarding_appointment_time: Yup.string().required("First Onboarding Appointment Time is required"),
    no_of_people_at_first_training: Yup.number()
      .typeError("Must be a number")
      .required("No of People at First Training is required"),

    actual_employee_size: Yup.number().typeError("Must be a number").required("Actual Employee Size is required"),
  }),
  location_name: Yup.string().required("Location Name is required"),
});

export const userProfileValidation = Yup.object().shape({
  access_code: Yup.string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be digits")
    .max(6, "Must be 6 digits")
    .min(6, "Must be 6 digits"),
});

export interface UserProfile extends UserProfileTable {
  user_profile_id: number;
  user_profile?: number;
  is_physician: boolean;
  is_professional: boolean;
  access_code: string;
}
export interface UserProfileTable {
  user_profile_id: number;
  full_name: string;
  state: string;
  country: string;
  access_code: string;
  license_number: string;
}

const NewLocationForm: React.FC<{
  initialValues: any;
  disabled?: boolean;
  isEdit?: boolean;
  onSubmit?: (...args: any) => any;
  onDelete?: (...args: any) => any;
  [k: string]: any;
}> = (props) => {
  const {
    initialValues,
    onSubmit,
    onDelete,
    disabled = false,
    states,
    countries,
    businessInfo,
    employeeList,
    timezones,
    generalInfoId,
    index: id,
    isEdit = false,
  } = props;

  // employee license number state
  const [employeeSearch, setEmployeeSearch] = useState("");
  // state to hold employee search results
  const [searchData, setSearch] = useState<UserProfileTable[]>([]);

  // toggle form show or hide
  const [showForm, setShowForm] = useState(false);
  //  selected user profile
  const [selectedProfiles, setSelectedProfiles] = useState<UserProfile[]>(initialValues?.user_profiles_data || []);

  // state to store user profile from search
  const [userProfileTableData, setTableData] = useState<UserProfileTable[]>(selectedProfiles);

  // selected email list
  const [emailList, setEmailList] = useState<string[]>(initialValues?.location_emails || []);

  // api call to add location to partner
  const { mutate: PostLocationInfo, isLoading: postLoading } = useMutation(
    !isEdit ? postLocationData : editLocationData,
    {
      onSuccess: () => {
        toast(`Location ${isEdit ? "updated" : "added"}`, {
          autoClose: 3000,
        });
        setShowForm(false);
        setDisable(true);
      },
      onError: (e: any) => {
        console.log("Error in Location details", { ...e });
        toast.error(e?.response?.data?.message, {
          pauseOnHover: false,
          theme: "colored",
        });
      },
    },
  );
  // api call to fetch employee profiles (singular)
  const { mutate: getEmployee } = useMutation(fetchEmployeeQry, {
    onError: () => {
      toast("No Employee Found");
    },
    onSuccess(data) {
      // using set state so we can manipulate results locally
      setSearch(data);
    },
  });

  const callApi = () => {
    getEmployee(employeeSearch);
  };

  // function to add to user profile table
  const addToProfessionals = (search: any) => {
    setSelectedProfiles((s) =>
      s
        .filter((i) => i.user_profile_id !== search.user_profile_id)
        .concat({
          ...s,
          access_code: search?.access_code,
          is_physician: true,
          is_professional: true,
          user_profile_id: search?.user_profile_id,
          user_profile: search?.user_profile_id,
        }),
    );
    setTableData((s) => s.filter((i) => i.user_profile_id !== search.user_profile_id).concat(search));
  };

  // render user profile item
  const renderSearchResults = () => {
    return searchData?.map((search, index) => (
      <div key={`search_${Date.now()}_${index}`} className="user-profile-container">
        <Formik
          initialValues={{
            ...search,
            access_code: "",
          }}
          enableReinitialize
          onSubmit={(values) => {
            addToProfessionals(values);
            setSearch([]);
          }}
          validationSchema={userProfileValidation}
        >
          {(formik) => {
            return (
              <>
                <h4 className="user-profile-name">{search?.full_name}</h4>
                <div className="user-profile-address">
                  {search?.state ? <>{search?.state}</> : "NA"}, {search?.country}
                </div>
                <span className="user-profile-license">{search?.license_number}</span>

                <FormikTextInput
                  name="access_code"
                  label=""
                  placeholder="Access Code"
                  className="user-profile-access-code"
                  inputProps={{ maxLength: 6 }}
                />
                <button
                  type="button"
                  className="user-profile-action"
                  onClick={() => {
                    formik.validateForm();
                    formik.handleSubmit();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <AddIcon className="add" />
                </button>
              </>
            );
          }}
        </Formik>
      </div>
    ));
  };

  // handle large form submit
  const handleSubmit = (values: any, form: any) => {
    const payload = {
      ...values,
      primary_representative: Number(values.primary_representative),
      secondary_representative: Number(values.secondary_representative),
      state: Number(values.state),
      country: Number(values.country),
      time_zone: Number(values.time_zone),
      general_info: generalInfoId,
      user_profiles: selectedProfiles.map((i) => {
        return {
          access_code: i?.access_code,
          is_physician: true,
          is_professional: true,
          user_profile: i?.user_profile || i?.user_profile_id,
        };
      }),
      location_emails: emailList.filter((x) => x),
    };

    PostLocationInfo(payload);

    onSubmit?.(payload);
  };
  // disable state of the form
  const [disable, setDisable] = useState(disabled);

  const addEmail = (e: any) => {
    const value = e.target.value;
    const emailList = value?.split(" ");
    setEmailList((s) => Array.from(new Set(s.concat(emailList))));
    e.target.value = "";
  };

  const handleEmailChange = (e: any) => {
    const value = e.target.value;
    if (value.slice(-1) === " ") {
      addEmail(e);
    }
  };

  const handleDelete = () => {
    onDelete?.(id, initialValues?.id);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={newLocationValidationSchema}>
      {(formik) => {
        const state = states?.find((i: any) => i.id === Number(formik.values.state));

        const locationName = `${formik.values.city} ${state?.name || ""} ${formik.values.zip_code}`;

        Object.keys(formik.errors)?.length && console.log(formik.errors);
        const countryId = formik?.values?.country;

        const stateList = countryId
          ? states.filter((i: any) => String(i.country) === String(countryId))
          : states?.filter((i: any) => String(i.country) === "1");

        formik.isValid && console.log(formik.errors);

        return (
          <div className="location-entry">
            <Form>
              <ConnectedFocusError />

              <div className={`location-header-container ${showForm ? "open" : "close"}`}>
                <span className="caret" onClick={() => setShowForm((s) => !s)}>
                  {" "}
                  {showForm ? <FaCaretUp color="#fff" /> : <FaCaretDown color="#fff" />}{" "}
                </span>
                <h5 className="location-name" onClick={() => setShowForm((s) => !s)}>
                  {locationName.trim() || "New Location"}{" "}
                </h5>
                <span className="trashout" onClick={handleDelete} title="Remove">
                  <DeleteForever className="delete-icon" />
                </span>
              </div>
              <div className="location-form-container" style={{ display: showForm ? "block" : "none" }}>
                <div className="sub-group">
                  <h6 className="sub-group-header">Location Information</h6>

                  <Row>
                    <Col>
                      <FormikTextInput
                        name="location_id"
                        label="Location ID"
                        className="location_id"
                        inputProps={{ disabled: true }}
                        placeholder="SS-XXXXXXX-L"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormikTextInput name="location_address" label="Location Address" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormikTextInput name="city" label="City" />
                    </Col>

                    <Col>
                      <FormikTextInput name="county" label="County" />
                    </Col>
                    <Col>
                      <FormikSelect name="state" label="State" key={countryId} options={stateList} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormikTextInput name="zip_code" label="Zip Code" />
                    </Col>
                    <Col>
                      <FormikSelect
                        name="country"
                        label="Country"
                        key={countryId}
                        options={countries}
                        onChange={() => {
                          formik.setFieldValue("state", "");
                        }}
                      />
                    </Col>
                  </Row>
                </div>
                {/* Sub Group 2 */}
                <div className="sub-group">
                  <h6 className="sub-group-header">Location Contact Information</h6>

                  <Row>
                    <Col>
                      <FormikTextInput
                        label="Location Contact ID"
                        name="location_contact_info.location_contact_id"
                        className="location_id"
                        inputProps={{ disabled: true }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormikTextInput name="location_contact_info.first_name" label="First Name" />
                    </Col>

                    <Col>
                      <FormikTextInput name="location_contact_info.last_name" label="Last Name" />
                    </Col>

                    <Col>
                      <FormikTextInput name="location_contact_info.contact_title" label="Contact Title" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormikTextInput
                        name="location_contact_info.email"
                        label="Email"
                        inputProps={{ type: "email" }}
                      />
                    </Col>

                    <Col>
                      <FormikTextInput
                        name="location_contact_info.mobile_number"
                        label="Mobile Number"
                        inputProps={{ type: "tel" }}
                      />
                    </Col>
                  </Row>
                </div>
                {/* Sub Group 3 */}
                <div className="sub-group">
                  <h6 className="sub-group-header">Business Information</h6>

                  <Row>
                    <Col>
                      {/* <div></div> */}
                      <div className="form-group">
                        <label>Primary Business SIC (Check all that apply)</label>
                        <FormGroup>
                          {businessInfo?.map((theme: any, i: Number) => (
                            <FormControlLabel
                              key={theme.id}
                              control={
                                <input
                                  // style={{size}}
                                  type="checkbox"
                                  // name={`nativeThemes[${i}]`}
                                  name="business_info.primary_business_sic"
                                  checked={formik.values.business_info.primary_business_sic.includes(theme.id)}
                                  //value={"checked"}
                                  //checked= {true}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      formik.setFieldValue("business_info.primary_business_sic", [
                                        ...formik.values.business_info.primary_business_sic,
                                        theme.id,
                                      ]);
                                    } else {
                                      formik.setFieldValue(
                                        "business_info.primary_business_sic",
                                        formik.values.business_info.primary_business_sic.filter(
                                          (item: any) => item !== theme.id,
                                        ),
                                      );
                                    }
                                  }}
                                  onBlur={formik.handleBlur}
                                />
                              }
                              label={theme.name}
                            />
                          ))}

                          <div style={{ color: "red" }}>
                            {(formik.errors as any).business_info?.primary_business_sic}
                          </div>
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormikTextInput
                        name="business_info.contract_date"
                        label="Contract Date"
                        inputProps={{ type: "date" }}
                      />
                    </Col>

                    <Col>
                      <FormikTextInput
                        name="business_info.onboarding_date"
                        label="Onboarding Date"
                        inputProps={{ type: "date" }}
                      />
                    </Col>
                    <Col>
                      <FormikTextInput
                        name="business_info.first_onboarding_appointment_time"
                        label="First On-boarding Appointment Time"
                        inputProps={{ type: "time" }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormikTextInput
                        name="business_info.no_of_people_at_first_training"
                        label="No. of People At First Training"
                        inputProps={{ type: "number" }}
                      />
                    </Col>

                    <Col>
                      <FormikTextInput
                        name="business_info.actual_employee_size"
                        label="Actual Employee Size"
                        inputProps={{ type: "number" }}
                      />
                    </Col>
                  </Row>
                </div>
                {/* Sub Group 4 */}
                <div className="sub-group margin-btn">
                  <h6 className="sub-group-header">Representative Information</h6>

                  <Row>
                    <Col>
                      <FormikSelect
                        options={employeeList}
                        name="primary_representative"
                        label="Primary Representative"
                      />
                    </Col>

                    <Col>
                      <FormikSelect
                        options={employeeList}
                        name="secondary_representative"
                        label="Secondary Representative"
                      />
                    </Col>
                  </Row>
                </div>
                {/* Sub Group 5 */}

                <div className="sub-group">
                  <h6 className="sub-group-header">Hours Information</h6>

                  <Row>
                    <Col>
                      <FormikTextInput name="open_hours" label="Open Hours" inputProps={{ type: "time" }} />
                    </Col>

                    <Col>
                      <FormikTextInput name="close_hours" label="Close Hours" inputProps={{ type: "time" }} />
                    </Col>

                    <Col>
                      <FormikSelect options={timezones} name="time_zone" label="Timezone" />
                    </Col>
                  </Row>
                </div>
                {/* Sub Group 6 */}
                <div className="sub-group ">
                  <h6 className="sub-group-header">Professionals</h6>

                  <Row>
                    <Col>
                      <FormikTextInput name="location_name" label="Location Name" className="location_id" />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="form-group">
                        <label>
                          Device Email List <sup>* Press space to add emails</sup>
                        </label>
                        <div className="mar-gin"></div>
                        <input
                          type="email"
                          className="form-control location_id"
                          placeholder={"Professional Email"}
                          onChange={handleEmailChange}
                          onKeyUp={(e) => {
                            if (e.code === "Space" && e.keyCode === 32) {
                              addEmail(e);
                            }
                          }}
                        />
                      </div>

                      <div className="email-list">
                        {emailList?.map(
                          (item, index) =>
                            item && (
                              <Chip
                                key={`chip_${index}`}
                                label={item}
                                onDelete={() => setEmailList((s) => s.filter((i) => i !== item))}
                              />
                            ),
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="form-group">
                        <label>Operator List</label>
                        <div className="employee-search location_id">
                          <input
                            className="form-control search-input"
                            type={"text"}
                            placeholder="Search by license number or email"
                            onChange={(e) => {
                              setEmployeeSearch(e.target.value);
                            }}
                            onKeyDown={(e: any) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                callApi();
                              }
                            }}
                          />
                          <HqmIcon name="search" size={20} onClick={callApi} className="search-icon" />
                        </div>

                        {!!searchData?.length && (
                          <sub className="employee-search-info">* Name, Address, License Number</sub>
                        )}

                        {/* <LocEmpTale data={search} /> */}
                        {renderSearchResults()}

                        {userProfileTableData?.length ? (
                          <LocationEmployeeTable
                            setSelectedId={1}
                            data={userProfileTableData}
                            onChange={(tableData) => {
                              setTableData(tableData);
                              setSelectedProfiles(tableData);
                            }}
                          />
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </div>

                {!disable && (
                  <div
                    className="location_btn_group module-action location_btn_container"
                    style={{ marginBottom: "20px" }}
                  >
                    <button
                      type="submit"
                      className="sub-btn module-approve-button"
                      //onClick={(e: any) => formik.handleSubmit(e)}
                      onClick={(e: any) => {
                        formik.handleSubmit(e);
                      }}
                    >
                      Confirm
                      {postLoading && (
                        <Spinner
                          style={{ marginLeft: "1rem" }}
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                    <button type="button" className=" module-add-button add-btn" onClick={(e: any) => onDelete?.(id)}>
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default NewLocationForm;
