import axios from "axios";

export const fetchReferralView = async (refer_id:number,page:number,paginateBy:number ) => {
    try{
      return await axios({
            baseURL: process.env.REACT_APP_BASE_URL,
            method: "GET",
            url: `/api/rest/referral/transactions/?user_profile__uuid=${refer_id}&page_number=${page}&paginate_by=${paginateBy}`,
          });
        }catch(e:any){
          return e;
        }
}



