import React from "react";
import "./PatientData.scss";

const CommentModal = ({ comment }: any) => {
  return (
    <>
      <div className="comment-grid">
        <div>
          <div className="form-group" style={{ marginTop: "0.3rem" }}>
            <div>
              <label className="input-text">Treatment Regimen:</label>
              <textarea
                className="phy-comment-normal"
                name="treatment_regime"
                value={comment?.treatment_regimen}
                rows={5}
                // disabled={props?.lock}
              />
            </div>
          </div>
          <div className="form-group" style={{ marginTop: "0.3rem" }}>
            <div>
              <label className="input-text">Treatment Adjustment:</label>
              <textarea
                className="phy-comment-normal"
                name="treatment_adjustment"
                value={comment?.treatment_adjustment}
                rows={5}
                // disabled={props?.lock}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="form-group" style={{ marginTop: "0.3rem" }}>
            <div>
              <label className="input-text">Treatment Directives:</label>
              <textarea
                name="treatment_directives"
                className="phy-comment-normal"
                value={comment?.treatment_directives}
                rows={5}
                // disabled={props?.lock}
              />
            </div>
          </div>
          <div className="form-group" style={{ marginTop: "0.3rem" }}>
            <div className="aftermath">
              <label className="input-text">Comments:</label>
              <textarea
                name="comment"
                value={comment?.comment}
                className="phy-comment-normal"
                rows={5}
                // disabled={props?.lock}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentModal;
