import Container from "@mui/material/Container";
import ShowMoreContent from "components/PhysicianLayout/Report/ShowMoreContent";
import _ from "lodash";
import { Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchSubmittedProduct } from "redux/productDate/productDate";
import "../Users/user.scss";
import "./../../assets/style/screens/_Home.scss";
import MHCSpinner from "components/MHCSpinner";

const ViewTreatmentModal: React.FC = () => {
  let history = useHistory();
  const { id }: any = useParams();
  //   const { data: permissionResGroups } = useQuery("fetch permission groups", fetchPermissionGroups);
  const { data, isLoading } = useQuery(["fetch user treatment data ", id], () => id && fetchSubmittedProduct(id), {
    refetchOnMount: true,
  });

  const userTreatmentData = data && !_.isEmpty(data) ? data : [];

  const loading = false;

  return (
    <>
      {isLoading ? (
        <div className="mhc-loader-flexbox">
          <MHCSpinner type="light" />
        </div>
      ) : (
        <div className="view-user">
          <span>User: </span> <span> {userTreatmentData?.end_user?.username}</span>
          <div>
            <Container maxWidth="sm">
              {/* <Box sx={{ height: "100vh" }} /> */}
              <img
                src={userTreatmentData?.get_image_path}
                alt="Medicine_Image"
                style={{ maxWidth: "600px", height: "500px" }}
              />
            </Container>
          </div>
          <div className="form-group-treatment">
            <label htmlFor="">Product Category</label>

            <div className="textarea-treatment"> {userTreatmentData?.treatment_category?.category_desc}</div>
          </div>
          <div className="form-group-treatment">
            <label htmlFor="">Product name (in English)</label>

            <div className="textarea-treatment">{userTreatmentData?.product_name}</div>
          </div>
          {/* <div className="form-group module-action" style={{ marginTop: "2rem" }}> */}
          {/* In case of two button use above snippet and delete below snippet */}
          <div className="module-action" style={{ marginTop: "2rem", marginBottom: "1rem" }}>
            {/* <button className="module-add-button">
              <span style={{ marginRight: loading ? "0.5rem" : 0 }}>Approve</span>{" "}
              {loading && <Spinner animation="border" size="sm" />}
            </button> */}

            <button
              onClick={() => {
                history.goBack();
              }}
              className="module-add-button cancel"
            >
              Cancel
            </button>
          </div>
          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default ViewTreatmentModal;
