import { SNACKBAR_TYPE } from "components/MHCAlert";
import { useSetStatus } from "context/statusContext";
import { FocusError } from "focus-formik-error";
import { Form, FormikProvider, useFormik } from "formik";
import _ from "lodash";
import { forwardRef, useEffect, useImperativeHandle, useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchPatientHistory, postPatientInfo } from "redux/PatientHistory/api";
// import { PhysicianPortalTypes } from "redux/PhysicianPortalData/PhysicianPortalTypes";
import { RootState } from "redux/store";
import * as Yup from "yup";
import * as routes from "../../../constants/routes";
import "./PatientData.scss";
import { DRAFT_RECORD } from "redux/PhysicianPortalData/PhysicianPortalTypes";
import { log } from "console";
import reduxStore from "index";
import { typographyClasses } from "@mui/material";

export interface patientCommentInterface {
  lock: boolean;
}

const draftRecordSelector = (state: RootState) => state.physicianPortalReducer.draft_record;

const PatientComment = forwardRef((props: patientCommentInterface, ref) => {
  // function PatientComment(){
  let history = useHistory();
  const dispatch = useDispatch();

  const patient_user_profile_id = useSelector((state: RootState) => {
    return state.physicianPortalReducer.patient_user_profile_id;
  });

  const comment = useSelector((state: RootState) => state.physicianPortalReducer.comment_data);
  const treatment_directives = useSelector((state: RootState) => state.physicianPortalReducer.treatment_directive_data);
  const treatment_regimen = useSelector((state: RootState) => state.physicianPortalReducer.treatment_regimen_data);
  const treatment_adjustment = useSelector(
    (state: RootState) => state.physicianPortalReducer.treatment_adjustment_data,
  );

  // fetch fetchPatientHistory
  const { data: patientComments, isLoading } = useQuery(["patient Comments"], async () => {
    const res = await fetchPatientHistory(patient_user_profile_id);

    const patientComments = res;

    const patientCommentData =
      patientComments && !_.isEmpty(patientComments.data)
        ? patientComments?.data?.[patientComments?.data.length - 1]
        : {};

    return patientCommentData;
  });

  // const [treatmentRegime, setTreatmentRegime] = useState("");
  // const [treatmentAdjustment, setTreatmentAdjustment] = useState("");
  // const [treatmentComment, setComment] = useState("");
  // const [treatmentDirectives, setTreatmentDirectives] = useState("");

  // // move this to onSuccess of the useQuery
  // useEffect(() => {
  //   if (props.lock) {
  //     setTreatmentRegime(patientCommentData?.treatment_regimen);
  //     setTreatmentAdjustment(patientCommentData?.treatment_adjustment);
  //     setComment(patientCommentData?.comment);
  //     setTreatmentDirectives(patientCommentData?.treatment_directives);
  //   } else {
  //     setTreatmentRegime("");
  //     setTreatmentAdjustment("");
  //     setComment("");
  //     setTreatmentDirectives("");
  //   }
  // }, []);

  useImperativeHandle(ref, () => ({
    getAlert() {
      alert("getAlert from Child");
    },
    getComments() {
      return formik.values?.comment;
    },
    getDirectiveData() {
      return formik.values?.treatment_directives;
    },
    getRegimenData() {
      return formik.values?.treatment_regimen;
    },
    getAdjustmentData() {
      return formik?.values?.treatment_adjustment;
    },
  }));

  const {
    mutate: PostPatientComment,
    isLoading: postLoading,
    isSuccess: postIsSuccess,
    isError: postIsError,
    error: postError,
  } = useMutation(postPatientInfo);

  const setStatus = useSetStatus();

  useEffect(() => {
    if (postIsSuccess && !postLoading && !postIsError && !postError) {
      setStatus(SNACKBAR_TYPE.SUCCESS, "Posted data successfully.");
      // refetch();
      setInterval(() => {
        window.location.reload();
      }, 1000);
    } else if (!postLoading && postIsError && postError) {
      // setStatus(SNACKBAR_TYPE.ERROR, (postError as any)?.message)
      setStatus(SNACKBAR_TYPE.ERROR, (postError as any)?.message);
    }
  });

  // draft_record reducer (which persist value on changing of formik field) used for updating/rehydrating the unreviewed(!lock) formik field value of comment field

  const draft = useMemo(() => {
    const [store] = reduxStore;
    const snap: any = store.getState();

    return snap.physicianPortalReducer.draft_record;
  }, []);

  const { comment_data, treatment_adjustment_data, treatment_directives_data, treatment_regimen_data } = useMemo(() => {
    if (props.lock) {
      return {
        treatment_regimen_data: patientComments?.treatment_regimen || "",
        comment_data: patientComments?.comment || "",
        treatment_directives_data: patientComments?.treatment_directives || "",
        treatment_adjustment_data: patientComments?.treatment_adjustment || "",
      };
    } else {
      try {
        return {
          treatment_regimen_data: treatment_regimen || draft[patient_user_profile_id].treatment_regimen_data,
          comment_data: comment || draft[patient_user_profile_id].comment_data,
          treatment_directives_data: treatment_directives || draft[patient_user_profile_id].treatment_directive_data,
          treatment_adjustment_data: treatment_adjustment || draft[patient_user_profile_id].treatment_adjustment_data,
        };
      } catch (err) {
        return {
          treatment_regimen_data: "",
          comment_data: "",
          treatment_directives_data: "",
          treatment_adjustment_data: "",
        };
      }
    }
  }, [
    comment,
    draft,
    patientComments,
    patient_user_profile_id,
    props.lock,
    treatment_adjustment,
    treatment_directives,
    treatment_regimen,
  ]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      patient_user_profile_id,
      treatment_regimen: treatment_regimen_data,
      treatment_adjustment: treatment_adjustment_data,
      comment: comment_data,
      treatment_directives: treatment_directives_data,
    },
    validationSchema: Yup.object({
      treatment_regimen: Yup.string().required(),
      treatment_adjustment: Yup.string().required(),
      comment: Yup.string().required(),
      treatment_directives: Yup.string().required(),
    }),
    onSubmit: (values: any) => {
      console.log("send values through mutation");
      handlePatientComment(values);
    },
  });
  const handlePatientComment = (values: any) => {
    return PostPatientComment(values);
  };

  useEffect(() => {
    dispatch({
      type: DRAFT_RECORD,
      payload: formik.values,
    });
  }, [dispatch, formik.values]);

  return (
    <>
      <div
        onClick={() => {
          history.push(routes.PATIENTS_COMMENT_HISTORY);
        }}
      >
        <button className="buttons-phy" style={{ marginTop: "2rem" }}>
          Comment History
        </button>
      </div>
      <FormikProvider value={formik}>
        <FocusError formik={formik} />
        <Form>
          <div className="comment-grid">
            <div>
              <div className="form-group" style={{ marginTop: "0.3rem" }}>
                <div>
                  <label className="input-text">Treatment Regimen:</label>
                  <textarea
                    className="phy-comment-normal"
                    name="treatment_regimen"
                    value={formik.values?.treatment_regimen}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    rows={5}
                    disabled={props?.lock}
                  />
                </div>
              </div>
              <div className="form-group" style={{ marginTop: "0.3rem" }}>
                <div>
                  <label className="input-text">Treatment Adjustment:</label>
                  <textarea
                    className="phy-comment-normal"
                    name="treatment_adjustment"
                    value={formik.values?.treatment_adjustment}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    rows={5}
                    disabled={props?.lock}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="form-group" style={{ marginTop: "0.3rem" }}>
                <div>
                  <label className="input-text">Treatment Directives:</label>
                  <textarea
                    name="treatment_directives"
                    className="phy-comment-normal"
                    value={formik.values?.treatment_directives}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    rows={5}
                    disabled={props?.lock}
                  />
                </div>
              </div>
              <div className="form-group" style={{ marginTop: "0.3rem" }}>
                <div className="aftermath">
                  <label className="input-text">Comments:</label>
                  <textarea
                    name="comment"
                    value={formik.values?.comment}
                    className="phy-comment-normal"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    rows={5}
                    disabled={props?.lock}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </>
  );
});
export default PatientComment;
