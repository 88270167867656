import { IAction } from "../../types";
import {
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  SET_ACCESS_TOKEN,
  CLEAR_TOKENS,
} from "../constants";

const initialState = {
  //loading: false,
  user: null,
  error: "",
  isLoggedIn: false,
};

export function loginReducer(state = initialState, action:any) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        //loading: true,
        ...state,
        error: "",
        isLoggedIn: false,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        //loading: false,
        user: action.user,
        isLoggedIn: true,
      };

    case LOGIN_ERROR:
      return {
        ...state,
        error: action.message,
        isLoggedIn: false,
        //loading: false
      };

    case LOGOUT_REQUEST:
      return {
        loading: true,
        ...state,
        isLoggedIn: false,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        //loading: false,
        user: null,
        origUser: null,
        isLoggedIn: false,
      };

    case LOGOUT_ERROR:
      return {
        ...state,
        error: action.message,
        isLoggedIn: false,
        //loading:false
        
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        user: action.payload,
      };
    case CLEAR_TOKENS:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
}

export default loginReducer;
