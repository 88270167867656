import { Divider } from "@mui/material";
import MHCSpinner from "components/MHCSpinner";
import SummaryTable from "components/SummaryTable/SummaryTable";
import * as routes from "constants/routes";
import { ReactNode, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { getAPatientData } from "redux/PatientList/PatientList";
import { update_emr } from "redux/PhysicianPortalData/PhysicianPortalActions";
import { fetchProductDateTable } from "redux/productDate/productDate";
import { RootState } from "redux/store";
import PatientDataBNA from "../../components/PhysicianComponent/PatientDataBNA/PatientDataBNA";
import "./styles.scss";
import StatusHistory from "components/PhysicianComponent/PatientDataBNA/StatusHistory";

const PatientData = (props: { lock?: boolean }) => {
  const { lock = false } = props;
  const dispatch = useDispatch();
  const patient_emr = useSelector((state: RootState) => state.physicianPortalReducer.emr_no);
  const patient_pk_id = useSelector((state: RootState) => state.physicianPortalReducer.patient_pk_id);
  const patient_user_profile_id = useSelector((state: RootState) => {
    return state.physicianPortalReducer.patient_user_profile_id;
  });

  let active = true;

  const { data: patientDataList, isLoading } = useQuery(["patient data list", active], () =>
    fetchProductDateTable(patient_user_profile_id, active),
  );

  const { data: patientDataDetails } = useQuery(
    ["get patient by id"],
    () => {
      return getAPatientData(patient_pk_id);
    },
    {
      onSuccess: (data: any) => {
        // const emr = data?.data?.emr_number || data?.data?.patient_id;
        // if (emrRef.current)
        //   emrRef.current?.setAttribute("value", patient_emr || data?.data?.patient_id || data?.data?.emr_number);
        // // dispatch(update_emr(emr));
        const emr = patient_emr || data?.data?.emr_number || data?.data?.patient_id;
        if (emrRef.current) emrRef.current.setAttribute("value", emr);
        dispatch(update_emr(emr));
      },
    },
  );

  const [summaryLoading, setSummaryLoading] = useState(false);
  const [bnaLoading, setBnaLoading] = useState(false);

  const patientBnaRef = useRef<any>(null);

  const tableData = useMemo(() => {
    return patientDataList?.data;
  }, [patientDataList?.data]);

  const handlePatientIdChange = (e: any) => {
    dispatch(update_emr(e?.target?.value));
  };

  // const emr = emrRef.current?.setAttribute("value", patient_emr);

  const emrRef = useRef<HTMLInputElement>(null);

  const headingItems = [
    {
      title: "EMR No:",
      component: <input ref={emrRef} type="text" onChange={handlePatientIdChange} />,
    },
    {
      title: "Full Name:",
      component: patientDataDetails?.data?.user_profile?.full_name,
    },
    {
      title: "Gender:",
      component: <span style={{ textTransform: "capitalize" }}>{patientDataDetails?.data?.user_profile?.gender} </span>,
    },
    {
      title: "DOB:",
      component: patientDataDetails?.data?.user_profile?.date_of_birth
        ? patientDataDetails?.data?.user_profile?.date_of_birth
        : "-",
    },
  ];

  if (summaryLoading && isLoading && bnaLoading && patientBnaRef.current?.isLoading) {
    return <MHCSpinner type="light" />;
  }

  if (!patient_pk_id) {
    return <Redirect to={routes.PATIENTS} />;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1rem 0",
          maxWidth: "91vw",
          margin: "auto",
        }}
      >
        {headingItems.map((item, index) => (
          <HeadingComponent key={"headingItems" + index} {...item} />
        ))}
      </div>
      <Divider sx={{ mb: "1rem" }} />
      {/* <div className="status_position">
        <StatusHistory />
      </div>
      <Divider sx={{ mb: "1rem" }} /> */}

      <SummaryTable
        ref={patientBnaRef}
        data={tableData}
        patientDataList={patientDataDetails}
        onLoading={setSummaryLoading}
        lock={lock}
      />

      {patientDataList && <PatientDataBNA onLoading={setBnaLoading} patientDataList={patientDataDetails} lock={lock} />}
    </>
  );
};

export const HeadingComponent: React.FC<{ title: string; component: ReactNode }> = (props) => {
  return (
    <div className="heading-container">
      <h5>{props.title}</h5> {props?.component}
    </div>
  );
};

export default PatientData;
