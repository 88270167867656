import SearchModule from "components/SearchModule";
import ReferralView from "layouts/ReferralView/ReferralView";
import _ from "lodash";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { fetchReferrral } from "redux/referral/api";
import PaginatedTable from "../../components/Table/PaginatedTable";
import config from "../../config";
import { MODULES } from "../../constants/modules";
import "./referral.scss";
import { TableConstantsR } from "./TableConstants";

const Referral = ({ active }: any) => {
  const [page, setPage] = useState(1);
  const [paginateBy, setPaginateBy] = useState(config.PAGE_SIZE);
  const [sortField, setSortField] = useState("first_name");
  const [sortDirection, setSortDirection] = useState("desc");
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState("");
  const [view, setView] = useState(null);

  const { isLoading, isError, error, data, isFetching, isPreviousData } = useQuery(
    ["projects", page, paginateBy, active, searchQuery, sortField, sortDirection],
    () => fetchReferrral(page, paginateBy, active, searchQuery, sortField, sortDirection),
    { keepPreviousData: true },
  );

  const pageData = data && !_.isEmpty(data.data?.data) ? data.data?.data : [];

  const pageCount = (data && data.data?.page_count) || 0;

  const totalCount = (data && data.data?.total_count) || 0;

  const current_page_number = (data && data.data?.current_page_number) || 0;

  const handlePagination = (page: number) => {
    setPage(page);
  };

  const handleEntries = (pagesize: number) => {
    setPaginateBy(pagesize);
  };
  const handleSort = (key: string, direction: string) => {
    setSortField(key);
    setSortDirection(direction);
  };
  const handleClick = (row: any) => {
    setView(row);
  };
  const handleSearch = (searchKey: any) => {
    setSearchQuery(searchKey);
  };

  return (
    <div className="page">
      {view ? (
        <ReferralView view={view} setView={setView} />
      ) : (
        <span>
          <div className="module-header">
            <div className="module-name">Points</div>
            <div className="module-action">
              {" "}
              <SearchModule handleSearch={handleSearch} />
            </div>
          </div>
          <PaginatedTable
            cols={TableConstantsR(handleClick)}
            data={pageData}
            isDark={false}
            bordered
            striped={false}
            hoverable
            entries={paginateBy}
            onRowClick={() => {}}
            //selected={selectedRowID}
            componentName={MODULES.REFERRAL}
            handlePagination={handlePagination}
            handleEntries={handleEntries}
            currentPage={current_page_number}
            totalCount={totalCount}
            size="lg"
            isDataLoading={isFetching}
            handleSort={handleSort}
            isPaginationRequired={true}
          />
        </span>
      )}
    </div>
  );
};

export default Referral;
