import HqmIcon from "components/icon";
import moment from "moment";

export const PatientHistoryTable = (handleOpenModal: Function) => {
  return [
    {
      title: "Updated By",
      name: "updated_by",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.updated_by_data}</span>;
      },
    },
    {
      title: "Location",
      name: "location",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.location_data}</span>;
      },
    },

    {
      title: " Created At ",
      name: "created_at",
      sortable: false,
      render: (rowData: any) => {
        return <span>{moment(rowData?.created_at).format("YYYY/MM/DD")}</span>;
      },
    },

    {
      title: "View",
      name: "action",
      sortable: false,
      render: (rowData: any) => {
        return <HqmIcon name="eye" size={20} style={{ color: "black" }} onClick={() => handleOpenModal(rowData)} />;
      },
    },
  ];
};
