import DatePick from "components/DatePick/DatePick";
import MHCSpinner from "components/MHCSpinner";
import moment from "moment";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getProductUsedDates } from "redux/productUsedDates/productUsedDates";
import { RootState } from "redux/store";
import crossedCalenderIcon from "../../assets/brand/crossCalender.svg";
import { ReactComponent as laser } from "./assets/laser.svg";
import { ReactComponent as oral } from "./assets/oral.svg";
import { ReactComponent as shampoo } from "./assets/shampoo.svg";
import { colTitles } from "./ColumnTitle";
import "./styles.scss";
import { Table1RowsType, useTableToRows } from "./Utility/useTableToRows";

import { Box } from "@material-ui/core";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { IconButton, Stack } from "@mui/material";
import { theme } from "theme";
import { compareHeights, formatDate, getChildrenList, setCssProperty } from "./helpers";
import ProductCategoryEnum from "./Utility/ProductCategoryEnum";
import { useField } from "formik";

export interface patientDataInterface {
  data: any;
  onLoading?: (load: boolean) => void;
  lock: boolean;
  patientDataList: any;
}

const imageMap = {
  [ProductCategoryEnum.LASER]: laser,
  [ProductCategoryEnum.ORAL]: oral,
  [ProductCategoryEnum.SHAMPOO]: shampoo,
};

const SummaryTable = (props: patientDataInterface, parentRef: any) => {
  // get product types

  const productTypeMap = useMemo(() => {
    return (
      props?.data &&
      props?.data?.reduce((t: any, c: any) => {
        t[`${c?.treatment_product_id}`] = c.treatment_category.category_code;
        return t;
      }, {} as any)
    );
  }, [props?.data]);
  let patientTreatmentStatus = props?.patientDataList?.data?.retention_status;

  let table1Rows = useTableToRows(props?.data);
  // if (table1Rows) table1Rows = [...table1Rows, ...table1Rows];
  const [selectedDate, setSelectedDate] = React.useState(moment(new Date()).format("YYYY-MM"));
  const [isMouseHovering, setIsMouseHovering] = React.useState(false);

  const patient_user_profile_id = useSelector((state: RootState) => {
    return state.physicianPortalReducer.patient_user_profile_id;
  });

  const ref = useRef<any>(null);
  const id = patient_user_profile_id;

  const [year, month] = selectedDate.split("-");

  const noOfDays = new Date(Number(year), Number(month), 0).getDate();

  const dates = useMemo(
    () =>
      new Array(Number(noOfDays)).fill(1).map((item, index) => {
        return index + 1;
      }),
    [noOfDays],
  );

  const { data: usedDateList, isLoading: usedDateLoading } = useQuery(["used date", id, selectedDate], () => {
    return getProductUsedDates(id, selectedDate);
  });

  const responseData = usedDateList?.data; //date of product used by patient

  // rows for second table
  let table2Rows = table1Rows?.map((t1Row) => {
    const t2row = {} as any;
    t2row["treatmentProductId"] = String(t1Row?.treatmentProductId);

    const correspondingUsedDaysOfProduct = responseData?.[String(t1Row?.treatmentProductId)];

    t2row["usedDays"] = correspondingUsedDaysOfProduct
      ? correspondingUsedDaysOfProduct.map((day: string) => {
          return parseInt(moment(day).format("DD"));
        })
      : [];

    return t2row;
  });

  // const idDateMap = table1Rows?.map((item: any, index: any) => {
  //   return { [item?.treatmentProductId]: [table2Rows?.[index]?.usedDays] };
  // });

  const idDateMap = table2Rows?.reduce((t, c) => {
    t[c?.treatmentProductId] = c?.usedDays || [];
    return t;
  }, {} as Record<string, number[]>);

  const handleMouseOver = (val: any) => {
    setIsMouseHovering(true);
  };
  const handleMouseOut = (val: any) => {
    setIsMouseHovering(false);
  };

  useEffect(() => {
    props?.onLoading?.(usedDateLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usedDateLoading]);

  const LegendIcon = (props: { icon: ProductCategoryEnum }) => {
    const Icon = imageMap[props.icon];
    return <Icon className="calender-icon-svg legend-icon" />;
  };

  useImperativeHandle(parentRef, () => {
    return {
      isLoading: usedDateLoading,
    };
  });

  // hook to apply style sync from two tables
  useStylesFromJs();

  const summaryTable = () => {
    if (!table1Rows?.length)
      return (
        <Box>
          <h3>No Data found</h3>
        </Box>
      );

    return (
      <>
        <div className="summary-arrow-grp">
          <div className="treatment-arrow">
            <ArrowCircleLeft
              sx={{ color: theme.palette.primary.main }}
              style={{ margin: "auto", cursor: "pointer" }}
              onClick={() => ref.current && ref.current.handlePrevious()}
            />
          </div>
          <div className="treatment-arrow">
            {!usedDateLoading && (
              <ArrowCircleRight
                sx={{ color: theme.palette.primary.main }}
                style={{ margin: "auto", cursor: "pointer" }}
                onClick={() => ref.current && ref.current.handleNext()}
              />
            )}
          </div>
        </div>
        <div className="first-table">
          <table className="table-treatment table-y-scroll">
            {table1Rows?.length ? (
              <thead>
                <tr>
                  {colTitles.map((item: any, index) => {
                    return (
                      <th
                        key={`table1header${index}`}
                        className={`fixedHeaderAndBody treatmentHeader ${
                          item.toLowerCase() === "treatment" && "treatment-name"
                        }`}
                      >
                        {item}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            ) : null}
            <tbody>
              {table1Rows?.length
                ? table1Rows?.map((row: Table1RowsType, index: number) => {
                    // console.log("daiil", row);

                    return (
                      <tr className={`row_height_${row?.treatmentProductId}`} key={`table1${index}`}>
                        <td className="table_1_cell"> {row.total}</td>
                        <td className="table_1_cell" title={row.remarks}>
                          {row.endDate ? (
                            <Stack direction={"row"}>
                              {formatDate(new Date(row.endDate))} {row?.remarks && <Info title={row.remarks}></Info>}
                            </Stack>
                          ) : (
                            <img src={crossedCalenderIcon} alt="calenderIcon" style={{ height: "24px" }} />
                          )}
                        </td>
                        <td className="table_1_cell"> {row.startDate}</td>

                        <td className="table_1_cell treatment-name" style={{ textAlign: "left" }}>
                          {/* displaying the product name */}
                          {/* {isMouseHovering
                            ? row.productName
                            : row.productName.length > 12
                            ? row.productName.substring(0, 13) + ".."
                            : row.productName} */}

                          {row.productName}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
        <div className="second-table table-y-scroll-second">
          {usedDateLoading ? (
            <div className="mhc-loader-flexbox" style={{ marginLeft: "150px" }}>
              <MHCSpinner type="light" />
            </div>
          ) : (
            <table className="table-dates">
              {table1Rows?.length ? (
                <thead>
                  <tr className="fixedHeaderAndBody">
                    {dates.map((day, index: number) => (
                      <th className="fixedHeaderAndBody date-header" key={`dates${index}`}>
                        {day}
                      </th>
                    ))}
                  </tr>
                </thead>
              ) : null}

              {/* {console.log("table2",table2Rows)} */}

              <tbody>
                {table2Rows?.length && productTypeMap
                  ? table2Rows?.map((singleIdDate, index) => {
                      const temp = [...singleIdDate?.usedDays?.sort((a: number, b: number) => a - b)];

                      // console.log(singleIdDate, temp);

                      return (
                        <tr key={`table2data${index}`} className={`row_height2_${singleIdDate?.treatmentProductId}`}>
                          {dates.map((day, index: number) => {
                            //might be numeric string

                            if (temp?.[0] === day) {
                              temp.shift();

                              const imageType = productTypeMap[singleIdDate?.treatmentProductId];

                              const Icon = imageMap[imageType as ProductCategoryEnum];

                              return (
                                <td key={`date_${JSON.stringify(singleIdDate)}_${day}`} className={`date_cell`}>
                                  <div className="calender-icon" title={imageType}>
                                    <Icon className="calender-icon-svg" />
                                  </div>
                                </td>
                              );
                            }

                            return (
                              <td key={`date_${JSON.stringify(singleIdDate)}_${day}`} className={`date_cell`}>
                                -
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          )}
        </div>
        <div className="second-table-scroll">
          <div className="second-table-placeholder"></div>
        </div>
        <div className="summary-arrow-grp">
          <div className="treatment-arrow">
            {!usedDateLoading && (
              <ArrowCircleRight
                sx={{ color: theme.palette.primary.main }}
                style={{ margin: "auto", cursor: "pointer" }}
                onClick={() => ref.current && ref.current.handleNext()}
              />
            )}
          </div>
          <div className="treatment-arrow">
            <ArrowCircleLeft
              sx={{ color: theme.palette.primary.main }}
              style={{ margin: "auto", cursor: "pointer" }}
              onClick={() => ref.current && ref.current.handlePrevious()}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="date-pick-container">
        <div className="regimen-date-grp">
          <h4 className="summmary_table_header">Treatment Regimen</h4>
          <DatePick ref={ref} onChange={setSelectedDate} />
        </div>
        <div className="regimen-date-grp">
          <h4 className="summmary_table_header">Retention</h4>
          <div
            style={{ width: "5rem", height: "1rem" }}
            title={
              patientTreatmentStatus === "Red"
                ? " less than 8 diary entry in the past 30 days"
                : patientTreatmentStatus === "Green"
                ? "All entry up to date"
                : patientTreatmentStatus === "Yellow"
                ? " more than 9 diary entry but less than 5 treatment used in the past 30 days"
                : "badge-ca"
            }
            className={
              patientTreatmentStatus === "Red"
                ? "patient-list badge-error-status"
                : patientTreatmentStatus === "Green"
                ? "patient-list badge-success-status"
                : patientTreatmentStatus === "Yellow"
                ? "patient-list badge-warning-status"
                : "patient-list badge-ca"
            }
          ></div>
        </div>

        <div className="legend">
          <div className="legendItem">
            <div className="calender-icon ">
              <LegendIcon icon={ProductCategoryEnum.ORAL} />
            </div>
            <span>Oral</span>
          </div>

          <div className="legendItem">
            <div className="calender-icon legend-item">
              <LegendIcon icon={ProductCategoryEnum.LASER} />
            </div>
            <span>Devices</span>
          </div>
          <div className="legendItem">
            <div className="calender-icon legend-item">
              <LegendIcon icon={ProductCategoryEnum.SHAMPOO} />
            </div>

            <span>Topical / Shampoo</span>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", margin: "1rem 0rem 1rem 0rem", position: "relative" }}>
        {summaryTable()}
      </div>
    </>
  );
};

const Info = (props: { title: string }) => {
  const { title } = props;
  const [show, setShow] = useState(false);

  return (
    <Box
      // onMouseEnter={() => setShow(true)}
      // onMouseLeave={() => setShow(false)}
      sx={{ position: "relative", display: "flex", alignItems: "stretch" }}
    >
      <IconButton sx={{ p: " 0 0 0 0.3rem " }}>
        <InfoIcon sx={{ fontSize: "1.2rem" }} />
      </IconButton>
    </Box>
  );
};

const useStylesFromJs = () => {
  const [screenSize, setScreenSize] = useState(0);

  const cbk = useCallback(() => {
    setScreenSize((s) => s + 1);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => cbk());
    return window.removeEventListener("resize", () => cbk());
  }, [cbk]);

  useEffect(() => {
    // set height of headers and cell
    const headerCell = document.querySelector(".treatmentHeader");
    const callback = () => {
      const heightHeader = headerCell?.getBoundingClientRect()?.height;
      setCssProperty("header-height", heightHeader || 100);
    };
    callback();
  });

  // function to mirror row height
  const mirrorRowHeight = useCallback(() => {
    // get rows from both table
    const tableTreatmentRows = getChildrenList("table.table-treatment tbody");
    const tableDatesRows = getChildrenList("table.table-dates tbody");

    // compare height of rows
    if (tableTreatmentRows?.length !== tableDatesRows?.length) {
      console.log("Row length mismatch");
      return;
    }

    // set the height of both rows equally
    tableTreatmentRows.forEach((item, index) => compareHeights(item, tableDatesRows[index]));
  }, []);

  useEffect(() => {
    //mirror row height
    mirrorRowHeight();
  });

  const treatmentTableScroll = document.querySelector("table.table-treatment") as HTMLElement;
  const datesTableScroll = document.querySelector(".second-table");

  const treatmentScrollCbk = (e: Event) => {
    const table = e.target as Element;
    if (datesTableScroll) datesTableScroll.scrollTop = table.scrollTop;
  };
  // const datesScrollCbk = (e: Event) => {
  //   const table = e.target as Element;
  //   if (treatmentTableScroll) treatmentTableScroll.scrollTop = table.scrollTop;
  // };

  useEffect(() => {
    if (treatmentTableScroll) {
      treatmentTableScroll?.addEventListener("scroll", treatmentScrollCbk);
      treatmentTableScroll.addEventListener("mouseenter", (e) => treatmentTableScroll.focus());
    }

    // datesTableScroll &&
    //   datesTableScroll.addEventListener("mouseenter", (e) => {
    //     treatmentTableScroll?.removeEventListener("scroll", treatmentScrollCbk);
    //     datesTableScroll?.addEventListener("scroll", datesScrollCbk);
    //   });
  });

  useEffect(() => {
    const firstTable = document.querySelector(".first-table");
    const secondTable = document.querySelector(".second-table");

    if (firstTable && secondTable) {
      const firstTableWidth = firstTable.getBoundingClientRect().width;
      const secondTableWidth = secondTable.getBoundingClientRect().width;

      if (firstTableWidth + secondTableWidth > 0)
        document.documentElement.style.setProperty("--summary-table-width", `${firstTableWidth + secondTableWidth}px`);
    }
  });

  // scroll horitzontally
  useEffect(() => {
    const secondTable = document.querySelector(".second-table");
    const secondScroll = document.querySelector(".second-table-scroll");

    if (secondTable && secondScroll) {
      secondTable.addEventListener("wheel", function (e: any) {
        if (e.deltaY > 0) {
          secondTable.scrollLeft += 50;
          e.preventDefault();
          // prevenDefault() will help avoid worrisome
          // inclusion of vertical scroll
        } else {
          secondTable.scrollLeft -= 50;
          e.preventDefault();
        }
        secondScroll.scrollLeft = secondTable.scrollLeft;
      });
    }
  });

  useEffect(() => {
    const secondTable = document.querySelector(".second-table");
    const tableDates = document.querySelector("table.table-dates");
    const secondTableScroll = document.querySelector(".second-table-scroll");

    if (secondTable && tableDates && secondTableScroll) {
      const secondTableBounds = secondTable.getBoundingClientRect();
      const tableDatesBounds = tableDates?.getBoundingClientRect();

      setCssProperty("second-table-offset", secondTableBounds.x);
      setCssProperty("second-table-width", secondTableBounds.width);
      setCssProperty("second-table-content-width", tableDatesBounds.width);

      secondTableScroll.addEventListener("scroll", (e: any) => {
        secondTable.scrollLeft = e.target?.scrollLeft;
      });
    }
  });
};

export default forwardRef(SummaryTable);
