import {
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
} from "../constants";

const loginRequest = (data:any) => {
  const { email, password } = data;
  return {
    type: LOGIN_REQUEST,
    email,
    password,
  };
};

const loginSuccess = (action:any) => {
  return {
    type: LOGIN_SUCCESS,
    user: action,
  };
};

const loginError = (message:any) => {
  return {
    type: LOGIN_ERROR,
    message: message,
  };
};

const logoutRequest = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

const logoutSuccess = (action:any) => {
  return {
    type: LOGOUT_SUCCESS,
    user: action,
  };
};

const logoutError = (message:any) => {
  return {
    type: LOGOUT_ERROR,
    message: message,
  };
};

export {
  loginRequest,
  loginError,
  loginSuccess,
  logoutRequest,
  logoutSuccess,
  logoutError,
};
