import { PermissionList, PERMISSION_LIST } from "./PermissionTypes";

const initial_state: PermissionList = {
  permission_list: [],
};

export const permissionReducer = (state: PermissionList = initial_state, action: any) => {
  switch (action.type) {
    case PERMISSION_LIST: {
      return { ...state, permission_list: action.payload };
    }
    default:
      return state;
  }
};
