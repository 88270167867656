import { FC } from "react";

//import { useAppSelector } from "hooks";
import { Route, Redirect, RouteProps } from "react-router-dom";
//import { makeSelectToken } from "store/globalSelectors";

interface PublicRouteProps extends RouteProps {
  component: FC;
}

function renderComponent(props: any, Component: FC, isLoggedIn: boolean) {
  return isLoggedIn ? <Redirect to="/" /> : <Component {...props} />;
}

const PublicRoute: FC<PublicRouteProps> = ({
  component: Component,
  ...rest
}) => {
  //const token = useAppSelector(makeSelectToken);

  const isLoggedIn = true;//!!token;

  return (
    <Route
      {...rest}
      render={(props) => renderComponent(props, Component, isLoggedIn)}
    />
  );
};

export default PublicRoute;
