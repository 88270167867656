import api from "api";
import { toast } from "react-toastify";

export const fetchReviewView = async (id: Number) => {
  try {
    return await api({
      baseURL: process.env.REACT_APP_BASE_URL,
      method: "GET",
      url: `/scores/`,
      params: { review_id: id },
    });
  } catch (e: any) {
    // return e;
    console.log(e);
    return e;
  }
};

export const postRemarks = async (remark: any) => {
  remark["cost"] = parseInt(remark.package);

  if (!remark.forwarded_to && !remark.remarks && !remark.cost) {
    toast("Please select a field");
    // delete remark['id']
    // delete remark['remarks']
    // delete remark['cost']
    // delete remark['status']
    // delete remark['package']
    // delete remark['forwarded_to']
  } else {
    if (!remark.cost) {
      delete remark["cost"];
      remark["status"] = "UPLOADING";
    }
    if (remark["status"] === "REVIEWED") {
      delete remark["forwarded_to"];
    }
    delete remark["package"];

    console.log("remarks is", remark);
    if (remark.forwarded_to?.length === 0) {
      delete remark["forwarded_to"];
    }

    if (!remark.forwarded_to) {
      if (remark.remarks.length === 0 || !remark.cost) {
        delete remark["status"];
        delete remark["id"];
      }
      if (!remark.remarks) {
        toast("please enter remarks");
      }
      if (!remark.status && !remark.cost) {
        toast("please select cost");
      }
    }
  }
  console.log(remark);

  return api({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "PUT",
    url: `api/web/review/v2/review/${remark.id}/`,
    data: remark,
  });
};

export const fetchHistoryDetails = async (id: string) => {
  const res = await api({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "GET",
    url: "api/web/review/v2/logs",
    params: {
      hair_image_review_id: id,
    },
  });
  return res?.data;
};

export const fetchReviews = async (patient_user_profile_id: string) => {
  return api({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "GET",
    url: `api/web/review/v2/review/${patient_user_profile_id}/`,
  });
};
// api for cancellation

export const fetchCancellationReasons = async () => {
  const res = await api.get("api/web/review/v2/cancellation-reasons");
  return res?.data;
};

export const postCancellationReason = async ({ id, data }: { id: string; data: any }) => {
  const res = await api.put(`api/web/review/v2/review/${id}`, data);
  return res?.data;
};
