import axios from "axios";

export const fetchPatientsByStatus = async (
  page: number,
  paginate_by: number,
  searchQuery: string,
  sortField: string,
  sortDirection: string,
  status: string,
  retention: string,
) => {
  return axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method: "GET",
    url: `/patients/`,
    params: {
      status: status,
      retention_status: retention,
      search: searchQuery,
      page,
      paginate_by,
      order_by: sortField,
      sort_order: sortDirection,
    },
  });
};

export const fetchPatientsByInactive = async (
  status: string,
  page: number,
  paginate_by: number,
  searchQuery: string,
) => {
  return axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method: "GET",
    url: `/patients/`,
    params: {
      status,
      search: searchQuery,
      page,
      paginate_by,
      order: "desc",
      sort: "appointment_time",
    },
  });
};
