import api from "api";
import axios from "axios";
import { FilterOptions } from "hooks/useFilter";

export const fetchSubmittedProduct = async (id: number) => {
  const res = await axios({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "GET",
    url: `user/submitted-product/${id}/`,
  });
  return res?.data;
};

export const fetchSubmittedProducts = async (filterOptions?: FilterOptions) => {
  const res = await api.get("user/submitted-product/", {
    params: {
      ...filterOptions,
    },
  });
  return res?.data;
};

export const fetchProductDateTable = async (id: number, active: boolean) => {
  const abc = await axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method: "GET",
    url: "user/products/",
    params: {
      user_profile_id: id,
      is_active: active ? "True" : "False",
    },
  });
  return abc;
};

export const fetchProductDateTables = async (id: number, sortField: string, sortDirection: string) => {
  const abc = await axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method: "GET",
    url: "user/products/",
    params: {
      user_profile_id: id,
      order_by: sortField,
      sort_order: sortDirection,
    },
  });
  return abc;
};
