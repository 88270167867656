import SearchModule from "components/SearchModule";
import PaginatedTable from "components/Table/PaginatedTable";
import { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { fetchPatientsByInactive, fetchPatientsByStatus } from "redux/PatientInactive/api";
import { patient_pk_id, patient_user_profile_id } from "redux/PhysicianPortalData/PhysicianPortalActions";
import config from "../../../config/index";
import * as routes from "../../../constants/routes";
import { PatientCompletedTable } from "./PatientCompletedTable";
import { useDispatch } from "react-redux";

function PatientInactive() {
  let history = useHistory();
  const status = "INACTIVE";
  const dispatch = useDispatch();

  const [paginateBy, setPaginateBy] = useState(config.PAGE_SIZE);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { data: patientInactive, isFetching: isPatientDataFetching } = useQuery(
    ["patientInactive", status, page, paginateBy, searchQuery],
    () => fetchPatientsByInactive(status, page, paginateBy, searchQuery),
  );

  const patientCompletedList = patientInactive?.data;

  const handlePagination = (page: number) => {
    setPage(page);
  };
  const handleEntries = (pagesize: number) => {
    setPaginateBy(pagesize);
  };

  const current_page_number = patientCompletedList?.current_page_number || 0;
  const total_count = patientCompletedList?.total_count || 0;

  const handleSearch = (SearchKey: any) => {
    setSearchQuery(SearchKey);
  };

  return (
    <div className="patient-list-layout">
      <div className="module-header" style={{ margin: "2rem 0rem 1rem 0rem" }}>
        <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <div className="module-name">Reports</div>
          <div>
            <button
              className="Report-Button"
              onClick={() => {
                history.push(routes.PATIENTS);
              }}
            >
              Go Back To Patient List
            </button>
          </div>
        </div>
        <div className="module-action">
          <SearchModule handleSearch={handleSearch} />
        </div>
      </div>
      <PaginatedTable
        tableClass="align-left"
        cols={PatientCompletedTable()}
        data={patientCompletedList?.data}
        isDark={false}
        bordered
        striped={false}
        hoverable
        entries={paginateBy}
        onRowClick={(item: any) => {
          dispatch(patient_pk_id(item?.id));
          dispatch(patient_user_profile_id(item?.user_profile?.id));
          history.replace({ pathname: routes.PATIENT_COMPLETED_REPORT, state: { data: item, hideSave: true } });
        }}
        componentName={"PATIENTS LIST"}
        handlePagination={handlePagination}
        handleEntries={handleEntries}
        currentPage={current_page_number}
        totalCount={total_count}
        size="lg"
        isDataLoading={isPatientDataFetching}
        isPaginationRequired={true}
      />
      <div>
        <button
          className="Report-Button"
          onClick={() => {
            history.push(routes.PATIENTS);
          }}
        >
          Go Back To Patient List
        </button>
      </div>
    </div>
  );
}

export default PatientInactive;
