import { IAction } from "types/IAction";

export interface ISidebarItem {
  key: string;
  label: string;
  path?: string;
  Icon?: any;
  subItems?: (ISidebarItem | string)[];
  toolbarTitle?: string;
}

export const SIDEBAR_TOGGLE = "SIDEBAR_TOGGLE";

export interface ISidebarAction extends IAction {
  payload: any;
}
