import PaginatedTable from "components/Table/PaginatedTable";
import { MODULES } from "constants/modules";
import config from "../../config";
import _ from "lodash";
import { useState } from "react";
import { TableConstantsLog } from "./TableConstantsLog";
import backicon from "assets/brand/goback.png";
import "./reviewview.scss";

const HistoryLog = ({ historyDetail, setOpenHistory, view }: any) => {
  const [paginateBy, setPaginateBy] = useState(config.PAGE_SIZE);

  const pageData = historyDetail && !_.isEmpty(historyDetail) ? historyDetail : [];

  const totalCount = 1;

  const current_page_number = 1;
  console.log(pageData);
  const handlePagination = () => {};

  const handleEntries = () => {};
  const handleSort = (key: string, direction: string) => {};

  return (
    <>
      <div className="history-log">
        <img
          style={{ cursor: "pointer", marginBottom: "1rem" }}
          onClick={() => {
            setOpenHistory(null);
          }}
          src={backicon}
          alt="/"
        />
        <h3>Action History</h3>
      </div>
      <PaginatedTable
        cols={TableConstantsLog()}
        data={pageData}
        isDark={false}
        bordered
        striped={false}
        hoverable
        entries={paginateBy}
        onRowClick={() => {}}
        //selected={selectedRowID}
        componentName={MODULES.REVIEWS_VIEW}
        handlePagination={handlePagination}
        handleEntries={handleEntries}
        currentPage={current_page_number}
        totalCount={totalCount}
        size="lg"
        isDataLoading={false}
        handleSort={handleSort}
        isPaginationRequired={true}
      />

      <div></div>
    </>
  );
};

export default HistoryLog;
