export interface PhysicianPortalTypes {
  patient_pk_id: number;
  patient_user_profile_id: number;
  treatment_adjustment_data: string;
  treatment_directive_data: string;
  comment_data: string;
  treatment_regimen_data: string;
  emr_no: string;
  draft_record: Record<string, any>;
}

export const PATIENT_PK_ID = "PATIENT_PK_ID";
export const PATIENT_USER_PROFILE_ID = "PATIENT_USER_PROFILE_ID";
export const TREATMENT_ADJUSTMENT_DATA = "TREATMENT_ADJUSTMENT_DATE";
export const TREATMENT_DIRECTIVE_DATA = "treatment_directive_date";
export const COMMENT_DATA = "COMMENT_DATA";
export const TREATMENT_REGIMEN_DATA = "TREATMENT_REGIMEN_DATA";
export const EMR_NO_UPDATE = "EMR_NO_UPDATE";
export const CLEAR = "physician/CLEAR";
export const DRAFT_RECORD = "draft_record";
export const DRAFT_RECORD_REMOVE = "physician/draft_record_remove";
