
export const FourOhFourView = () => {
  // const { history } = useRouter();

  return <div className="d-flex justify-content-center align-items-center flex-column">Page not found</div>;
};
export const FourOhFour = () => (
  <>
    <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
      <FourOhFourView />
    </div>
  </>
);
