
import axios from "axios";

export const fetchReferrral = async (page:number,paginateBy:number,active:boolean,search:any,sortField:string,sortDirection:string) => {
    try{
      return await axios({
            baseURL: process.env.REACT_APP_BASE_URL,
            method: "GET",
            url: `api/rest/referral/v2/userprofile/?page_number=${page}&paginate_by=${paginateBy}&search=${search}&order_by=${sortField}&sort_order=${sortDirection}`,
          });
        }catch(e:any){
          return e;
        }
}
