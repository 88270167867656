import React, { useState } from "react";
import classNames from "classnames";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Divider, Tooltip, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ISidebarItem } from "../../redux/sidebar/types";
import { useLocation } from "react-router-dom";
import HqmIcon from "../icon";
import * as font from "../../assets/mhc-theme";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    borderLeft: "3px solid transparent",
    padding: "8px",
    color: theme.custom.grey,
    fontFamily: theme.custom.fontPrimary,
    fontWeight: 500,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.custom.white,
      color: "blue",
      fontWeight: 600,
    },
  },
  menuIcon: {
    color: theme.custom.grey,
  },
}));

interface ISidebarItemProps {
  depthStep?: number;
  depth?: number;
  sidebarItem: ISidebarItem;
  onClick: (item: ISidebarItem) => void;
}

const SidebarItem: React.FC<ISidebarItemProps> = ({ depth = 0, depthStep = 10, sidebarItem, onClick }) => {
  const { label, Icon, subItems, path, key } = sidebarItem;
  const classes = useStyles(useTheme());
  const location = useLocation();

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const toggleIsCollapsed = () => setIsCollapsed((prevValue) => !prevValue);

  const sidebarClasses = classNames({
    "sidebar-item": true,
    "sidebar-item--active":
      path === location.pathname || (subItems && subItems.some((item: any) => item.path === location.pathname)),
  });

  const onMenuClick = () => {
    if (Array.isArray(subItems)) {
      toggleIsCollapsed();
    }

    onClick(sidebarItem);
    // TODO: - Route handler
  };

  const renderExpandIcon = () => {
    let expandIcon = null;

    if (Array.isArray(subItems) && subItems.length) {
      expandIcon = !isCollapsed ? <HqmIcon name="chevron-up" size={10} /> : <HqmIcon name="chevron-down" size={10} />;
    }

    return expandIcon;
  };

  const isVisible = useSelector((state: RootState) => state.sidebar.isVisible);

  const iconRender = (type: string) => {
    switch (type) {
      default:
      case "reviewed":
        return "check-filled";
      case "pending":
        return "progress";
      case "archived":
        return "save";
      case "cancelled":
        return "info";
    }
  };

  return (
    <div className={`sidebar-item${depth > 0 ? " sub" : ""}`}>
      <ListItem className={`${sidebarClasses} ${classes.root}`} onClick={onMenuClick} button>
        <Tooltip title={label}>
          <div
            className="sidebar-item__content"
            style={{
              paddingLeft: depth * depthStep,
            }}
          >
            {renderExpandIcon()}
            {Icon && (
              <ListItemIcon
                style={{
                  paddingLeft: Array.isArray(subItems) && subItems.length ? ".75rem" : "1.375rem",
                  paddingRight: "1rem",
                  minWidth: "unset",
                }}
              >
                {typeof Icon === "string" ? <HqmIcon name={Icon} size={isVisible ? 20 : 24} /> : <Icon />}
              </ListItemIcon>
            )}
            {!Icon && depth > 0 && (
              <ListItemIcon
                style={{
                  marginLeft: "-2rem",
                  paddingRight: isVisible ? "1rem" : 0,
                  minWidth: "unset",
                }}
              >
                <HqmIcon name={iconRender(label.toLowerCase())} size={16} />
              </ListItemIcon>
            )}
            {isVisible && (
              <ListItemText className={`sidebar-item__label${depth === 0 ? " parent" : ""}`} primary={label} />
            )}
          </div>
        </Tooltip>
      </ListItem>
      <Collapse in={!isCollapsed} timeout="auto" unmountOnExit>
        {Array.isArray(subItems) ? (
          <List>
            {subItems.map((subItem, index) => (
              <React.Fragment key={index}>
                {typeof subItem === "string" ? (
                  <Divider className="sidebar-item__divider" />
                ) : (
                  <SidebarItem depth={depth + 1} depthStep={depthStep} sidebarItem={subItem} onClick={onClick} />
                )}
              </React.Fragment>
            ))}
          </List>
        ) : null}
      </Collapse>
    </div>
  );
};

export default SidebarItem;
