import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { loginReducer } from "./auth/reducer";
import { LOGOUT_SUCCESS } from "./constants";
import { permissionReducer } from "./Permission/PermissionReducer";
import physicianPortalReducer from "./PhysicianPortalData/PhysicianPortalReducer";
import reviewScreenReducer from "./reviewScreen";
import { sidebarReducer } from "./sidebar/reducer";

const appReducer = combineReducers({
  loginReducer,
  sidebar: sidebarReducer,
  reviewScreen: reviewScreenReducer,
  physicianPortalReducer: physicianPortalReducer,
  permissionReducer
});

const rootReducer = (state: any, action: any) => {
  if (action.type === LOGOUT_SUCCESS) {
    ////console.log("this action is being executed")

    storage.removeItem("persist:root");
    return appReducer(undefined, action);
  } else {
    ////console.log("This is action", action);
    return appReducer(state, action);
  }
};

export default rootReducer;

export type BaseAction<T, P = any> = { type: T; payload: P };
