import HqmIcon from "components/icon";
import { usePermissionCheck } from "hooks/useCheckPermission";
import { useHistory } from "react-router-dom";
import mhcTheme from "../../assets/style/style.module.scss";
import * as routes from "../../constants/routes";

// export const TableConstants = (handleView:Function) => {

//     return [
//         {
//             title: "User",
//             name: "user_profile",
//             sortable: false,
//             render: (rowData: any) => {
//               let name=rowData?.user_profile.full_name;

//               return <span>{rowData?.user_profile.full_name}</span>;
//             },
//           },
//           {
//             title: "Captured date",
//             name: "capture_date",
//             sortable: true,
//             render: (rowData: any) => {
//               const lastupdate= String(new Date(rowData?.capture_date).toLocaleString())
//                 return <span>{lastupdate}</span>;
//             },
//           },
//           {
//             title: "Stylist",
//             name: "stylist",
//             sortable: false,
//             render: (rowData: any) => {
//               return <span>{rowData?.hair_capture.license_number?rowData?.hair_capture.license_number:<span>N/A</span>}</span>;
//             },
//           },
//           {
//             title: "Spa/Saloon",
//             name: "spasaloon",
//             sortable: false,
//             render: (rowData: any) => {
//               return <span>{rowData?.hair_capture.license_number?rowData?.hair_capture.license_number:<span>N/A</span>}</span>;
//             },
//           },
//           {
//             title: '',
//             name: "action",
//             sortable: false,
//             render: (rowData: any) => {
//               return <HqmIcon name="eye" size={20} style={{color: mhcTheme.secondary}} onClick={()=>handleView(rowData)}/>;

//             },
//           },
//     ]
// }

export const TableConstants = (handleview: Function, permissionList: any) => {
  let history = useHistory();
  const view_hairimagescores = usePermissionCheck("view_hairimagescores");

  return [
    {
      title: "User",
      name: "user_profile",
      sortable: false,
      render: (rowData: any) => {
        let name = rowData?.user_profile.full_name;

        return <span>{rowData?.user_profile?.full_name}</span>;
      },
    },
    {
      title: "Captured date",
      name: "capture_date",
      sortable: true,
      render: (rowData: any) => {
        const lastupdate = String(new Date(rowData?.capture_date).toLocaleString());
        return <span>{lastupdate}</span>;
      },
    },
    {
      title: "Forwaded To",
      name: "forwaded_to_email",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.forwarded_to_email}</span>;
      },
    },
    {
      title: "Updated By",
      name: "reviewed_by",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.reviewed_by}</span>;
      },
    },
    {
      title: "Stylist",
      name: "stylist",
      sortable: false,
      render: (rowData: any) => {
        return (
          <span>
            {rowData?.hair_capture?.license_number ? rowData?.hair_capture?.license_number : <span>N/A</span>}
          </span>
        );
      },
    },
    {
      title: "Spa/Saloon",
      name: "spasaloon",
      sortable: false,
      render: (rowData: any) => {
        return (
          <span>
            {rowData?.hair_capture?.license_number ? rowData?.hair_capture?.license_number : <span>N/A</span>}
          </span>
        );
      },
    },
    {
      title: "",
      name: "action",
      sortable: false,
      render: (rowData: any) => {
        const a = (
          <HqmIcon
            name="eye"
            size={20}
            style={{ color: mhcTheme.secondary }}
            onClick={() => {
              handleview(rowData);
              history.push({ pathname: routes.VIEW_ASSIGNED_PENDING, state: { data: rowData } });
            }}
          />
        );
        return <span>{view_hairimagescores ? a : null}</span>;
      },
    },
  ];
};

export const TableConstantsReview = (handleview: Function, permissionList: any) => {
  // const [packageCost,setPackageCost]=useState(null)
  // const fetchCostDetails=async()=>{
  //   axios.get(`https://mhs-dev.myhairscope.com/api/web/review/v2/cost/`).then((res)=>{
  //     setPackageCost(res?.data?.id)
  //     console.log(res?.data)
  //   })
  // }
  // useEffect(()=>{fetchCostDetails()},[])
  let history = useHistory();
  const view_hairimagescores = usePermissionCheck("view_hairimagescores");

  return [
    {
      title: "User",
      name: "user_profile",
      sortable: false,
      render: (rowData: any) => {
        let name = rowData?.user_profile.full_name;
        return <span>{rowData?.user_profile?.full_name}</span>;
      },
    },
    {
      title: "Captured date",
      name: "capture_date",
      sortable: true,
      render: (rowData: any) => {
        const lastupdate = String(new Date(rowData?.capture_date).toLocaleString());
        return <span>{lastupdate}</span>;
      },
    },
    {
      title: "Cost",
      name: "cost",
      sortable: false,
      render: (rowData: any) => {
        let a = "";
        if (rowData?.cost === 1) {
          a = "Free Review";
        } else if (rowData?.cost === 2) {
          a = "Silver Review";
        } else if (rowData?.cost === 3) {
          a = "Gold Review";
        } else {
          a = "No Cost";
        }

        // return<span>{rowData?.cost}</span>
        return (
          <span
            className={
              rowData?.cost === 1
                ? "badge badge-primary"
                : rowData?.cost === 2
                ? "badge  badge-silver"
                : !rowData?.cost
                ? "badge badge-disabled"
                : "badge  badge-warning"
            }
          >
            {a}
          </span>
        );
      },
    },
    {
      title: "Forwaded To",
      name: "forwaded_to_email",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.forwarded_to_email}</span>;
      },
    },
    {
      title: "Updated By",
      name: "reviewed_by",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.reviewed_by}</span>;
      },
    },
    {
      title: "Stylist",
      name: "stylist",
      sortable: false,
      render: (rowData: any) => {
        return (
          <span>
            {rowData?.hair_capture?.license_number ? rowData?.hair_capture?.license_number : <span>N/A</span>}
          </span>
        );
      },
    },
    {
      title: "Spa/Saloon",
      name: "spasaloon",
      sortable: false,
      render: (rowData: any) => {
        return (
          <span>
            {rowData?.hair_capture?.license_number ? rowData?.hair_capture?.license_number : <span>N/A</span>}
          </span>
        );
      },
    },
    {
      title: "",
      name: "action",
      sortable: false,
      render: (rowData: any) => {
        const a = (
          <HqmIcon
            name="eye"
            size={20}
            style={{ color: mhcTheme.secondary }}
            onClick={() => {
              handleview(rowData);
              history.push({ pathname: routes.VIEW_ASSIGNED_REVIEWED, state: { data: rowData } });
              console.log(rowData);
            }}
          />
        );
        return <span>{view_hairimagescores ? a : null}</span>;
      },
    },
  ];
};

export const TableConstantsCancelled = (handleview: Function, permissionList: any) => {
  let history = useHistory();
  const view_hairimagescores = usePermissionCheck("view_hairimagescores");

  return [
    {
      title: "User",
      name: "user_profile",
      sortable: false,
      render: (rowData: any) => {
        let name = rowData?.user_profile.full_name;

        return <span>{rowData?.user_profile?.full_name}</span>;
      },
    },
    {
      title: "Captured date",
      name: "capture_date",
      sortable: true,
      render: (rowData: any) => {
        const lastupdate = String(new Date(rowData?.capture_date).toLocaleString());
        return <span>{lastupdate}</span>;
      },
    },
    {
      title: "Forwaded To",
      name: "forwaded_to_email",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.forwarded_to_email}</span>;
      },
    },
    {
      title: "Updated By",
      name: "reviewed_by",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.reviewed_by}</span>;
      },
    },
    {
      title: "Stylist",
      name: "stylist",
      sortable: false,
      render: (rowData: any) => {
        return (
          <span>
            {rowData?.hair_capture?.license_number ? rowData?.hair_capture?.license_number : <span>N/A</span>}
          </span>
        );
      },
    },
    {
      title: "Spa/Saloon",
      name: "spasaloon",
      sortable: false,
      render: (rowData: any) => {
        return (
          <span>
            {rowData?.hair_capture?.license_number ? rowData?.hair_capture?.license_number : <span>N/A</span>}
          </span>
        );
      },
    },
    {
      title: "",
      name: "action",
      sortable: false,
      render: (rowData: any) => {
        const a = (
          <HqmIcon
            name="eye"
            size={20}
            style={{ color: mhcTheme.secondary }}
            onClick={() => {
              history.push({ pathname: routes.VIEW_ASSIGNED_CANCELLED, state: { data: rowData } });
            }}
          />
        );
        return <span>{view_hairimagescores ? a : null}</span>;
      },
    },
  ];
};
