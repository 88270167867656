// export const fetchReviewView = async (uuid:string, capdate:string,page:number,paginateBy:number ) => {
//     try{
//       return await axios({
//             baseURL: process.env.REACT_APP_BASE_URL,
//             method: "GET",
//             url: `api/web/review/v2/scores/?uuid=${uuid}&capture_date=${capdate}&page_number=${page}&paginate_by=${paginateBy}`,
//           });
//         }catch(e:any){
//           return e;
//         }
// }

import axios from "axios";

export const fetchReviewviewView = async (id: string) => {
  try {
    return await axios({
      baseURL: process.env.REACT_APP_BASE_URL,
      method: "GET",
      url: `/scores/`,
      params: {
        id,
      },
    });
  } catch (e: any) {
    return e;
  }
};
export const posthairValues = async (hairValue: any) => {
  return axios({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "PUT",
    url: `/scores/${hairValue.id}/`,
    data: hairValue,
  });
};
