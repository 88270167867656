import { useState } from "react";

export const useCheckBoxAssign = (page:number,pageData:Array<any>,refresh:boolean,setRefresh:Function) => {
    const [checked,setChecked]=useState<Array<number>>([]);
    const [allChecked,setAllChecked] = useState<Map<number,boolean>>(new Map().set(1,false));

    const checkAssign = (id:number) => {
        let newChecked = checked;
        if(checked?.includes(id)){
          newChecked = checked?.filter(i => i != id);
          if(allChecked?.has(page)){
            allChecked?.set(page,false);
          }
        }else{
          newChecked?.push(id);
          let dataIds = pageData?.map((d:any) => parseInt(d.id));
          if(dataIds?.some((i:number) => !(checked?.includes(i)))){
            allChecked.set(page,false)
          }else{
            allChecked.set(page,true);
          }
        }
        setAllChecked(allChecked);
        setChecked(newChecked);
        setRefresh(!refresh);
      }
  
      const checkAll = (page: number) => {
          let dataIds = pageData?.map((d:any) => parseInt(d.id));
          if(!(allChecked?.has(page)) || (allChecked?.has(page) && allChecked.get(page) === false)){
            allChecked?.set(page,true);
            let newChecked = checked?.concat(dataIds);
            setAllChecked(allChecked);
            setChecked(newChecked);
          }else{
            allChecked?.set(page,false);
            let newChecked = checked?.filter(i => !(dataIds?.includes(i)));
            setChecked(newChecked);
          }
      }

    return {checkAssign , checkAll,checked,allChecked,setChecked,setAllChecked};
}