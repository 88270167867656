import React from "react";
import { Line, Text } from "react-konva";

/**
 * Function to get name
 * @param index
 * @param cb
 * @returns
 */
const renderLineName = (index: number, cb: "BA" | "CD" = "CD") => {
  const diff = 10 - index;

  if (diff === -10) {
    return cb[0] + cb[0];
  } else if (diff === 10) {
    return cb[1] + cb[1];
  } else if (diff === 0) {
    return cb === "BA" ? "AB" : cb;
  } else {
    return `${cb[diff <= 0 ? 0 : 1]}${Math.abs(diff)}`;
  }
};

//colors
const BLUE = "rgb(112 182 237)";
const RED = "#e03b3b";

const STROKE_WIDTH = 1;
const OPACITY = 0.5;

/**
 * Function to render text color base on text
 * @param name
 * @returns
 */
const renderColor = (name: string) => {
  if (name === "A" || name === "B" || name === "C" || name === "D") return "white";
  else return RED;

  // if (name === "AB" || name === "CD") return RED;
  // if (name.includes("A")) return BLUE;
  // else if (name.includes("C")) return RED;
  // else return "white";
};
const renderColortxt = (name: string) => {
  return "white";
};
// const renderColor = (name: string) => {
//   if (name[0] === "D" || name[0] === "C") return "#e03b3b"; // red
//   if (name[0] === "A" || name[0] === "B") return "#0077be"; // blue
//   else return "white";
// };

interface ReviewScreenABCDGridProps {
  cpd: {
    // Cropped area
    h: number;
    w: number;
  };
  imageInitialWidth: number;
  show?: boolean;
}

const ReviewScreenABCDGrid: React.FC<ReviewScreenABCDGridProps> = (props) => {
  const { cpd, imageInitialWidth, show } = props;

  //single block size = totalheight / 11 * 2
  //dividing each block in hal
  const blockSize = cpd.h / 22;
  //get center point = image width / 2
  const outsideVBlockWidth = (imageInitialWidth - cpd.w) / 2;

  if (!show) return <></>;

  return (
    <>
      {[...Array(21)].map((line, index) => {
        //line number
        const index1 = index + 1;
        //check if line is even
        const isIndex1Even = index1 % 2 === 0;
        //single block starting starting point
        const singleBlock = index1 * blockSize;
        // even line points
        const pointsEven = [0, singleBlock, imageInitialWidth, singleBlock];
        // odd line points
        const pointsOdd = [outsideVBlockWidth, singleBlock, cpd.w + outsideVBlockWidth, singleBlock];
        const textGap = 12;
        const fontSize = 30;
        const alignVertical = 10;
        return (
          <React.Fragment key={`horizonatlLine${index}`}>
            <Line
              //dash if odd
              //horizontal red line
              dash={!isIndex1Even ? [19] : undefined}
              points={isIndex1Even ? pointsEven : pointsOdd}
              pointerLength={5}
              pointerWidth={5}
              stroke={isIndex1Even ? RED : BLUE}
              strokeWidth={STROKE_WIDTH}
              fill="black"
              opacity={OPACITY}
            />

            {/**
             * if line number not even
             */}
            {!isIndex1Even && (
              <>
                {/* for cd */}
                <Text
                  fill={renderColortxt(renderLineName(index, "CD")[0])}
                  fontSize={fontSize} // text font size
                  //position of text= image area - 12px
                  x={outsideVBlockWidth / 2 - 24}
                  //align to center vertically
                  y={singleBlock - alignVertical}
                  text={renderLineName(index, "CD")[0]}
                ></Text>
                <Text
                  fill={renderColor(renderLineName(index, "CD")[1])}
                  fontSize={fontSize} // text font size
                  //position of text= image area - 12px
                  x={outsideVBlockWidth / 2}
                  //align to center vertically
                  y={singleBlock - alignVertical}
                  text={renderLineName(index, "CD")[1]}
                ></Text>

                {/* for ba plane */}
                <Text
                  fill={renderColortxt(renderLineName(index, "BA")[0])}
                  fontSize={fontSize} // text font size
                  //position of text= image area - 12px
                  x={imageInitialWidth - outsideVBlockWidth / 2 + textGap - 24}
                  //align to center vertically
                  y={singleBlock - alignVertical}
                  text={renderLineName(index, "BA")[0]}
                ></Text>
                <Text
                  fill={renderColor(renderLineName(index, "BA")[1])}
                  fontSize={fontSize} // text font size
                  //position of text= image area - 12px
                  x={imageInitialWidth - outsideVBlockWidth / 2 + textGap - 4}
                  //align to center vertically
                  y={singleBlock - alignVertical}
                  text={renderLineName(index, "BA")[1]}
                ></Text>
              </>
            )}
          </React.Fragment>
        );
      })}

      {/* horizonal blue line */}
      <Line
        points={[imageInitialWidth / 2, 0, imageInitialWidth / 2, cpd.h]}
        stroke={RED}
        strokeWidth={STROKE_WIDTH}
        fill="black"
        opacity={OPACITY}
      />

      {[...Array(4)].map((line, index) => {
        //line number
        const index1 = index + 1;
        //cropped block width
        const cpdBlockSize = cpd.w / 4;
        //x coordinates
        const pointX = outsideVBlockWidth + cpdBlockSize * index1;

        if (index1 % 2 === 0) return null;

        return (
          <Line
            //vertical red lines
            key={`verticalline${index1}`}
            dash={[17]}
            points={[pointX, 0, pointX, cpd.h]}
            pointerLength={5}
            pointerWidth={5}
            stroke={BLUE}
            strokeWidth={STROKE_WIDTH}
            fill="black"
            opacity={OPACITY}
          />
        );
      })}
    </>
  );
};

export default ReviewScreenABCDGrid;
