import axios from "axios";

export const getAllPatients = async (page: number, paginate_by: number, searchQuery: string) => {
  if (searchQuery?.length > 0) {
    page = 1;
  }
  return axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method: "GET",
    url: `patients/`,
    params: {
      search: searchQuery,
      page,
      paginate_by,
    },
  });
};

export const getAPatientData = async (id: string | number) => {
  return axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method: "GET",
    url: `patients/${id}`,
  });
};
