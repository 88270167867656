import HqmIcon from "components/icon";
import MHCAlert, { SNACKBAR_TYPE } from "components/MHCAlert";
import MHCConfirm from "components/MHCConfirm";
import SearchModule from "components/SearchModule";
import { useSetStatus } from "context/statusContext";
import { useCheckBoxArchive } from "hooks/useCheckBoxArchive";
import { useCheckBoxPartners } from "hooks/useCheckBoxPartners";
import { usePermissionCheck } from "hooks/useCheckPermission";
import _ from "lodash";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { theme } from "theme";
import PaginatedTable from "../../components/Table/PaginatedTable";
import config from "../../config";
import { MODULES } from "../../constants/modules";
import * as routes from "../../constants/routes";
import {
  bulkApprovePartners,
  bulkDeletePartners,
  deletePartners,
  fetchPartners,
  permanentlyDeletePartners,
  restorePartners,
} from "../../redux/partners/partnersApi";
import { TablePartners, TablePartnersArchived, TablePartnersPending } from "./TablePartners";

const Partners = ({ active, permissionList, isDeleted }: any) => {
  const [page, setPage] = useState(1);
  const [paginateBy, setPaginateBy] = useState(config.PAGE_SIZE);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortField, setSortField] = useState("partner_id");
  const [sortDirection, setSortDirection] = useState("desc");

  const [openModal, setOpenModal] = useState(false);
  const [selectedPartners, setSelectedPartners] = useState<any>(null);
  const [refresh, setRefresh] = useState(false);
  const [confirmation, setConfirmation] = useState("");
  // const [partnersDetail, setPartnersDetail] = useState(null);

  const [restoreConfirmationDeletionModal, setRestoreConfirmationDeletionModal] = useState("");
  const [restoreData, setRestoreData] = useState();

  const setStatus = useSetStatus();

  const { data, isFetching, refetch } = useQuery(
    ["projects", page, paginateBy, active, searchQuery, sortField, sortDirection, isDeleted],
    () => fetchPartners(page, paginateBy, active, searchQuery, sortField, sortDirection, isDeleted),
    { keepPreviousData: true },
  );

  const errorCbk = (err: any) => {
    console.log("Err", err);
    setStatus(SNACKBAR_TYPE.ERROR, err?.response?.data?.message || "Server Error in Partner section");
  };

  const clearTableChecks = () => {
    setChecked([]);
    allChecked?.forEach((v: any, k: any) => {
      allChecked.set(k, false);
    });

    refetch();
  };

  const { mutate: delPartners, isLoading: deleteLoading } = useMutation(deletePartners, {
    onError: errorCbk,
    onSuccess() {
      handleCloseModal();
      setStatus(SNACKBAR_TYPE.SUCCESS, "Deleted Partner successfully");
      refetch();
    },
  });

  const { mutate: bulkApprovePart, isLoading: bApproveLoading } = useMutation(bulkApprovePartners, {
    onSuccess: () => {
      clearTableChecks();
      setStatus(SNACKBAR_TYPE.SUCCESS, "Approved Partners successfully");

      refetch();
    },
    onError: errorCbk,
  });

  const { mutate: bulkDelPart } = useMutation(bulkDeletePartners, {
    onSuccess: () => {
      clearTableChecks();
      setStatus(SNACKBAR_TYPE.SUCCESS, "Deleted Partners successfully");
      refetch();
    },
    onError: errorCbk,
  });

  const { mutate: restorePart } = useMutation(restorePartners, {
    onSuccess: () => {
      setStatus(SNACKBAR_TYPE.SUCCESS, "Restored Partner successfully");
      refetch();
    },
    onError: errorCbk,
  });

  const { mutate: permanentDelPart } = useMutation(permanentlyDeletePartners, {
    onError: errorCbk,
    onSuccess() {
      setStatus(SNACKBAR_TYPE.SUCCESS, "Permanently deleted Partner successfully");
      refetch();
    },
  });

  const pageData = data && !_.isEmpty(data.data?.data) ? data.data?.data : [];

  const pageCount = (data && data.data?.page_count) || 0;

  const totalCount = (data && data.data?.total_count) || 0;

  const current_page_number = (data && data.data?.current_page_number) || 0;

  const { checkPartners, checkAll, checked, allChecked, setChecked, setAllChecked } = useCheckBoxPartners(
    page,
    pageData,
    refresh,
    setRefresh,
  );

  const { checkSelectArchive, checkAllArchive, checkedArchive, allCheckedArchive } = useCheckBoxArchive(
    page,
    pageData,
    refresh,
    setRefresh,
  );

  const handlePagination = (page: number) => {
    setPage(page);
  };

  const handleEntries = (pagesize: number) => {
    setPaginateBy(pagesize);
  };
  const handleSort = (key: string, direction: string) => {
    setSortField(key);
    setSortDirection(direction);
  };

  const handleSearch = (searchKey: any) => {
    setSearchQuery(searchKey);
  };

  const handleCloseModal = () => {
    setSelectedPartners(null);
    setOpenModal(false);
  };

  const handleViewPartner = (partner: any) => {
    // setPartnersDetail(partner);
    setOpenModal(true);
  };

  const handleApproveChecked = () => {
    bulkApprovePart({ ids: checked });
  };

  const handleDeleteChecked = () => {
    bulkDelPart({ ids: checked });
  };

  const handleConfirmation = () => {
    if (confirmation === "bulkApprove") {
      handleApproveChecked();
    } else if (confirmation === "bulkDelete") {
      handleDeleteChecked();
    }
    setConfirmation("");
  };
  const add_generalinformation = usePermissionCheck("add_generalinformation");
  const delete_generalinformation = usePermissionCheck("delete_generalinformation");
  const approve_generalinformation = usePermissionCheck("approve_generalinformation");

  const history = useHistory();

  const handleView = (row: any) => {
    // setPartnersDetail(row);
  };

  const handlePermanentlyDelete = (row: any) => {
    setRestoreConfirmationDeletionModal("delete");
    setRestoreData(row);
  };

  const handleRestore = (row: any) => {
    setRestoreConfirmationDeletionModal("restore");
    setRestoreData(row);
  };

  const handleConfirmationRestoreDelete = (values: any) => {
    if (restoreConfirmationDeletionModal === "restore") {
      if (checkedArchive?.length) {
        restorePart({ ids: checkedArchive });
        setRestoreConfirmationDeletionModal("");
      } else {
        restorePart({ ids: [restoreData] });
        setRestoreConfirmationDeletionModal("");
      }
    } else if (restoreConfirmationDeletionModal === "delete") {
      if (checkedArchive?.length) {
        permanentDelPart({ ids: checkedArchive, permanently_delete: true });

        setRestoreConfirmationDeletionModal("");
      } else {
        permanentDelPart({ ids: [restoreData], permanently_delete: true });
        setRestoreConfirmationDeletionModal("");
      }
    }
  };

  return (
    <>
      <MHCConfirm
        open={confirmation}
        message={
          confirmation === "bulkApprove"
            ? "Are you sure you want to approve the selected partners?"
            : "Are you sure you want to delete the selected partners?"
        }
        title={confirmation === "bulkApprove" ? "Approval Confirmation" : "Deletion Confirmation"}
        onCancel={() => setConfirmation("")}
        onConfirm={handleConfirmation}
        yesLabel={confirmation === "bulkApprove" ? "Approve" : "Delete"}
        noLabel="Cancel"
      />
      <MHCConfirm
        open={restoreConfirmationDeletionModal}
        message={
          restoreConfirmationDeletionModal === "restore"
            ? "Are you sure you want to restore the selected partner?"
            : "Are you sure you want to permanently delete the selected partner?"
        }
        title={
          restoreConfirmationDeletionModal === "restore" ? "Restore Confirmation" : "Permanently Delete Confirmation"
        }
        onCancel={() => setRestoreConfirmationDeletionModal("")}
        onConfirm={handleConfirmationRestoreDelete}
        yesLabel={restoreConfirmationDeletionModal === "restore" ? "Restore" : "Delete"}
        noLabel="Cancel"
      />
      <MHCAlert />

      {/* {active ? (
        <GeneralInformation partners={selectedPartners} handleSubmitPartners={addUpdatePartners} />
      ) : ( */}
      <div className="page">
        <div className="module-header">
          <div className="module-name">Partners</div>
          <div className="module-action">
            {add_generalinformation ? (
              <button
                className="module-add-button"
                onClick={() => {
                  history.push(routes.ADD_PARTNER);
                }}
              >
                + Add partner
              </button>
            ) : null}
            {!active && !isDeleted && checked?.length > 0 && (
              <>
                {approve_generalinformation ? (
                  <button className="module-approve-button leftmar" onClick={() => setConfirmation("bulkApprove")}>
                    Approve
                    {bApproveLoading && (
                      <Spinner
                        animation="border"
                        size="sm"
                        style={{ marginLeft: "0.5rem", color: theme.palette.primary.main }}
                      />
                    )}
                  </button>
                ) : null}
                {delete_generalinformation ? (
                  <button className="module-delete-button" onClick={() => setConfirmation("bulkDelete")}>
                    <HqmIcon name="trash" size={20} />
                  </button>
                ) : null}
              </>
            )}
            {active && !isDeleted && checked?.length > 0 && (
              <>
                {delete_generalinformation ? (
                  <button className="module-delete-button" onClick={() => setConfirmation("bulkDelete")}>
                    <HqmIcon name="trash" size={20} />
                  </button>
                ) : null}
              </>
            )}
            {isDeleted && checkedArchive?.length > 0 && (
              <>
                <button
                  className="module-approve-button leftmar"
                  onClick={() => setRestoreConfirmationDeletionModal("restore")}
                >
                  Restore
                </button>
                <button className="module-delete-button" onClick={() => setRestoreConfirmationDeletionModal("delete")}>
                  <HqmIcon name="trash" size={20} />
                </button>
              </>
            )}
            <SearchModule handleSearch={handleSearch} />
          </div>
        </div>
      </div>
      <PaginatedTable
        cols={
          active
            ? TablePartners(checkPartners, handleView, permissionList)
            : !isDeleted
            ? TablePartnersPending(checkPartners, checked, handleView, permissionList)
            : TablePartnersArchived(checkSelectArchive, checkedArchive, handleRestore, handlePermanentlyDelete)
        }
        data={pageData}
        isDark={false}
        bordered
        striped={false}
        hoverable
        entries={paginateBy}
        // onRowClick={(item: any) => {
        //   history.push({ pathname: routes.EDIT_PARTNERS_ACTIVE, state: { data: item } });
        // }}
        //selected={selectedRowID}
        componentName={MODULES.PARTNERS}
        handlePagination={handlePagination}
        handleEntries={handleEntries}
        currentPage={current_page_number}
        totalCount={totalCount}
        size="lg"
        isDataLoading={isFetching || deleteLoading}
        handleSort={handleSort}
        initialSortConfig={{ key: sortField, direction: sortDirection }}
        allChecked={isDeleted ? allCheckedArchive : allChecked}
        checkAll={isDeleted ? checkAllArchive : checkAll}
        isPaginationRequired={true}
      />
    </>
  );
};

export default Partners;
