import { Modal } from "@material-ui/core";
import { ReactNode } from "react";

interface MHCModalProps {
  open: string;
  message: string;
  title: string;
  onCancel: Function;
  onConfirm: Function;
  yesLabel?: ReactNode;
  noLabel?: string;
}
const MHCConfirm = ({ open, message, title, onCancel, onConfirm, yesLabel = "Yes", noLabel = "No" }: MHCModalProps) => {
  return (
    <Modal open={open ? true : false} className="mhc-confirm-modal">
      <div className="mhc-confirm">
        <div className="confirm-title">{title}</div>
        <div className="confirm-body">{message}</div>
        <div className="confirm-footer">
          <button className="confirm-no" onClick={() => onCancel()}>
            {noLabel}
          </button>
          <button className="confirm-yes" onClick={() => onConfirm()}>
            {yesLabel}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MHCConfirm;
