import axios from "axios";

export const fetchAssignedReviews = async (page:number,paginateBy:number,active:string, search:any, sortField:string, sortDirection:string, email:string, reviewer:any) => {
    try{
        if(active==='0'){active='REVIEWED'}
        else if(active==='1'){active='UPLOADING'}
        else{active='CANCELLED'}
      if(!reviewer) {
        
      
        
        //ifreviewer change asssingedtoemail to forwaded to and keep the smae email
        
      return  axios({
            baseURL: process.env.REACT_APP_BASE_URL,
            method: "GET",
            url: `api/web/review/v2/review/?status=${active}&page_number=${page}&paginate_by=${paginateBy}&search=${search}&order_by=${sortField}&sort_order=${sortDirection}&assigned_to_email=${email}`,
          });
        }
        if(reviewer) {
          return await axios({
            baseURL: process.env.REACT_APP_BASE_URL,
            method: "GET",
            url: `api/web/review/v2/review/?status=${active}&page_number=${page}&paginate_by=${paginateBy}&search=${search}&order_by=${sortField}&sort_order=${sortDirection}&forward_to_email=${email}`,
          });

        }
        }catch(e:any){
          return e;
        }
}
