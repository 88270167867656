import axios, { AxiosRequestConfig } from "axios";
import { auth } from "firebase-config";
import TokenService from "../utils/TokenService";
// import { API_URL } from "../utils/config";

const tokenService: TokenService = new TokenService();

export const addAuthHeader = (config: AxiosRequestConfig) => {
  const token =
    //tokenService._getAccessToken();
    (auth?.currentUser as any)?.accessToken;

  if (config && config.headers) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
};

export const HttpRequestInterceptor = axios.interceptors.request.use(addAuthHeader, (error) => {
  Promise.reject(error);
});

export const refreshAuthTokenOnError = (error: any) => {
  const originalRequest = error.config;

  if (error.response.status === 403 && !originalRequest._retry) {
    originalRequest._retry = true;
    // const refreshToken = tokenService._getRefreshToken();
    return auth?.currentUser
      ?.getIdToken(true)
      .then((res) => {
        if (res) {
          tokenService._setToken(res);
          originalRequest.headers["Authorization"] = "Bearer " + tokenService._getAccessToken();
          return axios(originalRequest);
        }
      })
      .catch((error) => {
        window.location.href = "/";
      });
  }

  if (error.response.status === 500 && originalRequest.headers["Authorization"] == "Bearer undefined") {
    window.location.href = "/";
  }
  return Promise.reject(error);
};

export const HttpResponseInterceptor = axios.interceptors.response.use((response) => {
  return response;
}, refreshAuthTokenOnError);
