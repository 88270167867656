import axios from "axios";

export const getProductUsedDates = async (user_profile_id: string | number, journal_date: string) => {
  const date = new Date(new Date(journal_date).getFullYear(), new Date(journal_date).getMonth() + 1);
  return axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    url: "hairjournal/month/",
    method: "GET",
    params: { user_profile_id: user_profile_id, journal_date: date },
  });
};
