import { FastField, FieldProps } from "formik";

export interface BaseInputProps {
  inputProps?: { [k: string]: any };
  name: string;
  label: string;
  placeholder?: string;
  className?: string;
  onChange?: (...params: any[]) => void;
}

export interface SelectItem {
  label: string;
  value: any;
}

export interface InputProps extends BaseInputProps {}
export interface SelectProps extends BaseInputProps {
  options: SelectItem[];
}

export const FormikTextInput: React.FC<InputProps> = (props) => {
  const { name, label, placeholder, inputProps, className } = props;

  return (
    <FastField name={name}>
      {({ field, meta: { error, touched } }: FieldProps) => {
        const inputClass = `${className || ""} ${error && touched ? "form-control is-invalid" : "form-control "}`;

        return (
          <div className="form-group">
            {label && <label>{label}</label>}
            <div className="mar-gin"></div>
            <input
              className={inputClass}
              name={name}
              placeholder={placeholder || label}
              value={field.value !== null ? field.value : ""}
              onChange={field.onChange}
              onBlur={field.onBlur}
              {...inputProps}
            />
            {error && touched && <div className="invalid-feedback">{error}</div>}
          </div>
        );
      }}
    </FastField>
  );
};

export const FormikSelect: React.FC<SelectProps> = (props) => {
  const { name, label, options, inputProps: selectProps, className, onChange } = props;

  return (
    <FastField name={name}>
      {(fieldProps: FieldProps) => {
        const {
          field,
          meta: { error, touched },
        } = fieldProps;

        const inputClass = `${className || ""} ${error && touched ? "form-select is-invalid" : "form-select "}`;

        return (
          <div className="form-group">
            <label>{label}</label>
            <div className="mar-gin"></div>
            <select
              className={inputClass}
              name={name}
              value={field.value}
              onChange={(e) => {
                field.onChange(e);
                onChange?.(e.target.value);
              }}
              onBlur={field.onBlur}
              {...selectProps}
            >
              <option value="">-------------</option>
              {options.map((item, index) => (
                <option key={`${name}_${index}`} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {error && touched && <div className="invalid-feedback">{error || ""}</div>}
          </div>
        );
      }}
    </FastField>
  );
};
