import ForgotPassword from "components/ForgotPassword/ForgotPassword";
import PatientPDF from "components/PhysicianLayout/Report/PatientjsPDF";
import { PrivateRoute } from "components/Route";
import { FourOhFour } from "components/Route/404";
import ReviewScreen from "layouts/ReviewScreen";
import { Route, Switch, useLocation } from "react-router-dom";
import Login from "../components/Login/Login";
import * as routes from "../constants/routes";
import Home from "../layouts/Home";
import Checkout from "layouts/Checkout/Checkout";

function Router() {
  const location: any = useLocation();
  return (
    <Switch>
      <Route exact path="/" location={location}>
        <Login />
      </Route>
      <Route path={routes.FORGOT_PASSWORD}>
        <ForgotPassword />
      </Route>

      <Route exact path={routes.CHECKOUT}>
        <Checkout />
      </Route>
      <Route exact path={routes.STRIPE_SUCCESS}>
        <Checkout success />
      </Route>

      <Route path={routes.CHECKOUT_COMPAT}>
        <Checkout />
      </Route>

      <PrivateRoute path={routes.HOME}>
        <Home />
      </PrivateRoute>

      <Route>
        <FourOhFour />
      </Route>
    </Switch>
  );
}

export default Router;
