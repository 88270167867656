import { useState } from "react";
// import TableConstant from './TableConstant'
import SearchModule from "components/SearchModule";
import PaginatedTable from "components/Table/PaginatedTable";
import { MODULES } from "constants/modules";
import { auth } from "firebase-config";
import { onAuthStateChanged } from "firebase/auth";
import { usePermissionCheck } from "hooks/useCheckPermission";
import { TableConstants, TableConstantsCancelled, TableConstantsReview } from "layouts/Assigned/TableConstants";
import _ from "lodash";
import { useQuery, useQueryClient } from "react-query";
import { fetchAssignedReviews } from "redux/Assigned/api";
import config from "../../config";
import "./Assigned.scss";

const Assigned = ({ active, permissionList }: any) => {
  const [getEmail, setGetEmail] = useState("");
  const [view, setView] = useState(null);

  onAuthStateChanged(auth, (currentUser: any) => {
    setGetEmail(currentUser?.email);
  });
  const [page, setPage] = useState(1);
  const [paginateBy, setPaginateBy] = useState(config.PAGE_SIZE);
  const [sortField, setSortField] = useState("capture_date");
  const [sortDirection, setSortDirection] = useState("desc");
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState("");

  const reviewer = usePermissionCheck("reviewer");

  const { isLoading, isError, error, data, isFetching, isPreviousData } = useQuery(
    ["projects", page, paginateBy, active, searchQuery, sortField, sortDirection, getEmail, reviewer],
    () => fetchAssignedReviews(page, paginateBy, active, searchQuery, sortField, sortDirection, getEmail, reviewer),
    { keepPreviousData: true },
  );
  const pageData = data && !_.isEmpty(data.data?.data) ? data.data?.data : [];
  const totalCount = (data && data.data?.total_count) || 0;
  const current_page_number = (data && data.data?.current_page_number) || 0;
  const handlePagination = (page: number) => {
    setPage(page);
  };
  const handleEntries = (pagesize: number) => {
    setPaginateBy(pagesize);
  };
  const handleSort = (key: string, direction: string) => {
    setSortField(key);
    setSortDirection(direction);
  };
  const handleSearch = (SearchKey: any) => {
    setSearchQuery(SearchKey);
  };

  const handleView = (data: any) => {
    setView(data);
  };

  return (
    <>
      <div className="searchAssigned">
        <h2>Assigned</h2>
        <SearchModule handleSearch={handleSearch} />
      </div>
      <PaginatedTable
        cols={
          active === "0"
            ? TableConstantsReview(handleView, permissionList)
            : active === "1"
            ? TableConstants(handleView, permissionList)
            : TableConstantsCancelled(handleView, permissionList)
        }
        data={pageData}
        isDark={false}
        bordered
        striped={false}
        hoverable
        entries={paginateBy}
        onRowClick={() => {}}
        //selected={selectedRowID}
        componentName={MODULES.ASSIGNED}
        handlePagination={handlePagination}
        handleEntries={handleEntries}
        currentPage={current_page_number}
        totalCount={totalCount}
        size="lg"
        isDataLoading={isFetching}
        handleSort={handleSort}
        isPaginationRequired={true}
        initialSortConfig={{ key: sortField, direction: sortDirection }}
      />
    </>
  );
};

export default Assigned;
