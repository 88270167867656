import AccountMenu from "components/AccountMenu/AccountMenu";
import { useLayoutEffect, useRef } from "react";
import { ReactComponent as Logo } from "../../assets/brand/logo.svg";

const NavBar = () => {
  const navbarRef = useRef<HTMLDivElement>(null);

  // setting navbar height for css
  useLayoutEffect(() => {
    const rootElement = document.documentElement;
    if (navbarRef.current) {
      const navbar = navbarRef.current;
      const navbarHeight = navbar.getBoundingClientRect()?.height;
      rootElement.style.setProperty("--navbar-height", `${navbarHeight}px`);
    }
  }, []);

  return (
    <div className="mhc-navbar" ref={navbarRef}>
      <div
        className="brand-wrapper"
        onClick={() => {
          window.location.reload();
        }}
      >
        <Logo className="brand-logo" />
        <span className="brand-name">MyHairCounts</span>
      </div>
      {/* <div className="misc-wrapper">
        <HqmIcon name="bell" size={25} />
        <HqmIcon name="user" size={25} />
       
      </div> */}
      {/* <div style={{ paddingRight: "1rem" }}>
      {/* <div style={{ paddingRight: "1rem" }}>
        <AccountMenu />
      </div> */}
    </div>
  );
};

export default NavBar;
