import eye from '../../assets/brand/eye.png'
import ReviewView from 'layouts/ReviewView/ReviewView'
import HqmIcon from 'components/icon'
import mhcTheme from '../../assets/style/style.module.scss'
import axios from 'axios'
import { useEffect, useState } from 'react'

export const TableConstantsLog = () => {
  return [
    {
      title: 'Id',
      name: 'id',
      sortable: false,
      render: (rowData: any) => {
        
        return <span>{rowData?.id}</span>
      },
    },
    {
        title: 'Log',
        name: 'log',
        sortable: false,
        render: (rowData: any) => {
         
          return <span>{rowData?.log}</span>
        },
      },
      {
        title: 'Updated At',
        name: 'UpdatedAt',
        sortable: false,
        render: (rowData: any) => {
         
          return <span>{rowData?.updated_at.substring(0,10)}</span>
        },
      },
    
  ]
}
