import { SelectItem } from "layouts/CompanyDetails/components/TextInput";
import { useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import Select, { MultiValue } from "react-select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postEmployeePermission } from "redux/PermissionGroups/PermissionGroups";
import { fetchPermissionGroups, fetchUsers } from "redux/users/api";
import "./../../assets/style/screens/_Home.scss";
import "./user.scss";

type SelectItemExtra = SelectItem & {
  isFixed?: boolean;
};

const ViewUser: React.FC = () => {
  let history = useHistory();
  const { id }: any = useParams();
  const { data: permissionResGroups } = useQuery("fetch permission groups", fetchPermissionGroups);
  const { data: userResData } = useQuery(["fetch user data ", id], () => id && fetchUsers({ id }), {
    refetchOnMount: true,
  });

  const loading = false;

  const userData = userResData?.data?.[0];
  console.log("userData", userData);
  const { mutate: updateUserPermissionGroups, isLoading: updateLoading } = useMutation(
    (groupIds: number[]) => postEmployeePermission(payload),
    {
      onSuccess: (data) => {
        toast.success("User permission updated successfully", {
          autoClose: 3000,
          pauseOnHover: false,
          theme: "colored",
        });
        setTimeout(() => {
          history.goBack();
        }, 1000);
        console.log("permission success working");
      },
      onError: (error) => {
        console.log("permission error", error);
      },
    },
  );
  const permissionGroups = useMemo(() => {
    if (!permissionResGroups) return;

    return permissionResGroups.map((item: any) => ({ label: item.name, value: item.id }));
  }, [permissionResGroups]);

  const [selectedPermissionGroups, setSelectedPermissionGroups] = useState<SelectItemExtra[]>([]);

  useEffect(() => {
    if (!permissionResGroups || !userData) return;

    const selectedGroups = permissionGroups.filter((group: { value: any }) => userData.groups.includes(group.value));
    const formattedGroups = selectedGroups.map((group: { label: any; value: any }) => ({
      label: group.label,
      value: group.value,
    }));

    setSelectedPermissionGroups(formattedGroups);
  }, [permissionGroups, permissionResGroups, userData]);

  // console.log("initialSelectedPermissionGroups", initialSelectedPermissionGroups);
  // const onChange = {};
  const onChange = (selectedValues: MultiValue<SelectItemExtra>) => {
    const selectedGroupIds = selectedValues.map((item) => item.value);

    // Update the selected permission groups in the component's state
    setSelectedPermissionGroups(selectedValues.slice());
    // setSelectedPermissionGroups(initialSelectedPermissionGroups);

    console.log("selectedGroupIds", selectedGroupIds);
    // const payload = {
    //   userId: userData?.id,
    //   groupIds: selectedGroupIds,
    // };
    // console.log("payload", payload);
    // console.log("permissionGroups", selectedPermissionGroups);
  };
  const groupIds = selectedPermissionGroups.map((group) => group.value);

  const allGroupIds = [...groupIds];
  const payload = {
    userId: userData?.id,
    groupIds: allGroupIds,
  };
  // console.log("payload", payload);
  // console.log("permissionGroups", selectedPermissionGroups);

  const handleSaveButtonClick = () => {
    const groupIds = selectedPermissionGroups.map((group) => group.value);

    // Update the user's permission groups in the backend
    updateUserPermissionGroups(groupIds);
  };

  return (
    <div className="view-user">
      <span>User</span> <span> {userData?.username}</span>
      <div className="form-group">
        <label htmlFor="">Username</label>
        <input disabled className={"form-control"} name="first_name" value={userData?.username} />
      </div>
      <hr />
      <div className="form-group">
        <label htmlFor="">First Name</label>
        <input disabled className={"form-control"} name="first_name" value={userData?.first_name} />
      </div>
      <hr />
      <div className="form-group">
        <label htmlFor="">Last Name</label>
        <input disabled className={"form-control"} name="first_name" value={userData?.last_name} />
      </div>
      <hr />
      <div className="form-group">
        <label htmlFor="">Email</label>
        <input disabled className={"form-control"} name="first_name" value={userData?.email} />
      </div>
      <hr />
      <div className="form-group permission-groups">
        <label>Permission Groups</label>

        <Select
          styles={{
            container: (base) => ({ ...base, flex: 1 }),
          }}
          isMulti
          options={permissionGroups}
          value={selectedPermissionGroups}
          onChange={onChange}
        />
      </div>
      <div className="form-group module-action">
        <button className="module-add-button" onClick={handleSaveButtonClick}>
          <span style={{ marginRight: loading ? "0.5rem" : 0 }}>Save</span>{" "}
          {loading && <Spinner animation="border" size="sm" />}
        </button>

        <button
          onClick={() => {
            history.goBack();
          }}
          className="module-add-button cancel"
        >
          Cancel
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ViewUser;
