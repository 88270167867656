import { formatDate } from "components/SummaryTable/helpers";
import { forwardRef, ReactNode, useCallback, useEffect, useMemo, useRef } from "react";
import { RowCellItem, RowHeaderItem } from "./types";
import head1 from "assets/brand/head1.svg";
import head2 from "assets/brand/head2.svg";
import head3 from "assets/brand/head3.svg";
import head4 from "assets/brand/head4.svg";
import head5 from "assets/brand/head5.svg";
import head6 from "assets/brand/head6.svg";
import head7 from "assets/brand/head7.svg";
import head8 from "assets/brand/head8.svg";
import head9 from "assets/brand/head9.svg";
import head10 from "assets/brand/head10.svg";
import head11 from "assets/brand/head11.svg";
import head12 from "assets/brand/head12.svg";

const baseUrl = "https://pub-ba15d364f51b4751a0d8e421a027cea8.r2.dev";
const logoPath = `${baseUrl}/Logo.png`;

/**
 * PDF header
 * @returns
 */
export const Header = () => {
  return (
    <div className="pdf-header">
      <img src={logoPath} className="logo" alt="Logo" />
      <span> Date: {formatDate(new Date())}</span>
    </div>
  );
};

/**
 * PDF page
 */
export type PageProps = {
  children: ReactNode;
  width?: number;
  height?: number;
  maxHeight?: number;
  className?: string;
};

export const Page = forwardRef((props: PageProps, ref: any) => {
  const { children, width, height, maxHeight, className } = props;

  return (
    <div className={`page ${className}`} style={{ width, minHeight: height, maxHeight }} ref={ref}>
      <Header />
      <div className="content">{children}</div>
    </div>
  );
});

/**
 * PDF image props
 */
export type BNAImageProps = {
  section: string;
  date?: string;
  image?: string;
  individualScores?: {
    anagen_follicles: number;
    dandruff: number;
    dormant_follicles: number;
    gray_hair: number;
    haircapture: number;
    remarks: string;
    terminal_hair: number;
    vellus_hair: number;
    comment1: string;
    comment2: string;
    comment3: string;
  };
  type: "baseline" | "latest";
};

const fetchImage = async (image: string) => {
  const blob = await fetch(image, { cache: "no-store" })
    .then((res) => res.blob())
    .catch();
  if (!blob) return "";

  const url: string = await new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onloadend = (e) => {
      resolve(fileReader.result as string);
    };
    fileReader.onerror = reject;
    fileReader.readAsDataURL(blob);
  });

  return url || "";
};

export const BNAPDFImage = (props: BNAImageProps) => {
  const { type, section, image, individualScores } = props;

  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    fetchImage(image || "").then((url) => {
      if (imageRef.current && url) {
        imageRef.current.src = url;
      }
    });
  }, [image]);

  const tableHeaders: RowHeaderItem[] = [
    {
      key: "empty",
      value: type === "baseline" ? "Baseline" : "Latest",
    },
    {
      key: "data",
      value: "Scores",
    },
  ];

  const tableData = useMemo(() => {
    const tableData: RowCellItem[] = [];

    if (!individualScores) return [];

    const {
      anagen_follicles,
      dandruff,
      dormant_follicles,
      gray_hair,
      terminal_hair,
      vellus_hair,
      remarks,
      comment1,
      comment2,
      comment3,
    } = individualScores;

    if (section === "crown" || section === "front" || section === "back" || section === "left" || section === "right") {
      remarks !== undefined && tableData.push({ empty: "Observation", data: remarks });
      comment1 !== undefined && tableData.push({ empty: "Comment 1", data: comment1 });
      comment2 !== undefined && tableData.push({ empty: "Comment 2", data: comment2 });
      comment3 !== undefined && tableData.push({ empty: "Comment 3", data: comment3 });
    } else {
      anagen_follicles !== undefined && tableData.push({ empty: "Anagen Follicles", data: anagen_follicles });
      dandruff !== undefined && tableData.push({ empty: "MyHairStatus", data: dandruff });
      dormant_follicles !== undefined && tableData.push({ empty: "Dormant Follicles", data: dormant_follicles });
      gray_hair !== undefined && tableData.push({ empty: "MyGrowthScore", data: gray_hair });
      terminal_hair !== undefined && tableData.push({ empty: "Terminal Hair", data: terminal_hair });
      vellus_hair !== undefined && tableData.push({ empty: "Vellus Hair", data: vellus_hair });
      remarks !== undefined && tableData.push({ empty: "Observation", data: remarks });

      comment1 !== undefined && tableData.push({ empty: "Comment 1", data: comment1 });
      comment2 !== undefined && tableData.push({ empty: "Comment 2", data: comment2 });
      comment3 !== undefined && tableData.push({ empty: "Comment 3", data: comment3 });
    }

    return tableData;
  }, [individualScores, section]);

  return (
    <div className="bna-image-container">
      <div className="image-container">
        <img src={image} alt="" className="image" ref={imageRef} />
      </div>
      <div className="individual-scores">
        <Table h={tableHeaders} r={tableData} />
      </div>
    </div>
  );
};

/**
 * Head section map
 */
export const headSectionMap: Record<string, string> = {
  "1C": head1,
  "1G": head2,
  "1F": head3,
  crown: head4,
  front: head5,
  back: head6,
  right: head7,
  left: head8,
  "1B": head9,
  "1E": head10,
  "1D": head11,
  "1A": head12,
};

/**
 * PDF table components
 * @param props
 * @returns
 */

export const Table = (props: { h: RowHeaderItem[]; r: RowCellItem[] }) => {
  const { r, h } = props;

  return (
    <table className="pdf-table">
      <thead>
        <tr className={"table_row"}>
          {h.map((item, index) => (
            <th key={`${item.key}_${index}`} className={"table_cell table_header"}>
              {item.value ?? "-"}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {r.map((row, index) => (
          <tr className={`table_row`} key={`${JSON.stringify(row)}_${index}`}>
            {h.map((header, index) => {
              if (header?.bold) {
                return (
                  <th key={`${JSON.stringify(header)}_${index}`} className={`table_cell`}>
                    <span className={`text`}>{row[header.key] ?? "-"} </span>
                  </th>
                );
              }

              return (
                <td key={`${JSON.stringify(header)}_${index}`} className={`table_cell`}>
                  {" "}
                  <span className={`text`}>{row[header.key] ?? "-"} </span>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
