import ReviewView from "layouts/ReviewView/ReviewView";
import eye from "../../assets/brand/Group.png";
import logo1 from "../../assets/brand/1A.png";
import logo2 from "../../assets/brand/1B.png";
import logo3 from "../../assets/brand/1C.png";
import logo4 from "../../assets/brand/1D.png";
import logo5 from "../../assets/brand/1E.png";
import logo6 from "../../assets/brand/1F.png";
import logo7 from "../../assets/brand/1G.png";
import logo_front from "../../assets/brand/front.png";
import logo_back from "../../assets/brand/back.png";
import logo_left from "../../assets/brand/left.png";
import logo_right from "../../assets/brand/right.png";
import logo_top from "../../assets/brand/top.png";
import HqmIcon from "components/icon";
import mhcTheme from "../../assets/style/style.module.scss";
import "./reviewview.scss";
// import InfoIcon from "@mui/icons-material/Info";

// export const TableConstantsR = (handleviewview: Function, handleOpenModal: Function) => {
export const TableConstantsR = () => {
  return [
    {
      title: "Section",
      name: "Section",
      sortable: false,
      render: (rowData: any) => {
        let img_src = `${rowData?.haircapture.head_section_code}`;
        if (img_src === "1A") {
          img_src = logo1;
        } else if (img_src === "1B") {
          img_src = logo2;
        } else if (img_src === "1C") {
          img_src = logo3;
        } else if (img_src === "1D") {
          img_src = logo4;
        } else if (img_src === "1E") {
          img_src = logo5;
        } else if (img_src === "1F") {
          img_src = logo6;
        } else if (img_src === "1G") {
          img_src = logo7;
        }
        return (
          <span>
            {" "}
            <img className="head-section-image" src={img_src} alt="imag" />
          </span>
        );
      },
    },
    {
      title: "Anagen Follicles",
      name: "AnagenFollicles",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.anagen_follicles}</span>;
      },
    },
    {
      title: "Dormant Follicles",
      name: "dormantfollicles",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.dormant_follicles}</span>;
      },
    },
    {
      title: "MyHairStatus",
      name: "dandruff",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.dandruff}</span>;
      },
    },
    {
      title: "Terminal Hair",
      name: "terminalhair",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.terminal_hair}</span>;
      },
    },
    {
      title: "Vellus Hair",
      name: "vellus hair",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.vellus_hair}</span>;
      },
    },
    {
      title: "MyGrowthScore",
      name: "grayhair",
      sortable: false,
      render: (rowData: any) => {
        return <span>{rowData?.gray_hair}</span>;
      },
    },
    {
      title: "Remarks",
      name: "remarks",
      sortable: false,
      render: (rowData: any) => {
        let remark = rowData?.remarks ? rowData?.remarks.substring(0, 7) + " ..." : "N/A";
        return <span>{remark}</span>;
      },
    },
    // for Future reference on Photo review priority
    // {
    //   title: (
    //     <>
    //       Review Photos{" "}
    //       <InfoIcon
    //         titleAccess="Photo Review Priorities"
    //         style={{ margin: "auto", cursor: "pointer", paddingLeft: "0.3rem" }}
    //         onClick={() => handleOpenModal()}
    //       />
    //     </>
    //   ),
    //   name: "request_for_analysis",
    //   sortable: false,
    //   render: (rowData: any) => {
    //     return (
    //       <span
    //         className={
    //           rowData?.haircapture?.request_for_analysis === "PRIORITY"
    //             ? "review-badge review-error"
    //             : rowData?.haircapture?.request_for_analysis === "YES"
    //             ? "review-badge review-warning"
    //             : rowData?.haircapture?.request_for_analysis === "NO"
    //             ? "review-badge review-no"
    //             : ""
    //         }
    //       >
    //         {rowData?.haircapture?.request_for_analysis === "PRIORITY"
    //           ? "Yes"
    //           : rowData?.haircapture?.request_for_analysis === "YES"
    //           ? "Yes"
    //           : rowData?.haircapture?.request_for_analysis === "NO"
    //           ? "No"
    //           : ""}
    //       </span>
    //     );
    //   },
    // },

    // {
    //   title: "Action",
    //   name: "mobile",
    //   sortable: false,
    //   render: (rowData: any) => {
    //     return (
    //       <HqmIcon
    //         name="edit"
    //         size={20}
    //         style={{ color: mhcTheme.secondary }}
    //         onClick={() => handleviewview(rowData)}
    //       />
    //     );
    //   },
    // },
  ];
};
