import { Box, FormControlLabel, Switch } from "@material-ui/core";
import MHCAlert, { SNACKBAR_TYPE } from "components/MHCAlert";
import MHCSpinner from "components/MHCSpinner";
import { useSetStatus } from "context/statusContext";
import { ConnectedFocusError } from "focus-formik-error";
import { FormikProvider, useFormik } from "formik";
import { FormikSelect, FormikTextInput, SelectItem } from "layouts/CompanyDetails/components/TextInput";
import { Col, Row } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { createProduct, deleteProduct, fetchCategories, fetchProductID } from "redux/Products/api";
import * as Yup from "yup";
import "./../../assets/style/screens/_Home.scss";
import styles from "./Product.module.scss";

type SelectItemExtra = SelectItem & {
  isFixed?: boolean;
};

export type ProductTypes = {
  product_name: string;
  product_desc: string;
  treatment_category_id: string | number;
  // treatment_category: {
  // category_name: string;
  // category_desc: string;
  // category_code: string;
  is_active: boolean;
  // icon: string;
  // number_of_products: number;
  // };
};

const ProductForm = (props: { add?: boolean }) => {
  const { add = false } = props;
  let history = useHistory();
  const { id }: any = useParams();

  //   const { data: permissionResGroups } = useQuery("fetch permission groups", fetchPermissionGroups);

  const { data: categoryData } = useQuery(["fetch categories "], async () => {
    const response = await fetchCategories();
    if (!response) throw new Error("Error in finding Product Categories");

    return response.map((item: any) => ({
      label: item.category_desc,
      value: item.id,
      treatment_category_id: item.id,
    }));
  });
  // const categoryData1 = categoryData || [];

  const { data: productsData, isLoading } = useQuery(["fetch user data ", id], () => id && fetchProductID(id), {
    refetchOnMount: id ? true : false,
  });
  const setStatus = useSetStatus();

  const handleProductDelete = () => {
    deleteProducts(id);
  };

  const { mutate: deleteProducts } = useMutation(deleteProduct, {
    onSuccess() {
      setStatus(SNACKBAR_TYPE.SUCCESS, "Deleted product successfully");
      setTimeout(() => {
        history.goBack();
      }, 1000);
    },
    onError(error: any) {
      setStatus(SNACKBAR_TYPE.ERROR, error?.response?.data?.message);
    },
  });

  const { mutate: createNewProduct } = useMutation(createProduct, {
    onSuccess() {
      setStatus(SNACKBAR_TYPE.SUCCESS, "Created new product successfully");
      setTimeout(() => {
        history.goBack();
      }, 1000);
    },
    onError(error: any) {
      (error as any)?.response?.data?.description?.errors?.forEach((err: any) => {
        let v: any = Object.values(err)[0];
        setStatus(SNACKBAR_TYPE.ERROR, v || (error as any)?.response?.data?.message || "Something went wrong");
      });
    },
  });

  const { mutate: updateExistingProduct } = useMutation(createProduct, {
    onSuccess() {
      setStatus(SNACKBAR_TYPE.SUCCESS, "Updated product successfully");
      setTimeout(() => {
        history.goBack();
      }, 1000);
    },
    onError(error: any) {
      setStatus(SNACKBAR_TYPE.ERROR, error?.response?.data?.message);
    },
  });

  const initialValues: ProductTypes = {
    product_name: "",
    product_desc: "",
    treatment_category_id: "",
    // treatment_category: {
    // category_name: "",
    // category_desc: "",
    // category_code: "",
    is_active: false,
    // icon: "",
    // number_of_products: 0,
    // },
  };
  const apiInitial = { ...initialValues, ...productsData?.data } as ProductTypes;

  const formik = useFormik<ProductTypes>({
    enableReinitialize: true,
    initialValues: id ? productsData : apiInitial,
    validationSchema: Yup.object({
      product_name: Yup.string().required("Product Name is required"),
      product_desc: Yup.string().required("Product Description is required"),
      treatment_category_id: Yup.number().required("Category Name is required"),
      // treatment_category: Yup.object().shape({
      // category_name: Yup.string().required("Product Category is required"),
      // category_desc: Yup.string().required("Product Category Description is required"),
      // category_code: Yup.string().required("Product Category Code is required"),
      is_active: Yup.boolean().required("Product Category Status is required"),
      // icon: Yup.string(),
      // number_of_products: Yup.number().required("Product Category Number of Products is required"),
      // }),
    }),
    onSubmit: (values) => {
      // const updatedId = { ...values, treatment_category_id: Number(values?.treatment_category_id) };
      const payload = {
        ...values,
        treatment_category_id: Number(values?.treatment_category_id),
        // treatment_category: {
        //   ...values.treatment_category,
        //   number_of_products: Number(values?.treatment_category?.number_of_products),
        // },
      };
      if (add) {
        createNewProduct(payload);
      } else {
        updateExistingProduct({ id, ...payload });
      }
    },
  });

  // const category_id = formik?.values?.treatment_category?.category_name || "";

  // const filteredCategoryData = categoryData1.filter((item: any) => item.category_desc === category_id);
  // console.log("filteredCategoryData ", filteredCategoryData);

  // const cat_id = categoryData1.find((x: any) => x.label === category_id)?.treatment_category_id || 0;

  // useEffect(() => {
  //   formik.setFieldValue("treatment_category_id", cat_id);
  // }, [cat_id, formik]);
  // useEffect(() => {
  //   if (cat_id) {
  //     formik.setFieldValue("treatment_category_id", cat_id);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [cat_id]);

  return (
    <>
      <div className="view-user">
        <MHCAlert />
        <Box
          sx={{
            position: "absolute",
            height: "99%",
            width: "99%",
            display: isLoading ? "grid" : "none",
            alignItems: "center",
          }}
        >
          <MHCSpinner type="light" />
        </Box>
        <FormikProvider value={formik}>
          <ConnectedFocusError />
          <Row>
            <Col>
              <FormikTextInput name="product_name" label="Product Name" />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormikTextInput name="product_desc" label="Product Description" />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormikSelect label="Category Name" name="treatment_category_id" options={categoryData || []} />
            </Col>
            {/* {formik.errors &&
              formik.errors?.treatment_category_id &&
              formik.touched &&
              formik.touched?.treatment_category_id && (
                <div className="invalid-feedback">{formik.errors?.treatment_category_id || ""}</div>
              )} */}
          </Row>

          {/* <Row>
            <Col>
              <FormikTextInput name="treatment_category.category_desc" label="Category Description" />
            </Col>
          </Row> */}
          {/* <Row>
            <Col>
              <FormikTextInput name="treatment_category.category_code" label="Category Code" />
            </Col>
          </Row> */}
          {/* <Row>
            <Col>
              <FormikTextInput name="treatment_category.number_of_products" label="Number of Products" />
            </Col>
          </Row> */}
          {/* <Row>
            <Col>
              <FormikTextInput
                inputProps={{
                  disabled: true,
                }}
                name="treatment_category_id"
                label="Treatment Category Id"
              />
            </Col>
          </Row> */}
          <div className="form-group">
            <label>Status</label>
            <br />
            <FormControlLabel
              control={
                <Switch
                  name="is_active"
                  color="primary"
                  checked={formik?.values?.is_active || false}
                  onChange={(e: any) => {
                    formik.setFieldValue("is_active", e?.target?.checked);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Active"
              style={{
                color: "#000",
              }}
            />
          </div>
          <div style={{ marginTop: "2rem" }}>
            <div className={styles.button_gap}>
              <div className="module-action" style={{ gap: "1rem" }}>
                <button
                  className="module-add-button"
                  onClick={(e: any) => {
                    formik.handleSubmit(e);
                  }}
                >
                  {add ? "Create" : "Update"}
                </button>
                {!add && (
                  <button className="module-add-button" onClick={handleProductDelete}>
                    Delete
                  </button>
                )}
              </div>
              <div className="module-action">
                <button
                  onClick={() => {
                    history.goBack();
                  }}
                  className="module-add-button cancel"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </FormikProvider>
      </div>
    </>
  );
};

export default ProductForm;
