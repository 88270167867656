import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { useFormik } from "formik";
import _ from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { fetchPartnerData, fetchProduct, fetchProducts } from "redux/discount/api";
import * as Yup from "yup";
import "./CreateDiscountForm.scss";

const CreateDiscountForm = ({ discount, handleSubmitDiscount, openModal, change_productdiscount, onCancel }: any) => {
  const { data: partnerData } = useQuery(["PartnerData"], async () => {
    const res = await fetchPartnerData();
    return res?.data;
  });
  // const { data: partnerProduct } = useQuery(["ProductData"], fetchProduct);

  const { data: partnerProducts } = useQuery(["ProductDatas"], fetchProducts);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: discount
      ? discount
      : {
          discount_code: "",
          sku: [],
          start_date: moment().format("YYYY-MM-DD"),
          end_date: moment().format("YYYY-MM-DD"),
          discount_percentage: "",
          is_active: false,
          partner: "",
        },
    validationSchema: Yup.object({
      discount_code: Yup.string().required("Required"),
      sku: Yup.array().min(1).required("Required"),
      start_date: Yup.date().required("Required"),
      end_date: Yup.date().required("Required"),
      discount_percentage: Yup.number()
        .typeError("Discount code must be numeric")
        .required("Required")
        .max(100, "Discount cannot exceed 100%"),
      partner: Yup.string().required("Required"),
      is_active: Yup.boolean().required("Required"),
    }),

    onSubmit: (values: any) => {
      values = { ...values, start_date: new Date(values.start_date), end_date: new Date(values.end_date) };
      handleSubmitDiscount(values);
    },
  });
  useEffect(() => {
    if (openModal === false && formik?.resetForm) {
      formik.resetForm();
    }
  }, [openModal]);

  return (
    <>
      <h3 className="module-name top-txt">Create Discount Code</h3>
      <div className="typo-disc">
        <div className="outer-layer">
          <div className="out-layout">
            <Row className="marrginbtntbl">
              <Col>
                <div className="form-group">
                  <label>Discount Code</label>
                  <input
                    disabled={!change_productdiscount}
                    className={
                      formik.errors && formik.errors?.discount_code && formik.touched && formik.touched?.discount_code
                        ? "form-control is-invalid"
                        : "form-control "
                    }
                    name="discount_code"
                    value={formik.values?.discount_code || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    //placeholder = "ATI-XXXXX"
                  />
                  {formik.errors && formik.errors?.discount_code && formik.touched && formik.touched?.discount_code && (
                    <div className="invalid-feedback">{formik.errors?.discount_code || ""}</div>
                  )}
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label>Partner</label>
                  <select
                    disabled={!change_productdiscount}
                    className={
                      formik.errors && formik.errors?.partner && formik.touched && formik.touched?.partner
                        ? "form-select is-invalid"
                        : "form-select"
                    }
                    name="partner"
                    value={formik.values?.partner || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option>-------------</option>
                    {partnerData?.map((item: any) => (
                      <option key={item.id} value={item.id}>
                        {item.company_name}
                      </option>
                    ))}
                  </select>

                  {formik.errors && formik.errors?.partner && formik.touched && formik.touched?.partner && (
                    <div className="invalid-feedback">{formik.errors?.partner || ""}</div>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="marrginbtntbl">
              <Col>
                <div className="form-group">
                  <label>SKUs</label>
                  <FormGroup>
                    {partnerProducts?.map((theme: any, i: Number) => (
                      <FormControlLabel
                        key={theme.id}
                        control={
                          <input
                            disabled={!change_productdiscount}
                            type="checkbox"
                            name="sku"
                            checked={formik.values?.sku.includes(theme.sku)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                formik.setFieldValue("sku", [...formik.values.sku, theme.sku]);
                              } else {
                                formik.setFieldValue(
                                  "sku",
                                  formik.values.sku.filter((item: any) => item !== theme.sku),
                                );
                              }
                            }}
                            onBlur={formik.handleBlur}
                          />
                        }
                        label={theme.ps_name}
                      />
                    ))}
                  </FormGroup>
                </div>
              </Col>
            </Row>
            <Row className="marrginbtntbl">
              <Col>
                <div className="form-group">
                  <label>Discount Percentage</label>
                  <input
                    type={"number"}
                    min={1}
                    max={100}
                    disabled={!change_productdiscount}
                    style={{ width: "40%" }}
                    className={
                      formik.errors &&
                      formik.errors?.discount_percentage &&
                      formik.touched &&
                      formik.touched?.discount_percentage
                        ? "form-control is-invalid"
                        : "form-control "
                    }
                    name="discount_percentage"
                    value={formik.values?.discount_percentage || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="0"
                  />
                  {formik.errors &&
                    formik.errors?.discount_percentage &&
                    formik.touched &&
                    formik.touched?.discount_percentage && (
                      <div className="invalid-feedback">{formik.errors?.discount_percentage || ""}</div>
                    )}
                </div>
              </Col>
            </Row>
            <Row className="marrginbtntbl">
              <Col>
                <div className="form-group">
                  <label>Start Date</label>
                  <input
                    disabled={!change_productdiscount}
                    type={"date"}
                    className={
                      formik.errors && formik.errors?.start_date && formik.touched && formik.touched?.start_date
                        ? "form-control is-invalid"
                        : "form-control "
                    }
                    name="start_date"
                    value={moment(formik.values?.start_date).format("YYYY-MM-DD") || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}

                    //placeholder = "ATI-XXXXX"
                  />
                  {formik.errors && formik.errors?.start_date && formik.touched && formik.touched?.start_date && (
                    <div className="invalid-feedback">{formik.errors?.start_date || ""}</div>
                  )}
                </div>
              </Col>

              <Col>
                <div className="form-group">
                  <label>End Date</label>

                  <input
                    disabled={!change_productdiscount}
                    //type={'date'}
                    type={"date"}
                    className={
                      formik.errors && formik.errors?.end_date && formik.touched && formik.touched?.end_date
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    name="end_date"
                    value={moment(formik.values?.end_date).format("YYYY-MM-DD") || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}

                    //placeholder="------------"
                  />

                  {formik.errors && formik.errors?.end_date && formik.touched && formik.touched?.end_date && (
                    <div className="invalid-feedback">{formik.errors?.end_date || ""}</div>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="marrginbtntbl">
              <Col>
                <div className="form-group position">
                  <label>Is active</label>
                  <Checkbox
                    style={{ width: "4%" }}
                    disabled={!change_productdiscount}
                    className={
                      formik.errors && formik.errors?.is_active && formik.touched && formik.touched?.is_active
                        ? "form-control is-invalid"
                        : "form-control "
                    }
                    name="is_active"
                    //value={formik.values?.is_active === true}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values?.is_active === true}
                    //placeholder = "ATI-XXXXX"
                  />
                  {formik.errors && formik.errors?.is_active && formik.touched && formik.touched?.is_active && (
                    <div className="invalid-feedback">{formik.errors?.is_active || ""}</div>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="marrginbtntbl module-action">
              <Col>
                <button
                  disabled={!change_productdiscount}
                  type="submit"
                  className="module-add-button"
                  onClick={(e: any) => formik.handleSubmit(e)}
                >
                  {discount ? "Update" : "Save"}
                </button>
              </Col>
              <Col>
                <button type="button" className="module-add-button cancel" onClick={onCancel}>
                  Cancel
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateDiscountForm;
