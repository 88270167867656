import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebase-config";
import React from "react";
import "./styles.scss";
import docImg from "../../../assets/brand/avatar.svg";
import PatientListDrawer from "../PatientsListComponent/PatientListDrawer";
import { Avatar } from "@mui/material";

function PhysicianNavbar() {
  const [user] = useAuthState(auth);

  console.log("namee", user?.displayName);

  return (
    <>
      <div className="navbar-container">
        <PatientListDrawer />

        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          {user?.displayName ? (
            <>
              <div className="container-section1">
                <div className="credentials">{user?.displayName}</div>
                <div className="credentials">Hair Specialist</div>
              </div>
              {/* <div className="container-section2">
                <img src={docImg} alt="doc icon" style={{ width: "40px" }} />
              </div> */}
            </>
          ) : (
            <></>
            // <Avatar />
          )}
        </div>
      </div>
    </>
  );
}

export default PhysicianNavbar;
