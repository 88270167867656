import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import _ from "lodash";
import moment from "moment";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { fetchCaptureDates } from "redux/physicianReview/api";
import { RootState } from "redux/store";
import { theme } from "theme";

const formatDate = (date: Date | string) => moment(date).format("YYYY-MM-DD");

// const dates = ["2022-09-08", "2022-09-11", "2022-09-19"];
export default function BasicDatePicker({ parentCallback, displayDate, type }: any) {
  const patient_user_profile_id = useSelector((state: RootState) => {
    return state.physicianPortalReducer.patient_user_profile_id;
  });

  const [activeDate, setActiveDate] = useState<Date | string>(new Date(displayDate));
  // const [monthChange, setMonthChange] = useState<Date | string>(moment(new Date()).format("YYYY-MM-DD"));

  // returns {created_at, capture_date}
  // created_at to display selected dates
  // capture_date to send to server
  const { data: capturedDates } = useQuery(
    ["capturedDates", patient_user_profile_id],
    () => fetchCaptureDates(patient_user_profile_id),
    {
      refetchOnWindowFocus: false,
      onSuccess({ data: res }) {
        const data = res.sort((a: any, b: any) => (a < b ? -1 : 1));
        if (data && type === "baseline") {
          setActiveDate(data[0]?.capture_date);
        } else {
          setActiveDate(data[data?.length - 1]?.capture_date);
        }
      },
    },
  );

  const patientsCapturedData = useMemo(() => {
    // capture date list
    const list =
      capturedDates && !_.isEmpty(capturedDates.data) ? capturedDates.data?.map((i: any) => i?.capture_date) : [];
    return list;
  }, [capturedDates]);

  const customDayRenderer = (date: Date, selectedDates: Array<Date | null>, pickersDayProps: PickersDayProps<Date>) => {
    const stringifiedDate = formatDate(date);

    const selectedDate = selectedDates?.[0] && formatDate(selectedDates?.[0]);

    const isSelectedDate = stringifiedDate === selectedDate;

    if (!patientsCapturedData.includes(stringifiedDate)) {
      //dates replace to patientCapture date
      return <PickersDay {...pickersDayProps} disabled />;
    }
    return (
      <PickersDay
        {...pickersDayProps}
        style={{ backgroundColor: isSelectedDate ? theme.palette.primary.main : "#fdd05d" }}
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="Date"
        inputFormat="yyyy-MM-dd"
        value={activeDate}
        onChange={(newValue) => {
          if (newValue) {
            // uploaded date
            const date = formatDate(newValue);
            // send capture date
            parentCallback(date);
            setActiveDate(newValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            sx={{
              "& .MuiOutlinedInput-input": {
                padding: "10px",
                caretColor: "transparent",
              },
            }}
            InputProps={{
              disabled: true,
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: false,
              sx: { display: "none" },
            }}
            {...params}
          />
        )}
        renderDay={customDayRenderer}
        onMonthChange={(month) => {
          // setMonthChange(moment(month).format("YYYY-MM-DD") || "2022-8-1");
        }}
      />
    </LocalizationProvider>
  );
}
