import axios from "axios";
export const fetchPatientHistory = async (id: string | number) => {
  return axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method: "GET",
    url: `/review-comment/user/${id}`,
  });
};

export const postPatientInfo = async (values: any) => {
  return axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method: "POST",
    url: `/review-comment/user/${values?.id}`,//url: `/review-comment/user/${id}`,
    data: values,
  });
};
