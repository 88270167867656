import api from "api";

export const fetchLocationEmp = async (id: number) => {
  try {
    return await api({
      baseURL: process.env.REACT_APP_BASE_URL,
      method: "GET",
      // url: `/api/web/partner/v2/location/${id}`,
      url: `/partner/locations/${id}`,
    });
  } catch (e: any) {
    return e;
  }
};

export const editLocationData = async (values: any) => {
  return await api({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "PUT",
    url: `/partner/locations/${values?.id}/`,
    data: values,
  });
};

export const postLocationData = async (values: any) => {
  console.log("API CALLED");
  return await api({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "POST",
    url: `/partner/locations/`,
    data: values,
  });
};

export const deleteLocation = async (id: number) => {
  return await api({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "DELETE",
    url: `/partner/locations/${id}/`,
  });
};
