import axios from "axios";

export const fetchBsic = async () => {
  return axios({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "GET",
    url: "partner/primary-business/",
  });
};

export const fetchLocationdata = async (id: number) => {
  return axios({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "GET",
    url: `partner/locations/?general_info_id=${id}`,
  });
};

export const fetchPrimaryLocationData = async () => {
  return axios({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "GET",
    url: "employee/list_approved_employees_names",
  });
};

export const fetchInfostate = async () => {
  return axios({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "GET",
    url: "user/state/",
  });
};

export const fetchCountriesData = async () => {
  return axios({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "GET",
    url: "user/country/",
  });
};

export const fetchTimeZone = async () => {
  return axios({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "GET",
    url: "api/web/partner/v2/timezone/",
  });
};

export const fetchlocation = async (id: number) => {
  return axios({
    baseURL: process?.env?.REACT_APP_BASE_URL,
    method: "GET",
    url: `partner/locations/${id}`,
  });
};
