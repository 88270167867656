export const HOME = "/home";
//checkout
export const CHECKOUT = "/checkout";
export const CHECKOUT_COMPAT = "/checkout/:id";
export const STRIPE_SUCCESS = "/checkout/success";
// employees
export const EMPLOYEES = "/home/employees";
export const EMPLOYEES_ACTIVE = "/home/employees/active";
export const EMPLOYEES_PENDING = "/home/employees/pending";
export const EMPLOYEES_ARCHIVED = "/home/employees/archived";

// partners
export const PARTNERS = "/home/partners";
export const PARTNERS_ACTIVE = "/home/partners/active";
export const PARTNERS_PENDING = "/home/partners/pending";
export const PARTNERS_ARCHIVED = "/home/partners/archived";
export const ADD_PARTNER = "/home/partners/add";
export const EDIT_PARTNER = "/home/partners/:id/edit";
// export const EDIT_PARTNERS_ACTIVE = "/home/partners/active/edit";
// export const EDIT_PARTNERS_PENDING = "/home/partners/pending/edit";

// reviews
export const REVIEWS = "/home/reviews";
export const REVIEWS_PENDING = "/home/reviews/pending";
export const REVIEWS_REVIEWED = "/home/reviews/reviewed";
export const REVIEWS_CANCELLED = "/home/reviews/cancelled";
export const REVIEWS_ARCHIVE = "/home/reviews/archive";
export const VIEW_REVIEWS_PENDING = "/home/reviews/pending/view";
export const VIEW_REVIEWS_REVIEWED = "/home/reviews/reviewed/view";
export const VIEW_REVIEWS_CANCELLED = "/home/reviews/cancelled/view";
export const REVIEW_VIEW = "/home/reviews/view";

// assigned
export const ASSIGNED_ACTIVE = "/home/assigned/active";
export const ASSIGNED_PENDING = "/home/assigned/pending";
export const ASSIGNED_CANCELLED = "/home/assigned/cancelled";
export const ASSIGNED_PENDING_VIEW = "/home/assigned/pending/view";
export const ASSIGNED_REVIEWED_VIEW = "/home/assigned/reviewed/view";
export const ASSIGNED_CANCELLED_VIEW = "/home/assigned/cancelled/view";

// referral
export const REFERRAL = "/home/referral";
export const REFERRAL_VIEW = "/home/referral/view";

// discount
export const DISCOUNT = "/home/discount";
export const CREATE_DISCOUNT_FORM = "/home/discount-form";

export const LOG_OUT = "/home/logout";
export const NEW_LOCATION = "/home/new-location";
export const FORGOT_PASSWORD = "/forgotPassword";
export const MULTI_LOCATION = "/home/multi-location";

// assigned
export const VIEW_ASSIGNED_PENDING = "/home/assigned/pending/view";
export const VIEW_ASSIGNED_REVIEWED = "/home/assigned/reviewed/view";
export const VIEW_ASSIGNED_CANCELLED = "/home/assigned/cancelled/view";

//for physician
export const PHYSICIAN_ROUTES = "/home/physician";
export const PATIENTS = "/home/physician/patient-in-review/patients-list";
export const PATIENTS_INACTIVE = "/home/physician/patient-reviewed-list";

//physician component view
export const PATIENTDATA = "/home/physician/in-review/patientdata";
export const PATIENT_HISTORY = "/home/physician/patienthistory";
export const PATIENT_LIST_DRAWER = "/home/physician/patientlistdrawer";
export const PATIENTS_COMMENT_HISTORY = "/home/physician/patients/comment_history";
//for physician
export const PATIENTREPORT = "/home/physician/in-reviewed/patientdata/report";
export const PATIENT_COMPLETED_REPORT = "/home/physician/patient-reviewed/report";
export const PATIENT_REVIEWED_DATA = "/home/physician/reviewed/patientdata";

// for users

export const USERS = "/home/users";
export const VIEW_USER = "/home/users/:id";

//for User Submitted Products
export const TREATMENT = "/home/user-submitted-product";
export const VIEW_TREATMENT = "/home/user-submitted-product/:id";

//for User Submitted Products
export const PRODUCTS = "/home/product";
export const EDIT_PRODUCTS = "/home/product/:id";
export const ADD_PRODUCTS = "/home/product-add";
