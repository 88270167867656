import { Checkbox, Divider } from "@mui/material";
import ShowMoreContent from "components/PhysicianLayout/Report/ShowMoreContent";
import { Form, FormikProvider, useFormik } from "formik";
import { useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { checkDiscountCode, createCheckoutSession, fetchProductDetails } from "redux/Checkout/api";
import * as Yup from "yup";
import { ReactComponent as Logo } from "../../assets/brand/logo.svg";
import styles from "./Checkout.module.scss";
import data from "components/PhysicianLayout/PatientList/dummydata";

function Checkout({ success }: any) {
  let history = useHistory();

  const { id: productId }: any = useParams();
  console.log("productId", productId);

  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const { userId, productID } = useMemo(() => {
    // const payload1 = urlParams.get("payload");
    // const payload = urlParams.get("payload");

    // try {
    //   if (payload) {
    //     const decodedString = Buffer.from(payload, "base64").toString("ascii");
    //     const decoded: any = JSON.parse(decodedString);
    //     console.log("decoded", decoded);

    //     return {
    //       email: decoded?.email || "",
    //       name: decoded?.fullName || "",
    //       productID: decoded?.productID,
    //       // productID: 8 || "",
    //     };
    //   }
    // } catch (error) {
    //   return {
    //     email: "",
    //     name: "",
    //     productID: 0,
    //   };
    // }

    // const fullName = urlParams.get("full_name");
    // const email = urlParams.get("email");
    //  */

    const itemId = !success ? productId : urlParams.get("product_id");

    return {
      userId: urlParams.get("userID"),
      productID: Number(itemId) || 0,
    };
  }, [productId, success, urlParams]);
  // console.log("userId", productID);

  const [message, setMessage] = useState<string>();

  const { data: productDetails } = useQuery(["Product Details ", productID], () => fetchProductDetails(productID));
  const { mutate: handleDiscountVerification } = useMutation(checkDiscountCode, {
    onSuccess: (data) => {
      if (data?.data?.is_active) {
        setMessage("Discount Code Available");
      }
    },
    onError() {
      setMessage("Discount Code Not Available");
    },
  });
  const {
    mutate: handleCheckout,
    // data: resData,
    // isSuccess: resSuccess,
    isError,
    error: createCheckoutSessionError,
  } = useMutation(createCheckoutSession, {
    onSuccess: (data) => {
      if (data.url) {
        window.location.replace(`${data.url}`);
      }
    },
  });

  const errorPayload = (createCheckoutSessionError as any)?.response?.data?.message;

  const [showDiscount, setShowDiscount] = useState(false);
  const handleDiscount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowDiscount(e.target.checked);
  };

  const formik = useFormik({
    initialValues: {
      discountCode: "",
    },
    validationSchema: Yup.object({
      discountCode: Yup.string(),
    }),
    onSubmit: (values) => {
      handleDiscountVerification(values);
    },
  });
  const handleSubmit = () => {
    if (message === "Discount Code Available") {
      const payload = {
        productId: productID,
        userId: userId,
        discountCode: formik.values.discountCode,
      };

      handleCheckout(payload);
    } else {
      const payload = {
        productId: productID,
        userId: userId,
      };
      handleCheckout(payload);
    }
  };
  const currency = "$";

  return (
    <>
      <ToastContainer />
      <div className={styles.Page}>
        <div
          className={styles.brand_wrapper}
          onClick={() => {
            window.location.reload();
          }}
        >
          <Logo className={styles.brand_logo} />
          <span className={styles.brand_name}>MyHairCounts</span>
        </div>
        <Divider sx={{ m: "1rem 0" }} />
        {success && (
          <div className={styles.txt_gap} style={{ color: "green", fontWeight: "700" }}>
            You have successfully purchased the product.
          </div>
        )}
        {/* <div>User Name:</div> */}
        {/* <div className={styles.txt_gap}>{name}</div> */}

        <h3 className={styles.heading}>Product Details:</h3>
        <div className={styles.txt_gap}>{productDetails?.data?.ps_name}</div>
        <h3 className={styles.heading}>Description:</h3>
        <div className={styles.txt_gap}>
          <div>{productDetails?.data?.ps_desc && <ShowMoreContent str={productDetails?.data?.ps_desc} />}</div>
        </div>
        <h3 className={styles.heading}>Price:</h3>
        <div className={styles.txt_gap}>
          {currency}
          {productDetails?.data?.list_price}
        </div>
        <h3 className={styles.heading}>Session Price:</h3>
        <div className={styles.txt_gap}>
          {currency}
          {productDetails?.data?.session_price}
        </div>
        {productDetails?.data?.discount && (
          <>
            <div>Discount:</div>
            <div className={styles.txt_gap}>{productDetails?.data?.discount}%</div>
          </>
        )}
        <Divider sx={{ m: "1rem 0" }} />
        {!success ? (
          <div>
            <div className={styles.checkbox_label}>
              <Checkbox
                color="primary"
                checked={showDiscount}
                onChange={handleDiscount}
                inputProps={{ "aria-label": "controlled" }}
              />
              <div>I have a Discount Code.</div>
            </div>{" "}
            {showDiscount && (
              <div>
                <div className={styles.input_field}>
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <div>Discount Code:</div>
                      <input
                        name="discountCode"
                        value={formik.values.discountCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Form>
                  </FormikProvider>
                  <div>
                    {" "}
                    <button
                      className={styles.ckt_btn}
                      onClick={(e: any) => {
                        formik.handleSubmit(e);
                        // handleApply(e)
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
                <div>
                  {message ? (
                    <div className={styles.txt_success}>{message}</div>
                  ) : (
                    <div className={styles.txt_fail}>{message}</div>
                  )}
                </div>
              </div>
            )}
            <span className={styles.txt_fail}> {isError && "* " + errorPayload}</span>
            <div
              className={`${styles.txt_gap} module-action`}
              style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              <button
                className={`${styles.ckt_btn} module-add-button`}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Checkout
              </button>
            </div>
          </div>
        ) : (
          <div
            className={`${styles.txt_gap} module-action`}
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <button
              className={`${styles.ckt_btn} module-add-button`}
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default Checkout;
