import axios from "axios";
import { CaptureImageData } from "components/PhysicianComponent/PatientDataBNA/types";

export const fetchPhysicianReview = async (userProfileId: number, captureDate?: string) => {
  const res = await axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method: "GET",
    url: `/api/web/review/physician-reviews/`,
    params: { user_profile_id: userProfileId, capture_date: captureDate },
  });

  const data = res?.data as CaptureImageData[];

  if (data) {
    // captured dates
    return data.sort((a, b) => new Date(a.capture_date).getTime() - new Date(b.capture_date).getTime());
  }

  return data;
};

export const fetchCaptureDates = async (userProfileId: number, captureDate?: string | Date) => {
  return axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method: "GET",
    url: `/api/web/review/capture-date/`,
    params: { user_profile_id: userProfileId, capture_date: captureDate },
  });
};
