import { useHistory } from "react-router-dom";
import HqmIcon from "components/icon";
import mhcTheme from "../../assets/style/style.module.scss";
import "./Partners.scss";
import restoreIcon from "../../assets/brand/restoreIcon.svg";
import * as routes from "constants/routes";
import { usePermissionCheck } from "hooks/useCheckPermission";

export const TablePartners = (checkPartners: Function, handleView: Function, permissionList: any) => {
  const change_generalinformation = usePermissionCheck("change_generalinformation");
  const delete_generalinformation = usePermissionCheck("delete_generalinformation");
  const approve_generalinformation = usePermissionCheck("approve_generalinformation");
  let history = useHistory();

  return [
    {
      title: "ID",
      name: "partner_id",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.partner_id}</span>;
      },
    },
    {
      title: "Company Name",
      name: "company_name",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.company_name}</span>;
      },
    },
    {
      title: "Address",
      name: "address",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.address}</span>;
      },
    },
    {
      title: "Primary Contact",
      name: "primary_contact_info.mobile_number",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.primary_contact_info?.mobile_number}</span>;
      },
    },
    {
      title: "Primary Contact Email",
      name: "email",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.primary_contact_info?.email}</span>;
      },
    },

    {
      title: "Edit",
      name: "action",
      sortable: true,
      render: (rowData: any) => {
        if (change_generalinformation === true) {
          return (
            <span>
              <HqmIcon
                //disabled={!change_generalinformation}
                name="edit"
                size={20}
                style={{ color: mhcTheme.secondary }}
                onClick={() => {
                  // handleView(rowData);
                  history.push({ pathname: routes.EDIT_PARTNER.replace(":id", rowData?.id), state: { data: rowData } });
                }}
              />
            </span>
          );
        }
      },
    },
  ];
};

export const TablePartnersPending = (
  checkPartners: Function,
  checked: Array<number> = [],
  handleView: Function,
  permissionList: any,
) => {
  const change_generalinformation = usePermissionCheck("change_generalinformation");
  const delete_generalinformation = usePermissionCheck("delete_generalinformation");
  const approve_generalinformation = usePermissionCheck("approve_generalinformation");
  const history = useHistory();

  return [
    {
      title: "checkbox",
      name: "checkbox",
      notClickable: true,
      render: (rowData: any) => {
        return (
          <div className="form-check">
            <input
              disabled={!delete_generalinformation && !approve_generalinformation}
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              onChange={() => checkPartners(parseInt(rowData.id))}
              onClick={(e) => e.stopPropagation()}
              checked={checked.includes(parseInt(rowData.id))}
            />
          </div>
        );
      },
    },
    {
      title: "ID",
      name: "partner_id",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.partner_id}</span>;
      },
    },
    {
      title: "Company Name",
      name: "company_name",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.company_name}</span>;
      },
    },
    {
      title: "Address",
      name: "address",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.address}</span>;
      },
    },
    {
      title: "Primary Contact",
      name: "primary_contact_info.mobile_number",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.primary_contact_info?.mobile_number}</span>;
      },
    },
    {
      title: "Status",
      name: "status",
      sortable: true,
      render: (rowData: any) => {
        return (
          <span
            className={
              rowData?.status === "NEW"
                ? "badge badge-warning"
                : rowData?.status === "UPDATED"
                ? "badge badge-primary"
                : "badge badge-ca"
            }
          >
            {rowData?.status}
          </span>
        );
      },
    },
    {
      title: "Edit",
      name: "action",
      sortable: true,
      render: (rowData: any) => {
        if (change_generalinformation === true) {
          return (
            <span>
              <HqmIcon
                //disabled={!change_generalinformation}
                name="edit"
                size={20}
                style={{ color: mhcTheme.secondary }}
                onClick={() => {
                  handleView(rowData);
                  history.push({
                    pathname: routes.EDIT_PARTNER?.replace(":id", rowData?.id),
                    state: { data: rowData },
                  });
                }}
              />
            </span>
          );
        }
      },
    },
  ];
};

export const TablePartnersArchived = (
  checkSelectArchive: Function,
  checkedArchive: Array<number> = [],
  // handleView: Function,
  handleRestore: Function,
  handlePermanentlyDelete: Function,
) => {
  return [
    {
      title: "checkbox",
      name: "checkbox",
      notClickable: true,
      render: (rowData: any) => {
        return (
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              onChange={() => checkSelectArchive(parseInt(rowData.id))}
              onClick={(e) => e.stopPropagation()}
              checked={checkedArchive.includes(parseInt(rowData.id))}
            />
          </div>
        );
      },
    },
    {
      title: "ID",
      name: "partner_id",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.partner_id}</span>;
      },
    },
    {
      title: "Company Name",
      name: "company_name",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.company_name}</span>;
      },
    },
    {
      title: "Address",
      name: "address",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.address}</span>;
      },
    },
    {
      title: "Primary Contact",
      name: "primary_contact_info.mobile_number",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.primary_contact_info?.mobile_number}</span>;
      },
    },
    {
      title: "Primary Contact Email",
      name: "email",
      sortable: true,
      render: (rowData: any) => {
        return <span>{rowData?.primary_contact_info?.email}</span>;
      },
    },
    {
      title: "Restore",
      name: "restore",
      //sortable: true,
      render: (rowData: any) => {
        return (
          <img
            src={restoreIcon}
            alt="restoreIcon"
            style={{ maxWidth: "32px" }}
            onClick={() => handleRestore(rowData?.id)}
          />
        );
      },
    },
    {
      title: "Delete",
      name: "delete",
      render: (rowData: any) => {
        return (
          <HqmIcon
            name="trash"
            size={20}
            style={{ color: mhcTheme.secondary }}
            onClick={() => handlePermanentlyDelete(rowData?.id)}
          />
        );
      },
    },
  ];
};
